import { getTeamUrl } from 'Traqa/Helpers'

export default () => (dispatch, getState) => {

  const { formState, columnOffset } = getState().screens.PurchasePlanner.Report

  const { values } = formState

  const params = {
    search: values.search,
    stock_location_id: values.stockLocationId || undefined,
    supplier_id: values.supplierId || undefined,
    invoice_no: values.invoiceNo || undefined,
    column_offset: columnOffset,
  }

  let path = 'reports/purchase_planner/export?'

  Object.keys(params).forEach(key => {
    if (params[key] === undefined) return
    path = `${path}&${key}=${params[key]}`
  })

  window.location = getTeamUrl(path, 'exports')

}
