import { connect } from 'react-redux'
import ContentDetails from './ContentDetails'

import selectCalculatedCost from './Selectors/selectCalculatedCost'

const mapStateToProps = (state) => ({
  calculatedCost: selectCalculatedCost(state),
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(ContentDetails)
