import { combineReducers } from 'redux'

import NotificationPopover from './NotificationPopover/reducer'
import TransactionsTable from './TransactionsTable/reducer'
import TransactionsDrawer from './TransactionsDrawer/reducer'
import Activity from './Activity/reducer'
import StockshotsPopover from './StockshotsPopover/reducer'

export default combineReducers({
  NotificationPopover,
  TransactionsTable,
  TransactionsDrawer,
  Activity,
  StockshotsPopover,
})
