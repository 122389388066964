import { combineReducers } from 'redux'

import { reducer as initialValues } from './State/initialValues'
import { reducer as formState } from './State/formState'
import { reducer as data } from './State/data'
import { reducer as totals } from './State/totals'
import { reducer as isFetching } from './State/isFetching'
import { reducer as isFetchingTotals } from './State/isFetchingTotals'
import { reducer as interaction } from './State/interaction'

export default combineReducers({
  initialValues,
  formState,
  data,
  totals,
  isFetching,
  isFetchingTotals,
  interaction,
})
