import { connect } from "react-redux"
import Item from "./Item"

import selectIsNew from '../../../../Selectors/selectIsNew'
import createIsSelectedSelector from './Selectors/createIsSelectedSelector'
import selectSourceProducts from './Selectors/selectSourceProducts'

import onToggle from './Handlers/onToggle'

const mapStateToProps = (state, props) => {
  
  const selectIsSelected = createIsSelectedSelector()

  return {
    isNew: selectIsNew(state, props),
    isSelected: selectIsSelected(state, props),
    products: state.core.products,
    sourceProducts: selectSourceProducts(state, props),
  }
}

const actionCreators = {
  onToggle,
}

export default connect(mapStateToProps, actionCreators)(Item)
