export default (values) => () => {

  const errors = {}

  if (!values.date) errors.date = true

  if (!values.time) errors.time = true

  return errors

}
