import React from 'react'
import PropTypes from 'prop-types'
import moment from "moment"
import numeral from "numeral"
import { Table as AntTable } from 'antd'
import { NavLink } from 'react-router-dom'
import { getTeamUrl } from 'Traqa/Helpers'
import styles from "./Table.css"

import FulfilmentProgress from './Components/FulfillmentProgress'

const Table = (props) => {

  const {
    data,
    isFetching,
    onChange,
  } = props
  
  return (

    <AntTable
      dataSource={data}
      loading={isFetching}
      rowKey="id"
      pagination={false}
      onChange={onChange}
    >

      <AntTable.Column
        key='number'
        title="No."
        width={90}
        sorter
        render={(text, record) => (
          <NavLink key={record.id} to={getTeamUrl(`purchase_orders/${record.id}`)}>
            {record.number}
          </NavLink>
        )}
      />

      <AntTable.Column
        key='supplier.name'
        title="Supplier"
        sorter
        render={(text, record) => record.supplier.name}
      />

      <AntTable.Column
        key='description'
        title="Description"
        sorter
        render={(text, record) => record.description}
      />

      <AntTable.Column
        key='date'
        title="Date"
        width={120}
        sorter
        render={(text, record) => moment(record.date).format('D MMM YYYY')}
      />

      <AntTable.Column
        key='total_landed_cost'
        title="Landed Cost"
        sorter
        render={(text, record) => `£${numeral(record.total_landed_cost).format('0,0.00')}`}
        className={styles.numericColumn}
      />

      <AntTable.Column
        key='user.name'
        title="User"
        sorter
        render={(text, record) => record.user.name}
      />

      <AntTable.Column
        key='fulfilled_percentage'
        title="Fulfillment"
        width={340}
        sorter
        render={(text, record) => (
          <div className={styles.fulfillment}>
            <FulfilmentProgress purchaseOrder={record} />
          </div>
        )}
      />

    </AntTable>

  )
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Table