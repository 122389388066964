import intervalRef from '../intervalRef'
import checkForNotifications from './checkForNotifications'

export default () => (dispatch) => {
  
  const interval = 1000 * 15 // milliseconds x seconds
  
  const ref = setInterval(() => dispatch(checkForNotifications()), interval)
  
  intervalRef(ref)
  
  dispatch(checkForNotifications())
  
}