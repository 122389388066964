import React from 'react'
import { Module } from 'Traqa/Packages'

import Table from './Components/Table'

export default () => (

  <Module>

    <Module.Header
      title='Goods Out Records'
    />

    <Table />

  </Module>

)
