import { createAction, handleActions } from 'redux-actions'

const loadPurchaseOrders = createAction("CORE/PURCHASE_ORDERS/LOAD_PURCHASE_ORDERS")

const defaultState = []

const reducer = handleActions(
  {
    [loadPurchaseOrders]: (state, action) => action.payload,
  },
  defaultState
)

export { loadPurchaseOrders, reducer }