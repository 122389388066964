import { createAction, handleActions } from 'redux-actions'

const setLiveAssessment = createAction('SCREENS/PRODUCT/SET_LIVE_ASSESSMENT')
const resetLiveAssessment = createAction('SCREENS/PRODUCT/RESET_LIVE_ASSESSMENT')

const defaultState = null

const reducer = handleActions(
  {
    [setLiveAssessment]: (state, action) => action.payload,
    [resetLiveAssessment]: () => defaultState,
  },
  defaultState
)

export { setLiveAssessment, resetLiveAssessment, reducer }