import moment from 'moment'
import { getProducts } from '../../Api/products'
import { loadKits } from '../State/kits'

let lastGetTimestamp = null

const cacheTTL = 60000

export default ({ force } = {}) => async (dispatch) => {

  try {

    if (!force && lastGetTimestamp) {

      const now = Number(moment().format('x'))

      if (now < (lastGetTimestamp + cacheTTL)) return
    }

    lastGetTimestamp = Number(moment().format('x'))

    const params = {
      sort: 'code',
      include: 'product_categories',
    }

    params['filter[has_bill_of_materials]'] = true

    const response = await getProducts({ params })

    dispatch(loadKits(response.data.data))

  } catch (error) {

    throw error

  }
}
