import { connect } from 'react-redux'
import PlasticTaxRate from './PlasticTaxRate'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onSubmit from './Handlers/onSubmit'
import onDelete from './Handlers/onDelete'

const mapStateToProps = (state) => ({
  submitting: state.modals.PlasticTaxRate.Form.formState.submitting,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onSubmit,
  onDelete,
}

export default connect(mapStateToProps, actionCreators)(PlasticTaxRate)
