import screenProps from '../screenProps'
import { updateTransferInSage } from '../../../Api/transfers'
import { setTransfer } from '../State/transfer'
import fetch from './fetch'
//import fetchActivity from '../../../Modules/Activity/Services/fetch'
import { handleException } from 'Traqa/Packages'

export default (inSage) => async (dispatch, getState) => {

  try {

    dispatch(setTransfer({
      ...getState().screens.Transfer.transfer,
      in_sage: inSage,
    }))

    const { id } = screenProps().match.params

    const data = { in_sage: inSage }

    await updateTransferInSage({ id, data })

    await dispatch(fetch())

    //dispatch(fetchActivity())

  } catch (error) {

    handleException(error)

  }
}