import { createAction, handleActions } from 'redux-actions'

const setFormState = createAction('SCREENS/STOCK_ADJUSTMENTS/REPORT/SET_FORM_STATE')
const setFormFields = createAction('SCREENS/STOCK_ADJUSTMENTS/REPORT/SET_FORM_FIELDS')
const resetFormState = createAction('SCREENS/STOCK_ADJUSTMENTS/REPORT/RESET_FORM_STATE')

const defaultState = {
  values: {},
}

const reducer = handleActions(
  {
    [setFormState]: (state, action) => action.payload,
    [setFormFields]: (state, action) => ({
      ...state,
      values: {
        ...state.values,
        ...action.payload,
      },
    }),
    [resetFormState]: () => defaultState,
  },
  defaultState,
)

export { setFormState, setFormFields, resetFormState, reducer }
