import { connect } from 'react-redux'
import Component from './Component'

const mapStateToProps = (state) => ({
  showAdvanced: state.screens.Stocktakes.StocktakesTable.advanced.show,
})

const actionCreators = {}

export default connect(mapStateToProps, actionCreators)(Component)
