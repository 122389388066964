import React from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import { Divider } from 'antd'
import { LinkButton } from 'Traqa/Packages'
import styles from './GroupFooter.css'

const GroupFooter = (props) => {

  const {
    product,
    onAddItem,
  } = props
  
  return (

    <div className={styles.wrapper}>

      <LinkButton
        key={product.id}
        type="primary"
        icon="plus"
        onClick={() => onAddItem(product.id)}
        style={{ marginRight: 32 }}
      >
        Add
      </LinkButton>

      <Divider />

    </div>

  )
}

GroupFooter.propTypes = {
  product: PropTypes.object.isRequired,
  onAddItem: PropTypes.func.isRequired,
}

export default GroupFooter