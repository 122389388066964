import { resetInitialized } from '../State/initialized'
import { resetReading } from '../State/reading'
import resetEditor from '../Components/Editor/Services/reset'

export default () => async (dispatch) => {

  dispatch(resetInitialized())
  dispatch(resetReading())
  dispatch(resetEditor())
  
}