import React from 'react'
import PropTypes from 'prop-types'
import { Module } from 'Traqa/Packages'
import { Table, Button } from 'antd'
import moment from 'moment'
import numeral from 'numeral'
import styles from './Component.css'

export default class extends React.PureComponent {

  static propTypes = {
    product: PropTypes.object,
    isFetching: PropTypes.bool.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onRowClick: PropTypes.func.isRequired,
  }

  static defaultProps = {
    product: null,
  }

  render() {

    const {
      product,
      isFetching,
      onClickNew,
      onRowClick,
    } = this.props

    return (

      <Module style={{ borderTop: 'none' }}>

        <Module.Header
          renderRight={() => (
            <Button type='primary' onClick={onClickNew}>
              New Value
            </Button>
          )}
        />

        <Table
          dataSource={product ? product.product_values : []}
          loading={isFetching}
          rowKey='id'
          onRow={record => ({
            onClick: () => onRowClick(record),
          })}
          className={styles.table}
          pagination={false}
        >

          <Table.Column
            key='effective_date'
            title='Effective Date'
            render={(text, record) => moment(record.effective_date).format('DD MMM YYYY')}
          />

          <Table.Column
            key='value'
            title='Value'
            render={(text, record) => `£${numeral(record.value).format('0,0.[00000]')}`}
          />

          <Table.Column
            key='created_by_user.name'
            title='Created By'
            sorter
            render={(text, record) => record.created_by_user.name}
          />

          <Table.Column
            key='updated_by_user.name'
            title='Last Updated By'
            sorter
            render={(text, record) => record.updated_by_user.name}
          />

        </Table>

      </Module>

    )

  }

}
