export default (values) => () => {

  function isEmptyNullOrUndefined(value) {
    if (value === '') return true;
    if (value === null) return true;
    if (value === undefined) return true;
    return false;
  }

  const errors = {}

  if (!values.number) errors.number = "Required"

  if (!values.supplier_id) errors.supplier_id = "Required"

  if (!values.date) errors.date = "Required"

  if (!values.user_id) errors.user_id = "Required"

  if (!values.exchange_rate) errors.exchange_rate = "Required"

  if (Array.isArray(values.purchase_order_items)) {

    errors.purchase_order_items = values.purchase_order_items.map(item => {

      const itemErrors = {}

      if (!item.product_id) itemErrors.product_id = 'Required'

      if (!item.supplier_id) itemErrors.supplier_id = 'Required'

      if (isEmptyNullOrUndefined(item.landed_cost)) itemErrors.landed_cost = 'Required'
      
      if (!item.quantity) itemErrors.quantity = 'Required'

      return itemErrors
      
    })
  }

  return errors

}