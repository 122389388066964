import { combineReducers } from "redux"

import { reducer as data } from "./State/data"
import { reducer as isFetching } from "./State/isFetching"
import { reducer as searchTerm } from "./State/searchTerm"
import { reducer as advanced } from "./State/advanced"
import { reducer as sorting } from "./State/sorting"
import { reducer as pagination } from "./State/pagination"
import { reducer as activeTab } from "./State/activeTab"

export default combineReducers({
  data,
  isFetching,
  searchTerm,
  advanced,
  sorting,
  pagination,
  activeTab,
})
