import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button, Icon, Typography } from 'antd'
import Dropzone from 'react-dropzone'
import classNames from 'classnames'
import { Module } from 'Traqa/Packages'
import styles from './styles.css'

const Prompt = () => (

  <div className={classNames(styles.dropzone)}>

    <div className={styles.dropzoneBorder}>

      <span className={styles.dropzoneIcon}>
        <Icon type='upload' />
      </span>

      <div className={styles.dropzoneTitle}>
        Images
      </div>

      <div className={styles.dropzoneText}>
        Drop your files here to upload them
      </div>

    </div>

  </div>

)

const ImageViewer = props => {

  useEffect(() => {
    props.componentDidMount(props)
    return () => {
      props.componentWillUnmount()
    }
  }, [])

  const [index, setIndex] = useState(0)

  const {
    modelType,
    modelId,
    data,
    onDrop,
    onRename,
    onDelete,
    onClose,
  } = props

  const tabIndex = (increase) => {

    if (increase) {
      if (index === (data.length - 1)) {
        setIndex(0)
      } else {
        setIndex(index + 1)
      }
      return
    }

    if (index === 0) {
      setIndex((data.length - 1))
    } else {
      setIndex(index - 1)
    }

  }

  return (

    <Dropzone
      onDrop={files => onDrop(modelType, modelId, files)}
    >
      {({ getRootProps, getInputProps, open, isDragActive }) => {

        return (

          <div {...getRootProps()} onClick={() => null}>

            <input {...getInputProps()} />

            <Modal
              visible
              title='Images'
              width={900}
              footer={false}
              onCancel={onClose}
            >

              {isDragActive && <Prompt />}

              {!isDragActive && data.length === 0 && (

                <Module.Empty
                  title='Images'
                  text={(
                    <span>
                      {'Drag a file from your desktop or '}
                      <a onClick={open}>select a file</a>
                      {' to upload'}
                    </span>
                  )}
                />

              )}

              {!isDragActive && data.length > 0 && (

                <div className={styles.wrapper}>

                  <img height={600} src={data[index].image_url} />

                  <div className={styles.imageFooter}>

                    <Typography.Text ellipsis editable={{ onChange: (name) => onRename(data[index].id, name) }}>
                      {data[index].name}
                    </Typography.Text>

                    <div className={styles.deleteButton}>
                      <Button
                        size='small'
                        onClick={() => {
                          onDelete(data[index].id, index === data.length - 1 && tabIndex.bind(this))
                        }}
                      >
                        <Icon type='delete' style={{ color: '#FF4D4F' }} />
                      </Button>
                    </div>

                  </div>

                  <div className={styles.imageControls}>

                    <Button
                      onClick={() => tabIndex(false)}
                    >
                      <Icon type='left' />
                    </Button>

                    <div className={styles.counter}>{`${index + 1}/${data.length}`}</div>

                    <Button
                      onClick={() => tabIndex(true)}
                    >
                      <Icon type='right' />
                    </Button>

                  </div>

                  <a onClick={open} style={{ marginTop: 5 }}>Upload</a>

                </div>
                
              )}

            </Modal>

          </div>

        )

      }}
    </Dropzone>

  )

}

ImageViewer.propTypes = {
  modelType: PropTypes.string.isRequired,
  modelId: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  componentDidUpdate: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ImageViewer
