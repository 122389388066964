import { setMovementControls } from '../../../State/movementControls'
import { resetMovement } from '../../../State/movement'
import fetchMovement from '../../../Services/fetchMovement'

export default (field, value) => (dispatch) => {

  dispatch(setMovementControls({ [field]: value }))

  if (field === 'type') return

  dispatch(resetMovement())
  dispatch(fetchMovement())

}
