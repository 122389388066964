import { setSorting } from '../../../State/sorting'
import fetch from '../../../Services/fetch'

export default (pagination, filters, sorting) => async (dispatch) => {

  dispatch(setSorting({
    columnKey: sorting.columnKey,
    descending: sorting.order === 'descend',
  }))
  
  await dispatch(fetch())

}