import { connect } from 'react-redux'
import Controls from './Controls'

import onChangeForm from './Handlers/onChangeForm'
import onChangeFilter from './Handlers/onChangeFilter'
import onChangeColumnOffset from './Handlers/onChangeColumnOffset'
import onSubmit from './Handlers/onSubmit'

const mapStateToProps = (state) => ({
  stockLocations: state.core.stockLocations,
  suppliers: state.core.suppliers,
  total: state.screens.PurchasePlanner.Report.data.total,
  formState: state.screens.PurchasePlanner.Report.formState,
  columnOffset: state.screens.PurchasePlanner.Report.columnOffset,
})

const actionCreators = {
  onChangeForm,
  onChangeFilter,
  onChangeColumnOffset,
  onSubmit,
}

export default connect(mapStateToProps, actionCreators)(Controls)
