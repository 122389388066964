import { getUser } from 'Traqa/Helpers'
import { getNotifications } from '../../../Api/notifications'
import { setNotifications } from '../State/notifications'

export default () => async (dispatch, getState) => {

  try {
    
    const { id } = getUser()
    const { filter } = getState().modules.NotificationPopover
    
    const params = {
      'filter[for_user]': id,
      sort: '-created_at'
    }
    
    if (filter === 'UNREAD') params['filter[where_unread]'] = 1

    const response = await getNotifications({ id, params })

    dispatch(setNotifications(response.data.data))

  } catch (error) {
    
    throw error

  }
}