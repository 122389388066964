import fetchCouriers from '../../../Core/Services/fetchCouriers'
import fetchCourierServices from '../../../Core/Services/fetchCourierServices'
import fetchStockLocations from '../../../Core/Services/fetchStockLocations'
import fetchFilterOptions from '../Components/Table/Services/fetchFilterOptions'
import fetchTableData from '../Components/Table/Services/fetch'

export default () => (dispatch) => {

  dispatch(fetchCouriers())
  dispatch(fetchCourierServices())
  dispatch(fetchStockLocations())

  dispatch(fetchFilterOptions())
  dispatch(fetchTableData())
  
}
