import moment from 'moment'
import { getCourierServices } from '../../Api/courierServices'
import { loadCourierServices } from '../State/courierServices'

let lastGetTimestamp = null

const cacheTTL = 60000

export default ({ force } = {}) => async (dispatch) => {

  try {

    if (!force && lastGetTimestamp) {

      const now = Number(moment().format('x'))

      if (now < (lastGetTimestamp + cacheTTL)) return
    }
      
    lastGetTimestamp = Number(moment().format('x'))

    const params = {
      sort: 'name',
    }

    const response = await getCourierServices({ params })

    dispatch(loadCourierServices(response.data.data))

  } catch (error) {
    
    throw error

  }

}
