import { defer } from 'lodash'
import { getUser } from 'Traqa/Helpers'
import formService from '../formService'

export default () => () => {

  const initialValues = {
    stock_location_id: 2,
    date: null,
    time: null,
    user_id: getUser().id,
  }

  defer(() => formService().initialize(initialValues))

}