import modalProps from '../../../modalProps'
import { updateGoodsOutRecord } from '../../../../../Api/goodsOutRecords'
import { handleException } from 'Traqa/Packages'

export default (consignmentId) => async () => {

  const { goodsOutRecord } = modalProps()

  try {

    await updateGoodsOutRecord({ id: goodsOutRecord.id, data: { consignment_id: consignmentId } })

    modalProps().onClose(true)

  } catch (error) {

    handleException(error)
    
    throw error

  }

}
