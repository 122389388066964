import screenProps from '../screenProps'
import { getGoodsInRecord } from '../../../Api/goodsInRecords'
import { setGoodsInRecord } from '../State/goodsInRecord'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    if (id === 'new') return

    const response = await getGoodsInRecord({ id })

    dispatch(setGoodsInRecord(response.data.data))

  } catch (error) {

    handleException(error)

  }
}