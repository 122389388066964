import { message } from 'antd'
import modalProps from '../../../modalProps'
import { createPlasticTaxRate, updatePlasticTaxRate } from '../../../../../Api/plasticTaxRates'
import { handleException } from 'Traqa/Packages'

export default (values) => async () => {

  const { plasticTaxRate } = modalProps()

  const data = {
    effective_from: values.effective_from.format('YYYY-MM-DD'),
    recycled_perc: values.recycled_perc,
    rate_per_tonne: values.rate_per_tonne,
    exempt_product_category_ids: values.exempt_product_category_ids,
  }

  try {

    if (plasticTaxRate) {

      await updatePlasticTaxRate({ id: plasticTaxRate.id, data })

      message.success('Updated Tax Rate')

    } else {

      await createPlasticTaxRate({ data })

      message.success('Created Tax Rate')

    }
    
    modalProps().onClose(true)

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
