import { combineReducers } from "redux"

import { reducer as productCategoryModal } from "./State/productCategoryModal"

import ProductCategoriesTable from './Components/ProductCategoriesTable/reducer'

export default combineReducers({
  productCategoryModal,
  ProductCategoriesTable,
})
