import { combineReducers } from 'redux'

import { reducer as initialized } from './State/initialized'
import { reducer as salesOrder } from './State/salesOrder'
import { reducer as goodsOutRecordModal } from './State/goodsOutRecordModal'

import Editor from './Components/Editor/reducer'
import GoodsOutRecordsTable from './Components/GoodsOutRecordsTable/reducer'

export default combineReducers({
  initialized,
  salesOrder,
  goodsOutRecordModal,

  Editor,
  GoodsOutRecordsTable,
})
