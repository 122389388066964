import React from "react"
import PropTypes from "prop-types"
import { Button } from 'antd'
import Screen from '../../Components/Screen'
import StockLocationsTable from "./Components/StockLocationsTable"

import StockLocation from '../../Modals/StockLocation'

export default class StockLocationsScreen extends React.PureComponent {

  static propTypes = {
    stockLocationModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onCloseStockLocationModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  renderHeaderRight = () => (

    <Button type="primary" onClick={this.props.onClickNew}>
      New Location
    </Button>
    
  )

  render () {

    const {
      stockLocationModal,
      onCloseStockLocationModal,
    } = this.props

    return (

      <Screen
        title="Stock Locations"
        headerRight={this.renderHeaderRight()}
      >

        <StockLocationsTable />

        {stockLocationModal.show && (
          <StockLocation
            onClose={onCloseStockLocationModal}
            stockLocationId={stockLocationModal.stockLocationId}
          />
        )}

      </Screen>

    )
  }
}
