import { message } from 'antd'
import modalProps from '../modalProps'
import { deleteCourierCharge } from '../../../Api/courierCharges'
import { handleException } from 'Traqa/Packages'

export default () => async () => {

  const { id } = modalProps()

  try {

    await deleteCourierCharge({ id })

    modalProps().onClose(true)

    message.success('Deleted Charge')

  } catch (error) {

    handleException(error)

  }
  
}
