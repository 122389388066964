import fetchStockLocations from "../../../Core/Services/fetchStockLocations"
import fetchCustomers from '../../../Core/Services/fetchCustomers'
import fetchCustomerSites from '../../../Core/Services/fetchCustomerSites'
import fetchUsers from '../../../Core/Services/fetchUsers'
import fetchTableData from '../Components/Table/Services/fetch'

export default () => (dispatch) => {

  dispatch(fetchStockLocations())

  dispatch(fetchCustomers())

  dispatch(fetchCustomerSites())

  dispatch(fetchUsers())

  dispatch(fetchTableData())
  
}
