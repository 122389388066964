import { push } from 'connected-react-router'
import { message } from 'antd'
import { getTeamUrl } from 'Traqa/Helpers'
import screenProps from '../../../screenProps'
import { createGoodsOutRecord, updateGoodsOutRecord } from '../../../../../Api/goodsOutRecords'
import initialize from './initialize'
import fetch from '../../../Services/fetch'
//import fetchActivity from '../../../../../Modules/Activity/Services/fetch'
import { handleException } from 'Traqa/Packages'

export default (values) => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    const data = {
      stock_location_id: values.stock_location_id,
      customer_id: values.customer_id,
      customer_site_id: values.customer_site_id,
      date_time: `${values.date.format('YYYY-MM-DD')} ${values.time.format('HH:mm')}:00`,
      user_id: values.user_id,
      invoice_no: values.invoice_no || null,
      contact_details: values.contact_details || null,
      picking_notes: values.picking_notes || null,
      is_return: values.is_return,
      goods_out_record_items: values.goods_out_record_items.map(item => ({
        id: item.id,
        product_id: item.product_id,
        batch_no: item.batch_no || null,
        expiry_date: item.expiry_date ? item.expiry_date.format('YYYY-MM-DD') : null,
        quantity: item.quantity,
        source_product_id: item.source_product_id || null,
        picked_by_user_id: item.picked_by_user_id || null,
        sales_order_id: item.sales_order_id || null,
      })),
    }

    if (id === 'new') {

      const { data: { data: { id: newId } } } = await createGoodsOutRecord({ data })

      dispatch(push(getTeamUrl(`goods_out_records/${newId}`)))

      message.success('Saved')

    } else {

      await updateGoodsOutRecord({ id, data })

      await dispatch(fetch())

      await dispatch(initialize())

      //dispatch(fetchActivity())

      message.success('Saved')

    }

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
