import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, FormSpy, Field } from 'react-final-form'
import { Form, Input, Select, DatePicker, Row, Col, Button } from 'antd'
import { Module, LinkButton, ExposeFormToHelper } from 'Traqa/Packages'
import formHelper from './formHelper'
import styles from './styles.css'

const Advanced = (props) => {

  const {
    productId,
    advanced,
    stockLocations,
    products,
    onFormChange,
    onSubmit,
    onClickClose,
  } = props

  const renderHeaderRight = () => (

    <LinkButton
      type='default'
      icon='close'
      onClick={onClickClose}
      style={{ fontSize: 18 }}
    />

  )

  return (

    <Module.Section
      title='Advanced Search'
      renderHeaderRight={renderHeaderRight}
      borderBottom
    >

      <FormProvider
        initialValues={advanced.values}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, submitting }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            autoComplete='off'
            colon={false}
          >

            <ExposeFormToHelper helper={formHelper} />

            <FormSpy subscription={{ values: true }} onChange={onFormChange} />

            <Field name='ownerType'>
              {({ input }) => (
                <Form.Item label='Type'>
                  <Select
                    {...input}
                    disabled={submitting}
                    allowClear
                    optionFilterProp='children'
                  >
                    <Select.Option value='Stocktake'>Stocktake</Select.Option>
                    <Select.Option value='GoodsInRecord'>Goods In</Select.Option>
                    <Select.Option value='GoodsOutRecord'>Goods Out</Select.Option>
                    <Select.Option value='Transfer'>Transfer</Select.Option>
                    <Select.Option value='Assembly'>Assembly</Select.Option>
                    <Select.Option value='Reading'>Reading</Select.Option>
                    <Select.Option value='Adjustment'>Adjustment</Select.Option>
                    <Select.Option value='PlannedPurchase'>Planned Purchase</Select.Option>
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name='searchTerm'>
              {({ input }) => (
                <Form.Item label='Search'>
                  <Input.Search
                    {...input}
                    disabled={submitting}
                    onSearch={handleSubmit}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='stockLocationId'>
              {({ input }) => (
                <Form.Item label='Location'>
                  <Select
                    {...input}
                    disabled={submitting}
                    allowClear
                    optionFilterProp='children'
                  >
                    <Select.OptGroup label='Active'>
                      {stockLocations.filter(stockLocation => !stockLocation.archived_at).map(stockLocation => (
                        <Select.Option key={stockLocation.id} value={stockLocation.id}>
                          {stockLocation.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                    <Select.OptGroup label='Archived'>
                      {stockLocations.filter(stockLocation => !!stockLocation.archived_at).map(stockLocation => (
                        <Select.Option key={stockLocation.id} value={stockLocation.id} style={{ color: '#999' }}>
                          {stockLocation.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  </Select>
                </Form.Item>
              )}
            </Field>

            {!productId && (

              <Field name='productId'>
                {({ input }) => (
                  <Form.Item label='Product'>
                    <Select
                      {...input}
                      disabled={submitting}
                      allowClear
                      optionFilterProp='children'
                    >
                      <Select.OptGroup label='Active'>
                        {products.filter(product => !product.archived_at).map(product => (
                          <Select.Option key={product.id} value={product.id}>
                            {`${product.code} - ${product.name}`}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                      <Select.OptGroup label='Archived'>
                        {products.filter(product => product.archived_at).map(product => (
                          <Select.Option key={product.id} value={product.id} style={{ color: '#999' }}>
                            {`${product.code} - ${product.name}`}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                    </Select>
                  </Form.Item>
                )}
              </Field>

            )}

            <Field name='batchNo'>
              {({ input }) => (
                <Form.Item label='Batch No.'>
                  <Input
                    {...input}
                    disabled={submitting}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='transactionAtBetween'>
              {({ input }) => (
                <Form.Item label='Date Range'>
                  <DatePicker.RangePicker
                    {...input}
                    disabled={submitting}
                    format='DD/MM/YYYY'
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              )}
            </Field>

            <Row>
              <Col offset={7}>

                <Button
                  type='primary'
                  onClick={handleSubmit}
                >
                  Search
                </Button>

              </Col>
            </Row>

          </Form>
        
        )}
      </FormProvider>

    </Module.Section>

  )

}

Advanced.propTypes = {
  productId: PropTypes.number,
  advanced: PropTypes.object.isRequired,
  stockLocations: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  onFormChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired,
}

export default Advanced
