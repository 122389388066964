import { combineReducers } from "redux"

import { reducer as stockLocations } from "./State/stockLocations"
import { reducer as products } from "./State/products"
import { reducer as kits } from "./State/kits"
import { reducer as productCategories } from "./State/productCategories"
import { reducer as uploads } from "./State/uploads"
import { reducer as users } from "./State/users"
import { reducer as customers } from "./State/customers"
import { reducer as customerSites } from "./State/customerSites"
import { reducer as suppliers } from "./State/suppliers"
import { reducer as purchaseOrders } from "./State/purchaseOrders"
import { reducer as salesOrders } from "./State/salesOrders"
import { reducer as plasticTaxRates } from "./State/plasticTaxRates"
import { reducer as couriers } from "./State/couriers"
import { reducer as courierServices } from "./State/courierServices"
import { reducer as courierZones } from "./State/courierZones"

export default combineReducers({
  stockLocations,
  products,
  kits,
  productCategories,
  uploads,
  users,
  customers,
  customerSites,
  suppliers,
  purchaseOrders,
  salesOrders,
  plasticTaxRates,
  couriers,
  courierServices,
  courierZones,
})
