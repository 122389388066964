import { connect } from 'react-redux'
import Table from './Table'

import onChange from './Handlers/onChange'

const mapStateToProps = (state) => ({
  data: state.screens.PurchaseOrders.Table.data,
  isFetching: state.screens.PurchaseOrders.Table.isFetching,
})

const actionCreators = {
  onChange,
}

export default connect(mapStateToProps, actionCreators)(Table)
