import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import Screen from '../../Components/Screen'
import Table from './Components/Table'

export default class extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  componentWillUnmount = () => this.props.componentWillUnmount()

  renderHeaderRight = () => ([

    <Button key='new' type='primary' onClick={this.props.onClickNew}>
      New Consignment
    </Button>
    
  ])

  render () {

    return (

      <Screen
        title='Consignments'
        headerRight={this.renderHeaderRight()}
      >

        <Table />

      </Screen>

    )

  }

}
