import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Menu, Icon } from 'antd'
import styles from './styles.css'

import FulfilmentProgress from './Components/FulfillmentProgress'

const Header = (props) => {

  const {
    salesOrder,
    onChangeStatus,
  } = props

  const statusOptions = [
    { label: 'Proforma', value: 'Proforma', icon: 'hourglass', color: 'grey' },
    { label: 'Raised', value: 'Raised', icon: 'check-circle', color: 'limegreen' },
  ]

  const currentStatus = statusOptions.filter(option => option.value === salesOrder.status)[0]

  const statusMenu = (

    <Menu style={{ minWidth: 120 }} onClick={({ key }) => onChangeStatus(key)}>

      {statusOptions.map(option => (

        <Menu.Item key={option.value}>
          <Icon type={option.icon} className={styles.icon} style={{ color: option.color }} />
          {option.label}
        </Menu.Item>

      ))}

    </Menu>

  )

  return (

    <div className={styles.wrapper}>

      <Dropdown
        trigger={['click']}
        placement='bottomLeft'
        overlay={statusMenu}
      >

        <div className={styles.select} tabIndex={-1}>
          <Icon type={currentStatus.icon} className={styles.icon} style={{ color: currentStatus.color }} />
          {currentStatus.label}
          <Icon type='caret-down' className={styles.caret} />
        </div>

      </Dropdown>

      <div style={{ width: 32 }} />

      <FulfilmentProgress {...props} />

    </div>

  )

}

Header.propTypes = {
  salesOrder: PropTypes.object.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
}

export default Header
