import { combineReducers } from 'redux'

import { reducer as manualRunRate } from './State/manualRunRate'

import Form from './Components/Form/reducer'

export default combineReducers({
  manualRunRate,

  Form,
})
