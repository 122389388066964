import { connect } from 'react-redux'
import Consignments from './Consignments'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onClickNew from './Handlers/onClickNew'

const mapStateToProps = () => ({
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onClickNew,
}

export default connect(mapStateToProps, actionCreators)(Consignments)
