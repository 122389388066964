import { createAction, handleActions } from 'redux-actions'

const toggleShowItems = createAction("SCREENS/STOCKTAKE/EDITOR/TOGGLE_SHOW_ITEMS")
const resetShowItems = createAction("SCREENS/STOCKTAKE/EDITOR/RESET_SHOW_ITEMS")

const defaultState = true

const reducer = handleActions(
  {
    [toggleShowItems]: (state) => !state,
    [resetShowItems]: () => defaultState,
  },
  defaultState
)

export { toggleShowItems, resetShowItems, reducer }
