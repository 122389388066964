import { message } from 'antd'
import { duplicateGoodsInRecord } from '../../../../../Api/goodsInRecords'
import fetch from './fetch'
import { handleException } from 'Traqa/Packages'

export default (id) => async (dispatch) => {

  try {

    await duplicateGoodsInRecord({ id })

    dispatch(fetch())

    message.success('Duplicated')

  } catch (error) {

    handleException(error)

  }
  
}
