import { createAction, handleActions } from 'redux-actions'

const setColumnOffset = createAction('SCREENS/PURCHASE_PLANNER/REPORT/SET_COLUMN_OFFSET')
const resetColumnOffset = createAction('SCREENS/PURCHASE_PLANNER/REPORT/RESET_COLUMN_OFFSET')

const defaultState = 0

const reducer = handleActions(
  {
    [setColumnOffset]: (state, action) => action.payload,
    [resetColumnOffset]: () => defaultState,
  },
  defaultState,
)

export { setColumnOffset, resetColumnOffset, reducer }
