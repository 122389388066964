import { connect } from "react-redux"
import Item from "./Item"

import selectIsNew from '../../../../Selectors/selectIsNew'
import createIsSelectedSelector from './Selectors/createIsSelectedSelector'
import selectIsImport from './Selectors/selectIsImport'

import onToggle from './Handlers/onToggle'

const mapStateToProps = (state, props) => {
  
  const selectIsSelected = createIsSelectedSelector()

  return {
    isNew: selectIsNew(state, props),
    isSelected: selectIsSelected(state, props),
    isImport: selectIsImport(state),
    products: state.core.products,
    purchaseOrders: state.core.purchaseOrders,
  }
}

const actionCreators = {
  onToggle,
}

export default connect(mapStateToProps, actionCreators)(Item)
