import { getUnreadNotificationCount } from '../../../Api/notifications'
import { setHasUnread } from '../State/hasUnread'

export default () => async (dispatch) => {
  
  try {
    
    const response = await getUnreadNotificationCount()
    
    dispatch(setHasUnread(response.data.data > 0))
    
  } catch (error) {
    
    // console.error(error)
    
  }
  
}