import { connect } from 'react-redux'
import Table from './Table'

import moduleProps from '../../moduleProps'

const mapStateToProps = (state) => ({
  data: state.modules.TransactionsDrawer.data,
  isFetching: state.modules.TransactionsDrawer.isFetching,
  withProductColumn: moduleProps().withProductColumn,
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Table)
