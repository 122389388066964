import { setHoldingControls } from '../../../State/holdingControls'
import { resetHoldingValue } from '../../../State/holdingValue'
import { resetHoldingWeeks } from '../../../State/holdingWeeks'
import fetchHoldingValue from '../../../Services/fetchHoldingValue'
import fetchHoldingWeeks from '../../../Services/fetchHoldingWeeks'

export default (field, value) => (dispatch) => {

  dispatch(setHoldingControls({ [field]: value }))

  if (field === 'type') return

  dispatch(resetHoldingValue())
  dispatch(fetchHoldingValue())

  if (field === 'productCategoryId') {
    dispatch(resetHoldingWeeks())
    dispatch(fetchHoldingWeeks())
  }

}
