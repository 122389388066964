import axios from 'axios'
import { getTeamUrl } from 'Traqa/Helpers'

axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
}

const api = axios.create({
  baseURL: `/api/`,
})

const teamApi = axios.create({
  baseURL: getTeamUrl(null, 'api'),
})

const intercept401 = async error => {
  if (error.response.status === 401) {
    window.location.reload()
    await new Promise(() => null)
  }
  return Promise.reject(error)
}

api.interceptors.response.use(response => response, intercept401)
teamApi.interceptors.response.use(response => response, intercept401)

export { api, teamApi }