export default (values) => () => {

  const errors = {}

  if (!values.code) errors.code = true

  if (!values.parent_courier_zone_code) errors.parent_courier_zone_code = true
  
  return errors

}
