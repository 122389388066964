import { createAction, handleActions } from 'redux-actions'

const setFormState = createAction('SCREENS/PURCHASE_PLANNER/REPORT/SET_FORM_STATE')
const setFormValues = createAction('SCREENS/PURCHASE_PLANNER/REPORT/SET_FORM_VALUES')
const resetFormState = createAction('SCREENS/PURCHASE_PLANNER/REPORT/RESET_FORM_STATE')

const defaultState = {
  values: {},
  submitting: false,
}

const reducer = handleActions(
  {
    [setFormState]: (state, action) => action.payload,
    [setFormValues]: (state, action) => ({ ...state, values: action.payload }),
    [resetFormState]: () => defaultState,
  },
  defaultState,
)

export { setFormState, setFormValues, resetFormState, reducer }
