import React from "react"
import PropTypes from "prop-types"
import { Button } from 'antd'
import Screen from '../../Components/Screen'
import CustomersTable from "./Components/CustomersTable"

import Customer from '../../Modals/Customer'

export default class CustomersScreen extends React.PureComponent {

  static propTypes = {
    customerModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onCloseCustomerModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  renderHeaderRight = () => (

    <Button type="primary" onClick={this.props.onClickNew}>
      New Customer
    </Button>
    
  )

  render () {

    const {
      customerModal,
      onCloseCustomerModal,
    } = this.props

    return (

      <Screen
        title="Customers"
        headerRight={this.renderHeaderRight()}
      >

        <CustomersTable />

        {customerModal.show && (
          <Customer
            {...customerModal}
            onClose={onCloseCustomerModal}
          />
        )}

      </Screen>

    )
  }
}
