export default (values) => () => {

  function isEmptyNullOrUndefined(value) {
    if (value === '') return true;
    if (value === null) return true;
    if (value === undefined) return true;
    return false;
  }

  const errors = {}

  if (isEmptyNullOrUndefined(values.quantity)) errors.quantity = 'Quantity is required'
  
  return errors

}
