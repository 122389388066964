export default (values) => () => {

  const errors = {}

  if (!values.effective_date) errors.effective_date = 'Required'

  if (!values.value) errors.value = 'Required'
  
  return errors

}
