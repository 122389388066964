import { getSalesOrders } from '../../../../../Api/salesOrders'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { setPagination } from '../State/pagination'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { activeTab, advanced, searchTerm, sorting, pagination } = getState().screens.SalesOrders.Table

    const params = {}

    if (advanced.show) {

      const {
        type,
        keywords,
        customerIds,
        customerSiteIds,
        dateRange,
      } = advanced.values

      if (type) params['filter[type]'] = type

      if (keywords) params.search = keywords

      if (customerIds) params['filter[customer_id]'] = customerIds.join(',')

      if (customerSiteIds) params['filter[customer_site_id]'] = customerSiteIds.join(',')

      if (dateRange) params['filter[date_between]'] = dateRange.map(moment => moment.format('YYYY-MM-DD')).join(',')

    } else {

      if (activeTab !== 'All' && activeTab !== 'Archived') params['filter[type]'] = activeTab
      
      if (searchTerm) params.search = searchTerm

    }

    if (activeTab !== 'Archived') params['filter[without_archived]'] = 1

    if (activeTab === 'Archived') params['filter[only_archived]'] = 1

    params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : '-date'

    params.include = [
      'customer',
      'customer_site',
      'user',
    ].join()
    
    params['page[number]'] = pagination.pageNumber
    params['page[size]'] = pagination.pageSize

    dispatch(startFetching())

    const response = await getSalesOrders({ params })

    dispatch(setData(response.data.data))
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
    }))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }
  
}
