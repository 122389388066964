import { connect } from 'react-redux'
import Controls from './Controls'

import selectFilterApplied from './Selectors/selectFilterApplied'

import onChangeControls from './Handlers/onChangeControls'
import onClickAllFilters from './Handlers/onClickAllFilters'
import onSubmit from './Handlers/onSubmit'

const mapStateToProps = (state) => ({
  productCategories: state.core.productCategories,
  stockLocations: state.core.stockLocations,
  controls: state.screens.Demand.Report.controls,
  filterApplied: selectFilterApplied(state),
})

const actionCreators = {
  onChangeControls,
  onClickAllFilters,
  onSubmit,
}

export default connect(mapStateToProps, actionCreators)(Controls)
