import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import Screen from '../../Components/Screen'
import { isSupplyChain } from 'Traqa/Helpers'

import Report from './Components/Report'
import GoodsInRecord from '../../Modals/GoodsInRecord'
import AddProductToGoodsInRecord from '../../Modals/AddProductToGoodsInRecord'
import GoodsInRecordItem from '../../Modals/GoodsInRecordItem'
import Comments from '../../Modals/Comments'

export default class extends React.PureComponent {

  static propTypes = {
    initialized: PropTypes.bool.isRequired,
    goodsInRecordModal: PropTypes.object.isRequired,
    addProductToGoodsInRecordModal: PropTypes.object.isRequired,
    goodsInRecordItemModal: PropTypes.object.isRequired,
    goodsInRecordCommentsModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onCloseGoodsInRecordModal: PropTypes.func.isRequired,
    onCloseAddProductToGoodsInRecordModal: PropTypes.func.isRequired,
    onCloseGoodsInRecordItemModal: PropTypes.func.isRequired,
    onCloseGoodsInRecordCommentsModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  renderHeaderRight = () => {
    const items = []
    if (isSupplyChain()) {
      items.push(
        <Button key='NEW' type='primary' onClick={this.props.onClickNew}>
          New Planned Purchase
        </Button>
      )
    }
    return items
  }

  render () {

    const {
      initialized,
      goodsInRecordModal,
      addProductToGoodsInRecordModal,
      goodsInRecordItemModal,
      goodsInRecordCommentsModal,
      onCloseGoodsInRecordModal,
      onCloseAddProductToGoodsInRecordModal,
      onCloseGoodsInRecordItemModal,
      onCloseGoodsInRecordCommentsModal,
    } = this.props

    return (

      <Screen
        title='Purchase Planner'
        headerRight={this.renderHeaderRight()}
        contentLoading={!initialized}
        backToTop
      >
        
        <Report />

        {goodsInRecordModal.show && (
          <GoodsInRecord
            {...goodsInRecordModal}
            onClose={onCloseGoodsInRecordModal}
          />
        )}

        {addProductToGoodsInRecordModal.show && (
          <AddProductToGoodsInRecord
            {...addProductToGoodsInRecordModal}
            onClose={onCloseAddProductToGoodsInRecordModal}
          />
        )}

        {goodsInRecordItemModal.show && (
          <GoodsInRecordItem
            {...goodsInRecordItemModal}
            onClose={onCloseGoodsInRecordItemModal}
          />
        )}

        {goodsInRecordCommentsModal.show && (
          <Comments
            {...goodsInRecordCommentsModal}
            modelType='GoodsInRecord'
            onClose={onCloseGoodsInRecordCommentsModal}
          />
        )}

      </Screen>

    )

  }
  
}
