import { push } from 'connected-react-router'
import { defer, omit } from 'lodash'
import moment from 'moment'
import chance from 'chance'
import { getTeamUrl, getUser } from 'Traqa/Helpers'
import { setInitialValues } from '../Components/Editor/State/initialValues'
import formService from '../Components/Editor/formService'

export default () => async (dispatch, getState) => {

  const { values } = getState().screens.SalesOrder.Editor.formState

  dispatch(push(getTeamUrl(`sales_orders/new`)))

  const initialValues = {
    ...omit(values, [
      'id',
      'number',
      'customer_po_no',
      'status',
      'total_pending',
      'total_expected',
      'total_picked',
      'total_completed',
      'pending_percentage',
      'fulfilled_percentage',
      'complete_percentage',
    ]),
    ...{
      date: moment(),
      due_date: moment(),
      user_id: getUser().id,
      sales_order_items: values.sales_order_items.map(item => ({
        guid: chance().guid(),
        ...omit(item, ['guid', 'id', 'pending', 'expected', 'picked', 'completed']),
      })),
    },
  }

  dispatch(setInitialValues(initialValues))

  defer(() => formService().initialize(initialValues))

}
