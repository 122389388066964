import { createSelector } from 'reselect'
import { get, find, orderBy } from 'lodash'

const selectCouriers = state => state.core.couriers
const selectCourierId = (state) => get(state.screens.Consignment.Editor.formState, 'values.courier_id', null)

const selectCourierZones = createSelector(
  selectCouriers,
  selectCourierId,
  (couriers, courierId) => {
    
    const courier = find(couriers, { id: courierId })

    return courier ? orderBy(courier.courier_zones, 'code') : []

  },
)

export default selectCourierZones
