export default [

  {
    key: 'supply_chain',
    name: 'SUPPLY_CHAIN',
    name_formatted: 'Supply Chain',
    description: 'Can control the purchase planner & manual run rates',
  },

  {
    key: 'warehouse',
    name: 'WAREHOUSE',
    name_formatted: 'Warehouse',
    description: 'Only has access to basic warehouse functions',
  },

]
