import { connect } from 'react-redux'
import Header from './Header'

import selectActiveTab from '../../Selectors/selectActiveTab'

import onBack from './Handlers/onBack'
import onEdit from './Handlers/onEdit'
import onDelete from './Handlers/onDelete'
import onChangeTab from './Handlers/onChangeTab'

const mapStateToProps = (state) => ({
  courier: state.screens.Courier.courier,
  activeTab: selectActiveTab(state),
})

const actionCreators = {
  onBack,
  onEdit,
  onDelete,
  onChangeTab,
}

export default connect(mapStateToProps, actionCreators)(Header)
