import { connect } from 'react-redux'
import Controls from './Controls'

import selectIsFiltered from './Selectors/selectIsFiltered'

import onChangeSearch from './Handlers/onChangeSearch'
import onSearch from './Handlers/onSearch'
import onChangeFilter from './Handlers/onChangeFilter'
import onNewPlasticTaxRate from './Handlers/onNewPlasticTaxRate'
import onClickPlasticTaxRate from './Handlers/onClickPlasticTaxRate'

const mapStateToProps = (state) => ({
  suppliers: state.core.suppliers,
  plasticTaxRates: state.core.plasticTaxRates,
  filters: state.screens.PlasticTax.Report.filters,
  isFiltered: selectIsFiltered(state),
})

const actionCreators = {
  onChangeSearch,
  onSearch,
  onChangeFilter,
  onNewPlasticTaxRate,
  onClickPlasticTaxRate,
}

export default connect(mapStateToProps, actionCreators)(Controls)
