import { teamApi as axios } from './axios'

export const createUser = ({ data }) => axios.post(`users`, data)
export const deleteUser = ({ id }) => axios.delete(`users/${id}`)
export const findUser = ({ id, params } = {}) => axios.get(`users/${id}`, { params })
export const getUsers = ({ params } = {}) => axios.get(`users`, { params })
export const updateUser = ({ id, data }) => axios.put(`users/${id}`, data)

export const addUserPermission = ({ id, key }) => axios.post(`users/${id}/permissions/${key}`)
export const removeUserPermission = ({ id, key }) => axios.delete(`users/${id}/permissions/${key}`)
