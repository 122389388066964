import { resetData } from '../State/data'
import { resetFetching } from '../State/isFetching'
import { resetSearchTerm } from '../State/searchTerm'
import { resetSorting } from '../State/sorting'
import { resetPagination } from '../State/pagination'

export default () => (dispatch) => {

  dispatch(resetData())
  dispatch(resetFetching())
  dispatch(resetSearchTerm())
  dispatch(resetSorting())
  dispatch(resetPagination())

}
