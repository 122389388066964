import { push } from 'connected-react-router'
import { defer, omit } from 'lodash'
import moment from 'moment'
import chance from 'chance'
import { getTeamUrl, getUser } from 'Traqa/Helpers'
import { setInitialValues } from '../Components/Editor/State/initialValues'
import formService from '../Components/Editor/formService'

export default () => async (dispatch, getState) => {

  const { values } = getState().screens.GoodsInRecord.Editor.formState

  const user = getUser()

  dispatch(push(getTeamUrl(`goods_in_records/new`)))

  const initialValues = {
    ...omit(values, [
      'id',
      'status',
      'invoice_no',
      'in_sage',
    ]),
    ...{
      date: moment(),
      time: moment(),
      user_id: user.id,
      goods_in_record_items: values.goods_in_record_items.map(item => ({
        guid: chance().guid(),
        expiry_date: null,
        ...omit(item, ['guid', 'id', 'purchase_order_id', 'batch_no', 'expiry_date', 'blend', 'recycled_perc', 'weight_kgs']),
      })),
    },
  }

  dispatch(setInitialValues(initialValues))

  defer(() => formService().initialize(initialValues))

}
