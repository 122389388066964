import React from 'react'
import PropTypes from 'prop-types'

import Created from './Components/Created'
import Updated from './Components/Updated'
import Deleted from './Components/Deleted'

export default class extends React.PureComponent {

  static propTypes = {
    log: PropTypes.object.isRequired,
    properties: PropTypes.object.isRequired,
  }

  render() {

    const {
      log,
      properties,
    } = this.props

    if (log.description === 'created') return <Created properties={properties} />

    if (log.description === 'updated') return <Updated properties={properties} />

    if (log.description === 'deleted') return <Deleted properties={properties} />

    return false

  }

}
