import { connect } from "react-redux"

import Component from "./Component"

import componentDidMount from "./Handlers/componentDidMount"
import onClickNew from "./Handlers/onClickNew"
import onCloseStockLocationModal from "./Handlers/onCloseStockLocationModal"

const mapStateToProps = (state) => ({
  stockLocationModal: state.screens.StockLocations.stockLocationModal,
})

const actionCreators = {
  componentDidMount,
  onClickNew,
  onCloseStockLocationModal,
}

export default connect(mapStateToProps, actionCreators)(Component)
