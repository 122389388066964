import moment from 'moment'
import fetchSuppliers from '../../../Core/Services/fetchSuppliers'
import fetchPlasticTaxRates from '../../../Core/Services/fetchPlasticTaxRates'
import { setFilter } from '../Components/Report/State/filters'
import fetchReport from '../Components/Report/Services/fetch'

export default () => (dispatch) => {

  dispatch(fetchSuppliers())
  dispatch(fetchPlasticTaxRates())

  dispatch(setFilter({
    filter: 'dateBetween',
    value: [moment().startOf('quarter'), moment().endOf('quarter')],
  }))
  
  dispatch(fetchReport())
  
}
