import { connect } from "react-redux"
import MoreMenu from "./MoreMenu"

import onCreateGoodsIn from './Handlers/onCreateGoodsIn'
import onExport from './Handlers/onExport'
import onDelete from './Handlers/onDelete'
import onDuplicate from './Handlers/onDuplicate'

const mapStateToProps = () => ({})

const actionCreators = {
  onCreateGoodsIn,
  onExport,
  onDelete,
  onDuplicate,
}

export default connect(mapStateToProps, actionCreators)(MoreMenu)
