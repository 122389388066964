import { connect } from "react-redux"
import Header from "./Header"

import onChangeStatus from './Handlers/onChangeStatus'
import onChangeInSage from './Handlers/onChangeInSage'

const mapStateToProps = (state) => ({
  goodsInRecord: state.screens.GoodsInRecord.goodsInRecord,
})

const actionCreators = {
  onChangeStatus,
  onChangeInSage,
}

export default connect(mapStateToProps, actionCreators)(Header)
