import { closeGoodsInRecordModal } from '../State/goodsInRecordModal'
import fetch from '../Components/Report/Services/fetch'

export default (shouldReload = false) => (dispatch) => {
  
  dispatch(closeGoodsInRecordModal())

  if (shouldReload) {
    dispatch(fetch())
  }
  
}
