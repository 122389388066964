import { createSelector } from 'reselect'
import { find, get } from 'lodash'

const selectProducts = state => state.core.products
const selectProductId = (state, props) => props.productId

export default createSelector(
  selectProducts,
  selectProductId,
  (products, productId) => get(find(products, product => product.id === productId), 'division_of_products', []),
)
