import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, FormSpy, Field } from 'react-final-form'
import { Form, Select, Input, Row, Col, Button, Icon } from 'antd'
import { Module, ExposeFormToHelper } from 'Traqa/Packages'
import formService from './formService'
import styles from './styles.css'

const Controls = (props) => {

  const {
    stockLocations,
    suppliers,
    total,
    formState,
    columnOffset,
    onChangeForm,
    onChangeFilter,
    onChangeColumnOffset,
    onSubmit,
  } = props

  const canDecreaseColumnOffset = (value) => value > 0

  const canIncreaseColumnOffset = (value) => {
    const { columnCount } = formState.values
    return total > value + columnCount
  }

  return (

    <Module.Section
      borderBottom
      contentStyle={{ padding: 15 }}
    >

      <Row gutter={8}>

        <FormProvider
          initialValues={formState.values}
          onSubmit={onSubmit}
        >

          {({ handleSubmit, submitting }) => (

            <Form
              onSubmit={handleSubmit}
              className={styles.form}
              autoComplete='off'
            >

              <ExposeFormToHelper helper={formService} />

              <FormSpy subscription={{ values: true, submitting: true }} onChange={onChangeForm} />

              <Col span={6}>

                <Field name="search">
                  {({ input }) => (
                    <Input.Search
                      {...input}
                      placeholder='Search Product or Category'
                      disabled={submitting}
                      onSearch={handleSubmit}
                    />
                  )}
                </Field>

              </Col>

              <Col span={4}>

                <Field name='stockLocationId' allowNull>
                  {({ input }) => (
                    <Select
                      {...input}
                      value={input.value === null ? undefined : input.value}
                      onChange={value => {
                        input.onChange(value === undefined ? null : value)
                        onChangeFilter()
                      }}
                      disabled={submitting}
                      allowClear
                      showSearch
                      placeholder='All Locations'
                      optionFilterProp='children'
                    >
                      <Select.OptGroup label='Active'>
                        {stockLocations.filter(stockLocation => !stockLocation.archived_at).map(stockLocation => (
                          <Select.Option key={stockLocation.id} value={stockLocation.id}>
                            {stockLocation.name}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                      <Select.OptGroup label='Archived'>
                        {stockLocations.filter(stockLocation => !!stockLocation.archived_at).map(stockLocation => (
                          <Select.Option key={stockLocation.id} value={stockLocation.id} style={{ color: '#999' }}>
                            {stockLocation.name}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                    </Select>
                  )}
                </Field>

              </Col>

              <Col span={4}>

                <Field name='supplierId' allowNull>
                  {({ input }) => (
                    <Select
                      {...input}
                      value={input.value === null ? undefined : input.value}
                      onChange={value => {
                        input.onChange(value === undefined ? null : value)
                        onChangeFilter()
                      }}
                      disabled={submitting}
                      allowClear
                      showSearch
                      placeholder='All Suppliers'
                      optionFilterProp='children'
                    >
                      <Select.OptGroup label='Active'>
                        {suppliers.filter(supplier => !supplier.archived_at).map(supplier => (
                          <Select.Option key={supplier.id} value={supplier.id}>
                            {supplier.name}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                      <Select.OptGroup label='Archived'>
                        {suppliers.filter(supplier => !!supplier.archived_at).map(supplier => (
                          <Select.Option key={supplier.id} value={supplier.id} style={{ color: '#999' }}>
                            {supplier.name}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                    </Select>
                  )}
                </Field>

              </Col>

              <Col span={4}>

                <Field name='invoiceNo'>
                  {({ input }) => (
                    <Input.Search
                      {...input}
                      onKeyDownCapture={e => {
                        if (e.which === 13) {
                          input.onChange(e.target.value)
                          onChangeFilter()
                        }
                      }}
                      disabled={submitting}
                      onSearch={handleSubmit}
                      placeholder='Reference'
                    />
                  )}
                </Field>

              </Col>

            </Form>

          )}

        </FormProvider>

        <Col span={4} offset={2}>

          <Button.Group style={{ width: '100%' }}>
            <Button
              onClick={() => {
                onChangeColumnOffset(0)
              }}
              disabled={!canDecreaseColumnOffset(columnOffset) || formState.submitting}
              style={{ width: '25%' }}
            >
              <Icon type='vertical-right' />
            </Button>
            <Button
              onClick={() => {
                if (canDecreaseColumnOffset(columnOffset)) {
                  onChangeColumnOffset(columnOffset - 1)
                }
              }}
              disabled={!canDecreaseColumnOffset(columnOffset) || formState.submitting}
              style={{ width: '25%' }}
            >
              <Icon type='left' />
            </Button>
            <Button
              onClick={() => {
                if (canIncreaseColumnOffset(columnOffset)) {
                  onChangeColumnOffset(columnOffset + 1)
                }
              }}
              disabled={!canIncreaseColumnOffset(columnOffset) || formState.submitting}
              style={{ width: '25%' }}
            >
              <Icon type='right' />
            </Button>
            <Button
              onClick={() => {
                onChangeColumnOffset(total - formState.values.columnCount)
              }}
              disabled={!canIncreaseColumnOffset(columnOffset) || formState.submitting}
              style={{ width: '25%' }}
            >
              <Icon type='vertical-left' />
            </Button>
          </Button.Group>

        </Col>

      </Row>

    </Module.Section>

  )

}

Controls.propTypes = {
  stockLocations: PropTypes.array.isRequired,
  suppliers: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  formState: PropTypes.object.isRequired,
  columnOffset: PropTypes.number.isRequired,
  onChangeForm: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onChangeColumnOffset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default Controls
