import { closeCourierZoneModal } from '../State/courierZoneModal'
import fetch from '../Services/fetch'

export default (shouldReload = false) => (dispatch) => {
  
  dispatch(closeCourierZoneModal())
  
  if (shouldReload) dispatch(fetch())
  
}
