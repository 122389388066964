import { setColumnOffset } from '../../../State/columnOffset'
import formService from '../formService'

export default (columnOffset) => (dispatch) => {
  
  dispatch(setColumnOffset(columnOffset))

  formService().submit()

}
