import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openGoodsInRecordModal = createAction('SCREENS/PURCHASE_PLANNER/OPEN_GOODS_IN_RECORD_MODAL')
const closeGoodsInRecordModal = createAction('SCREENS/PURCHASE_PLANNER/CLOSE_GOODS_IN_RECORD_MODAL')

const defaultState = {
  show: false,
  id: null,
}

const reducer = handleActions(
  {
    [openGoodsInRecordModal]: (state, action) => Object.assign({}, state, {
      show: true,
      id: get(action, 'payload.id', null),
    }),
    [closeGoodsInRecordModal]: () => Object.assign({}, defaultState),
  },
  defaultState
)

export { openGoodsInRecordModal, closeGoodsInRecordModal, reducer }
