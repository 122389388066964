import { createSelector } from 'reselect'
import { find } from 'lodash'
import pluralize from 'pluralize'

import selectProducts from '../../../Selectors/selectProducts'

const selectStockLocations = state => state.screens.StockAssessment.Report.data.stock_locations
const selectIsFetching = state => state.screens.StockAssessment.Report.isFetching
const selectSortBy = state => state.screens.StockAssessment.Report.formState.values.sortBy

const config = {
  quantity: {
    title: 'Qty',
    format: '{total:,.0f}',
    pointFormat: '<b>{series.name}</b><br />{point.form}: <b>{point.y:,.0f}</b><br />Value: <b>£{point.value:,.0f}</b>',
    alternateKey: 'value',
  },
  value: {
    title: 'Value',
    format: '£{total:,.0f}',
    pointFormat: '<b>{series.name}</b><br />{point.form}: <b>{point.quantity:,.0f}</b><br />Value: <b>£{point.y:,.0f}</b>',
    alternateKey: 'quantity',
  },
}

export default createSelector(
  selectProducts,
  selectStockLocations,
  selectIsFetching,
  selectSortBy,
  (products, stockLocations, isFetching, sortBy) => {

    if (sortBy === 'code') return {}

    const selection = products.slice(0, 15)

    return {
      chart: {
        type: 'column',
        height: 300,
        events: {
          load() {
            // eslint-disable-next-line no-unused-expressions
            isFetching ? this.showLoading() : this.hideLoading()
          },
        },
      },
      title: {
        enabled: true,
        text: config[sortBy].title,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      xAxis: {
        categories: selection.map(product => product.code),
      },
      yAxis: {
        title: {
          enabled: false,
        },
        stackLabels: {
          enabled: true,
          format: config[sortBy].format,
        },
      },
      tooltip: {
        pointFormat: config[sortBy].pointFormat,
      },
      series: stockLocations.map(stockLocation => ({
        name: stockLocation.name,
        data: selection.map(product => {
          const $stockLocation = find(product.stock_locations, row => row.id === stockLocation.id)
          return {
            y: $stockLocation[sortBy],
            form: pluralize(product.form),
            [config[sortBy].alternateKey]: $stockLocation[config[sortBy].alternateKey],
            color: stockLocation.colour,
          }
        }),
        showInLegend: !!selection.filter(product => find(product.stock_locations, row => row.id === stockLocation.id)[sortBy] > 0).length,
      })),
    }

  },
)
