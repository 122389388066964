import { hideAdvanced, resetValues } from '../../../State/advanced'
import { firstPage } from '../../../State/pagination'
import fetch from '../../../Services/fetch'

export default () => (dispatch) => {

  dispatch(hideAdvanced())
  dispatch(resetValues())
  dispatch(firstPage())
  
  dispatch(fetch())

}
