import { connect } from 'react-redux'
import SalesOrder from './SalesOrder'

import selectIsNew from './Selectors/selectIsNew'
import selectIsEditing from './Selectors/selectIsEditing'
import selectIsSubmitting from './Selectors/selectIsSubmitting'

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onBack from './Handlers/onBack'
import onSave from './Handlers/onSave'
import onCancel from './Handlers/onCancel'
import onCloseGoodsOutRecordModal from './Handlers/onCloseGoodsOutRecordModal'
import onFileChanges from './Handlers/onFileChanges'

const mapStateToProps = (state, props) => ({
  id: props.match.params.id,
  isNew: selectIsNew(state, props),
  isEditing: selectIsEditing(state, props),
  isSubmitting: selectIsSubmitting(state, props),
  initialized: state.screens.SalesOrder.initialized,
  salesOrder: state.screens.SalesOrder.salesOrder,
  goodsOutRecordModal: state.screens.SalesOrder.goodsOutRecordModal,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onBack,
  onSave,
  onCancel,
  onCloseGoodsOutRecordModal,
  onFileChanges,
}

export default connect(mapStateToProps, actionCreators)(SalesOrder)
