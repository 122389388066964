import modalProps from '../modalProps'
import fetch from '../Services/fetch'

export default (props) => (dispatch) => {

  modalProps(props)

  dispatch(fetch())
  
}
