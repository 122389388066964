import { createAction, handleActions } from 'redux-actions'

const setFormState = createAction('MODALS/SALES_ORDER_GOODS_OUT_RECORD/FORM/SET_FORM_STATE')
const resetFormState = createAction('MODALS/SALES_ORDER_GOODS_OUT_RECORD/FORM/RESET_FORM_STATE')

const defaultState = {
  submitting: false,
}

const reducer = handleActions(
  {
    [setFormState]: (state, action) => action.payload,
    [resetFormState]: () => defaultState,
  },
  defaultState
)

export { setFormState, resetFormState, reducer }
