import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'antd'
import numeral from 'numeral'
import { Detail } from 'Traqa/Packages'
import styles from './Component.css'

import ProductAssessment from '../../../../Components/ProductAssessment'

export default class _Availability_ extends React.PureComponent {

  static propTypes = {
    product: PropTypes.object,
    liveAssessment: PropTypes.object,
  }

  static defaultProps = {
    product: null,
    liveAssessment: null,
  }

  render () {

    const {
      product,
      liveAssessment,
    } = this.props

    if (!product || !liveAssessment) return null

    const { quantity, unallocated } = liveAssessment.actual

    return (

      <Card title="Availability">

        <ProductAssessment
          productAssessment={{
            ...liveAssessment,
            stock_locations: liveAssessment.stock_locations.map(stockLocation => ({
              ...stockLocation,
              quantity: stockLocation.actual.quantity,
              value: stockLocation.actual.quantity_value,
            }))
          }}
        >

          <span>

            <Detail label="On Hand" className={styles.detail} contentClassName={styles.number}>
              {numeral(Math.floor(quantity)).format('0,0')}
            </Detail>

          </span>

        </ProductAssessment>

        <ProductAssessment
          productAssessment={{
            ...liveAssessment,
            stock_locations: liveAssessment.stock_locations.map(stockLocation => ({
              ...stockLocation,
              quantity: stockLocation.actual.unallocated,
              value: stockLocation.actual.unallocated_value,
            }))
          }}
        >

          <span>

            <Detail label="Available" className={styles.detail} contentClassName={styles.number}>
              {numeral(Math.floor(unallocated)).format('0,0')}
            </Detail>

          </span>

        </ProductAssessment>

      </Card>

    )
  }
}