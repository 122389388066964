import { createAction, handleActions } from 'redux-actions'

const loadCustomers = createAction("CORE/CUSTOMERS/LOAD_CUSTOMERS")

const defaultState = []

const reducer = handleActions(
  {
    [loadCustomers]: (state, action) => action.payload,
  },
  defaultState
)

export { loadCustomers, reducer }