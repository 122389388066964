import { resetInitialValues } from '../State/initialValues'
import { resetFormState } from '../State/formState'
import { resetSearchTerm } from '../State/searchTerm'
import { resetHideZeros } from '../State/hideZeros'
import { resetShowItems } from '../State/showItems'

export default () => async (dispatch) => {

  dispatch(resetInitialValues())
  dispatch(resetFormState())
  dispatch(resetSearchTerm())
  dispatch(resetHideZeros())
  dispatch(resetShowItems())
  
}