import { resetData } from '../State/data'
import { resetFetching } from '../State/isFetching'
import { resetFormState } from '../State/formState'
import { resetPreviousStocktakes } from '../State/previousStocktakes'

export default () => (dispatch) => {

  dispatch(resetData())
  dispatch(resetFetching())
  dispatch(resetFormState())
  dispatch(resetPreviousStocktakes())

}