import screenProps from '../../../screenProps'
import { openGoodsInRecordModal } from '../../../State/goodsInRecordModal'

export default () => (dispatch) => {

  const { id } = screenProps().match.params

  dispatch(openGoodsInRecordModal({
    purchaseOrderId: id,
    purchaseOrderItemIds: null,
  }))

}
