import { connect } from 'react-redux'
import SalesOrders from './SalesOrders'

import componentDidMount from './Handlers/componentDidMount'
import onClickNew from './Handlers/onClickNew'

const mapStateToProps = () => ({})

const actionCreators = {
  componentDidMount,
  onClickNew,
}

export default connect(mapStateToProps, actionCreators)(SalesOrders)
