import { getTeamUrl } from 'Traqa/Helpers'

export default (key) => async (dispatch, getState) => {

  const { advanced, activeTab, searchTerm, sorting, hideInSage } = getState().screens.GoodsInRecords.Table

  let path

  if (key === 'goods-in-records') {
    path = `goods_in_records/export?`
  }

  if (key === 'landed-costs') {
    path = `landed_costs/export?`
  }

  if (advanced.show) {

    const {
      keywords,
      type,
      dateRange,
      countryCodes,
      supplierIds,
      stockLocationIds,
    } = advanced.values

    if (keywords) {
      path = `${path}&search=${keywords}`
    }

    if (type) {
      path = `${path}&type=${type}`
    }

    if (dateRange.length > 0) {
      path = `${path}&filter[date_between]=${dateRange.map(moment => moment.format('YYYY-MM-DD')).join(',')}`
    }

    if (countryCodes.length > 0) {
      path = `${path}&filter[country_code]=${countryCodes.join(',')}`
    }

    if (supplierIds.length > 0) {
      path = `${path}&filter[supplier_id]=${supplierIds.join(',')}`
    }

    if (stockLocationIds.length > 0) {
      path = `${path}&filter[stock_location_id]=${stockLocationIds.join(',')}`
    }

  } else {

    path = `${path}&filter[type]=${activeTab}`

    if (searchTerm) {
      path = `${path}&search=${searchTerm}`
    }

    if (hideInSage) {
      path = `${path}&filter[in_sage]=false`
    }

  }

  path = `${path}&sort=${sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : '-date_time'}`

  window.open(getTeamUrl(path, 'exports'))

}
