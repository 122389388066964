import React from 'react'
import PropTypes from 'prop-types'
import { Field, FormSpy } from 'react-final-form'
import { Form, Row, Col, Select, Input, InputNumber, DatePicker, TimePicker } from 'antd'
import { Module } from 'Traqa/Packages'

const Details = (props) => {

  const {
    products,
    stockLocations,
    users,
  } = props
  
  return (

    <Module.Section
      contentStyle={{ paddingBottom: 15 }}
    >

      <FormSpy subscription={{ submitting: true }}>

        {({ submitting }) => (

          <React.Fragment>

            <Row gutter={8}>

              <Col span={10}>

                <Field name="product_id">
                  {({ input, meta }) => (
                    <Form.Item
                      label="Product"
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        disabled
                      >

                        <Select.OptGroup label="Products">
                          {products.filter(product => !product.archived_at).map(product => (
                            <Select.Option key={product.id} value={product.id}>
                              {[product.code, product.name].filter(string => string).join(' - ')}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>

                        <Select.OptGroup label="Archived">
                          {products.filter(product => product.archived_at).map(product => (
                            <Select.Option key={product.id} value={product.id} style={{ color: '#999' }}>
                              {[product.code, product.name].filter(string => string).join(' - ')}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>

                      </Select>
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={2}>

                <Field name="quantity" allowNull>
                  {({ input, meta }) => (
                    <Form.Item
                      label="Quantity"
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        disabled={submitting}
                        min={0}
                        step={1}
                        precision={0}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={4}>

                <Field name="stock_location_id">
                  {({ input, meta }) => (
                    <Form.Item
                      label="Location"
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        disabled={submitting}
                        optionFilterProp="children"
                        showSearch
                        allowClear
                      >
                        <Select.OptGroup label='Active'>
                          {stockLocations.filter(stockLocation => !stockLocation.archived_at).map(stockLocation => (
                            <Select.Option key={stockLocation.id} value={stockLocation.id}>
                              {stockLocation.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                        <Select.OptGroup label='Archived'>
                          {stockLocations.filter(stockLocation => !!stockLocation.archived_at).map(stockLocation => (
                            <Select.Option key={stockLocation.id} value={stockLocation.id} style={{ color: '#999' }}>
                              {stockLocation.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                      </Select>
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={3}>

                <Field name='date' allowNull>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Date'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <DatePicker
                        {...input}
                        disabled={submitting}
                        format='DD/MM/YYYY'
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={3}>

                <Field name='time' allowNull>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Time'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <TimePicker
                        {...input}
                        disabled={submitting}
                        format='HH:mm'
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

            </Row>

            <Row gutter={8}>

              <Col span={5}>

                <Field name="user_id">
                  {({ input, meta }) => (
                    <Form.Item
                      label="User"
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        disabled={submitting}
                        optionFilterProp="children"
                        showSearch
                        allowClear
                      >
                        {users.map(user => (
                          <Select.Option key={user.id} value={user.id}>
                            {user.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={5}>

                <Field name="batch_no" allowNull>
                  {({ input, meta }) => (
                    <Form.Item
                      label="Batch No."
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Input
                        {...input}
                        disabled={submitting}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={3}>

                <Field name="expiry_date" allowNull>
                  {({ input, meta }) => (
                    <Form.Item
                      label="Expiry Date"
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <DatePicker
                        {...input}
                        disabled={submitting}
                        format="DD/MM/YYYY"
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={3}>

                <Field name='packing_cost'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Packing Cost'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        disabled={submitting}
                        min={0}
                        step={1}
                        precision={2}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={3}>

                <Field name='transfer_cost'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Transfer Cost'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        disabled={submitting}
                        min={0}
                        step={1}
                        precision={2}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

            </Row>

          </React.Fragment>
      
        )}

      </FormSpy>
  
    </Module.Section>

  )
}

Details.propTypes = {
  products: PropTypes.array.isRequired,
  stockLocations: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
}

Details.defaultProps = {
  
}

export default Details