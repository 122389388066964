import chance from 'chance'
import moment from 'moment'
import { defer } from 'lodash'
import screenProps from '../../../screenProps'
import { getAdjustment } from '../../../../../Api/adjustments'
import { setInitialValues } from '../State/initialValues'
import { handleException } from 'Traqa/Packages'
import formService from '../formService'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    if (id === 'new') {

      const initialValues = {
        date: moment(),
        time: moment(),
        adjustment_items: [
          {
            guid: chance().guid(),
            expiry_date: null,
          }
        ]
      }

      dispatch(setInitialValues(initialValues))

      defer(() => formService().initialize(initialValues))

    } else {

      const {
        data: {
          data: adjustment
        }
      } = await getAdjustment({
        id,
        params: {
          include: 'adjustment_items'
        }
      })

      const dateTime = moment(adjustment.date_time)

      const initialValues = {
        stock_location_id: adjustment.stock_location_id,
        description: adjustment.description,
        direction: adjustment.direction,
        date: dateTime,
        time: dateTime,
        created_by_user_id: adjustment.created_by_user_id,
        updated_by_user_id: adjustment.updated_by_user_id,
        adjustment_items: adjustment.adjustment_items.map(item => ({
          guid: chance().guid(),
          id: item.id,
          product_id: item.product_id,
          landed_cost: item.landed_cost,
          batch_no: item.batch_no,
          expiry_date: item.expiry_date ? moment(item.expiry_date) : null,
          quantity: item.quantity,
          source_product_id: item.source_product_id,
        }))
      }

      dispatch(setInitialValues(initialValues))

      defer(() => formService().initialize(initialValues))

    } 

  } catch (error) {

    handleException(error)

  }
}