import { message } from 'antd'
import { push } from 'connected-react-router'
import { deleteReading } from '../../../Api/readings'
import { getTeamUrl } from 'Traqa/Helpers'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { reading } = getState().screens.Reading

    await deleteReading({ id: reading.id })

    message.success(`Deleted Reading "${reading.id}"`)

    dispatch(push(getTeamUrl(`transactions`)))

  } catch (error) {

    handleException(error)

  }
}