import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Screen from '../../Components/Screen'

import Report from './Components/Report'

const PlasticTax = props => {

  const {
    componentDidMount,
    componentWillUnmount,
  } = props

  useEffect(() => {
    componentDidMount()
    return () => componentWillUnmount()
  }, [])

  return (

    <Screen
      title='Plastic Tax'
    >
      
      <Report />

    </Screen>

  )

}

PlasticTax.propTypes = {
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
}

export default PlasticTax
