import moment from 'moment'
import { getSuppliers } from '../../Api/suppliers'
import { loadSuppliers } from '../State/suppliers'

let lastGetTimestamp = null

const cacheTTL = 60000

export default ({ force } = {}) => async (dispatch) => {

  try {

    if (!force && lastGetTimestamp) {

      const now = Number(moment().format('x'))

      if (now < (lastGetTimestamp + cacheTTL)) return
    }
      
    lastGetTimestamp = Number(moment().format('x'))

    const params = {
      sort: 'name'
    }

    const response = await getSuppliers({ params })

    dispatch(loadSuppliers(response.data.data))

  } catch (error) {
    
    throw error

  }
}