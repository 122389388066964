import { connect } from "react-redux"
import Header from "./Header"

import onChangeStatus from './Handlers/onChangeStatus'

const mapStateToProps = (state) => ({
  purchaseOrder: state.screens.PurchaseOrder.purchaseOrder,
  formValues: state.screens.PurchaseOrder.Editor.formState.values,
})

const actionCreators = {
  onChangeStatus,
}

export default connect(mapStateToProps, actionCreators)(Header)
