import modalProps from '../modalProps'
import { resetData } from '../State/data'

export default () => (dispatch) => {

  modalProps.reset()

  dispatch(resetData())
  
}
