export default (values) => () => {

  const errors = {}

  if (!values.date) errors.date = true

  if (!values.stock_location_id) errors.stock_location_id = true

  if (!values.customer_id) errors.customer_id = true

  if (!values.courier_id) errors.courier_id = true

  if (values.courier_id !== 'null') {

    if (!values.customer_site_id) errors.customer_site_id = true

    if (!values.reference) errors.reference = true
    
  }

  if (!values.total_kgs) errors.total_kgs = true

  return errors

}
