import { connect } from "react-redux"
import GroupItem from "./GroupItem"

import createComponentPropsSelector from '../../Selectors/createComponentPropsSelector'

import onDeleteItem from './Handlers/onDeleteItem'

const mapStateToProps = (state, props) => {
  
  const componentPropsSelector = createComponentPropsSelector()

  const componentProps = componentPropsSelector(state, props)

  return {
    ...componentProps
  }
}

const actionCreators = {
  onDeleteItem,
}

export default connect(mapStateToProps, actionCreators)(GroupItem)
