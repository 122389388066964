import { connect } from 'react-redux'
import Header from './Header'

import onChangeTab from './Handlers/onChangeTab'
import onChangeSearch from './Handlers/onChangeSearch'
import onSearch from './Handlers/onSearch'
import onClickAdvanced from './Handlers/onClickAdvanced'
import onChangeHidePrinted from './Handlers/onChangeHidePrinted'

const mapStateToProps = (state) => ({
  activeTab: state.screens.GoodsOutRecords.Table.activeTab,
  searchTerm: state.screens.GoodsOutRecords.Table.searchTerm,
  hidePrinted: state.screens.GoodsOutRecords.Table.hidePrinted,
})

const actionCreators = {
  onChangeTab,
  onChangeSearch,
  onSearch,
  onClickAdvanced,
  onChangeHidePrinted,
}

export default connect(mapStateToProps, actionCreators)(Header)
