import { connect } from 'react-redux'
import Table from './Table'

import selectTableRows from './Selectors/selectTableRows'

import onClickAddProductToGoodsInRecord from './Handlers/onClickAddProductToGoodsInRecord'
import onClickEditGoodsInRecord from './Handlers/onClickEditGoodsInRecord'
import onClickGoodsInRecordComments from './Handlers/onClickGoodsInRecordComments'
import onClickDuplicateGoodsInRecord from './Handlers/onClickDuplicateGoodsInRecord'
import onClickConvertIntoPurchaseOrder from './Handlers/onClickConvertIntoPurchaseOrder'
import onClickDeleteGoodsInRecord from './Handlers/onClickDeleteGoodsInRecord'
import onClickEditableCell from './Handlers/onClickEditableCell'

const mapStateToProps = (state) => ({
  goodsInRecords: state.screens.PurchasePlanner.Report.data.goods_in_records,
  tableRows: selectTableRows(state),
  isFetching: state.screens.PurchasePlanner.Report.isFetching,
})

const actionCreators = {
  onClickAddProductToGoodsInRecord,
  onClickEditGoodsInRecord,
  onClickGoodsInRecordComments,
  onClickDuplicateGoodsInRecord,
  onClickConvertIntoPurchaseOrder,
  onClickDeleteGoodsInRecord,
  onClickEditableCell,
}

export default connect(mapStateToProps, actionCreators)(Table)
