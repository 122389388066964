import { defer } from 'lodash'
import moment from 'moment'
import modalProps from '../../../modalProps'
import formService from '../formService'

export default () => () => {

  const { plasticTaxRate } = modalProps()

  let initialValues

  if (plasticTaxRate) {

    initialValues = {
      ...plasticTaxRate,
      effective_from: moment(plasticTaxRate.effective_from),
    }

  } else {

    initialValues = {
      effective_from: moment(),
      exempt_product_category_ids: [],
    }

  }

  defer(() => formService().initialize(initialValues))

}
