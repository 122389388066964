import { isEmpty, omit } from 'lodash'
import fetch from '../../../Services/fetch'
import { setFormFields } from '../../../State/formState'

export default (formState) => (dispatch, getState) => {

  const { values } = getState().screens.StockAdjustments.Report.formState

  if (isEmpty(values)) {
    dispatch(setFormFields(formState.values))
  } else {
    dispatch(setFormFields(omit(formState.values, [
      'unallocatedOnly',
      'includeExpected',
      'hideZeros',
      'sortBy',
      'sortDirection',
      'product_search',
      'transaction_batchNo',
    ])))
  }

  dispatch(fetch())

}
