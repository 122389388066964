import { connect } from 'react-redux'
import Chart from './Chart'

import selectConfig from './Selectors/selectConfig'

const mapStateToProps = (state) => ({
  sortBy: state.screens.StockAdjustments.Report.formState.values.sortBy,
  config: selectConfig(state),
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Chart)
