import React from 'react'
import PropTypes from 'prop-types'
import Screen from '../../Components/Screen'

import Header from './Components/Header'
import CourierServices from './Components/CourierServices'
import CourierZones from './Components/CourierZones'
import CourierRates from './Components/CourierRates'
import CourierCharges from './Components/CourierCharges'
import Courier from '../../Modals/Courier'
import CourierService from '../../Modals/CourierService'
import CourierZone from '../../Modals/CourierZone'
import CourierRate from '../../Modals/CourierRate'
import CourierCharge from '../../Modals/CourierCharge'

export default class extends React.PureComponent {
  
  static propTypes = {
    id: PropTypes.number,
    initialized: PropTypes.bool.isRequired,
    activeTab: PropTypes.number.isRequired,
    courierModal: PropTypes.object.isRequired,
    courierServiceModal: PropTypes.object.isRequired,
    courierZoneModal: PropTypes.object.isRequired,
    courierRateModal: PropTypes.object.isRequired,
    courierChargeModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onCloseCourierModal: PropTypes.func.isRequired,
    onCloseCourierServiceModal: PropTypes.func.isRequired,
    onCloseCourierZoneModal: PropTypes.func.isRequired,
    onCloseCourierRateModal: PropTypes.func.isRequired,
    onCloseCourierChargeModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {
    
    const {
      id,
      initialized,
      activeTab,
      courierModal,
      courierServiceModal,
      courierZoneModal,
      courierRateModal,
      courierChargeModal,
      onCloseCourierModal,
      onCloseCourierServiceModal,
      onCloseCourierZoneModal,
      onCloseCourierRateModal,
      onCloseCourierChargeModal,
    } = this.props

    return (

      <Screen
        HeaderInner={Header}
        headerStyle={{ height: 200 }}
        contentStyle={{ backgroundColor: 'white', paddingTop: 0, paddingBottom: 60, minHeight: 500 }}
      >
        
        {(initialized && activeTab === 0) && <CourierServices />}
        
        {(initialized && activeTab === 1) && <CourierZones />}
        
        {(initialized && activeTab === 2) && <CourierRates />}
        
        {(initialized && activeTab === 3) && <CourierCharges />}

        {courierModal.show && (
          <Courier
            id={id}
            onClose={onCloseCourierModal}
          />
        )}

        {courierServiceModal.show && (
          <CourierService
            id={courierServiceModal.id}
            courierId={courierServiceModal.courierId}
            onClose={onCloseCourierServiceModal}
          />
        )}

        {courierZoneModal.show && (
          <CourierZone
            id={courierZoneModal.id}
            courierId={courierZoneModal.courierId}
            onClose={onCloseCourierZoneModal}
          />
        )}

        {courierRateModal.show && (
          <CourierRate
            id={courierRateModal.id}
            courierId={courierRateModal.courierId}
            onClose={onCloseCourierRateModal}
          />
        )}

        {courierChargeModal.show && (
          <CourierCharge
            id={courierChargeModal.id}
            courierId={courierChargeModal.courierId}
            onClose={onCloseCourierChargeModal}
          />
        )}
        
      </Screen>

    )

  }

}
