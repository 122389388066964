import { createSelector } from 'reselect'

const selectFilters = state => state.screens.PlasticTax.Report.filters

export default createSelector(
  selectFilters,
  (filters) => {

    if (filters.supplierId.length) return true
    if (filters.countryCode.length) return true
    if (filters.status.length) return true
    if (filters.overdue) return true

    return false

  },
)
