import drawerProps from '../drawerProps'
import { getTransactions } from '../../../../../Api/transactions'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  const { product, stockLocation } = drawerProps()

  const { values } = getState().screens.StockAssessment.Report.formState

  const {
    date,
    time,
    aggregate,
    includeExpected,
    transaction_batchNo: batchNo,
  } = values

  const productId = aggregate ? [
    product.id,
    ...product.divides_into_products.map(row => row.id),
    ...product.division_of_products.map(row => row.id),
  ].join() : product.id

  const dateTime = `${date.format('YYYY-MM-DD')} ${time.format('HH:mm')}:00`

  const params = {
    'filter[product_id]': productId,
    'filter[transaction_at_up_to]': dateTime,
  }

  if (!includeExpected) params['filter[is_expectation]'] = 0

  if (stockLocation) params['filter[stock_location_id]'] = stockLocation.id

  if (batchNo) {
    params['filter[batch_no]'] = batchNo
    // If the user has applied a batch #, then we'll assume they want to see all transactions.
  } else {
    params['page[size]'] = 500
  }

  params.include = [
    'stock_location',
    'product',
  ].join()

  params.append = [
    'owner_type_formatted',
    'owner_type_slug',
    'quantity_formatted',
    'details',
  ].join()

  params.sort = '-transaction_at'

  dispatch(startFetching())

  try {

    const response = await getTransactions({ params })

    dispatch(setData(response.data.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetching())
    
  }

}
