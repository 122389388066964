import React from 'react'
import PropTypes from 'prop-types'
import { Input, Radio } from 'antd'
import { Module, LinkButton } from 'Traqa/Packages'
import styles from './Header.css'

const Header = (props) => {

  const {
    activeTab,
    searchTerm,
    onChangeTab,
    onChangeSearch,
    onSearch,
    onClickAdvanced,
  } = props

  const renderLeft = () => (

    <Radio.Group onChange={onChangeTab} value={activeTab}>
      <Radio.Button value="All" className={styles.tab}>All</Radio.Button>
      <Radio.Button value="Draft" className={styles.tab}>Draft</Radio.Button>
      <Radio.Button value="Expected" className={styles.tab}>Scheduled</Radio.Button>
      <Radio.Button value="Completed" className={styles.tab}>Assembled</Radio.Button>
    </Radio.Group>

  )

  const renderRight = () => (

    <React.Fragment>

      <LinkButton
        type='primary'
        onClick={onClickAdvanced}
      >
        Advanced
      </LinkButton>

      <div style={{ width: 16 }} />

      <Input.Search
        value={searchTerm}
        onChange={onChangeSearch}
        onSearch={onSearch}
        placeholder="Search"
        style={{ width: 200 }}
      />
    
    </React.Fragment>

  )
  
  return (

    <Module.Header
      renderLeft={renderLeft}
      renderRight={renderRight}
    />

  )
}

Header.propTypes = {
  activeTab: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onClickAdvanced: PropTypes.func.isRequired,
}

export default Header