import React from "react"
import PropTypes from "prop-types"
import Screen from '../../Components/Screen'

import Report from "./Components/Report"
import TransactionsDrawer from '../../Modules/TransactionsDrawer'

export default class ReconciliationScreen extends React.PureComponent {

  static propTypes = {
    initialized: PropTypes.bool.isRequired,
    transactionsDrawer: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    onCloseTransactionsDrawer: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {

    const {
      initialized,
      transactionsDrawer,
      onBack,
      onCloseTransactionsDrawer,
    } = this.props

    return (

      <Screen
        title="Reconciliation"
        contentLoading={!initialized}
        backToTop
        showBackButton
        onBack={onBack}
      >

        <Report />

        {transactionsDrawer.show && (

          <TransactionsDrawer
            {...transactionsDrawer}
            onClose={onCloseTransactionsDrawer}
          />

        )}

      </Screen>

    )
  }
}
