import { message } from 'antd'
import { deleteCustomerSite } from '../../../Api/customerSites'
import modalProps from '../modalProps'
import { handleException } from 'Traqa/Packages'

export default () => async () => {

  try {

    const { customerSiteId } = modalProps()

    await deleteCustomerSite({ id: customerSiteId })
    
    modalProps().onClose(true)

    message.success('Deleted Site')

  } catch (error) {

    handleException(error)

  }
}