import modalProps from '../../../modalProps'
import { createCustomer, updateCustomer } from '../../../../../Api/customers'
import fetchCustomers from '../../../../../Core/Services/fetchCustomers'
import { handleException } from 'Traqa/Packages'

export default (values) => async (dispatch) => {

  try {

    const { customerId } = modalProps()

    const data = {
      name: values.name,
    }

    let response

    if (customerId) {

      response = await updateCustomer({ id: customerId, data })

      modalProps().onClose(response.data.data, 'update')

    } else {

      response = await createCustomer({ data })

      modalProps().onClose(response.data.data, 'create')

    }

    dispatch(fetchCustomers({ force: true }))

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}