import screenProps from '../screenProps'
import { findUser } from '../../../Api/users'
import { setUser } from '../State/user'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch) => {

  const { id } = screenProps().match.params

  const params = {
    include: 'teams',
  }

  try {

    const response = await findUser({ id, params })

    const user = response.data.data

    dispatch(setUser(user))

  } catch (error) {

    handleException(error)

  }

}
