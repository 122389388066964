import { updateGoodsOutRecord } from '../../../../../Api/goodsOutRecords'
import fetch from '../../../Services/fetch'
//import fetchActivity from '../../../../../Modules/Activity/Services/fetch'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  const { goodsOutRecord } = getState().screens.GoodsOutRecord

  try {

    await updateGoodsOutRecord({ id: goodsOutRecord.id, data: { consignment_id: null } })

    await dispatch(fetch())

    //dispatch(fetchActivity())

  } catch (error) {

    handleException(error)

    throw error

  }

}
