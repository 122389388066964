import { connect } from "react-redux"
import MediaPopover from "./MediaPopover"
import upload from '../../Core/Services/upload'

const mapStateToProps = () => ({})

const actionCreators = {
  upload,
}

export default connect(mapStateToProps, actionCreators)(MediaPopover)
