import { getAssemblies } from '../../../../../Api/assemblies'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { setPagination } from '../State/pagination'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { advanced, activeTab, searchTerm, sorting, pagination } = getState().screens.Assemblies.Table

    const params = {}

    params.include = [
      'product',
      'stock_location',
      'user',
    ].join()

    params.append = [
      'availabilities',
    ].join()

    if (advanced.show) {

      const {
        productId,
        stockLocationIds,
        userIds,
        batchNo,
      } = advanced.values

      if (productId) params['filter[product_id]'] = productId

      if (stockLocationIds) params['filter[stock_location_id]'] = stockLocationIds.join(',')

      if (userIds) params['filter[user_id]'] = userIds.join(',')

      if (batchNo) params['filter[batch_no]'] = batchNo

    } else {

      if (activeTab !== 'All') params['filter[status]'] = activeTab
      
      if (searchTerm) params.search = searchTerm

    }

    params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : '-date_time'
    
    params['page[number]'] = pagination.pageNumber
    params['page[size]'] = pagination.pageSize

    dispatch(startFetching())

    const response = await getAssemblies({ params })

    dispatch(setData(response.data.data))
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
    }))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }
}