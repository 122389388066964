import { connect } from 'react-redux'
import Component from './Component'
import propsHelper from '../../propsHelper'

import onChangeFormState from './Handlers/onChangeFormState'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'
import onClickImages from './Handlers/onClickImages'

const mapStateToProps = (state) => ({
  id: Number(propsHelper().match.params.id),
  isNew: propsHelper().match.params.id === undefined,
  productCategories: state.core.productCategories,
  products: state.core.products,
  initialValues: state.screens.ProductEditor.Form.initialValues,
})

const actionCreators = {
  onChangeFormState,
  onSubmit,
  validate,
  onClickImages,
}

export default connect(mapStateToProps, actionCreators)(Component)
