import { defer } from 'lodash'
import modalProps from '../../../modalProps'
import formService from '../formService'
import { getProductCategory } from '../../../../../Api/productCategories'
import { handleException } from 'Traqa/Packages'

export default () => async () => {

  try {

    const { productCategoryId } = modalProps()

    if (productCategoryId) {

      const response = await getProductCategory({ id: productCategoryId })

      const productCategory = response.data.data

      const initialValues = {
        name: productCategory.name,
      }
    
      defer(() => formService().initialize(initialValues))

    } else {

      const initialValues = {}
    
      defer(() => formService().initialize(initialValues))

    }

  } catch (error) {

    handleException(error)
  }

}