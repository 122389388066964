import { createAction, handleActions } from 'redux-actions'

const setIsExpanded = createAction('MODULES/ACTIVITY/SET_IS_EXPANDED')
const toggleIsExpanded = createAction('MODULES/ACTIVITY/TOGGLE_IS_EXPANDED')
const resetIsExpanded = createAction('MODULES/ACTIVITY/RESET_IS_EXPANDED')

const defaultState = false

const reducer = handleActions(
  {
    [setIsExpanded]: () => true,
    [toggleIsExpanded]: (state) => state !== true,
    [resetIsExpanded]: () => false,
  },
  defaultState,
)

export { setIsExpanded, toggleIsExpanded, resetIsExpanded, reducer }
