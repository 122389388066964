import moment from 'moment'
import propsHelper from '../../../propsHelper'
import { startFetching, finishFetching } from '../State/isFetching'
import { setInitialValues } from '../State/initialValues'
import { getProduct } from '../../../../../Api/products'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch) => {

  try {

    const { id } = propsHelper().match.params

    dispatch(setInitialValues({
      valid_from: null,
      valid_to: null,
      product_categories: [],
      bill_of_materials: [],
      images: [],
    }))

    if (!id) return

    const params = {

      include: [
        'product_categories',
        'bill_of_materials',
        'images',
      ].join(),
      
    }

    dispatch(startFetching())

    const { data: { data: product } } = await getProduct({ id, params })

    const initialValues = {
      code: product.code,
      name: product.name,
      form: product.form,
      hs_code: product.hs_code,
      valid_from: product.valid_from ? moment(product.valid_from) : null,
      valid_to: product.valid_to ? moment(product.valid_to) : null,
      product_categories: product.product_categories.map(cat => cat.id),
      bill_of_materials: [],
      images: product.images,
    }

    product.bill_of_materials.forEach((entry) => {

      initialValues.bill_of_materials.push({
        quantity: entry.pivot.quantity,
        component_id: entry.pivot.component_id,
      })

    })

    dispatch(setInitialValues(initialValues))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }
}