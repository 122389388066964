import React from 'react'
import PropTypes from 'prop-types'
import { Input, Checkbox } from 'antd'
import { LinkButton } from 'Traqa/Packages'
import styles from './ListHeader.css'

const ListHeader = (props) => {

  const {
    showItems,
    searchTerm,
    hideZeros,
    onToggleShowItems,
    onChangeSearchTerm,
    onToggleHideZeros,
  } = props
  
  return (

    <div className={styles.wrapper}>

      <LinkButton icon={showItems ? "down" : "right"} className={styles.toggle} onClick={onToggleShowItems} />
      
      <div className={styles.title}>Items</div>
      
      {showItems && (
        <React.Fragment>
          <Input.Search
            value={searchTerm}
            onChange={onChangeSearchTerm}
            placeholder="Filter"
            className={styles.search}
          />
          <div style={{ width: 15 }} />
          <Checkbox
            checked={hideZeros}
            onChange={onToggleHideZeros}
          >
            Hide Zeros
          </Checkbox>
        </React.Fragment>
      )}

    </div>

  )
}

ListHeader.propTypes = {
  showItems: PropTypes.bool.isRequired,
  searchTerm: PropTypes.string.isRequired,
  hideZeros: PropTypes.bool.isRequired,
  onToggleShowItems: PropTypes.func.isRequired,
  onChangeSearchTerm: PropTypes.func.isRequired,
  onToggleHideZeros: PropTypes.func.isRequired,
}

export default ListHeader