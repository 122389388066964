import chance from 'chance'
import moment from 'moment'
import screenProps from '../../../screenProps'
import { getSalesOrder } from '../../../../../Api/salesOrders'
import { setInitialValues } from '../State/initialValues'
import { getUser } from 'Traqa/Helpers'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    if (id === 'new') {

      const initialValues = {
        date: moment(),
        due_date: moment(),
        user_id: getUser().id,
        is_export: false,
        sales_order_items: [
          {
            guid: chance().guid(),
          },
        ],
      }

      dispatch(setInitialValues(initialValues))

    } else {

      const {
        data: {
          data: salesOrder,
        },
      } = await getSalesOrder({
        id,
        params: {
          include: 'sales_order_items'
        },
      })

      const initialValues = {
        number: salesOrder.number,
        customer_id: salesOrder.customer_id,
        customer_site_id: salesOrder.customer_site_id,
        is_export: !!salesOrder.is_export,
        customer_po_no: salesOrder.customer_po_no,
        date: moment(salesOrder.date),
        due_date: moment(salesOrder.due_date),
        description: salesOrder.description,
        user_id: salesOrder.user_id,
        sales_order_items: salesOrder.sales_order_items.map(salesOrderItem => ({
          guid: chance().guid(),
          id: salesOrderItem.id,
          product_id: salesOrderItem.product_id,
          price: salesOrderItem.price,
          quantity: salesOrderItem.quantity,
          pending: salesOrderItem.pending,
          expected: salesOrderItem.expected,
          picked: salesOrderItem.picked,
          completed: salesOrderItem.completed,
        })),
      }

      dispatch(setInitialValues(initialValues))

    } 

  } catch (error) {

    handleException(error)

  }

}
