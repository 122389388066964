import { connect } from "react-redux"
import Details from "./Details"

const mapStateToProps = (state) => ({
  suppliers: state.core.suppliers,
  stockLocations: state.core.stockLocations,
  users: state.core.users,
  isNew: !state.screens.GoodsInRecord.goodsInRecord,
})

const actionCreators = {
  
}

export default connect(mapStateToProps, actionCreators)(Details)
