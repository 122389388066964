import { connect } from 'react-redux'
import Footer from './Footer'

import selectTotals from './Selectors/selectTotals'

import onClickExport from './Handlers/onClickExport'

const mapStateToProps = (state) => ({
  totals: selectTotals(state),
  isFetchingTotals: state.screens.StockAdjustments.Report.isFetchingTotals,
})

const actionCreators = {
  onClickExport,
}

export default connect(mapStateToProps, actionCreators)(Footer)
