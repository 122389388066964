import { createAction, handleActions } from 'redux-actions'

const setHideInSage = createAction('SCREENS/GOODS_IN_RECORDS/GOODS_IN_RECORDS_TABLE/SET_HIDE_IN_SAGE')
const resetHideInSage = createAction('SCREENS/GOODS_IN_RECORDS/GOODS_IN_RECORDS_TABLE/RESET_HIDE_IN_SAGE')

const defaultState = false

const reducer = handleActions(
  {
    [setHideInSage]: (state, action) => action.payload,
    [resetHideInSage]: () => defaultState,
  },
  defaultState
)

export { setHideInSage, resetHideInSage, reducer }
