import fetchProductCategories from '../../../Core/Services/fetchProductCategories'
import fetchUsers from '../../../Core/Services/fetchUsers'
import fetchProductCategoriesTableData from '../Components/ProductCategoriesTable/Services/fetch'

export default () => (dispatch) => {
  
  dispatch(fetchProductCategories())
  dispatch(fetchUsers())

  dispatch(fetchProductCategoriesTableData())
  
}