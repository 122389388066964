import PropTypes from 'prop-types'
import { useField } from 'react-final-form'

const BomErrorSpy = (props) => {

  const { index, children } = props

  const fieldA = useField(`bill_of_materials[${index}].quantity`)
  const fieldB = useField(`bill_of_materials[${index}].component_id`)

  const meta = {
    touched: fieldA.meta.touched && fieldB.meta.touched,
    error: null,
  }

  if (fieldA.meta.error) meta.error = fieldA.meta.error

  else if (fieldB.meta.error) meta.error = fieldB.meta.error

  return children(meta)

}

BomErrorSpy.propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.func.isRequired,
}

export default BomErrorSpy