import modalProps from '../../../modalProps'
import { createCourierService, updateCourierService } from '../../../../../Api/courierServices'
import { handleException } from 'Traqa/Packages'

export default (values) => async () => {

  const { id, courierId } = modalProps()

  const data = {
    name: values.name,
  }

  try {

    if (id) {

      await updateCourierService({ id, data })

    } else {

      data.courier_id = courierId

      await createCourierService({ data })

    }
    
    modalProps().onClose(true)

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
