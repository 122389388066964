import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { Module } from 'Traqa/Packages'

const Footer = (props) => {

  const {
    onClickExport,
  } = props

  const renderLeft = () => (

    <Button
      type="default"
      icon="download"
      onClick={onClickExport}
    >
      Export Data
    </Button>

  )
  
  return (

    <Module.Footer
      renderLeft={renderLeft}
    />

  )
}

Footer.propTypes = {
  onClickExport: PropTypes.func.isRequired,
}

export default Footer