import { connect } from 'react-redux'
import PrimaryDetails from './PrimaryDetails'

import selectCustomerSites from './Selectors/selectCustomerSites'

const mapStateToProps = (state) => ({
  stockLocations: state.core.stockLocations,
  customers: state.core.customers,
  customerSites: selectCustomerSites(state),
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(PrimaryDetails)
