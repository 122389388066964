import React from 'react'
import PropTypes from 'prop-types'
import { Card, Tag } from 'antd'
import { Detail } from 'Traqa/Packages'
import styles from './Component.css'

export default class _ProductDetails_ extends React.PureComponent {

  static propTypes = {
    product: PropTypes.object,
  }

  static defaultProps = {
    product: null,
  }

  render () {

    const {
      product,
    } = this.props

    if (!product) return null

    return (

      <Card title="Product Details">

        <Detail label="Code" className={styles.detail}>
          {product.code}
        </Detail>

        <Detail label="Name" className={styles.detail}>
          {product.name}
        </Detail>

        <Detail label="Form" className={styles.detail}>
          <Tag color='volcano'>{product.form}</Tag>
        </Detail>

        <Detail label="Categories" className={styles.detail}>
          {product.product_categories.map(category => category.name).join(', ')}
        </Detail>
        
      </Card>

    )
  }
}