import { createSelector } from 'reselect'
import { groupBy, keyBy, orderBy, keys, has, sumBy } from 'lodash'

const stocktakeItemsSelector = state => state.screens.Stocktake.Editor.formState.values.stocktake_items || []
const productsSelector = state => state.core.products
const searchTermSelector = state => state.screens.Stocktake.Editor.searchTerm
const hideZerosSelector = state => state.screens.Stocktake.Editor.hideZeros

const selectListRows = createSelector(
  stocktakeItemsSelector,
  productsSelector,
  searchTermSelector,
  hideZerosSelector,
  (stocktakeItems, products, searchTerm, hideZeros) => {
    
    const ListRows = []

    const filteredProducts = searchTerm ? products.filter(product => {
      const string = `${product.code} ${product.name} ${product.form}`.toLowerCase()
      const categories = product.product_categories.map(row => row.name).join(' ').toLowerCase()
      if (string.includes(searchTerm.toLowerCase())) return true
      if (categories.includes(searchTerm.toLowerCase())) return true
      return false
    }) : products

    const productsById = keyBy(filteredProducts, "id")

    const stocktakeItemsByProductId = groupBy(stocktakeItems.map((item, index) => ({ ...item, index })), "product_id")

    let productsWithStocktakeItems = keys(stocktakeItemsByProductId).filter(productId => has(productsById, productId)).map(productId => ({
      ...productsById[productId],
      stocktakeItems: stocktakeItemsByProductId[productId],
    }))

    productsWithStocktakeItems = orderBy(productsWithStocktakeItems, 'code')

    productsWithStocktakeItems.forEach(product => {

      if (hideZeros && !sumBy(product.stocktakeItems, 'quantity')) return

      ListRows.push({
        component: 'GroupHeader',
        componentProps: {
          product,
        },
        height: 40,
      })

      product.stocktakeItems.forEach((stocktakeItem, groupIndex) => {

        ListRows.push({
          component: 'GroupItem',
          componentProps: {
            product,
            stocktakeItem,
            groupIndex,
          },
          height: groupIndex === 0 ? 74 : 45,
        })

      })

      ListRows.push({
        component: 'GroupFooter',
        componentProps: {
          product,
        },
        height: 65,
      })

    })

    return ListRows

  }
)

export default selectListRows