import { resetInitialValues } from '../State/initialValues'
import { resetFormState } from '../State/formState'
import { resetSelectedItems } from '../State/selectedItems'

export default () => async (dispatch) => {

  dispatch(resetInitialValues())
  dispatch(resetFormState())
  dispatch(resetSelectedItems())
  
}