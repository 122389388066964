import { createAction, handleActions } from 'redux-actions'

const toggleHideZeros = createAction("SCREENS/STOCKTAKE/EDITOR/TOGGLE_HIDE_ZEROS")
const resetHideZeros = createAction("SCREENS/STOCKTAKE/EDITOR/RESET_HIDE_ZEROS")

const defaultState = false

const reducer = handleActions(
  {
    [toggleHideZeros]: (state) => !state,
    [resetHideZeros]: () => defaultState,
  },
  defaultState
)

export { toggleHideZeros, resetHideZeros, reducer }
