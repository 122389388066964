import React from "react"
import PropTypes from "prop-types"
import { Tabs, Button, Tag } from 'antd'
import Screen from '../../Components/Screen'
import TransactionsTable from '../../Modules/TransactionsTable'
import styles from './Component.css'

import MoreMenu from "./Components/MoreMenu"
import ActivityChart from "./Components/ActivityChart"
import ProductDivisions from "./Components/ProductDivisions"
import ProductValues from "./Components/ProductValues"
import ProductValue from "../../Modals/ProductValue"
import ProductDetails from "./Components/ProductDetails"
import Availability from "./Components/Availability"

export default class ProductScreen extends React.PureComponent {

  static propTypes = {
    id: PropTypes.number.isRequired,
    isFetching: PropTypes.bool.isRequired,
    product: PropTypes.object,
    activeTab: PropTypes.string.isRequired,
    productValueModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onChangeActiveTab: PropTypes.func.isRequired,
    onCloseProductValueModal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    product: null,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  renderHeaderRight = () => (

    <div className={styles.headerRight}>

      <Button type="primary" onClick={this.props.onClickEdit}>
        Edit Product
      </Button>

      <div style={{ width: 8 }} />

      <MoreMenu />
    
    </div>
    
  )

  render () {

    const {
      id,
      isFetching,
      product,
      activeTab,
      productValueModal,
      onBack,
      onChangeActiveTab,
      onCloseProductValueModal,
    } = this.props

    return (

      <Screen
        title={!!product && (
          <div>
            {`${product.code} - ${product.name}`}
            <Tag color='volcano' style={{ marginLeft: 8, fontSize: 20, height: 30, lineHeight: '25px' }}>{product.form}</Tag>
          </div>
        )}
        headerRight={this.renderHeaderRight()}
        contentLoading={!product || (!product && isFetching)}
        showBackButton
        onBack={onBack}
      >

        <div className={styles.wrapper}>

          <div className={styles.main}>

            <ActivityChart />

            <br />

            <ProductDivisions />

            <br />

            <Tabs
              activeKey={activeTab}
              type='card'
              onChange={onChangeActiveTab}
              style={{ height: '40px' }}
            >
              <Tabs.TabPane tab='Transactions' key='1' />
              <Tabs.TabPane tab='Allocations' key='2' />
              <Tabs.TabPane tab='Scheduled' key='3' />
              <Tabs.TabPane tab='Values' key='4' />
            </Tabs>

            {activeTab === '1' && <TransactionsTable productId={id} withDivisionProducts format='transactions' />}

            {activeTab === '2' && <TransactionsTable productId={id} withDivisionProducts format='allocations' />}

            {activeTab === '3' && <TransactionsTable productId={id} withDivisionProducts format='expectations' />}

            {activeTab === '4' && <ProductValues />}

          </div>

          <div className={styles.sidebar}>

            <ProductDetails />

            <br />

            <Availability />
            
          </div>

        </div>

        {productValueModal.show && (
          <ProductValue
            {...productValueModal}
            onClose={onCloseProductValueModal}
          />
        )}

      </Screen>

    )
  }
}
