import React from "react"
import PropTypes from "prop-types"
import { Button } from 'antd'
import Screen from '../../Components/Screen'
import StocktakesTable from "./Components/StocktakesTable"

import CreateStocktake from '../../Modals/CreateStocktake'

export default class StocktakesScreen extends React.PureComponent {

  static propTypes = {
    createStocktakeModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onCloseCreateStocktakeModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  renderHeaderRight = () => (

    <Button type="primary" onClick={this.props.onClickNew}>
      New Stocktake
    </Button>
    
  )

  render () {

    const {
      createStocktakeModal,
      onCloseCreateStocktakeModal,
    } = this.props

    return (

      <Screen
        title="Stocktakes"
        headerRight={this.renderHeaderRight()}
      >

        <StocktakesTable />

        {createStocktakeModal.show && (
          <CreateStocktake
            onClose={onCloseCreateStocktakeModal}
          />
        )}

      </Screen>

    )
  }
}
