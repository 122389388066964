import { createAction, handleActions } from 'redux-actions'

const loadKits = createAction("CORE/PRODUCTS/LOAD_KITS")

const defaultState = []

const reducer = handleActions(
  {
    [loadKits]: (state, action) => action.payload,
  },
  defaultState
)

export { loadKits, reducer }