import { find } from 'lodash'
import { toggleActivity } from '../../../State/expandedActivities'
import { closeLog } from '../../../State/expandedLogs'

export default (key) => (dispatch, getState) => {

  const { data } = getState().modules.Activity

  const activity = find(data, row => row.key === key)

  dispatch(toggleActivity(key))

  activity.logs.forEach(log => dispatch(closeLog(log.id)))
  
}
