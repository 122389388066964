import { combineReducers } from "redux"

import { reducer as initialValues } from "./State/initialValues"
import { reducer as formState } from "./State/formState"
import { reducer as searchTerm } from "./State/searchTerm"
import { reducer as hideZeros } from "./State/hideZeros"
import { reducer as showItems } from "./State/showItems"

export default combineReducers({
  initialValues,
  formState,
  searchTerm,
  hideZeros,
  showItems,
})
