import { has } from 'lodash'
import { login } from '../../Api/auth'

export default (credentials) => async () => {
  
  try {

    const response = await login({ data: credentials })

    window.location = response.data.redirectPath

    return undefined

  } catch (error) {

    if (has(error,'response.status') && error.response.status === 401) {

      throw new Error('INVALID_CREDENTIALS')

    }

    throw error

  }

}