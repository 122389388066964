import { createAction, handleActions } from 'redux-actions'

const startFetchingHoldingWeeks = createAction('SCREENS/DASHBOARD/COLUMN_CHARTS/START_FETCHING_HOLDING_WEEKS')
const finishFetchingHoldingWeeks = createAction('SCREENS/DASHBOARD/COLUMN_CHARTS/FINISH_FETCHING_HOLDING_WEEKS')

const defaultState = false

const reducer = handleActions(
  {
    [startFetchingHoldingWeeks]: () => true,
    [finishFetchingHoldingWeeks]: () => defaultState,
  },
  defaultState,
)

export { startFetchingHoldingWeeks, finishFetchingHoldingWeeks, reducer }
