import { connect } from 'react-redux'
import Header from './Header'

import selectActiveTab from '../../Selectors/selectActiveTab'

import onBack from './Handlers/onBack'
import onEdit from './Handlers/onEdit'
import onResetPassword from './Handlers/onResetPassword'
import onChangeTab from './Handlers/onChangeTab'

const mapStateToProps = (state) => ({
  user: state.screens.User.user,
  activeTab: selectActiveTab(state),
})

const actionCreators = {
  onBack,
  onEdit,
  onResetPassword,
  onChangeTab,
}

export default connect(mapStateToProps, actionCreators)(Header)
