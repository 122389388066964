import React from 'react'
import PropTypes from 'prop-types'
import { Table as AntTable, Menu, Icon, Dropdown } from 'antd'
import ReactCountryFlag from 'react-country-flag'
import countryList from 'react-select-country-list'
import { LinkButton } from 'Traqa/Packages'

const Table = (props) => {

  const {
    data,
    isFetching,
    onChange,
    onClick,
    onArchive,
    onUnarchive,
    onDelete,
  } = props

  const menu = (record) => (

    <Menu style={{ minWidth: 150 }}>

      {!record.archived_at && (
        <Menu.Item onClick={() => onArchive(record)}>
          <Icon type="eye-invisible" />
          Archive
        </Menu.Item>
      )}

      {!!record.archived_at && (
        <Menu.Item onClick={() => onUnarchive(record)}>
          <Icon type="eye" />
          Unarchive
        </Menu.Item>
      )}

      <Menu.Item onClick={() => onDelete(record)}>
        <Icon type="delete" />
        Delete
      </Menu.Item>

    </Menu>

  )
  
  return (

    <AntTable
      dataSource={data}
      loading={isFetching}
      rowKey="id"
      pagination={false}
      onChange={onChange}
    >

      <AntTable.Column
        key='name'
        title="Name"
        sorter
        render={(text, record) => (
          <LinkButton type="primary" onClick={() => onClick(record)}>
            {record.name}
          </LinkButton>
        )}
      />

      <AntTable.Column
        key='telephone'
        title="Telephone"
        sorter
        render={(text, record) => record.telephone}
      />

      <AntTable.Column
        key='email'
        title="Email"
        sorter
        render={(text, record) => record.email}
      />

      <AntTable.Column
        key='country_code'
        title="Country"
        sorter
        render={(text, record) => (
          <div style={{ display: 'flex' }}>
            <ReactCountryFlag countryCode={record.country_code} svg style={{ width: '1.5em', height: '1.5em', marginRight: 8 }} />
            {record.country_code ? `${countryList().getLabel(record.country_code)} [${record.country_code}]` : null}
          </div>
        )}
      />

      <AntTable.Column
        key='archived_at'
        width={150}
        render={(text, record) => record.archived_at ? (
          <div style={{ color: '#999' }}>
            <Icon type="eye-invisible" style={{ color: '#999' }} />
            {' Archived'}
          </div>
        ) : null}
      />

      <AntTable.Column
        key='options'
        width={150}
        render={(text, record) => (
          <Dropdown
            trigger={["click"]}
            placement="bottomRight"
            overlay={menu(record)}
          >
            <LinkButton iconRight="caret-down">Options</LinkButton>
          </Dropdown>
        )}
      />

    </AntTable>

  )
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
  onUnarchive: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default Table