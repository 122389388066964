import { retakeStockshots } from '../../../Api/stockshots'
import fetch from './fetch'
import { setSubmitting } from '../State/submitting'

export default () => async (dispatch) => {

  dispatch(setSubmitting(true))

  try {

    await retakeStockshots()

    dispatch(fetch())

  } catch (error) {
    
    throw error

  } finally {

    dispatch(setSubmitting(false))

  }

}
