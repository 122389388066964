import { createAction, handleActions } from 'redux-actions'

const startFetching = createAction('SCREENS/DASHBOARD/GOODS_IN_RECORDS/START_FETCHING')
const finishFetching = createAction('SCREENS/DASHBOARD/GOODS_IN_RECORDS/FINISH_FETCHING')

const defaultState = false

const reducer = handleActions(
  {
    [startFetching]: () => true,
    [finishFetching]: () => defaultState,
  },
  defaultState,
)

export { startFetching, finishFetching, reducer }
