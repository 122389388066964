import { connect } from 'react-redux'
import Form from './Form'

import modalProps from '../../modalProps'

import onChangeFormState from './Handlers/onChangeFormState'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'

const mapStateToProps = (state) => ({
  courierId: modalProps().courierId,
  courierServices: state.core.courierServices,
  courierZones: state.core.courierZones,
})

const actionCreators = {
  onChangeFormState,
  onSubmit,
  validate,
}

export default connect(mapStateToProps, actionCreators)(Form)
