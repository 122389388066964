import { connect } from 'react-redux'
import ImageViewer from './ImageViewer'

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onDrop from './Handlers/onDrop'
import onRename from './Handlers/onRename'
import onDelete from './Handlers/onDelete'

const mapStateToProps = (state) => ({
  data: state.modals.ImageViewer.data,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onDrop,
  onRename,
  onDelete,
}

export default connect(mapStateToProps, actionCreators)(ImageViewer)
