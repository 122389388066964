import { push } from 'connected-react-router'
import { message } from 'antd'
import { convertGoodsInRecordIntoPurchaseOrder } from '../../../../../Api/goodsInRecords'
import fetch from './fetch'
import { getTeamUrl } from 'Traqa/Helpers'
import { handleException } from 'Traqa/Packages'

export default (id, purchaseOrderNumber) => async (dispatch) => {

  try {

    const data = {
      number: purchaseOrderNumber,
    } 

    const response = await convertGoodsInRecordIntoPurchaseOrder({ id, data })

    dispatch(fetch())

    message.success('PO Created')

    dispatch(push(getTeamUrl(`purchase_orders/${response.data.data.id}`)))

  } catch (error) {

    handleException(error)

  }
  
}
