import { createAction, handleActions } from 'redux-actions'

const loadSuppliers = createAction("CORE/SUPPLIERS/LOAD_SUPPLIERS")

const defaultState = []

const reducer = handleActions(
  {
    [loadSuppliers]: (state, action) => action.payload,
  },
  defaultState
)

export { loadSuppliers, reducer }