import modalProps from '../../../modalProps'
import { createCustomerSite, updateCustomerSite } from '../../../../../Api/customerSites'
import fetchCustomerSites from '../../../../../Core/Services/fetchCustomerSites'
import { handleException } from 'Traqa/Packages'

export default (values) => async (dispatch) => {

  try {

    const { customerSiteId, customerId } = modalProps()

    const data = {
      customer_id: customerId,
      name: values.name,
      delivery_notes: values.delivery_notes,
      requires_tail_lift: values.requires_tail_lift,
    }

    if (customerSiteId) {

      await updateCustomerSite({ id: customerSiteId, data })

    } else {

      await createCustomerSite({ data })

    }

    dispatch(fetchCustomerSites({ force: true }))
    
    modalProps().onClose(true)

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}