import React from 'react'
import { Screen } from 'Traqa/Packages'
import AppBar from '../../Components/AppBar'

export default class Temp extends React.PureComponent {

  render = () => (
    
    <Screen AppBar={AppBar} />

  )
}