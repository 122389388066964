import React from 'react'
import { Module } from 'Traqa/Packages'

const Header = () => (

  <Module.Header
    title="Goods In Records"
  />

)

export default Header