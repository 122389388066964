import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, Spin } from 'antd'
import styles from './styles.css'

const Content = (props) => {

  const { data, fetching, submitting, onSubmit } = props

  return (

    <div className={styles.wrapper}>

      {!data || fetching ? <Spin /> : (

        <div className={styles.status}>

          {data.is_up_to_date && (
            <React.Fragment>
              <Icon type='check-circle' style={{ fontSize: 32, color: 'limegreen' }} />
              {'FIFO landed costs are up to date'}
            </React.Fragment>
          )}
    
          {!data.is_up_to_date && (
            <React.Fragment>
              <Icon type='warning' style={{ fontSize: 32, color: 'orange' }} />
              {'FIFO landed costs are not up to date'}
            </React.Fragment>
          )}
    
          <Button
            type='primary'
            disabled={data.is_up_to_date || data.is_retake_requested}
            loading={data.is_retaking || submitting}
            onClick={onSubmit}
          >
            {!data.is_retake_requested && !data.is_retaking && 'Update'}
            {data.is_retake_requested && !data.is_retaking && 'Update Requested'}
            {!data.is_retake_requested && data.is_retaking && 'Update Running'}
          </Button>

        </div>

      )}

    </div>
  )
}

Content.propTypes = {
  data: PropTypes.object,
  fetching: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

Content.defaultProps = {
  data: null,
}

export default Content
