import { resetInitialized } from '../State/initialized'
import { resetAssembly } from '../State/assembly'
import resetEditor from '../Components/Editor/Services/reset'

export default () => async (dispatch) => {

  dispatch(resetInitialized())
  dispatch(resetAssembly())
  dispatch(resetEditor())
  
}