import { getDashboardHoldingWeeksByProduct } from '../../../../../Api/dashboard'
import { setHoldingWeeks } from '../State/holdingWeeks'
import { startFetchingHoldingWeeks, finishFetchingHoldingWeeks } from '../State/isFetchingHoldingWeeks'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  const { holdingControls } = getState().screens.Dashboard.ColumnCharts

  const params = {
    product_category_id: holdingControls.productCategoryId,
  }

  dispatch(startFetchingHoldingWeeks())

  try {

    const response = await getDashboardHoldingWeeksByProduct({ params })

    dispatch(setHoldingWeeks(response.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetchingHoldingWeeks())
    
  }

}
