import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openProductCategoryModal = createAction('SCREENS/PRODUCT_CATEGORIES/OPEN_CREATE_PRODUCT_CATEGORY_MODAL')
const closeProductCategoryModal = createAction('SCREENS/PRODUCT_CATEGORIES/CLOSE_CREATE_PRODUCT_CATEGORY_MODAL')

const defaultState = {
  show: false,
  productCategoryId: null,
}

const reducer = handleActions(
  {
    [openProductCategoryModal]: (state, action) => Object.assign({}, state, {
      show: true,
      productCategoryId: get(action, 'payload.productCategoryId', null),
    }),
    [closeProductCategoryModal]: () => Object.assign({}, defaultState),
  },
  defaultState
)

export { openProductCategoryModal, closeProductCategoryModal, reducer }