import React from 'react'
import PropTypes from 'prop-types'
import { Field, FormSpy } from 'react-final-form'
import { Form, Row, Col, Select, DatePicker } from 'antd'
import { Module } from 'Traqa/Packages'

const PrimaryDetails = (props) => {

  const {
    stockLocations,
    customers,
    customerSites,
  } = props
  
  return (

    <Module.Section
      contentStyle={{ paddingBottom: 15 }}
    >

      <FormSpy subscription={{ values: true, submitting: true }}>

        {({ values, submitting, form }) => (

          <div>

            <Row gutter={8}>

              <Col span={12}>

                <Field name='date' allowNull>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Date'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <DatePicker
                        {...input}
                        disabled={submitting}
                        format='DD/MM/YYYY'
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>
              
              </Col>

              <Col span={12}>

                <Field name='stock_location_id'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Location'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        disabled={submitting}
                        allowClear
                        showSearch
                        optionFilterProp='children'
                      >
                        <Select.OptGroup label='Active'>
                          {stockLocations.filter(stockLocation => !stockLocation.archived_at).map(stockLocation => (
                            <Select.Option key={stockLocation.id} value={stockLocation.id}>
                              {stockLocation.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                        <Select.OptGroup label='Archived'>
                          {stockLocations.filter(stockLocation => !!stockLocation.archived_at).map(stockLocation => (
                            <Select.Option key={stockLocation.id} value={stockLocation.id} style={{ color: '#999' }}>
                              {stockLocation.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                      </Select>
                    </Form.Item>
                  )}
                </Field>

              </Col>

            </Row>

            <Field name='customer_id'>
              {({ input, meta }) => (
                <Form.Item
                  label='Customer'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Select
                    {...input}
                    onChange={value => {
                      input.onChange(value)
                      form.change('customer_site_id', null)
                    }}
                    disabled={submitting}
                    allowClear
                    showSearch
                    optionFilterProp='children'
                  >
                    <Select.OptGroup label='Active'>
                      {customers.filter(customer => !customer.archived_at).map(customer => (
                        <Select.Option key={customer.id} value={customer.id}>
                          {customer.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                    <Select.OptGroup label='Archived'>
                      {customers.filter(customer => !!customer.archived_at).map(customer => (
                        <Select.Option key={customer.id} value={customer.id} style={{ color: '#999' }}>
                          {customer.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  </Select>
                </Form.Item>
              )}
            </Field>

            {values.courier_id !== 'null' && (

              <Field name='customer_site_id'>
                {({ input, meta }) => (
                  <Form.Item
                    label='Site'
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Select
                      {...input}
                      disabled={submitting}
                      allowClear
                      showSearch
                      optionFilterProp='children'
                    >
                      <Select.OptGroup label='Active'>
                        {customerSites.filter($customerSite => !$customerSite.archived_at).map($customerSite => (
                          <Select.Option key={$customerSite.id} value={$customerSite.id}>
                            {$customerSite.name}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                      <Select.OptGroup label='Archived'>
                        {customerSites.filter($customerSite => !!$customerSite.archived_at).map($customerSite => (
                          <Select.Option key={$customerSite.id} value={$customerSite.id} style={{ color: '#999' }}>
                            {$customerSite.name}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                    </Select>
                  </Form.Item>
                )}
              </Field>

            )}

          </div>
      
        )}

      </FormSpy>
  
    </Module.Section>

  )
  
}

PrimaryDetails.propTypes = {
  stockLocations: PropTypes.array.isRequired,
  customers: PropTypes.array.isRequired,
  customerSites: PropTypes.array.isRequired,
}

export default PrimaryDetails
