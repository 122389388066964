import moduleProps from '../moduleProps'
import fetch from '../Services/fetch'
import fetchStockLocation from '../../../Core/Services/fetchStockLocations'
import fetchProducts from '../../../Core/Services/fetchProducts'

export default (props) => (dispatch) => {

  moduleProps(props)

  dispatch(fetch())

  dispatch(fetchStockLocation())

  dispatch(fetchProducts())
  
}
