import modalProps from '../modalProps'
import { resetManualRunRate } from '../State/manualRunRate'

export default () => (dispatch) => {

  modalProps.reset()

  dispatch(resetManualRunRate())
  
}
