import { defer } from 'lodash'
import modalProps from '../../../modalProps'
import { findCourier } from '../../../../../Api/couriers'
import formService from '../formService'
import { handleException } from 'Traqa/Packages'

export default () => async () => {

  const { id } = modalProps()

  if (!id) return

  try {

    const response = await findCourier({ id })

    const courier = response.data.data

    const initialValues = {
      name: courier.name,
      tail_lift_surcharge: courier.tail_lift_surcharge,
    }

    defer(() => formService().initialize(initialValues))

  } catch (error) {

    handleException(error)

  }

}
