import { createAction, handleActions } from 'redux-actions'

const openCreateAssemblyModal = createAction('SCREENS/ASSEMBLIES/OPEN_CREATE_ASSEMBLY_MODAL')
const closeCreateAssemblyModal = createAction('SCREENS/ASSEMBLIES/CLOSE_CREATE_ASSEMBLY_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openCreateAssemblyModal]: (state) => Object.assign({}, state, {
      show: true,
    }),
    [closeCreateAssemblyModal]: () => Object.assign({}, defaultState),
  },
  defaultState
)

export { openCreateAssemblyModal, closeCreateAssemblyModal, reducer }