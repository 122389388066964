import modalProps from '../../../modalProps'
import { createUser, updateUser } from '../../../../../Api/users'
import { handleException } from 'Traqa/Packages'

export default (values) => async () => {

  const { id } = modalProps()

  const data = {
    first_name: values.first_name,
    last_name: values.last_name,
    username: values.username,
    email: values.email,
  }

  let response

  try {

    if (id) {

      response = await updateUser({ id, data })

    } else {

      data.password = values.password

      response = await createUser({ data })

    }
    
    modalProps().onClose(response.data.data)

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
