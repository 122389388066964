import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field, FormSpy } from 'react-final-form'
import { Form, Input, Divider } from 'antd'
import { ExposeFormToHelper } from 'Traqa/Packages'
import formService from './formService'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    id: PropTypes.number,
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  static defaultProps = {
    id: null,
  }

  render () {

    const {
      id,
      onChangeFormState,
      onSubmit,
      validate,
    } = this.props
    
    return (

      <FormProvider
        validate={values => validate(values, id)}
        onSubmit={onSubmit}
      >

        {({ handleSubmit, submitting }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            autoComplete='off'
          >

            <ExposeFormToHelper helper={formService} />

            <FormSpy subscription={{ submitting: true }} onChange={onChangeFormState} />

            <Field name='first_name'>
              {({ input, meta }) => (
                <Form.Item
                  label='First Name'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Input
                    {...input}
                    disabled={submitting}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='last_name'>
              {({ input, meta }) => (
                <Form.Item
                  label='Last Name'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Input
                    {...input}
                    disabled={submitting}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='username'>
              {({ input, meta }) => (
                <Form.Item
                  label='Username'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Input
                    {...input}
                    disabled={submitting}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='email'>
              {({ input, meta }) => (
                <Form.Item
                  label='Email'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Input
                    {...input}
                    disabled={submitting}
                  />
                </Form.Item>
              )}
            </Field>

            {!id && (

              <React.Fragment>

                <Divider />

                <Field name='password'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Password'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Input.Password
                        {...input}
                        disabled={submitting}
                      />
                    </Form.Item>
                  )}
                </Field>

                <Field name='password_confirm'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Confirm'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Input.Password
                        {...input}
                        disabled={submitting}
                      />
                    </Form.Item>
                  )}
                </Field>

              </React.Fragment>

            )}

          </Form>

        )}

      </FormProvider>

    )
    
  }

}
