import { createSelector } from 'reselect'
import { find } from 'lodash'

const selectSuppliers = state => state.core.suppliers
const selectSupplierId = state => state.screens.GoodsInRecord.Editor.formState.values.supplier_id

export default createSelector(
  selectSuppliers,
  selectSupplierId,
  (suppliers, supplierId) => {
    const supplier = find(suppliers, row => row.id === supplierId)
    if (!supplier) return false
    return supplier.country_code !== 'GB'
  },
)
