import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'

import Form from './Components/Form'

export default class ManualRunRate extends React.PureComponent {

  static propTypes = {
    manualRunRate: PropTypes.object,
    values: PropTypes.object.isRequired,
    submitting: PropTypes.bool.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {

    const {
      manualRunRate,
      values,
      submitting,
      onSubmit,
      onDelete,
      onClose,
    } = this.props

    const footer = []

    if (manualRunRate) {
      footer.push(
        <Button key="delete" style={{ float: 'left' }} type="danger" loading={submitting} onClick={onDelete}>
          Delete
        </Button>
      )
    }

    footer.push(
      <Button key='cancel' disabled={submitting} onClick={() => onClose()}>
        Cancel
      </Button>,
    )

    if (values.quantity !== null) {
      footer.push(
        <Button key='save' type='primary' loading={submitting} onClick={onSubmit}>
          Save
        </Button>,
      )
    }

    return (
        
      <Modal
        visible
        title='Official Run Rate'
        width={600}
        footer={footer}
        onCancel={() => onClose()}
      >
        
        <Form />

      </Modal>
      
    )

  }

}
