import { push } from 'connected-react-router'
import { getTeamUrl } from 'Traqa/Helpers'

export default (tab) => (dispatch, getState) => {

  const { user } = getState().screens.User

  dispatch(push(getTeamUrl(`users/${user.id}?tab=${tab}`)))

}
