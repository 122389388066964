import { resetInitialized } from '../State/initialized'
import { resetSalesOrder } from '../State/salesOrder'
import resetEditor from '../Components/Editor/Services/reset'
import resetGoodsOutRecordsTable from '../Components/GoodsOutRecordsTable/Services/reset'

export default () => async (dispatch) => {

  dispatch(resetInitialized())
  dispatch(resetSalesOrder())
  dispatch(resetEditor())
  dispatch(resetGoodsOutRecordsTable())
  
}
