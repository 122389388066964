import { connect } from 'react-redux'
import CourierRates from './CourierRates'

import onClickNew from './Handlers/onClickNew'
import onClickRow from './Handlers/onClickRow'

const mapStateToProps = (state) => ({
  data: state.screens.Courier.courier.courier_rates,
})

const actionCreators = {
  onClickNew,
  onClickRow,
}

export default connect(mapStateToProps, actionCreators)(CourierRates)
