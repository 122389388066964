import { resetInitialized } from '../State/initialized'
import { resetPurchaseOrder } from '../State/purchaseOrder'
import resetEditor from '../Components/Editor/Services/reset'
import resetGoodsInRecordsTable from '../Components/GoodsInRecordsTable/Services/reset'

export default () => async (dispatch) => {

  dispatch(resetInitialized())
  dispatch(resetPurchaseOrder())
  dispatch(resetEditor())
  dispatch(resetGoodsInRecordsTable())
  
}