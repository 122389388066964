import { createAction, handleActions } from 'redux-actions'

const setActiveTab = createAction('SCREENS/STOCK_LOCATIONS/STOCK_LOCATIONS_TABLE/SET_ACTIVE_TAB')
const resetActiveTab = createAction('SCREENS/STOCK_LOCATIONS/STOCK_LOCATIONS_TABLE/RESET_ACTIVE_TAB')

const defaultState = 'Active'

const reducer = handleActions(
  {
    [setActiveTab]: (state, action) => action.payload,
    [resetActiveTab]: () => defaultState,
  },
  defaultState
)

export { setActiveTab, resetActiveTab, reducer }