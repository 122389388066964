import { connect } from "react-redux"
import Item from "./Item"

import createAvailbilitySelector from './Selectors/createAvailbilitySelector'

const mapStateToProps = (state, props) => {
  
  const selectAvailability = createAvailbilitySelector()

  return {
    products: state.core.products,
    availability: selectAvailability(state, props),
  }
}

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Item)
