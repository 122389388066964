import moduleProps from '../moduleProps'
import { getTransactions } from '../../../Api/transactions'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch) => {

  const { params } = moduleProps()

  params.include = [
    'stock_location',
    'product',
  ].join()

  params.append = [
    'owner_type_formatted',
    'owner_type_slug',
    'quantity_formatted',
    'details',
  ].join()

  params.sort = '-transaction_at'

  dispatch(startFetching())

  try {

    const response = await getTransactions({ params })

    dispatch(finishFetching())

    dispatch(setData(response.data.data))

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }

}
