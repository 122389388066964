import React from 'react'
import PropTypes from 'prop-types'
import Screen from '../../Components/Screen'

import Header from './Components/Header'
import Permissions from './Components/Permissions'
import Activity from './Components/Activity'
import User from '../../Modals/User'
import ResetPassword from './Components/ResetPassword'

export default class extends React.PureComponent {
  
  static propTypes = {
    id: PropTypes.number,
    initialized: PropTypes.bool.isRequired,
    activeTab: PropTypes.number.isRequired,
    userModal: PropTypes.object.isRequired,
    passwordResetModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onCloseUserModal: PropTypes.func.isRequired,
    onClosePasswordResetModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {
    
    const {
      id,
      initialized,
      activeTab,
      userModal,
      passwordResetModal,
      onCloseUserModal,
      onClosePasswordResetModal,
    } = this.props

    return (

      <Screen
        HeaderInner={Header}
        headerStyle={{ height: 210 }}
        contentStyle={{ backgroundColor: 'white', paddingTop: 0, paddingBottom: 60, minHeight: 500 }}
      >
        
        {(initialized && activeTab === 0) && <Permissions />}
        
        {(initialized && activeTab === 1) && <Activity />}

        {userModal.show && (
          <User
            id={id}
            onClose={onCloseUserModal}
          />
        )}

        {passwordResetModal.show && (
          <ResetPassword
            id={id}
            onClose={onClosePasswordResetModal}
          />
        )}
        
      </Screen>

    )

  }

}
