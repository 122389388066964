import { connect } from 'react-redux'
import Controls from './Controls'

import onChangeForm from './Handlers/onChangeForm'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'

const mapStateToProps = (state) => ({
  stocktake: state.screens.Reconciliation.stocktake,
  formState: state.screens.Reconciliation.Report.formState,
  previousStocktakes: state.screens.Reconciliation.Report.previousStocktakes,
})

const actionCreators = {
  onChangeForm,
  onSubmit,
  validate,
}

export default connect(mapStateToProps, actionCreators)(Controls)