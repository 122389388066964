import { combineReducers } from 'redux'

import { reducer as data } from './State/data'
import { reducer as isFetching } from './State/isFetching'
import { reducer as initialized } from './State/initialized'
import { reducer as isExpanded } from './State/isExpanded'
import { reducer as expandedActivities } from './State/expandedActivities'
import { reducer as expandedLogs } from './State/expandedLogs'

export default combineReducers({
  data,
  isFetching,
  initialized,
  isExpanded,
  expandedActivities,
  expandedLogs,
})
