import React from 'react'
import PropTypes from 'prop-types'
import { Input, Radio, Icon } from 'antd'
import classNames from 'classnames'
import { Module, LinkButton } from 'Traqa/Packages'
import styles from './styles.css'

const Header = (props) => {

  const {
    activeTab,
    searchTerm,
    onChangeTab,
    onChangeSearch,
    onSearch,
    onClickAdvanced,
  } = props

  const renderLeft = () => (

    <Radio.Group onChange={onChangeTab} value={activeTab}>
      <Radio.Button value='Month' className={styles.tab}>
        <Icon type='calendar' style={{ marginRight: '5px' }} />
          Month
      </Radio.Button>
      <Radio.Button value='BackOrder' className={classNames(styles.tab, styles.overdue)}>
        <Icon type='exclamation-circle' style={{ marginRight: '5px' }} />
          Back Order
      </Radio.Button>

      <Radio.Button value='Proforma' className={styles.tab} style={{ marginLeft: '20px' }}>
        <Icon type='hourglass' style={{ marginRight: '5px' }} />
          Proforma
      </Radio.Button>
      <Radio.Button value='Export' className={styles.tab}>
        <Icon type='export' style={{ marginRight: '5px' }} />
          Export
      </Radio.Button>
      <Radio.Button value='Archived' className={styles.tab}>
        <Icon type='eye-invisible' style={{ marginRight: '5px' }} />
          Archived
      </Radio.Button>
      <Radio.Button value='All' className={styles.tab}>
        <Icon type='unordered-list' style={{ marginRight: '5px' }} />
          All
      </Radio.Button>
    </Radio.Group>

  )

  const renderRight = () => (

    <React.Fragment>

      <LinkButton
        type='primary'
        onClick={onClickAdvanced}
      >
        Advanced
      </LinkButton>

      <div style={{ width: 16 }} />

      <Input.Search
        value={searchTerm}
        onChange={onChangeSearch}
        onSearch={onSearch}
        placeholder='Search'
        style={{ width: 200 }}
      />
    
    </React.Fragment>

  )
  
  return (

    <Module.Header
      renderLeft={renderLeft}
      renderRight={renderRight}
    />

  )
  
}

Header.propTypes = {
  activeTab: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onClickAdvanced: PropTypes.func.isRequired,
}

export default Header
