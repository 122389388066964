import { createSelector } from 'reselect'
import { find, get } from 'lodash'

const selectCustomers = (state) => state.core.customers
const selectCouriers = (state) => state.core.couriers
const selectValues = (state) => state.screens.Consignment.Editor.formState.values

const calculateCost = (qty, courierRates, standardRate, key) => {
  let cost = 0
  for (let i = 1; i <= qty; i += 1) {
    const courierRate = find(courierRates, row => row.quantity === i)
    if (courierRate) {
      cost = Number(courierRate[key])
    } else if (standardRate) {
      cost += Number(standardRate[key])
    }
  }
  return cost
}

const getTailLiftSurcharge = (customerSite, courier, palletQty) => {

  if (!customerSite || !customerSite.requires_tail_lift) return 0
  if (!courier.tail_lift_surcharge) return 0

  return palletQty * Number(courier.tail_lift_surcharge)

}

const selectIsEditing = createSelector(
  selectCustomers,
  selectCouriers,
  selectValues,
  (customers, couriers, values) => {

    const {
      customer_id: customerId,
      customer_site_id: customerSiteId,
      courier_id: courierId,
      courier_service_id: courierServiceId,
      courier_zone_id: courierZoneId,
      pallet_full_qty: palletFullQty,
      pallet_half_qty: palletHalfQty,
      pallet_quarter_qty: palletQuarterQty,
      pallet_micro_qty: palletMicroQty,
      parcel_qty: parcelQty,
    } = values
    
    const customer = find(customers, row => row.id === customerId)
    const customerSite = find(get(customer, 'customer_sites'), row => row.id === customerSiteId)
    const courier = find(couriers, row => row.id === courierId)
    
    if (!courier) return null

    const { courier_zones: courierZones } = courier

    const courierZone = find(courierZones, row => row.id === courierZoneId)

    if (!courierZone) return null

    const courierRates = courier.courier_rates.filter(row => {
      if (row.courier_service_id !== courierServiceId) return false
      if (row.courier_zone_id !== courierZone.courier_zone_id) return false
      return true
    })

    const standardRate = find(courierRates, row => !row.quantity)

    const palletFullTotal = calculateCost(palletFullQty, courierRates, standardRate, 'pallet_full_rate')
    const palletHalfTotal = calculateCost(palletHalfQty, courierRates, standardRate, 'pallet_half_rate')
    const palletQuarterTotal = calculateCost(palletQuarterQty, courierRates, standardRate, 'pallet_quarter_rate')
    const palletMicroTotal = calculateCost(palletMicroQty, courierRates, standardRate, 'pallet_micro_rate')
    const parcelTotal = calculateCost(parcelQty, courierRates, standardRate, 'parcel_rate')

    let calculatedCost = palletFullTotal + palletHalfTotal + palletQuarterTotal + palletMicroTotal + parcelTotal

    // Charges

    const courierCharges = courier.courier_charges.filter(row => {
      if (row.courier_service_id !== courierServiceId) return false
      return true
    })

    const palletQty = (palletFullQty || 0) + (palletHalfQty || 0) + (palletQuarterQty || 0) + (palletMicroQty || 0)

    for (let i = 1; i <= palletQty; i += 1) {
      const courierCharge = find(courierCharges, row => row.type === 'Pallet' && row.quantity === i)
      if (courierCharge) {
        calculatedCost += Number(courierCharge.charge)
      }
    }

    for (let i = 1; i <= parcelQty; i += 1) {
      const courierCharge = find(courierCharges, row => row.type === 'Parcel' && row.quantity === i)
      if (courierCharge) {
        calculatedCost += Number(courierCharge.charge)
      }
    }

    // Tail lift surcharge

    const tailLiftSurcharge = getTailLiftSurcharge(customerSite, courier, palletQty)

    return calculatedCost + tailLiftSurcharge

  },
)

export default selectIsEditing
