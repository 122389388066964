import { createSelector } from 'reselect'
import { every, reject } from 'lodash'

const selectProducts = state => state.screens.Demand.Report.data.products
const selectHideZeros = state => state.screens.Demand.Report.controls.hideZeros
const selectRunRateSource = state => state.screens.Demand.Report.controls.runRateSource

const selectTableRows = createSelector(
  selectProducts,
  selectHideZeros,
  selectRunRateSource,
  (products, hideZeros, runRateSource) => {

    if (!products.length) return []

    const tableRows = []

    let filteredProducts

    if (hideZeros) {
      filteredProducts = reject(products, product => every(product.periods, period => {
        return period.demand === 0 &&
          period.run_rate === 0 &&
          period.incoming === 0 &&
          period.close === 0 &&
          period.forecast_run_rate === 0 &&
          period.expected_incoming === 0 &&
          period.forecast_close === 0 &&
          period.back_order === 0
      }))
    } else {
      filteredProducts = products
    }

    tableRows.push({
      type: 'Group',
      key: 'run_rate',
      title: 'Run Rate',
    })

    filteredProducts.forEach(product => {
      tableRows.push({
        type: 'Product',
        group: 'run_rate',
        key: `run_rate-${product.id}`,
        product,
        values: product.periods.map(period => {
          if (period.index < 0) return {
            value: period.run_rate,
            demand: runRateSource === 'demand' ? period.demand : undefined,
          }
          if (period.index > 0) return {
            value: period.forecast_run_rate,
          }
          return {
            past: {
              value: period.run_rate,
              demand: runRateSource === 'demand' ? period.demand : undefined,
            },
            future: {
              value: period.forecast_run_rate,
              back_order: period.back_order,
            },
          }
        }),
      })
    })

    tableRows.push({
      type: 'Group',
      key: 'incoming',
      title: 'Incoming',
    })

    filteredProducts.forEach(product => {
      tableRows.push({
        type: 'Product',
        group: 'incoming',
        key: `incoming-${product.id}`,
        product,
        values: product.periods.map(period => {
          if (period.index < 0) return {
            value: period.incoming,
          }
          if (period.index > 0) return {
            value: period.expected_incoming,
          }
          return {
            past: {
              value: period.incoming,
            },
            future: {
              value: period.expected_incoming,
            },
          }
        }),
      })
    })

    tableRows.push({
      type: 'Group',
      key: 'close',
      title: 'Close',
    })

    filteredProducts.forEach(product => {
      tableRows.push({
        type: 'Product',
        group: 'close',
        key: `close-${product.id}`,
        product,
        values: product.periods.map(period => {
          if (period.index < 0) return {
            value: period.close,
          }
          if (period.index > 0) return {
            value: period.forecast_close,
            runRateCountdown: period.run_rate_countdown,
          }
          return {
            past: {
              value: period.close,
            },
            future: {
              value: period.forecast_close,
              runRateCountdown: period.run_rate_countdown,
            },
          }
        }),
      })
    })

    return tableRows

  }
  
)

export default selectTableRows
