import { createAction, handleActions } from 'redux-actions'

const setFormState = createAction("SCREENS/RECONCILIATION/REPORT/SET_FORM_STATE")
const setFormValues = createAction("SCREENS/RECONCILIATION/REPORT/SET_FORM_VALUES")
const resetFormState = createAction("SCREENS/RECONCILIATION/REPORT/RESET_FORM_STATE")

const defaultState = {
  submitting: false,
  values: {},
}

const reducer = handleActions(
  {
    [setFormState]: (state, action) => action.payload,
    [setFormValues]: (state, action) => ({ ...state, values: action.payload }),
    [resetFormState]: () => defaultState,
  },
  defaultState
)

export { setFormState, setFormValues, resetFormState, reducer }
