import { connect } from 'react-redux'
import Content from './Content'

import onSubmit from './Handlers/onSubmit'

const mapStateToProps = (state) => ({
  data: state.modules.StockshotsPopover.data,
  fetching: state.modules.StockshotsPopover.fetching,
  submitting: state.modules.StockshotsPopover.submitting,
})

const actionCreators = {
  onSubmit,
}

export default connect(mapStateToProps, actionCreators)(Content)
