import { createAction, handleActions } from 'redux-actions'

const openGoodsInRecordCommentsModal = createAction('SCREENS/PURCHASE_PLANNER/OPEN_GOODS_IN_RECORD_COMMENTS_MODAL')
const closeGoodsInRecordCommentsModal = createAction('SCREENS/PURCHASE_PLANNER/CLOSE_GOODS_IN_RECORD_COMMENTS_MODAL')

const defaultState = {
  show: false,
  id: null,
}

const reducer = handleActions(
  {
    [openGoodsInRecordCommentsModal]: (state, action) => Object.assign({}, state, {
      show: true,
      id: action.payload.id,
    }),
    [closeGoodsInRecordCommentsModal]: () => Object.assign({}, defaultState),
  },
  defaultState
)

export { openGoodsInRecordCommentsModal, closeGoodsInRecordCommentsModal, reducer }
