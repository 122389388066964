import { combineReducers } from "redux"

import { reducer as supplierModal } from "./State/supplierModal"

import SuppliersTable from './Components/SuppliersTable/reducer'

export default combineReducers({
  supplierModal,
  SuppliersTable,
})
