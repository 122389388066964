import { connect } from "react-redux"
import Component from "./Component"

import selectIsEditing from './Selectors/selectIsEditing'
import selectIsSubmitting from './Selectors/selectIsSubmitting'

import componentDidMount from "./Handlers/componentDidMount"
import componentDidUpdate from "./Handlers/componentDidUpdate"
import componentWillUnmount from "./Handlers/componentWillUnmount"
import onBack from "./Handlers/onBack"
import onSave from "./Handlers/onSave"
import onCancel from "./Handlers/onCancel"
import onReconcile from "./Handlers/onReconcile"

const mapStateToProps = (state, props) => ({
  id: props.match.params.id,
  isEditing: selectIsEditing(state),
  isSubmitting: selectIsSubmitting(state),
  initialized: state.screens.Stocktake.initialized,
  stocktake: state.screens.Stocktake.stocktake,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onBack,
  onSave,
  onCancel,
  onReconcile,
}

export default connect(mapStateToProps, actionCreators)(Component)
