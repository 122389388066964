import { find } from 'lodash'
import getTeams from './getTeams'
import getCurrentTeam from './getCurrentTeam'

const isOwner = () => {

  const team = find(getTeams(), (t) => t.id === getCurrentTeam().id)

  return team.pivot.role === 'owner'

}

export default isOwner
