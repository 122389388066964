import { closeLinkConsignmentModal } from '../State/linkConsignmentModal'
import fetch from '../Services/fetch'
//import fetchActivity from '../../../Modules/Activity/Services/fetch'

export default (shouldFetch = false) => (dispatch) => {

  dispatch(closeLinkConsignmentModal())

  if (shouldFetch) {

    dispatch(fetch())

    //dispatch(fetchActivity())
    
  }
  
}
