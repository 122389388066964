import { connect } from 'react-redux'
import Component from './Component'

// TODO: Finalise implementation of empty vs no results display

const mapStateToProps = (state) => ({
  empty: false, // state.screens.Products.ProductsTable.data.length === 0,
  showAdvanced: state.screens.Products.ProductsTable.advanced.show,
})

const actionCreators = {}

export default connect(mapStateToProps, actionCreators)(Component)
