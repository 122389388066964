export default (values) => () => {

  const errors = {}

  if (!values.invoice_no) errors.invoice_no = 'Required'

  if (!values.supplier_id) errors.supplier_id = 'Required'

  if (!values.stock_location_id) errors.stock_location_id = 'Required'

  if (!values.user_id) errors.user_id = 'Required'

  if (!values.date_time) errors.date_time = 'Required'
  
  return errors

}
