import { connect } from 'react-redux'
import Couriers from './Couriers'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onClickNew from './Handlers/onClickNew'
import onCloseCourierModal from './Handlers/onCloseCourierModal'

const mapStateToProps = (state) => ({
  courierModal: state.screens.Couriers.courierModal,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onClickNew,
  onCloseCourierModal,
}

export default connect(mapStateToProps, actionCreators)(Couriers)
