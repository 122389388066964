import React from 'react'
import styles from './styles.css'

const Header = () => (

  <div className={styles.wrapper}>
    
    <div className={styles.title}>FIFO Landed Costs</div>
    
  </div>
  
)

export default Header
