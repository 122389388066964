import intervalRef from '../intervalRef'
import { resetNotifications } from '../State/notifications'

export default () => async (dispatch) => {
  
  clearInterval(intervalRef())
  
  intervalRef.reset()
  
  dispatch(resetNotifications())
  
}