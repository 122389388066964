import { setHidePrinted } from '../../../State/hidePrinted'
import { firstPage } from '../../../State/pagination'
import fetch from '../../../Services/fetch'

export default (value) => (dispatch) => {

  dispatch(setHidePrinted(value === 1))

  dispatch(firstPage())

  dispatch(fetch())

}
