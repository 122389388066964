import { closeCourierModal } from '../State/courierModal'
import fetchTableData from '../Components/Table/Services/fetch'
import fetchCoreCouriers from '../../../Core/Services/fetchCouriers'

export default (shouldReload = false) => (dispatch) => {
  
  dispatch(closeCourierModal())

  if (shouldReload === true) {
    dispatch(fetchTableData())
    dispatch(fetchCoreCouriers({ force: true }))
  }
  
}
