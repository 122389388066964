import { message } from 'antd'
import { deleteManualRunRate } from '../../../Api/manualRunRates'
import { handleException } from 'Traqa/Packages'
import modalProps from '../modalProps'

export default () => async (dispatch, getState) => {

  try {

    const { id } = getState().modals.ManualRunRate.manualRunRate

    await deleteManualRunRate({ id })

    modalProps().onClose(true)

    message.success(`Deleted Official Run Rate`)

  } catch (error) {

    handleException(error)

  }
  
}
