import { openManualRunRateModal } from '../../../../../State/manualRunRateModal'

export default (product, period) => (dispatch, getState) => {

  const { stockLocationId, periodType } = getState().screens.Demand.Report.controls
  
  dispatch(openManualRunRateModal({
    stockLocationId,
    product,
    periodType,
    period,
  }))

}
