import { createAction, handleActions } from 'redux-actions'

const setTotals = createAction('SCREENS/STOCK_ADJUSTMENTS/REPORT/SET_TOTALS')
const resetTotals = createAction('SCREENS/STOCK_ADJUSTMENTS/REPORT/RESET_TOTALS')

const defaultState = {
  actual: {
    in: 0,
    in_value: 0,
    out: 0,
    out_value: 0,
  },
  expected: {
    in: 0,
    in_value: 0,
    out: 0,
    out_value: 0,
  },
}

const reducer = handleActions(
  {
    [setTotals]: (state, action) => action.payload,
    [resetTotals]: () => defaultState,
  },
  defaultState,
)

export { setTotals, resetTotals, reducer }
