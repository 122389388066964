import { showAdvanced, setValues } from '../../../State/advanced'
import { setSearchTerm } from '../../../State/searchTerm'

export default () => async (dispatch, getState) => {

  dispatch(showAdvanced())

  const { searchTerm, activeTab } = getState().screens.PurchaseOrders.Table

  dispatch(setValues({
    fulfilment: activeTab === 'All' ? null : activeTab,
    keywords: searchTerm,
  }))

  dispatch(setSearchTerm(''))
  
}