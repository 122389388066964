import React from 'react'
import PropTypes from 'prop-types'
import { Module } from 'Traqa/Packages'

import Header from './Components/Header'
import Advanced from './Components/Advanced'
import Table from './Components/Table'
import Footer from './Components/Footer'
import Empty from './Components/Empty'

const ProductsTable = (props) => {

  const {
    empty,
    showAdvanced,
  } = props
    
  return (

    <Module>

      {empty && <Empty />}

      {!empty && (

        <React.Fragment>

          {!showAdvanced && <Header />}

          {showAdvanced && <Advanced />}

          <Table />

          <Footer />
        
        </React.Fragment>

      )}

    </Module>

  )
}

ProductsTable.propTypes = {
  empty: PropTypes.bool.isRequired,
  showAdvanced: PropTypes.bool.isRequired,
}

export default ProductsTable