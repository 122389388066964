import { createAction, handleActions } from 'redux-actions'
import moment from 'moment'

const setMonthYear = createAction('SCREENS/CONSIGNMENTS/TABLE/SET_MONTH_YEAR')
const resetMonthYear = createAction('SCREENS/CONSIGNMENTS/TABLE/RESET_MONTH_YEAR')

const defaultState = moment()

const reducer = handleActions(
  {
    [setMonthYear]: (state, action) => action.payload,
    [resetMonthYear]: () => defaultState,
  },
  defaultState,
)

export { setMonthYear, resetMonthYear, reducer }
