export default (values, id) => () => {

  const errors = {}

  if (!values.first_name) errors.first_name = true

  if (!values.last_name) errors.last_name = true

  if (!values.username) errors.username = true

  if (!values.email) errors.email = true

  if (!id) {

    if (!values.password) errors.password = true

    if (values.password_confirm !== values.password) errors.password_confirm = true
    
  }
  
  return errors

}
