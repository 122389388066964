import { createAction, handleActions } from 'redux-actions'

const loadSalesOrders = createAction("CORE/SALES_ORDERS/LOAD_SALES_ORDERS")

const defaultState = []

const reducer = handleActions(
  {
    [loadSalesOrders]: (state, action) => action.payload,
  },
  defaultState
)

export { loadSalesOrders, reducer }