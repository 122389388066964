import { connect } from "react-redux"

import Component from "./Component"

import componentDidMount from "./Handlers/componentDidMount"
import onClickNew from "./Handlers/onClickNew"
import onCloseSupplierModal from "./Handlers/onCloseSupplierModal"

const mapStateToProps = (state) => ({
  supplierModal: state.screens.Suppliers.supplierModal,
})

const actionCreators = {
  componentDidMount,
  onClickNew,
  onCloseSupplierModal,
}

export default connect(mapStateToProps, actionCreators)(Component)
