import React from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import { Table as AntTable, Icon, Tag } from 'antd'
import styles from "./Table.css"

const Table = (props) => {

  const {
    rows,
    isFetching,
    onClickRow,
  } = props

  const formatValue = (value) => {
    if (value < 0) {
      return `-£${numeral(value * -1).format('0,0')}`
    }
    return `£${numeral(value).format('0,0')}`
  }
  
  return (

    <AntTable
      dataSource={rows}
      loading={isFetching}
      rowKey="id"
      bordered
      size="middle"
      className={styles.table}
      rowClassName={styles.clickable}
      onRow={(record) => ({
        onClick: () => onClickRow(record),
      })}
      pagination={{
        style: {
          paddingRight: 15,
          marginBottom: -65,
        }
      }}
    >

      <AntTable.Column
        key='product'
        title="Product"
        render={(text, record) => (
          <div className={styles.productWrapper}>
            <div>
              {`${record.code} - ${record.name}`}
              <Tag color='volcano' style={{ marginLeft: 5 }}>{record.form}</Tag>
            </div>
          </div>
        )}
      />

      <AntTable.Column
        key='opening_quantity'
        title="Opening"
        width={110}
        onHeaderCell={() => ({ style: { textAlign: 'right' } })}
        onCell={() => ({ style: { textAlign: 'right' } })}
        render={(text, record) => record.opening_quantity === null ? 'N/A' : numeral(record.opening_quantity).format('0,0.[00]')}
      />

      <AntTable.Column
        key='closing_quantity'
        title="Closing"
        width={110}
        onHeaderCell={() => ({ style: { textAlign: 'right' } })}
        onCell={() => ({ style: { textAlign: 'right' } })}
        render={(text, record) => numeral(record.closing_quantity).format('0,0.[00]')}
      />

      <AntTable.Column
        key='expected_quantity'
        title="Expected"
        width={110}
        onHeaderCell={() => ({ style: { textAlign: 'right' } })}
        onCell={() => ({ style: { textAlign: 'right' } })}
        render={(text, record) => numeral(record.expected_quantity).format('0,0.[00]')}
      />

      <AntTable.Column
        key='variance_quantity'
        title="Variance"
        width={130}
        onHeaderCell={() => ({ style: { textAlign: 'right' } })}
        onCell={() => ({ style: { textAlign: 'right', fontWeight: 600 } })}
        render={(text, record) => (
          <React.Fragment>
            {record.variance_quantity !== 0 && (
              <React.Fragment>
                <Icon
                  type={record.variance_quantity > 0 ? 'arrow-up' : 'arrow-down'}
                  style={{ color: record.variance_quantity > 0 ? '#32a852' : '#d62424' }}
                />
                {' '}
              </React.Fragment>
            )}
            {numeral(record.variance_quantity).format('0,0.[00]')}
          </React.Fragment>
        )}
      />

      <AntTable.Column
        key='variance_value'
        title="Value"
        width={110}
        onHeaderCell={() => ({ style: { textAlign: 'right' } })}
        onCell={() => ({ style: { textAlign: 'right', fontWeight: 600 } })}
        render={(text, record) => formatValue(record.variance_value)}
      />

    </AntTable>

  )
}

Table.propTypes = {
  rows: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onClickRow: PropTypes.func.isRequired,
}

export default Table