import { createAction, handleActions } from 'redux-actions'

const startFetching = createAction('SCREENS/PRODUCT/START_FETCHING')
const finishFetching = createAction('SCREENS/PRODUCT/FINISH_FETCHING')

const defaultState = false

const reducer = handleActions(
  {
    [startFetching]: () => true,
    [finishFetching]: () => false,
  },
  defaultState
)

export { startFetching, finishFetching, reducer }