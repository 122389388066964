export default (values) => () => {

  const errors = {}

  if (!values.startDate) errors.startDate = true

  if (!values.endDate) errors.endDate = true

  return errors

}
