import { getReadings } from '../../../../../Api/readings'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { setPagination } from '../State/pagination'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { searchTerm, sorting, pagination } = getState().screens.Transactions.Readings

    const params = {}

    params.include = [
      'stock_location',
      'created_by_user',
      'updated_by_user',
    ].join()

    if (searchTerm) params.search = searchTerm

    params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : '-date_time'
    
    params['page[number]'] = pagination.pageNumber
    params['page[size]'] = pagination.pageSize

    dispatch(startFetching())

    const response = await getReadings({ params })

    dispatch(setData(response.data.data))
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
    }))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }
}