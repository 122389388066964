import { resetInitialized } from '../State/initialized'
import { resetGoodsInRecord } from '../State/goodsInRecord'
import resetEditor from '../Components/Editor/Services/reset'

export default () => async (dispatch) => {

  dispatch(resetInitialized())
  dispatch(resetGoodsInRecord())
  dispatch(resetEditor())
  
}