import React from 'react'
import PropTypes from 'prop-types'
import { Popover } from 'antd'

import styles from './NotificationPopover.css'
import Header from './Components/Header'
import List from './Components/List'
import Footer from './Components/Footer'

export default class NotificationPopover extends React.PureComponent {

  static propTypes = {
    children: PropTypes.node.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onVisibleChange: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)
  
  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)
  
  componentWillUnmount = () => this.props.componentWillUnmount(this.props)
  
  renderContent = () => (
    
    <React.Fragment>
        
      <Header />
      
      <List />
      
      <Footer />
        
    </React.Fragment>
  )
  
  render () {
    
    const {
      children,
      onVisibleChange,
    } = this.props
    
    return (
      
      <Popover
        content={this.renderContent()}
        placement="bottomRight"
        trigger={['click']}
        overlayClassName={styles.popover}
        align={{ offset: [-5, -18] }}
        onVisibleChange={onVisibleChange}
      >
        {children}
      </Popover>

    )
  }
}