import React from 'react'
import { Module } from 'Traqa/Packages'

import Header from './Components/Header'
import Table from './Components/Table'

export default () => (

  <Module>

    <Header />

    <Table />

  </Module>

)
