import { getMedia } from '../../../Api/media'
import modalProps from '../modalProps'
import { setData } from '../State/data'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch) => {

  const { modelType, modelId } = modalProps()

  const params = {
    'filter[model_type]': modelType,
    'filter[model_id]': modelId,
    'append': 'image_url',
  }

  try {

    const response = await getMedia({ params })
    
    dispatch(setData(response.data.data))

  } catch (error) {

    handleException(error)

  }

}
