import { connect } from "react-redux"
import GroupHeader from "./GroupHeader"

import createComponentPropsSelector from '../../Selectors/createComponentPropsSelector'

const mapStateToProps = (state, props) => {
  
  const componentPropsSelector = createComponentPropsSelector()

  const componentProps = componentPropsSelector(state, props)

  return {
    ...componentProps
  }
}

const actionCreators = {}

export default connect(mapStateToProps, actionCreators)(GroupHeader)
