import { createSelector } from 'reselect'
import { uniq } from 'lodash'

const selectProducts = state => state.core.products

const capitalizeFirstLetter = string => `${string.charAt(0).toUpperCase()}${string.slice(1)}`

const selectTableRows = createSelector(
  selectProducts,
  (products) => uniq(products.map(product => capitalizeFirstLetter(product.form))).sort(),
)

export default selectTableRows
