import React from 'react'
import PropTypes from 'prop-types'
import classNames from "classnames"
import moment from "moment"
import { Table as AntTable, Typography, Icon, Tooltip } from 'antd'
import { NavLink } from 'react-router-dom'
import { getTeamUrl } from 'Traqa/Helpers'
import styles from "./Table.css"

const Table = (props) => {

  const {
    data,
    isFetching,
    onChange,
  } = props
  
  return (

    <AntTable
      dataSource={data}
      loading={isFetching}
      rowKey="id"
      pagination={false}
      onChange={onChange}
    >

      <AntTable.Column
        key='id'
        title="No."
        width={90}
        sorter
        render={(text, record) => (
          <NavLink key={record.id} to={getTeamUrl(`transfers/${record.id}`)}>
            {record.id}
          </NavLink>
        )}
      />

      <AntTable.Column
        key='reference'
        title="Reference"
        sorter
        render={(text, record) => <Typography.Text strong copyable={!!record.reference}>{record.reference}</Typography.Text>}
      />

      <AntTable.Column
        key='in_sage'
        width={50}
        render={(text, record) => record.in_sage ? (
          <Tooltip title='In Sage'><Icon type='sync' className={styles.inSageIcon} /></Tooltip>
        ) : null}
      />

      <AntTable.Column
        key='origin.name'
        title="Origin"
        sorter
        render={(text, record) => record.origin.name}
      />

      <AntTable.Column
        key='destination.name'
        title="Destination"
        sorter
        render={(text, record) => record.destination.name}
      />

      <AntTable.Column
        key='dispatched_at'
        title='Dispatched'
        sorter
        render={(text, record) => record.dispatched_at ? moment(record.dispatched_at).calendar(null, {
          sameDay: '[Today], h:mm a',
          nextDay: '[Tomorrow], h:mm a',
          lastDay: '[Yesterday], h:mm a',
          nextWeek: 'D MMM YYYY [at] h:mm a',
          lastWeek: 'D MMM YYYY [at] h:mm a',
          sameElse: 'D MMM YYYY [at] h:mm a',
        }) : null}
      />

      <AntTable.Column
        key='completed_at'
        title='Completed'
        sorter
        render={(text, record) => record.completed_at ? moment(record.completed_at).calendar(null, {
          sameDay: '[Today], h:mm a',
          nextDay: '[Tomorrow], h:mm a',
          lastDay: '[Yesterday], h:mm a',
          nextWeek: 'D MMM YYYY [at] h:mm a',
          lastWeek: 'D MMM YYYY [at] h:mm a',
          sameElse: 'D MMM YYYY [at] h:mm a',
        }) : null}
      />

      <AntTable.Column
        key='user.name'
        title="Owner"
        sorter
        render={(text, record) => record.user.name}
      />

      <AntTable.Column
        key='status'
        title="Status"
        width={180}
        sorter
        render={(text, record) => {

          if (record.status === 'Draft') {
            return (
              <div className={styles.status}>
                <Icon type="edit" className={classNames(styles.icon, styles.draft)} />
                Draft
              </div>
            )
          }

          if (record.status === 'Expected') {
            return (
              <div className={styles.status}>
                <Icon type="check-circle" className={classNames(styles.icon, styles.expected)} />
                Scheduled
              </div>
            )
          }

          if (record.status === 'Completed') {
            return (
              <div className={styles.status}>
                <Icon type="check-circle" className={classNames(styles.icon, styles.completed)} />
                Completed
              </div>
            )
          }

          return null
          
        }}
      />

    </AntTable>

  )
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Table