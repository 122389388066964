import { showAdvanced, setValues } from '../../../State/advanced'
import { resetActiveTab } from '../../../State/activeTab'
import { resetSearchTerm } from '../../../State/searchTerm'

export default () => async (dispatch, getState) => {

  const { activeTab, searchTerm } = getState().modules.TransactionsTable

  dispatch(setValues({
    ownerType: activeTab === 'All' ? null : activeTab,
    searchTerm,
  }))

  dispatch(showAdvanced())

  dispatch(resetActiveTab())

  dispatch(resetSearchTerm())
  
}
