import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip, Progress } from 'antd'
import { get, find, reduce } from 'lodash'
import numeral from 'numeral'

const FulFillmentProgress = ({ salesOrder, formValues }) => {
  
  const salesOrderItems = get(formValues, 'sales_order_items', [])

  const totalQuantity = reduce(salesOrderItems, (carry, salesOrderItem) => carry + (salesOrderItem.quantity || 0), 0)

  const totalPending = reduce(salesOrderItems, (carry, salesOrderItem) => {
    const originalSalesOrderItem = find(salesOrder.sales_order_items, originalItem => originalItem.id === salesOrderItem.id)
    if (!originalSalesOrderItem) return carry + (salesOrderItem.quantity || 0)
    const { expected, picked, completed } = originalSalesOrderItem
    const pending = salesOrderItem.quantity - (expected + picked + completed)
    return carry + (pending > 0 ? pending : 0)
  }, 0)

  const totalExpected = salesOrder.total_expected
  const totalPicked = salesOrder.total_picked
  const totalCompleted = salesOrder.total_completed

  const pendingPercentage = (totalPending / totalQuantity) * 100
  const fulfilledPercentage = ((totalExpected + totalPicked + totalCompleted) / totalQuantity) * 100
  const completePercentage = (totalCompleted / totalQuantity) * 100

  const formatTooltipLine = (value) => {
    if (totalQuantity === 0) return `[${numeral(value).format('0,0')}]`
    const percentage = (value / totalQuantity)*100
    return `${numeral(percentage).format('0,0.[00]')}% [${numeral(value).format('0,0')}]`
  }

  return (

    <Tooltip
      title={(
        <div>
          {totalCompleted > 0 && (<div>{`${formatTooltipLine(totalCompleted)} Dispatched`}</div>)}
          {totalExpected > 0 && (<div>{`${formatTooltipLine(totalExpected)} Scheduled`}</div>)}
          {totalPicked > 0 && (<div>{`${formatTooltipLine(totalPicked)} Picked`}</div>)}
          {totalPending > 0 && (<div>{`${formatTooltipLine(totalPending)} Pending`}</div>)}
        </div>
      )}
    >
      <Progress
        size='small'
        percent={fulfilledPercentage}
        successPercent={completePercentage}
        strokeColor='#ffa500'
        style={{ width: 200 }}
        status={(() => {
          if (totalQuantity === 0) return 'exception'
          if (totalPending > 0) return 'exception'
          if (totalCompleted > totalQuantity) return 'exception'
          if ((totalCompleted + totalExpected + totalPicked) > totalQuantity) return 'exception'
          if (totalCompleted === totalQuantity) return 'success'
          if ((totalCompleted + totalExpected + totalPicked) === totalQuantity) return 'active'
          return null
        })()}
        format={() => {
          if (totalQuantity === 0) return 'Invalid Quantities'
          if (totalPending > 0) return `${numeral(pendingPercentage).format('0,0.[00]')}% Pending`
          if (totalCompleted > totalQuantity) return `${numeral(completePercentage - 100).format('0,0.[00]')}% Over Dispatched`
          if ((totalCompleted + totalExpected + totalPicked) > totalQuantity) return `${numeral(fulfilledPercentage - 100).format('0,0.[00]')}% Over Fulfilled`
          if (totalCompleted === totalQuantity) return '100% Dispatched'
          if ((totalCompleted + totalExpected + totalPicked) === totalQuantity) return '100% Fulfilled'
          return null
        }}
      />
    </Tooltip>

  )

}

FulFillmentProgress.propTypes = {
  salesOrder: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
}

export default FulFillmentProgress
