import { connect } from 'react-redux'
import Table from './Table'

import selectTableRows from './Selectors/selectTableRows'

import onClickManualRunRateCell from './Handlers/onClickManualRunRateCell'
import onClickTransactionsCell from './Handlers/onClickTransactionsCell'

const mapStateToProps = (state) => ({
  periods: state.screens.Demand.Report.data.periods,
  tableRows: selectTableRows(state),
  isFetching: state.screens.Demand.Report.isFetching,
  stockLocationId: state.screens.Demand.Report.controls.stockLocationId,
  periodType: state.screens.Demand.Report.controls.periodType,
  runRateSetting: state.screens.Demand.Report.controls.runRateSetting,
})

const actionCreators = {
  onClickManualRunRateCell,
  onClickTransactionsCell,
}

export default connect(mapStateToProps, actionCreators)(Table)
