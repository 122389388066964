import React from "react"
import PropTypes from "prop-types"
import { Button } from 'antd'
import Screen from '../../Components/Screen'
import styles from './Component.css'

import Header from './Components/Header'
import MoreMenu from './Components/MoreMenu'
import Editor from './Components/Editor'
import { Activity, Comments, MediaItems } from '../../Modules'

export default class TransferScreen extends React.PureComponent {

  static propTypes = {
    id: PropTypes.string.isRequired,
    isNew: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    initialized: PropTypes.bool.isRequired,
    transfer: PropTypes.object,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onFileChanges: PropTypes.func.isRequired,
  }

  static defaultProps = {
    transfer: null,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  getTitle () {

    const {
      isNew,
      initialized,
      transfer,
    } = this.props

    if (isNew) {
      return "New Transfer"
    }

    if (initialized) {
      return `Transfer ${transfer.id}`
    }

    return 'Transfer'
  }

  renderHeaderLeft = () => {

    const {
      isNew,
      isEditing,
      initialized,
    } = this.props

    if (!isNew && !isEditing && initialized) {
      return <Header />
    }

    return null
  }

  renderHeaderRight = () => {

    const {
      isNew,
      isEditing,
      isSubmitting,
      onSave,
      onCancel,
    } = this.props

    return (

      <div>

        {(!isNew && !isEditing) && (
          
          <React.Fragment>
        
            <MoreMenu />

          </React.Fragment>

        )}

        {(isNew || isEditing) && (
          
          <React.Fragment>

            <Button type="link" disabled={isSubmitting} onClick={() => onCancel(isNew)}>
              Cancel
            </Button>

            <Button type="primary" loading={isSubmitting} onClick={onSave}>
              {isNew ? "Create" : "Save Changes"}
            </Button>

          </React.Fragment>

        )}
      
      </div>

    )
  }

  render () {

    const {
      id,
      isNew,
      initialized,
      onBack,
      onFileChanges,
    } = this.props

    return (

      <Screen
        title={this.getTitle()}
        headerLeft={this.renderHeaderLeft()}
        headerRight={this.renderHeaderRight()}
        contentLoading={!initialized}
        showBackButton
        onBack={onBack}
        style={{ paddingBottom: 500 }}
      >

        <Editor />

        {!isNew && (

          <React.Fragment>

            <br />

            <Activity
              modelType="Transfer"
              modelId={id}
            />

            <br />

            <div className={styles.wrapper}>

              <div className={styles.left}>

                <Comments
                  modelType="Transfer"
                  modelId={id}
                />

              </div>

              <div style={{ width: 20 }} />

              <div className={styles.right}>

                <MediaItems
                  modelType="Transfer"
                  modelId={id}
                  onChangeCallback={onFileChanges}
                />

              </div>

            </div>

          </React.Fragment>
          
        )}

      </Screen>

    )
  }
}
