import React from 'react'
import PropTypes from 'prop-types'
import { List, Avatar, Icon } from 'antd'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    log: PropTypes.object.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
  }

  render() {

    const {
      log,
      isExpanded,
      onToggle,
    } = this.props

    const icons = {
      created: 'plus',
      updated: 'edit',
      deleted: 'delete',
    }

    const colors = {
      created: '#87d068',
      updated: '#ffbf00',
      deleted: 'red',
    }

    const descriptions = {
      created: 'Created',
      updated: 'Updated',
      deleted: 'Deleted',
    }

    const showOrHideProperties = () => (
      <div style={{ width: 150 }}>
        <Icon
          type={isExpanded ? 'down' : 'right'}
          style={{ marginRight: 5 }}
        />
        Details
      </div>
    )

    const getActions = () => [<a onClick={() => onToggle(log.id)}>{showOrHideProperties()}</a>]

    return (

      <List.Item
        actions={getActions()}
        className={styles.listItem}
      >
        <List.Item.Meta
          avatar={<Avatar size='large' icon={icons[log.description]} style={{ backgroundColor: colors[log.description] }} />}
          title={`${log.subject_type_formatted} ${log.subject_id}`}
          description={descriptions[log.description]}
          style={{ paddingLeft: 12 }}
        />
      </List.Item>

    )

  }

}
