import { defer } from 'lodash'
import modalProps from '../../../modalProps'
import { findUser } from '../../../../../Api/users'
import formService from '../formService'
import { handleException } from 'Traqa/Packages'

export default () => async () => {

  const { id } = modalProps()

  if (!id) return

  try {

    const response = await findUser({ id })

    const user = response.data.data

    const initialValues = {
      first_name: user.first_name,
      last_name: user.last_name,
      username: user.username,
      email: user.email,
    }

    defer(() => formService().initialize(initialValues))

  } catch (error) {

    handleException(error)

  }

}
