import { connect } from 'react-redux'
import Table from './Table'

import onChange from './Handlers/onChange'
import onClick from './Handlers/onClick'
import onArchive from './Handlers/onArchive'
import onUnarchive from './Handlers/onUnarchive'
import onDelete from './Handlers/onDelete'

const mapStateToProps = (state) => ({
  data: state.screens.StockLocations.StockLocationsTable.data,
  isFetching: state.screens.StockLocations.StockLocationsTable.isFetching,
})

const actionCreators = {
  onChange,
  onClick,
  onArchive,
  onUnarchive,
  onDelete,
}

export default connect(mapStateToProps, actionCreators)(Table)
