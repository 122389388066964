import { createAction, handleActions } from 'redux-actions'
import { includes, without, concat } from 'lodash'

const expandActivity = createAction('MODULES/ACTIVITY/EXPAND_ACTIVITY')
const closeActivity = createAction('MODULES/ACTIVITY/CLOSE_ACTIVITY')
const toggleActivity = createAction('MODULES/ACTIVITY/TOGGLE_ACTIVITY')
const setExpandedActivities = createAction('MODULES/ACTIVITY/SET_EXPANDED_ACTIVITIES')
const resetExpandedActivities = createAction('MODULES/ACTIVITY/RESET_EXPANDED_ACTIVITIES')

const defaultState = []

const reducer = handleActions(
  {
    [expandActivity]: (state, action) => !includes(state, action.payload) ? concat(state, action.payload) : state,
    [closeActivity]: (state, action) => includes(state, action.payload) ? without(state, action.payload) : state,
    [toggleActivity]: (state, action) => includes(state, action.payload) ? without(state, action.payload) : concat(state, action.payload),
    [setExpandedActivities]: (state, action) => action.payload,
    [resetExpandedActivities]: () => [],
  },
  defaultState
)

export { expandActivity, closeActivity, toggleActivity, setExpandedActivities, resetExpandedActivities, reducer }
