import React from 'react'
import PropTypes from 'prop-types'
import { Field, FormSpy } from 'react-final-form'
import { Form, Row, Col, Select, Input, DatePicker, Switch, Icon } from 'antd'
import { Module } from 'Traqa/Packages'

const Details = (props) => {

  const {
    customers,
    customerSites,
    users,
    isNew,
  } = props
  
  return (

    <Module.Section
      contentStyle={{ paddingBottom: 15 }}
    >

      <FormSpy subscription={{ submitting: true }}>

        {({ submitting, form }) => (

          <div>

            <Row gutter={8}>

              <Col span={6}>

                <Field name='number'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='SO No.'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Input
                        {...input}
                        disabled={submitting}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={8}>

                <Field name='customer_id'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Customer'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        onChange={value => {
                          input.onChange(value === undefined ? null : value)
                          form.change('customer_site_id', null)
                        }}
                        disabled={submitting}
                        optionFilterProp='children'
                        showSearch
                        allowClear
                        dropdownMatchSelectWidth={false}
                      >
                        <Select.OptGroup label='Active'>
                          {customers.filter(customer => !customer.archived_at).map(customer => (
                            <Select.Option key={customer.id} value={customer.id}>
                              {customer.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                        <Select.OptGroup label='Archived'>
                          {customers.filter(customer => !!customer.archived_at).map(customer => (
                            <Select.Option key={customer.id} value={customer.id} style={{ color: '#999' }}>
                              {customer.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                      </Select>
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={8}>

                <Field name='customer_site_id'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Site'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        disabled={submitting}
                        optionFilterProp='children'
                        showSearch
                        allowClear
                        dropdownMatchSelectWidth={false}
                      >
                        <Select.OptGroup label='Active'>
                          {customerSites.filter($customerSite => !$customerSite.archived_at).map($customerSite => (
                            <Select.Option key={$customerSite.id} value={$customerSite.id}>
                              {$customerSite.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                        <Select.OptGroup label='Archived'>
                          {customerSites.filter($customerSite => !!$customerSite.archived_at).map($customerSite => (
                            <Select.Option key={$customerSite.id} value={$customerSite.id} style={{ color: '#999' }}>
                              {$customerSite.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                      </Select>
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={2}>

                <Field name='is_export'>
                  {({ input }) => (
                    <Form.Item
                      label='Export'
                    >
                      <Switch
                        {...input}
                        checked={input.value}
                        disabled={submitting}
                        checkedChildren={<Icon type='check' />}
                        style={{ marginTop: 4 }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

            </Row>

            <Row gutter={8}>

              <Col span={6}>

                <Field name='customer_po_no'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Customer PO No.'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Input
                        {...input}
                        disabled={submitting}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={4}>

                <Field name='date' allowNull>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Order Date'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <DatePicker
                        {...input}
                        disabled={submitting}
                        format='DD/MM/YYYY'
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={4}>

                <Field name='due_date' allowNull>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Due Date'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <DatePicker
                        {...input}
                        disabled={submitting}
                        format='DD/MM/YYYY'
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

            </Row>

            <Row gutter={8}>

              <Col span={6}>

                <Field name='description'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Description'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Input
                        {...input}
                        disabled={submitting}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={4}>

                <Field name='user_id'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='User'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        disabled={submitting}
                        optionFilterProp='children'
                        showSearch
                        allowClear
                      >
                        {users.filter(user => !isNew || !user.deactivated_at).map(user => (
                          <Select.Option key={user.id} value={user.id}>
                            {user.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Field>

              </Col>

            </Row>

          </div>
      
        )}

      </FormSpy>
  
    </Module.Section>

  )

}

Details.propTypes = {
  customers: PropTypes.array.isRequired,
  customerSites: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  isNew: PropTypes.bool.isRequired,
}

export default Details
