import { createAction, handleActions } from 'redux-actions'

const openPasswordResetModal = createAction('SCREENS/PASSWORD_RESET/OPEN_PASSWORD_RESET_MODAL')
const closePasswordResetModal = createAction('SCREENS/PASSWORD_RESET/CLOSE_PASSWORD_RESET_MODAL')

const defaultState = {
  show: false,
  id: null,
}

const reducer = handleActions(
  {
    [openPasswordResetModal]: (state, action) => Object.assign({}, state, {
      show: true,
      id: action.payload.id,
    }),
    [closePasswordResetModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openPasswordResetModal, closePasswordResetModal, reducer }
