import { createAction, handleActions } from 'redux-actions'

const loadPlasticTaxRates = createAction('CORE/PLASTIC_TAX_RATES/LOAD_PLASTIC_TAX_RATES')

const defaultState = []

const reducer = handleActions(
  {
    [loadPlasticTaxRates]: (state, { payload }) => payload,
  },
  defaultState,
)

export { loadPlasticTaxRates, reducer }
