import screenProps from '../screenProps'
import { resetInitialized } from '../State/initialized'
import resetReport from '../Components/Report/Services/reset'
import initialize from '../Services/initialize'

export default (props, prevProps) => (dispatch) => {

  screenProps(props)

  if (props.match.params.id !== prevProps.match.params.id) {

    dispatch(resetInitialized())

    dispatch(resetReport())

    dispatch(initialize())

  }

}
