import { teamApi as axios } from './axios';

export const getGoodsInRecords = ({ params } = {}) => axios.get(`goods_in_records`, { params });
export const getGoodsInRecord = ({ params, id } = {}) => axios.get(`goods_in_records/${id}`, { params });
export const createGoodsInRecord = ({ data }) => axios.post(`goods_in_records`, data);
export const updateGoodsInRecord = ({ id, data }) => axios.put(`goods_in_records/${id}`, data);
export const deleteGoodsInRecord = ({ id }) => axios.delete(`goods_in_records/${id}`);
export const updateGoodsInRecordStatus = ({ id, data }) => axios.put(`goods_in_records/${id}/status`, data);
export const updateGoodsInRecordInSage = ({ id, data }) => axios.put(`goods_in_records/${id}/in_sage`, data);
export const duplicateGoodsInRecord = ({ id }) => axios.post(`goods_in_records/${id}/duplicate`);
export const convertGoodsInRecordIntoPurchaseOrder = ({ id, data }) => axios.post(`goods_in_records/${id}/convert_into_purchase_order`, data);