import React from "react"
import PropTypes from "prop-types"
import { Dropdown, Menu, Icon, Button } from "antd"

const MoreMenu = (props) => {

  const {
    onDelete,
  } = props

  const menu = (
    <Menu style={{ minWidth: 180 }}>

      <Menu.Item onClick={onDelete}>
        <Icon type="delete" />
        Delete Transfer
      </Menu.Item>

    </Menu>
  )

  return (

    <Dropdown
      trigger={["click"]}
      placement="bottomRight"
      overlay={menu}
    >
      <Button icon="ellipsis" shape="circle" />
    </Dropdown>

  )
}

MoreMenu.propTypes = {
  onDelete: PropTypes.func.isRequired,
}

export default MoreMenu