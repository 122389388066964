import { connect } from 'react-redux'
import CourierServices from './CourierServices'

import onClickNew from './Handlers/onClickNew'
import onClickRow from './Handlers/onClickRow'

const mapStateToProps = (state) => ({
  data: state.screens.Courier.courier.courier_services,
})

const actionCreators = {
  onClickNew,
  onClickRow,
}

export default connect(mapStateToProps, actionCreators)(CourierServices)
