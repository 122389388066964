import React from 'react'
import PropTypes from 'prop-types'
import { Table as TableProvider, Popover, Icon } from 'antd'
import numeral from 'numeral'
import moment from 'moment'
import classNames from 'classnames'
import ReactCountryFlag from 'react-country-flag'
import countryList from 'react-select-country-list'
import styles from './styles.css'

import SubTable from './Components/SubTable'

const statuses = {
  Draft: (
    <React.Fragment>
      <Icon type='edit' className={styles.statusIcon} style={{ color: 'grey' }} />
      Draft
    </React.Fragment>
  ),
  Speculated: (
    <React.Fragment>
      <Icon type='clock-circle' className={styles.statusIcon} style={{ color: 'orange' }} />
      Scheduled
    </React.Fragment>
  ),
  Expected: (
    <React.Fragment>
      <Icon type='check-circle' className={styles.statusIcon} style={{ color: '#1890ff' }} />
      Booked
    </React.Fragment>
  ),
  Completed: (
    <React.Fragment>
      <Icon type='check-circle' className={styles.statusIcon} style={{ color: 'limegreen' }} />
      Completed
    </React.Fragment>
  ),
}

const Table = props => {

  const {
    tableRows,
    isFetching,
  } = props

  return (

    <TableProvider
      dataSource={tableRows}
      rowKey='id'
      loading={isFetching}
      pagination={false}
      expandedRowRender={record => <SubTable tableRows={record.goods_in_record_items} />}
      expandedRowClassName={record => classNames({
        [styles.draft]: record.status === 'Draft',
        [styles.speculated]: record.status === 'Speculated',
        [styles.expected]: record.status === 'Expected',
        [styles.completed]: record.status === 'Completed',
      })}
      scroll={{
        y: window.innerHeight - 380,
      }}
    >

      <TableProvider.Column
        key='invoice_no'
        title='Reference'
        width={300}
        render={(text, record) => <div className={styles.invoiceNo}>{record.invoice_no}</div>}
      />

      <TableProvider.Column
        key='supplier'
        title='Supplier'
        render={(text, record) => (
          <Popover
            trigger='hover'
            title='Contact Details'
            content={(
              <React.Fragment>
                <div className={styles.contactDetail}>
                  <div className={styles.contactLabel}>Telephone:</div>
                  <div className={styles.contactValue}>{record.supplier.telephone || ''}</div>
                </div>
                <div className={styles.contactDetail}>
                  <div className={styles.contactLabel}>Email:</div>
                  <div className={styles.contactValue}>{record.supplier.email || ''}</div>
                </div>
              </React.Fragment>
            )}
          >
            <div className={styles.supplier}>{record.supplier.name}</div>
            <div className={styles.country}>
              <ReactCountryFlag countryCode={record.supplier.country_code} svg className={styles.flag} />
              {record.supplier.country_code ? countryList().getLabel(record.supplier.country_code) : null}
            </div>
          </Popover>
        )}
      />

      <TableProvider.Column
        key='date'
        title='Arrival'
        width={200}
        render={(text, record) => (
          <React.Fragment>
            <div className={styles.date} style={{ color: record.is_overdue && 'red' }}>
              {moment(record.date_time).format('DD/MM/YYYY')}
            </div>
            <div className={styles.fromNow} style={{ color: record.is_overdue ? 'red' : '#999999' }}>
              {moment(record.date_time).fromNow()}
            </div>
          </React.Fragment>
        )}
      />

      <TableProvider.Column
        key='status'
        title='Status'
        width={150}
        render={(text, record) => <div className={styles.status}>{statuses[record.status]}</div>}
      />

      <TableProvider.Column
        key='weight'
        title='Weight (KG)'
        width={200}
        onHeaderCell={() => ({
          style: { textAlign: 'right' },
        })}
        onCell={() => ({
          style: { textAlign: 'right' },
        })}
        render={(text, record) => (
          <React.Fragment>
            <div className={styles.weight}>
              {'Net: '}
              <span className={styles.value}>{numeral(record.weight_kgs).format('0,0')}</span>
            </div>
            <div className={styles.taxable}>
              {'Taxable: '}
              <span className={styles.value}>{numeral(record.taxable_kgs).format('0,0')}</span>
            </div>
          </React.Fragment>
        )}
      />

      <TableProvider.Column
        key='tax'
        title='Tax'
        width={200}
        onHeaderCell={() => ({
          style: { textAlign: 'right' },
        })}
        onCell={() => ({
          style: { textAlign: 'right' },
        })}
        render={(text, record) => <div className={styles.taxAmount}>{`£${numeral(record.tax_amount).format('0,0.00')}`}</div>}
      />

    </TableProvider>

  )
  
}

Table.propTypes = {
  tableRows: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
}

export default Table
