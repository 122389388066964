import React from 'react'
import PropTypes from 'prop-types'
import prettyBytes from 'pretty-bytes'
import { last } from 'lodash'
import { Table as AntTable, Typography, Icon, Dropdown, Menu } from 'antd'
import { LinkButton } from 'Traqa/Packages'
import styles from './Table.css'

const Table = (props) => {

  const {
    mediaItems,
    isFetching,
    onDownload,
    onRename,
    onDelete,
  } = props

  const getIconType = (media) => {

    const extension = last(media.file_name.toLowerCase().split('.'));

    const map = {
      "jpeg": "file-image",
      "jpg": "file-image",
      "png": "file-image",
      "gif": "file-image",
      "pdf": "file-pdf",
      "doc": "file-word",
      "docx": "file-word",
      "xls": "file-excel",
      "xlsx": "file-excel",
      "xlsm": "file-excel",
      "csv": "file-excel",
      "ppt": "file-ppt",
      "eml": "file-text",
      "rtf": "file-text",
      "txt": "file-text",
    }

    return map[extension] || 'file'
  }

  const getIconColor = (media) => {

    const iconType = getIconType(media)

    const map = {
      "file-image": "#1990FF",
      "file-pdf": "#D4241C",
      "file-word": "#2C5791",
      "file-excel": "#396A45",
      "file-ppt": "#C05535",
      "file-text": "#999",
      "file": "#999"
    }

    return map[iconType]
  }

  return (

    <AntTable
      dataSource={mediaItems}
      loading={isFetching}
      rowKey="id"
      pagination={false}
      showHeader={false}
    >

      <AntTable.Column
        key='name'
        title="Name"
        render={(text, media) => (
          <div className={styles.nameCol}>
            <div className={styles.thumb}>
              {media.thumb_url ? (
                <img src={media.thumb_url} />
              ) : (
                <Icon
                  type={getIconType(media)}
                  theme='filled'
                  style={{
                    color: getIconColor(media),
                    fontSize: 30,
                    lineHeight: '30px'
                  }}
                />
              )}
            </div>
            <div className={styles.name}>
              <Typography.Text ellipsis editable={{ onChange: (name) => onRename(media, name) }}>
                {media.name}
              </Typography.Text>
            </div>
          </div>
        )}
      />

      <AntTable.Column
        key='size'
        title="Size"
        width={100}
        render={(text, media) => (
          <Typography.Text type="secondary">
            {prettyBytes(media.size)}
          </Typography.Text>
        )}
      />

      <AntTable.Column
        key='actions'
        title=" "
        width={60}
        render={(text, media) => (
          <Dropdown
            trigger={["click"]}
            overlay={(
              <Menu style={{ width: 150 }}>
                <Menu.Item onClick={() => onDownload(media)}>
                  <Icon type="download" />
                  Download
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item onClick={() => onDelete(media)} className="ant-typography ant-typography-danger">
                  <Icon type="delete" />
                  Delete
                </Menu.Item>
              </Menu>
            )}
          >
            <Icon type="more" style={{ fontSize: 20, lineHeight: '15px', marginTop: 4, color: '#999' }} />
          </Dropdown>
        )}
      />

    </AntTable>
    
  )
}

Table.propTypes = {
  mediaItems: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onDownload: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default Table