import { resetActiveTab as resetPieChartsActiveTab } from '../Components/PieCharts/State/activeTab'
import { resetHoldingValue as resetPieChartsHoldingValue } from '../Components/PieCharts/State/holdingValue'
import { finishFetchingHoldingValue as finishPieChartsFetchingHoldingValue } from '../Components/PieCharts/State/isFetchingHoldingValue'

import { resetData as resetGoodsInRecordsData } from '../Components/GoodsInRecords/State/data'
import { finishFetching as finishGoodsInRecordsFetching } from '../Components/GoodsInRecords/State/isFetching'

import { resetActiveTab as resetColumnChartsActiveTab } from '../Components/ColumnCharts/State/activeTab'
import { resetHoldingControls as resetColumnChartsHoldingControls } from '../Components/ColumnCharts/State/holdingControls'
import { resetMovementControls as resetColumnChartsMovementControls } from '../Components/ColumnCharts/State/movementControls'
import { resetHoldingValue as resetColumnChartsHoldingValue } from '../Components/ColumnCharts/State/holdingValue'
import { resetHoldingWeeks as resetColumnChartsHoldingWeeks } from '../Components/ColumnCharts/State/holdingWeeks'
import { resetMovement as resetColumnChartsMovement } from '../Components/ColumnCharts/State/movement'
import { finishFetchingHoldingValue as finishColumnChartsFetchingHoldingValue } from '../Components/ColumnCharts/State/isFetchingHoldingValue'
import { finishFetchingHoldingWeeks as finishColumnChartsFetchingHoldingWeeks } from '../Components/ColumnCharts/State/isFetchingHoldingWeeks'
import { finishFetchingMovement as finishColumnChartsFetchingMovement } from '../Components/ColumnCharts/State/isFetchingMovement'

export default () => (dispatch) => {

  dispatch(resetPieChartsActiveTab())
  dispatch(resetPieChartsHoldingValue())
  dispatch(finishPieChartsFetchingHoldingValue())

  dispatch(resetGoodsInRecordsData())
  dispatch(finishGoodsInRecordsFetching())

  dispatch(resetColumnChartsActiveTab())
  dispatch(resetColumnChartsHoldingControls())
  dispatch(resetColumnChartsMovementControls())
  dispatch(resetColumnChartsHoldingValue())
  dispatch(resetColumnChartsHoldingWeeks())
  dispatch(resetColumnChartsMovement())
  dispatch(finishColumnChartsFetchingHoldingValue())
  dispatch(finishColumnChartsFetchingHoldingWeeks())
  dispatch(finishColumnChartsFetchingMovement())

}
