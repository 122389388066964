import { connect } from "react-redux"
import Component from "./Component"

import selectIsNew from './Selectors/selectIsNew'
import selectIsEditing from './Selectors/selectIsEditing'
import selectIsSubmitting from './Selectors/selectIsSubmitting'

import componentDidMount from "./Handlers/componentDidMount"
import componentDidUpdate from "./Handlers/componentDidUpdate"
import componentWillUnmount from "./Handlers/componentWillUnmount"
import onBack from "./Handlers/onBack"
import onSave from "./Handlers/onSave"
import onCancel from "./Handlers/onCancel"
import onFileChanges from "./Handlers/onFileChanges"

const mapStateToProps = (state, props) => ({
  id: props.match.params.id,
  isNew: selectIsNew(state, props),
  isEditing: selectIsEditing(state, props),
  isSubmitting: selectIsSubmitting(state, props),
  initialized: state.screens.GoodsInRecord.initialized,
  goodsInRecord: state.screens.GoodsInRecord.goodsInRecord,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onBack,
  onSave,
  onCancel,
  onFileChanges,
}

export default connect(mapStateToProps, actionCreators)(Component)
