import { combineReducers } from 'redux'

import { reducer as initialized } from './State/initialized'
import { reducer as stocktake } from './State/stocktake'
import { reducer as transactionsDrawer } from './State/transactionsDrawer'

import Report from './Components/Report/reducer'

export default combineReducers({
  initialized,
  stocktake,
  transactionsDrawer,

  Report,
})
