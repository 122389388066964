import React from 'react'
import PropTypes from 'prop-types'
import { Field, FormSpy } from 'react-final-form'
import { Form, Select, DatePicker, TimePicker } from 'antd'
import { Module } from 'Traqa/Packages'
import styles from './Details.css'

const Details = (props) => {

  const {
    stockLocations,
    users,
  } = props
  
  return (

    <Module.Section
      contentStyle={{ paddingBottom: 15 }}
    >

      <FormSpy subscription={{ submitting: true }}>

        {({ submitting }) => (

          <div className={styles.wrapper}>

            <div style={{ width: 200 }}>

              <Field name="stock_location_id" allowNull>
                {({ input, meta }) => (
                  <Form.Item
                    label="Location"
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Select
                      {...input}
                      disabled={submitting}
                      optionFilterProp="children"
                      showSearch
                      allowClear
                    >
                      <Select.OptGroup label='Active'>
                        {stockLocations.filter(stockLocation => !stockLocation.archived_at).map(stockLocation => (
                          <Select.Option key={stockLocation.id} value={stockLocation.id}>
                            {stockLocation.name}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                      <Select.OptGroup label='Archived'>
                        {stockLocations.filter(stockLocation => !!stockLocation.archived_at).map(stockLocation => (
                          <Select.Option key={stockLocation.id} value={stockLocation.id} style={{ color: '#999' }}>
                            {stockLocation.name}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                    </Select>
                  </Form.Item>
                )}
              </Field>

            </div>

            <div style={{ width: 8 }} />

            <div style={{ width: 200 }}>

              <Field name='date' allowNull>
                {({ input, meta }) => (
                  <Form.Item
                    label='Date'
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <DatePicker
                      {...input}
                      disabled={submitting}
                      format='DD/MM/YYYY'
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )}
              </Field>

            </div>

            <div style={{ width: 8 }} />

            <div style={{ width: 200 }}>

              <Field name='time' allowNull>
                {({ input, meta }) => (
                  <Form.Item
                    label='Time'
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <TimePicker
                      {...input}
                      disabled={submitting}
                      format='HH:mm'
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )}
              </Field>

            </div>

            <div style={{ width: 8 }} />

            <div style={{ width: 200 }}>

              <Field name="user_id" allowNull>
                {({ input, meta }) => (
                  <Form.Item
                    label="User"
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Select
                      {...input}
                      disabled={submitting}
                      optionFilterProp="children"
                      showSearch
                      allowClear
                    >
                      {users.map(user => (
                        <Select.Option key={user.id} value={user.id}>
                          {user.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Field>

            </div>

            <div style={{ width: 8 }} />

            <div style={{ width: 120 }}>

              <Field name="status" allowNull>
                {({ input, meta }) => (
                  <Form.Item
                    label="Status"
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Select
                      {...input}
                      disabled={submitting}
                    >
                      <Select.Option value="Draft">
                        Draft
                      </Select.Option>
                      <Select.Option value="Published">
                        Published
                      </Select.Option>
                    </Select>
                  </Form.Item>
                )}
              </Field>

            </div>

            <div style={{ width: 16 }} />

          </div>
      
        )}

      </FormSpy>
  
    </Module.Section>

  )
}

Details.propTypes = {
  stockLocations: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
}

Details.defaultProps = {
  
}

export default Details