import { connect } from 'react-redux'
import Form from './Form'

import modalProps from '../../modalProps'

import onChangeFormState from './Handlers/onChangeFormState'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'

const mapStateToProps = (state) => ({
  stockLocations: state.core.stockLocations,
  stockLocationId: modalProps().stockLocationId,
  product: modalProps().product,
  period: modalProps().period,
  manualRunRate: state.modals.ManualRunRate.manualRunRate,
})

const actionCreators = {
  onChangeFormState,
  onSubmit,
  validate,
}

export default connect(mapStateToProps, actionCreators)(Form)
