import fetch from './fetch'
import { initialized } from '../State/initialized'
import initializeReport from '../Components/Report/Services/initialize'

export default () => async (dispatch) => {

  await dispatch(fetch())

  await dispatch(initializeReport())

  dispatch(initialized())
  
}