import { goBack } from 'connected-react-router'
import { defer } from 'lodash'
import formService from '../Components/Editor/formService'

export default (isNew) => (dispatch, getState) => {

  if (isNew) {
    dispatch(goBack())
    return
  }

  const { initialValues } = getState().screens.Reading.Editor

  defer(() => formService().initialize(initialValues))
  
}