import React from 'react'
import PropTypes from 'prop-types'
import arrayMutators from 'final-form-arrays'
import { Form as FormProvider, FormSpy } from 'react-final-form'
import { Form } from 'antd'
import { Module } from 'Traqa/Packages'
import formService from './formService'

import Details from './Components/Details'
import ItemsArray from './Components/ItemsArray'

const Editor = (props) => {

  const {
    initialValues,
    onSubmit,
    onFormChange,
    validate,
  } = props
  
  return (

    <FormProvider
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmit}
      validate={validate}
      subscription={{}}
    >

      {({ handleSubmit, form }) => {
        
        formService(form)
        
        return (

          <Form
            onSubmit={handleSubmit}
            autoComplete='off'
            layout='vertical'
          >

            <FormSpy
              subscription={{ values: true, pristine: true, submitting: true }}
              onChange={onFormChange}
            />

            <Module>

              <Module.Header title='Details' />

              <Details />

              <ItemsArray />
          
            </Module>

          </Form>

        )
      
      }}
    
    </FormProvider>

  )

}

Editor.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
}

export default Editor
