import { connect } from 'react-redux'
import ControlsModal from './ControlsModal'

import onChangeControls from './Handlers/onChangeControls'

const mapStateToProps = (state) => ({
  productCategories: state.core.productCategories,
  stockLocations: state.core.stockLocations,
  controls: state.screens.Demand.Report.controls,
})

const actionCreators = {
  onChangeControls,
}

export default connect(mapStateToProps, actionCreators)(ControlsModal)
