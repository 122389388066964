import { markNotificationAsUnread } from '../../../../../Api/notifications'
import { handleException } from 'Traqa/Packages'
import checkForNotifications from '../../../Services/checkForNotifications'
import fetchNotifications from '../../../Services/fetchNotifications'

export default (notification) => async (dispatch) => {

  try {

    await markNotificationAsUnread({ id: notification.id })
    
    await Promise.all([
      dispatch(checkForNotifications()),
      dispatch(fetchNotifications()),
    ])

  } catch (error) {

    handleException(error)

    throw error

  }
  
}
