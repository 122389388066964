import { combineReducers } from "redux"

import { reducer as isFetching } from "./State/isFetching"
import { reducer as product } from "./State/product"
import { reducer as yearSummary } from "./State/yearSummary"
import { reducer as liveAssessment } from "./State/liveAssessment"
import { reducer as activeTab } from "./State/activeTab"
import { reducer as productValueModal } from "./State/productValueModal"

export default combineReducers({
  isFetching,
  product,
  yearSummary,
  liveAssessment,
  activeTab,
  productValueModal,
})
