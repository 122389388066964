import React from 'react'
import ReactDOM from 'react-dom'
import { Main, setupSentry } from 'Traqa/Packages'
import { store, persistor, history } from './App/store'
import App from './App'

setupSentry()

ReactDOM.render((
  <Main store={store} persistor={persistor} history={history}>
    <App />
  </Main>
), document.getElementById('root'))
