import { createAction, handleActions } from 'redux-actions'

const setDeliveryNotes = createAction('SCREENS/GOODS_OUT_RECORD/EDITOR/SET_DELIVERY_NOTES')
const resetDeliveryNotes = createAction('SCREENS/GOODS_OUT_RECORD/EDITOR/RESET_DELIVERY_NOTES')

const defaultState = null

const reducer = handleActions(
  {
    [setDeliveryNotes]: (state, action) => action.payload,
    [resetDeliveryNotes]: () => defaultState,
  },
  defaultState
)

export { setDeliveryNotes, resetDeliveryNotes, reducer }
