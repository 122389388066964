import markAsRead from '../Services/markAsRead'
import markAsUnread from '../Services/markAsUnread'

export default (notification) => async (dispatch) => {
  
  if (!notification.read_at) {
    
    await dispatch(markAsRead(notification))
    
  } else {
    
    await dispatch(markAsUnread(notification))
    
  }
  
}