import { createAction, handleActions } from 'redux-actions'

const startFetchingMovement = createAction('SCREENS/DASHBOARD/COLUMN_CHARTS/START_FETCHING_MOVEMENT')
const finishFetchingMovement = createAction('SCREENS/DASHBOARD/COLUMN_CHARTS/FINISH_FETCHING_MOVEMENT')

const defaultState = false

const reducer = handleActions(
  {
    [startFetchingMovement]: () => true,
    [finishFetchingMovement]: () => defaultState,
  },
  defaultState,
)

export { startFetchingMovement, finishFetchingMovement, reducer }
