import { getPurchasePlannerReport } from '../../../../../Api/reports'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { formState, columnOffset } = getState().screens.PurchasePlanner.Report

    const { values } = formState

    const params = {
      search: values.search,
      stock_location_id: values.stockLocationId,
      supplier_id: values.supplierId,
      invoice_no: values.invoiceNo,
      column_offset: columnOffset,
      column_count: values.columnCount,
    }

    dispatch(startFetching())

    const response = await getPurchasePlannerReport({ params })

    dispatch(setData(response.data))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }
  
}
