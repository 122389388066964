import moment from 'moment'
import { getProducts } from '../../Api/products'
import { loadProducts } from '../State/products'

let lastGetTimestamp = null

const cacheTTL = 60000

export default ({ force } = {}) => async (dispatch) => {

  try {

    if (!force && lastGetTimestamp) {

      const now = Number(moment().format('x'))

      if (now < (lastGetTimestamp + cacheTTL)) return
    }

    lastGetTimestamp = Number(moment().format('x'))

    const params = {
      sort: 'code',
      include: 'product_categories,division_of_products',
    }

    const response = await getProducts({ params })

    dispatch(loadProducts(response.data.data))

  } catch (error) {

    throw error

  }
}
