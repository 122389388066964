import { createAction, handleActions } from 'redux-actions'

const openGoodsInRecordModal = createAction('SCREENS/PURCHASE_ORDER/OPEN_GOODS_IN_RECORD_MODAL')
const closeGoodsInRecordModal = createAction('SCREENS/PURCHASE_ORDER/CLOSE_GOODS_IN_RECORD_MODAL')

const defaultState = {
  show: false,
  purchaseOrderId: null,
  purchaseOrderItemIds: [],
}

const reducer = handleActions(
  {
    [openGoodsInRecordModal]: (state, action) => Object.assign({}, state, {
      show: true,
      purchaseOrderId: action.payload.purchaseOrderId,
      purchaseOrderItemIds: action.payload.purchaseOrderItemIds,
    }),
    [closeGoodsInRecordModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openGoodsInRecordModal, closeGoodsInRecordModal, reducer }
