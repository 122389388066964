import { combineReducers } from "redux"

import { reducer as initialized } from "./State/initialized"
import { reducer as stocktake } from "./State/stocktake"

import Editor from "./Components/Editor/reducer"

export default combineReducers({
  initialized,
  stocktake,
  Editor,
})
