import { resetFormState } from '../State/formState'
import { resetColumnOffset } from '../State/columnOffset'
import { resetData } from '../State/data'

export default () => async (dispatch) => {

  dispatch(resetData())

  dispatch(resetFormState())

  dispatch(resetColumnOffset())
  
}
