import { setMonthYear } from '../../../State/monthYear'
import { firstPage } from '../../../State/pagination'
import fetch from '../../../Services/fetch'

export default (monthYear) => (dispatch) => {

  dispatch(setMonthYear(monthYear))

  dispatch(firstPage())

  dispatch(fetch())

}
