import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'

import Form from './Components/Form'

export default class CustomerSiteModal extends React.PureComponent {

  static propTypes = {
    customerSiteId: PropTypes.number,
    isSubmitting: PropTypes.bool.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    customerSiteId: null,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {

    const {
      customerSiteId,
      isSubmitting,
      onSubmit,
      onDelete,
      onClose,
    } = this.props

    const footer = []

    if (customerSiteId) {
      footer.push(
        <Button key="delete" style={{ float: 'left' }} type="danger" loading={isSubmitting} onClick={onDelete}>
          Delete
        </Button>
      )
    }

    footer.push(
      <Button key="cancel" disabled={isSubmitting} onClick={onClose}>
        Cancel
      </Button>,
      <Button key="save" type="primary" loading={isSubmitting} onClick={onSubmit}>
        {customerSiteId ? "Update" : "Create"}
      </Button>,
    )

    return (
        
      <Modal
        visible  
        title={customerSiteId ? "Site" : "New Site"}
        width={500}
        footer={footer}
        onCancel={() => onClose()}
      >
        
        <Form />

      </Modal>
      
    )
  }
}