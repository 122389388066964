import fetch from './fetch'
import { initialized } from '../State/initialized'
import initializeEditor from '../Components/Editor/Services/initialize'
import fetchGoodsOutRecords from '../Components/GoodsOutRecordsTable/Services/fetch'
import fetchCustomers from '../../../Core/Services/fetchCustomers'
import fetchCustomerSites from '../../../Core/Services/fetchCustomerSites'
import fetchUsers from '../../../Core/Services/fetchUsers'
import fetchProducts from '../../../Core/Services/fetchProducts'

export default () => async (dispatch) => {

  await Promise.all([
    dispatch(fetch()),
    dispatch(initializeEditor()),
    dispatch(fetchGoodsOutRecords()),
    dispatch(fetchCustomers()),
    dispatch(fetchCustomerSites()),
    dispatch(fetchUsers()),
    dispatch(fetchProducts()),
  ])

  dispatch(initialized())
  
}
