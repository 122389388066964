import modalProps from '../../../modalProps'
import { createCourierCharge, updateCourierCharge } from '../../../../../Api/courierCharges'
import { handleException } from 'Traqa/Packages'

export default (values) => async () => {

  const { id, courierId } = modalProps()

  const data = {
    courier_id: values.courier_id,
    courier_service_id: values.courier_service_id,
    type: values.type,
    quantity: values.quantity,
    charge: values.charge,
  }

  try {

    if (id) {

      await updateCourierCharge({ id, data })

    } else {

      data.courier_id = courierId

      await createCourierCharge({ data })

    }
    
    modalProps().onClose(true)

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
