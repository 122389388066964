import { createAction, handleActions } from 'redux-actions'
import moment from 'moment'

const setInitialValues = createAction('SCREENS/STOCK_ADJUSTMENTS/REPORT/SET_INITIAL_VALUES')
const resetInitialValues = createAction('SCREENS/STOCK_ADJUSTMENTS/REPORT/RESET_INITIAL_VALUES')

const defaultState = {
  startDate: moment().subtract(6, 'months'),
  endDate: moment(),
  aggregate: true,
  includeExpected: false,
  hideZeros: false,
  applyValues: false,
  sortBy: 'out_value',
  sortDirection: 'desc',

  product_search: '',
  product_ids: [],
  product_forms: [],
  product_categoryIds: [],
  transaction_batchNo: '',
  product_includeArchived: false,
  product_outersOnly: true,
  product_innersOnly: false,

  stockLocation_ids: [],
  stockLocation_includeArchived: false,
  transaction_supplierIds: [],
  transaction_customerIds: [],
  transaction_customerSiteIds: [],
  transaction_ownerTypes: [],
  transaction_exceptOwnerTypes: ['Transfer'],
}

const reducer = handleActions(
  {
    [setInitialValues]: (state, action) => action.payload,
    [resetInitialValues]: () => defaultState,
  },
  defaultState,
)

export { setInitialValues, resetInitialValues, reducer }
