import { connect } from 'react-redux'
import ColumnCharts from './ColumnCharts'

import onChangeActiveTab from './Handlers/onChangeActiveTab'

const mapStateToProps = (state) => ({
  activeTab: state.screens.Dashboard.ColumnCharts.activeTab,
})

const actionCreators = {
  onChangeActiveTab,
}

export default connect(mapStateToProps, actionCreators)(ColumnCharts)
