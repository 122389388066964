import { createAction, handleActions } from 'redux-actions'

const openCustomerSiteModal = createAction('MODALS/CUSTOMER/OPEN_CUSTOMER_SITE_MODAL')
const closeCustomerSiteModal = createAction('MODALS/CUSTOMER/CLOSE_CUSTOMER_SITE_MODAL')

const defaultState = {
  show: false,
  customerSiteId: null,
  customerId: null,
}

const reducer = handleActions(
  {
    [openCustomerSiteModal]: (state, action) => Object.assign({}, state, {
      show: true,
      customerSiteId: action.payload.customerSiteId,
      customerId: action.payload.customerId,
    }),
    [closeCustomerSiteModal]: () => Object.assign({}, defaultState),
  },
  defaultState
)

export { openCustomerSiteModal, closeCustomerSiteModal, reducer }
