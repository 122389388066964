import { createAction, handleActions } from 'redux-actions'

const setFilters = createAction('SCREENS/CONSIGNMENTS/TABLE/SET_FILTERS')
const resetFilters = createAction('SCREENS/CONSIGNMENTS/TABLE/RESET_FILTERS')

const defaultState = {}

const reducer = handleActions(
  {
    [setFilters]: (state, action) => ({ ...state, ...action.payload }),
    [resetFilters]: () => ({ ...defaultState }),
  },
  defaultState,
)

export { setFilters, resetFilters, reducer }
