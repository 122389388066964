import { setHideInSage } from '../../../State/hideInSage'
import { firstPage } from '../../../State/pagination'
import fetch from '../../../Services/fetch'

export default (value) => (dispatch) => {

  dispatch(setHideInSage(value === 1))

  dispatch(firstPage())

  dispatch(fetch())

}
