import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Select, Button, Icon } from 'antd'
import { upperFirst } from 'lodash'
import { Module } from 'Traqa/Packages'

const Footer = (props) => {

  const {
    controls,
    onClickExport,
    onChangeControls,
  } = props

  const periodTypeFormatted = upperFirst(controls.periodType)

  const renderLeft = () => (

    <Button
      type='default'
      icon='download'
      onClick={onClickExport}
    >
      Export Data
    </Button>

  )

  const renderRight = () => (

    <div style={{ display: 'flex', alignItems: 'center' }}>

      <div>

        {'Booked Only:'}

        <Switch
          checked={controls.expectedOnly}
          onChange={() => onChangeControls('expectedOnly', !controls.expectedOnly)}
          checkedChildren={<Icon type='check' />}
          style={{ marginLeft: 8, marginBottom: 2 }}
        />

      </div>

      <div style={{ width: 20 }} />

      <div>

        {'Back Orders:'}

        <Switch
          checked={controls.backOrders}
          onChange={() => onChangeControls('backOrders', !controls.backOrders)}
          checkedChildren={<Icon type='check' />}
          style={{ marginLeft: 8, marginBottom: 2 }}
        />

      </div>

      <div style={{ width: 20 }} />

      <div>

        {'Outers Only:'}

        <Switch
          checked={controls.outersOnly}
          onChange={() => onChangeControls('outersOnly', !controls.outersOnly)}
          checkedChildren={<Icon type='check' />}
          style={{ marginLeft: 8, marginBottom: 2 }}
        />

      </div>

      <div style={{ width: 20 }} />

      <div>

        {'Aggregate:'}

        <Switch
          checked={controls.aggregate}
          onChange={() => onChangeControls('aggregate', !controls.aggregate)}
          checkedChildren={<Icon type='check' />}
          style={{ marginLeft: 8, marginBottom: 2 }}
        />

      </div>

      <div style={{ width: 20 }} />

      <div>

        {'Run Rate:'}

        <Select
          value={controls.runRateSource}
          onChange={value => onChangeControls('runRateSource', value)}
          style={{ width: 180, marginLeft: 8 }}
        >
          <Select.OptGroup label='Source'>
            <Select.Option value='demand'>Demand</Select.Option>
            <Select.Option value='outgoing'>Outgoing</Select.Option>
          </Select.OptGroup>
        </Select>

        <Select
          value={controls.runRateSetting}
          onChange={value => onChangeControls('runRateSetting', value)}
          style={{ width: 180, marginLeft: 8 }}
        >
          <Select.OptGroup label='Mode'>
            <Select.Option value='expected'>Scheduled</Select.Option>
            <Select.Option value='manual'>Official Run Rates</Select.Option>
            {controls.periodType !== 'week' && (
              <Select.Option value='week'>Weekly Run Rates</Select.Option>
            )}
            {controls.periodType !== 'month' && (
              <Select.Option value='month'>Monthly Run Rates</Select.Option>
            )}
            <Select.Option value={1}>{`1 ${periodTypeFormatted} Average`}</Select.Option>
            <Select.Option value={3}>{`3 ${periodTypeFormatted} Average`}</Select.Option>
            <Select.Option value={6}>{`6 ${periodTypeFormatted} Average`}</Select.Option>
            <Select.Option value={12}>{`12 ${periodTypeFormatted} Average`}</Select.Option>
          </Select.OptGroup>
        </Select>

      </div>

    </div>

  )

  return (

    <Module.Footer
      renderLeft={renderLeft}
      renderRight={renderRight}
      border
      style={{ marginTop: '-1px' }}
    />

  )
  
}

Footer.propTypes = {
  controls: PropTypes.object.isRequired,
  onClickExport: PropTypes.func.isRequired,
  onChangeControls: PropTypes.func.isRequired,
}

export default Footer
