import modalProps from '../modalProps'
import fetchUsers from '../../../Core/Services/fetchUsers'
import fetch from '../Services/fetch'
import initializeForm from '../Components/Form/Services/initialize'

export default (props) => async (dispatch) => {

  modalProps(props)

  dispatch(fetchUsers())

  await dispatch(fetch())
  
  dispatch(initializeForm())
  
}