import { createAction, handleActions } from 'redux-actions'

const toggleAdvanced = createAction('SCREENS/ASSEMBLIES/ASSEMBLIES_TABLE/TOGGLE_ADVANCED')
const showAdvanced = createAction('SCREENS/ASSEMBLIES/ASSEMBLIES_TABLE/SHOW_ADVANCED')
const hideAdvanced = createAction('SCREENS/ASSEMBLIES/ASSEMBLIES_TABLE/HIDE_ADVANCED')
const setValues = createAction('SCREENS/ASSEMBLIES/ASSEMBLIES_TABLE/SET_VALUES')
const resetValues = createAction('SCREENS/ASSEMBLIES/ASSEMBLIES_TABLE/RESET_VALUES')
const resetAdvanced = createAction('SCREENS/ASSEMBLIES/ASSEMBLIES_TABLE/RESET_ADVANCED')

const defaultState = {
  show: false,
  values: {
    productId: null,
    stockLocationIds: [],
    userIds: [],
    batchNo: null,
  },
}

const reducer = handleActions(
  {
    [toggleAdvanced]: (state) => ({ ...state, show: !state.show }),
    [showAdvanced]: (state) => ({ ...state, show: true }),
    [hideAdvanced]: (state) => ({ ...state, show: false }),
    [setValues]: (state, action) => ({
      ...state,
      values: {
        productId: action.payload.productId || null,
        stockLocationIds: action.payload.stockLocationIds || [],
        userIds: action.payload.userIds || [],
        batchNo: action.payload.batchNo || null,
      }
    }),
    [resetValues]: (state) => ({ ...state, values: { ...defaultState.values } }),
    [resetAdvanced]: () => ({ ...defaultState }),
  },
  defaultState
)

export { toggleAdvanced, showAdvanced, hideAdvanced, setValues, resetValues, resetAdvanced, reducer }