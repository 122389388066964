import React from 'react'
import { createSelector } from 'reselect'
import { Tag } from 'antd'

const selectData = state => state.screens.PurchasePlanner.Report.data

const selectTableRows = createSelector(
  selectData,
  (data) => {

    const tableRows = []

    if (!data.products.length) return tableRows

    data.products.forEach(product => {
      tableRows.push({
        type: 'Product',
        key: product.id,
        title: (
          <div>
            {`${product.code} - ${product.name}`}
            <Tag color='volcano' style={{ marginLeft: 5 }}>{product.form}</Tag>
          </div>
        ),
        product,
        values: product.goods_in_records,
      })
    })

    tableRows.push({
      type: 'Total',
      key: 'total',
      values: data.goods_in_records.map((goodsInRecord) => ({
        total_pallets: goodsInRecord.total_pallets,
      })),
    })

    return tableRows

  }
  
)

export default selectTableRows
