import { createSelector } from 'reselect'

const selectHoldingValue = state => state.screens.Dashboard.PieCharts.holdingValue
const selectIsFetchingHoldingValue = state => state.screens.Dashboard.PieCharts.isFetchingHoldingValue

export default createSelector(
  selectHoldingValue,
  selectIsFetchingHoldingValue,
  (holdingValue, isFetchingHoldingValue) => {

    const { stock_locations: stockLocations } = holdingValue

    return {
      chart: {
        type: 'pie',
        height: 250,
        width: 700,
        events: {
          load() {
            // eslint-disable-next-line no-unused-expressions
            isFetchingHoldingValue ? this.showLoading() : this.hideLoading()
          },
        },
      },
      title: {
        text: '',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>£{point.y:,.0f}</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          },
        },
      },
      series: [{
        name: 'Value',
        colorByPoint: true,
        data: stockLocations.map(stockLocation => ({
          y: stockLocation.value,
          name: stockLocation.name,
          percentage: stockLocation.percentage,
          color: stockLocation.colour,
        })),
      }],
    }
    
  },
)
