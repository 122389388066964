import { connect } from 'react-redux'
import Report from './Report'

const mapStateToProps = () => ({
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Report)
