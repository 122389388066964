import screenProps from '../screenProps'
import { findCourier } from '../../../Api/couriers'
import { setCourier } from '../State/courier'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch) => {

  const { id } = screenProps().match.params

  const params = {}

  params.include = [
    'courier_services',
    'courier_zones.courier_zone',
    'courier_rates.courier_service',
    'courier_rates.courier_zone',
    'courier_charges.courier_service',
    'courier_charges',
    'consignments',
  ].join(',')

  try {

    const response = await findCourier({ id, params })

    const courier = response.data.data

    dispatch(setCourier(courier))

  } catch (error) {

    handleException(error)

  }

}
