import { message } from 'antd'
import { deletePlasticTaxRate } from '../../../Api/plasticTaxRates'
import { handleException } from 'Traqa/Packages'
import modalProps from '../modalProps'

export default () => async () => {

  const { plasticTaxRate } = modalProps()

  try {

    await deletePlasticTaxRate({ id: plasticTaxRate.id })

    message.success('Deleted Tax Rate')

    modalProps().onClose(true)

  } catch (error) {

    handleException(error)

  }

}
