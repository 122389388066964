import { connect } from 'react-redux'
import List from './List'

import onClickLink from './Handlers/onClickLink'
import onClickNew from './Handlers/onClickNew'

const mapStateToProps = (state) => ({
  data: state.modals.LinkConsignment.List.data,
})

const actionCreators = {
  onClickLink,
  onClickNew,
}

export default connect(mapStateToProps, actionCreators)(List)
