import { combineReducers } from "redux"

import { reducer as isFetching } from "./State/isFetching"
import { reducer as status } from "./State/status"
import { reducer as initialValues } from "./State/initialValues"

export default combineReducers({
  isFetching,
  status,
  initialValues,
})
