import { push } from 'connected-react-router'
import propsHelper from '../propsHelper'
import { getTeamUrl } from 'Traqa/Helpers'

export default () => (dispatch) => {

  const { id } = propsHelper().match.params

  const path = getTeamUrl(`products`)

  dispatch(push(id ? `${path}/${id}` : path))

}