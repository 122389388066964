import chance from 'chance'
import formService from '../formService'

export default (index) => () => {

  const item = {
    guid: chance().guid(),
  }

  if (index === undefined) {

    formService().mutators.push('transfer_items', item)

  } else {

    formService().mutators.insert('transfer_items', index+1, item)
    
  }

}