import { connect } from 'react-redux'
import Permissions from './Permissions'

import onTogglePermission from './Handlers/onTogglePermission'

const mapStateToProps = (state) => ({
  user: state.screens.User.user,
})

const actionCreators = {
  onTogglePermission,
}

export default connect(mapStateToProps, actionCreators)(Permissions)
