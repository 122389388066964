import { createSelector } from 'reselect'

import selectListRows from './selectListRows'

const selectIndex = (state, props) => props.index

const createComponentPropsSelector = () => createSelector(
  selectListRows,
  selectIndex,
  (listRows, index) => listRows[index].componentProps
)

export default createComponentPropsSelector