import { combineReducers } from 'redux'

import { reducer as user } from './State/user'
import { reducer as initialized } from './State/initialized'
import { reducer as userModal } from './State/userModal'
import { reducer as passwordResetModal } from './State/passwordResetModal'

import ResetPassword from './Components/ResetPassword/reducer'

export default combineReducers({
  user,
  initialized,
  userModal,
  passwordResetModal,

  ResetPassword,
})
