import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, FormSpy, Field } from 'react-final-form'
import { Form, Row, Col, Input, Select, DatePicker, TimePicker, Radio, Switch, Tag, Icon } from 'antd'
import moment from 'moment'
import { Module } from 'Traqa/Packages'

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
  marginTop: '5px',
}

const Controls = props => {

  const {
    stockLocations,
    productCategories,
    products,
    forms,
    initialValues,
    formState,
    onChangeForm,
    onChangeField,
    onSubmit,
    validate,
  } = props

  const { values } = formState

  return (

    <div>

      <Module.Header title='Controls' />

      <Module.Section>

        <FormProvider
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
        >

          {({ handleSubmit, form }) => (

            <Form
              onSubmit={handleSubmit}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              colon={false}
            >

              <FormSpy subscription={{ values: true }} onChange={onChangeForm} />

              <Row gutter={20}>

                <Col span={8}>

                  <h3>General</h3>

                  <Field name='date' allowNull>
                    {({ input, meta }) => (
                      <Form.Item
                        label='Date'
                        validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                      >
                        <DatePicker
                          {...input}
                          format='DD/MM/YYYY'
                          allowClear={false}
                        />
                      </Form.Item>
                    )}
                  </Field>

                  <Field name='time' allowNull>
                    {({ input, meta }) => (
                      <Form.Item
                        label='Time'
                        validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                      >
                        <TimePicker
                          {...input}
                          format='HH:mm'
                          allowClear={false}
                        />
                      </Form.Item>
                    )}
                  </Field>

                  <Form.Item label=' '>
                    <a
                      onClick={() => {
                        const now = moment()
                        if (values.date.format('YYYY-MM-DD') !== now.format('YYYY-MM-DD')) {
                          form.change('date', moment())
                        }
                        if (values.time.format('HH:mm') !== now.format('HH:mm')) {
                          form.change('time', moment())
                        }
                      }}
                    >
                      Reset date & time
                    </a>
                  </Form.Item>

                  <Field name='aggregate'>
                    {({ input }) => (
                      <Form.Item
                        label='Aggregate'
                      >
                        <Switch
                          {...input}
                          checked={input.value}
                          checkedChildren={<Icon type='check' />}
                        />
                        <span style={{ marginLeft: 10 }}>Combine outer & inner stock data</span>
                      </Form.Item>
                    )}
                  </Field>

                  <Form.Item
                    label='Unallocated'
                  >
                    <Switch
                      checked={values.unallocatedOnly}
                      onChange={value => onChangeField('unallocatedOnly', value)}
                      checkedChildren={<Icon type='check' />}
                    />
                    <span style={{ marginLeft: 10 }}>Show unallocated stock only</span>
                  </Form.Item>

                  <Form.Item
                    label='Scheduled'
                  >
                    <Switch
                      checked={values.includeExpected}
                      onChange={value => onChangeField('includeExpected', value)}
                      checkedChildren={<Icon type='check' />}
                    />
                    <span style={{ marginLeft: 10 }}>Include scheduled event transactions</span>
                  </Form.Item>

                  <Form.Item
                    label='Zeros'
                  >
                    <Switch
                      checked={values.hideZeros}
                      onChange={value => onChangeField('hideZeros', value)}
                      checkedChildren={<Icon type='check' />}
                    />
                    <span style={{ marginLeft: 10 }}>Hide products with zero stock</span>
                  </Form.Item>

                  <Field name='applyValues'>
                    {({ input }) => (
                      <Form.Item
                        label='Values'
                      >
                        <Switch
                          {...input}
                          checked={input.value}
                          checkedChildren={<Icon type='check' />}
                        />
                        <span style={{ marginLeft: 10 }}>Apply legacy values</span>
                      </Form.Item>
                    )}
                  </Field>

                  <Form.Item
                    label='Sort'
                  >
                    <Radio.Group
                      value={values.sortBy}
                      onChange={event => onChangeField('sortBy', event.target.value)}
                    >
                      <Radio value='code' style={radioStyle}>Product</Radio>
                      <Radio value='quantity' style={radioStyle}>Qty</Radio>
                      <Radio value='value' style={radioStyle}>Value</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    label='Direction'
                  >
                    <Radio.Group
                      value={values.sortDirection}
                      onChange={event => onChangeField('sortDirection', event.target.value)}
                    >
                      <Radio value='asc' style={radioStyle}>Ascending</Radio>
                      <Radio value='desc' style={radioStyle}>Descending</Radio>
                    </Radio.Group>
                  </Form.Item>

                </Col>

                <Col span={8}>

                  <h3>Product</h3>

                  <Form.Item label='Search'>
                    <Input.Search
                      value={values.product_search}
                      onChange={event => onChangeField('product_search', event.target.value)}
                      onBlur={handleSubmit}
                      onSearch={handleSubmit}
                      placeholder='Search products'
                    />
                  </Form.Item>

                  <Field name='product_ids'>
                    {({ input }) => (
                      <Form.Item label='Product'>
                        <Select
                          {...input}
                          mode='multiple'
                          showSearch
                          allowClear
                          optionFilterProp='children'
                          dropdownMatchSelectWidth={false}
                        >
                          <Select.OptGroup label='Products'>
                            {products.filter(product => !product.archived_at).map(product => (
                              <Select.Option key={product.id} value={product.id}>
                                {`${product.code} - ${product.name}`}
                                <Tag color='volcano' style={{ marginLeft: 5 }}>{product.form}</Tag>
                              </Select.Option>
                            ))}
                          </Select.OptGroup>
                          <Select.OptGroup label='Archived'>
                            {products.filter(product => product.archived_at).map(product => (
                              <Select.Option key={product.id} value={product.id} style={{ color: '#999' }}>
                                {`${product.code} - ${product.name}`}
                                <Tag color='volcano' style={{ marginLeft: 5 }}>{product.form}</Tag>
                              </Select.Option>
                            ))}
                          </Select.OptGroup>
                        </Select>
                      </Form.Item>
                    )}
                  </Field>

                  <Field name='product_forms'>
                    {({ input }) => (
                      <Form.Item label='Form'>
                        <Select
                          {...input}
                          mode='multiple'
                          showSearch
                          allowClear
                          optionFilterProp='children'
                        >
                          {forms.map(row => (
                            <Select.Option
                              key={row}
                              value={row}
                            >
                              {row}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                  </Field>

                  <Field name='product_categoryIds'>
                    {({ input }) => (
                      <Form.Item label='Category'>
                        <Select
                          {...input}
                          mode='multiple'
                          showSearch
                          allowClear
                          optionFilterProp='children'
                        >
                          <Select.OptGroup label='Active'>
                            {productCategories.filter(productCategory => !productCategory.archived_at).map(productCategory => (
                              <Select.Option key={productCategory.id} value={productCategory.id}>
                                {productCategory.name}
                              </Select.Option>
                            ))}
                          </Select.OptGroup>
                          <Select.OptGroup label='Archived'>
                            {productCategories.filter(productCategory => !!productCategory.archived_at).map(productCategory => (
                              <Select.Option key={productCategory.id} value={productCategory.id} style={{ color: '#999' }}>
                                {productCategory.name}
                              </Select.Option>
                            ))}
                          </Select.OptGroup>
                        </Select>
                      </Form.Item>
                    )}
                  </Field>

                  <Form.Item label='Batch #'>
                    <Input.Search
                      value={values.transaction_batchNo}
                      onChange={event => onChangeField('transaction_batchNo', event.target.value)}
                      onBlur={handleSubmit}
                      onSearch={handleSubmit}
                      placeholder='Search batch #'
                    />
                  </Form.Item>

                  <Field name='product_includeArchived'>
                    {({ input }) => (
                      <Form.Item
                        label='Archived'
                      >
                        <Switch
                          {...input}
                          checked={input.value}
                          checkedChildren={<Icon type='check' />}
                        />
                        <span style={{ marginLeft: 10 }}>Include archived products</span>
                      </Form.Item>
                    )}
                  </Field>

                  <Field name='product_outersOnly'>
                    {({ input }) => (
                      <Form.Item
                        label='Outers'
                      >
                        <Switch
                          {...input}
                          checked={input.value}
                          checkedChildren={<Icon type='check' />}
                        />
                        <span style={{ marginLeft: 10 }}>Show outer products only</span>
                      </Form.Item>
                    )}
                  </Field>

                  <Field name='product_innersOnly'>
                    {({ input }) => (
                      <Form.Item
                        label='Inners'
                      >
                        <Switch
                          {...input}
                          checked={input.value}
                          checkedChildren={<Icon type='check' />}
                        />
                        <span style={{ marginLeft: 10 }}>Show inner products only</span>
                      </Form.Item>
                    )}
                  </Field>

                </Col>

                <Col span={8}>

                  <h3>Location</h3>

                  <Field name='stockLocation_ids'>
                    {({ input }) => (
                      <Form.Item label='Location'>
                        <Select
                          {...input}
                          mode='multiple'
                          showSearch
                          allowClear
                          optionFilterProp='children'
                        >
                          <Select.OptGroup label='Locations'>
                            {stockLocations.filter(stockLocation => !stockLocation.archived_at).map(stockLocation => (
                              <Select.Option key={stockLocation.id} value={stockLocation.id}>
                                {stockLocation.name}
                              </Select.Option>
                            ))}
                          </Select.OptGroup>
                          <Select.OptGroup label='Archived'>
                            {stockLocations.filter(stockLocation => stockLocation.archived_at).map(stockLocation => (
                              <Select.Option key={stockLocation.id} value={stockLocation.id} style={{ color: '#999' }}>
                                {stockLocation.name}
                              </Select.Option>
                            ))}
                          </Select.OptGroup>
                        </Select>
                      </Form.Item>
                    )}
                  </Field>

                  <Field name='stockLocation_includeArchived'>
                    {({ input }) => (
                      <Form.Item
                        label='Archived'
                      >
                        <Switch
                          {...input}
                          checked={input.value}
                          checkedChildren={<Icon type='check' />}
                        />
                        <span style={{ marginLeft: 10 }}>Include archived locations</span>
                      </Form.Item>
                    )}
                  </Field>

                </Col>

              </Row>
              
            </Form>
            
          )}

        </FormProvider>

      </Module.Section>

    </div>

  )
  
}

Controls.propTypes = {
  stockLocations: PropTypes.array.isRequired,
  productCategories: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  forms: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  onChangeForm: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
}

export default Controls
