import React from "react"
import PropTypes from "prop-types"
import { Button } from "antd"
import Screen from "../../Components/Screen"
import UsersTable from "./Components/UsersTable"
import { isAdmin } from 'Traqa/Helpers'

import User from "../../Modals/User"

export default class _Users_ extends React.PureComponent {

  static propTypes = {
    userModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onCloseUserModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  renderHeaderRight = () => ([

    <Button key="NEW" type="primary" onClick={this.props.onClickNew}>
      New User
    </Button>
    
  ])

  render () {

    const {
      userModal,
      onCloseUserModal,
    } = this.props

    return (

      <Screen
        title="Users"
        headerRight={isAdmin() ? this.renderHeaderRight() : false}
      >

        <UsersTable />

        {userModal.show && (
          <User
            onClose={onCloseUserModal}
          />
        )}

      </Screen>

    )
  }
}
