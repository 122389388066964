import { connect } from 'react-redux'
import Log from './Log'

import onToggle from './Handlers/onToggle'

const mapStateToProps = (state, props) => ({
  isExpanded: state.modules.Activity.expandedLogs.indexOf(props.log.id) !== -1,
})

const actionCreators = {
  onToggle,
}

export default connect(mapStateToProps, actionCreators)(Log)
