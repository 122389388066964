import { connect } from 'react-redux'
import Details from './Details'

import selectCustomerSites from './Selectors/selectCustomerSites'
import selectCustomerSite from './Selectors/selectCustomerSite'

const mapStateToProps = (state) => ({
  stockLocations: state.core.stockLocations,
  customers: state.core.customers,
  customerSites: selectCustomerSites(state),
  users: state.core.users,
  isNew: !state.screens.GoodsOutRecord.goodsOutRecord,
  customerSite: selectCustomerSite(state),
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Details)
