import { message } from 'antd'
import { deleteStockLocation } from '../../../../../Api/stockLocations'
import fetch from './fetch'
import fetchStockLocationsCore from '../../../../../Core/Services/fetchStockLocations'
import { handleException } from 'Traqa/Packages'

export default (stockLocation) => async (dispatch) => {

  try {

    await deleteStockLocation({ id: stockLocation.id })

    await dispatch(fetch())

    message.success(`Deleted Location "${stockLocation.name}"`)
    
    dispatch(fetchStockLocationsCore({ force: true }))

  } catch (error) {

    handleException(error)

  }
}