import screenProps from '../screenProps'
import { getPurchaseOrder } from '../../../Api/purchaseOrders'
import { setPurchaseOrder } from '../State/purchaseOrder'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    if (id === 'new') return

    const params = {
      include: 'purchase_order_items',
    }

    const response = await getPurchaseOrder({ id, params })

    dispatch(setPurchaseOrder(response.data.data))

  } catch (error) {

    handleException(error)

  }
}