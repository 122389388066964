import chance from 'chance'
import { getStockAdjustmentsReportTotals } from '../../../../../Api/reports'
import { startFetchingTotals, finishFetchingTotals } from '../State/isFetchingTotals'
import { setTotals } from '../State/totals'
import { setInteractionTotalsId } from '../State/interaction'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  const { values } = getState().screens.StockAdjustments.Report.formState

  const params = {
    start_date_time: `${values.startDate.format('YYYY-MM-DD')} 00:00:00`,
    end_date_time: `${values.endDate.format('YYYY-MM-DD')} 23:59:59`,
    apply_values: values.applyValues ? 1 : 0,

    product_search: values.product_search || undefined,
    product_ids: values.product_ids.join(','),
    product_forms: values.product_forms.join(','),
    product_category_ids: values.product_categoryIds.join(','),
    transaction_batch_no: values.transaction_batchNo || undefined,
    product_include_archived: values.product_includeArchived ? 1 : 0,
    product_outers_only: values.product_outersOnly ? 1 : 0,
    product_inners_only: values.product_innersOnly ? 1 : 0,

    stock_location_ids: values.stockLocation_ids.join(','),
    stock_location_include_archived: values.stockLocation_includeArchived ? 1 : 0,
    transaction_supplier_ids: values.transaction_supplierIds.join(','),
    transaction_customer_ids: values.transaction_customerIds.join(','),
    transaction_customer_site_ids: values.transaction_customerSiteIds.join(','),
    transaction_owner_types: values.transaction_ownerTypes.join(','),
    transaction_except_owner_types: values.transaction_exceptOwnerTypes.join(','),
  }

  // Prevent slower API responses from overriding faster ones

  const guid = chance().guid()

  dispatch(setInteractionTotalsId(guid))

  params.interaction_id = guid

  dispatch(startFetchingTotals())

  try {

    const { data } = await getStockAdjustmentsReportTotals({ params })

    if (data.interaction_id === getState().screens.StockAdjustments.Report.interaction.totalsId) {

      dispatch(setTotals(data))

    }

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetchingTotals())

  }

}
