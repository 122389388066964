import React from 'react'
import PropTypes from 'prop-types'
import { Module } from 'Traqa/Packages'

import Header from './Components/Header'
import Table from './Components/Table'
import Footer from './Components/Footer'

export default class extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  render() {

    return (

      <Module style={{ borderTop: 'none' }}>

        <Header />

        <Table />

        <Footer />

      </Module>

    )

  }

}
