import screenProps from '../screenProps'
import { clearProduct } from '../State/product'
import { resetYearSummary } from '../State/yearSummary'
import { resetLiveAssessment } from '../State/liveAssessment'
import fetch from '../Services/fetch'

export default (props, prevProps) => async (dispatch) => {

  screenProps(props)

  if (props.match.params.id !== prevProps.match.params.id) {

    dispatch(clearProduct())
    dispatch(resetYearSummary())
    dispatch(resetLiveAssessment())

    await dispatch(fetch())

  }

}