import { message } from 'antd'
import { renameMedia } from '../../../Api/media'
import fetch from './fetch'
import { handleException } from 'Traqa/Packages'

export default (id, name) => async (dispatch) => {

  try {

    await renameMedia({ id, data: { name } })

    dispatch(fetch())

    message.success('Image renamed')

  } catch (error) {

    handleException(error)

    throw error

  }

}
