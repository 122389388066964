import chance from 'chance'
import { getStockAssessmentReportTotals } from '../../../../../Api/reports'
import { startFetchingTotals, finishFetchingTotals } from '../State/isFetchingTotals'
import { setTotals } from '../State/totals'
import { setInteractionTotalsId } from '../State/interaction'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  const { values } = getState().screens.StockAssessment.Report.formState

  const params = {
    date_time: `${values.date.format('YYYY-MM-DD')} ${values.time.format('HH:mm')}:00`,
    apply_values: values.applyValues ? 1 : 0,

    product_search: values.product_search || undefined,
    product_ids: values.product_ids.join(','),
    product_forms: values.product_forms.join(','),
    product_category_ids: values.product_categoryIds.join(','),
    transaction_batch_no: values.transaction_batchNo || undefined,
    product_include_archived: values.product_includeArchived ? 1 : 0,
    product_outers_only: values.product_outersOnly ? 1 : 0,
    product_inners_only: values.product_innersOnly ? 1 : 0,

    stock_location_ids: values.stockLocation_ids.join(','),
    stock_location_include_archived: values.stockLocation_includeArchived ? 1 : 0,
  }

  // Prevent slower API responses from overriding faster ones

  const guid = chance().guid()

  dispatch(setInteractionTotalsId(guid))

  params.interaction_id = guid

  dispatch(startFetchingTotals())

  try {

    const { data } = await getStockAssessmentReportTotals({ params })

    if (data.interaction_id === getState().screens.StockAssessment.Report.interaction.totalsId) {

      dispatch(setTotals(data))

    }

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetchingTotals())

  }

}
