import fetchStockLocations from '../../../Core/Services/fetchStockLocations'
import fetchCustomers from '../../../Core/Services/fetchCustomers'
import fetchCouriers from '../../../Core/Services/fetchCouriers'
import fetch from './fetch'
import initializeEditor from '../Components/Editor/Services/initialize'
import fetchGoodsOutRecords from '../Components/GoodsOutRecords/Services/fetch'
import fetchPickingStats from '../Components/PickingStats/Services/fetch'
import { initialized } from '../State/initialized'

export default () => async (dispatch) => {

  dispatch(fetchStockLocations())
  dispatch(fetchCustomers())
  dispatch(fetchCouriers())
  
  await dispatch(fetch())
  
  await dispatch(initializeEditor())
  
  await dispatch(fetchGoodsOutRecords())
  
  await dispatch(fetchPickingStats())

  dispatch(initialized())

}
