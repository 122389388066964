import { resetInitialized } from '../State/initialized'
import { resetStocktake } from '../State/stocktake'
import resetEditor from '../Components/Editor/Services/reset'

export default () => async (dispatch) => {

  dispatch(resetInitialized())
  dispatch(resetStocktake())
  dispatch(resetEditor())
  
}