import { combineReducers } from 'redux'

import { reducer as consignment } from './State/consignment'
import { reducer as initialized } from './State/initialized'

import Editor from './Components/Editor/reducer'
import GoodsOutRecords from './Components/GoodsOutRecords/reducer'
import PickingStats from './Components/PickingStats/reducer'

export default combineReducers({
  consignment,
  initialized,
  
  Editor,
  GoodsOutRecords,
  PickingStats,
})
