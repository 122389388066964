import { createAction, handleActions } from 'redux-actions'

const setStocktake = createAction('SCREENS/STOCKTAKE/SET_STOCKTAKE')
const resetStocktake = createAction('SCREENS/STOCKTAKE/RESET_STOCKTAKE')

const defaultState = null

const reducer = handleActions(
  {
    [setStocktake]: (state, action) => action.payload,
    [resetStocktake]: () => null,
  },
  defaultState
)

export { setStocktake, resetStocktake, reducer }