import { connect } from 'react-redux'
import Component from './Component'

import onRowClick from './Handlers/onRowClick'
import onArchive from './Handlers/onArchive'
import onUnarchive from './Handlers/onUnarchive'

const mapStateToProps = (state) => ({
  customer: state.modals.Customer.customer,
})

const actionCreators = {
  onRowClick,
  onArchive,
  onUnarchive,
}

export default connect(mapStateToProps, actionCreators)(Component)
