import modalProps from '../../../modalProps'
import { createGoodsInRecord, updateGoodsInRecord } from '../../../../../Api/goodsInRecords'
import { handleException } from 'Traqa/Packages'

export default (values) => async () => {

  try {

    const { id } = modalProps()

    const data = {
      invoice_no: values.invoice_no,
      supplier_id: values.supplier_id,
      stock_location_id: values.stock_location_id,
      user_id: values.user_id,
      date_time: `${values.date_time.format('YYYY-MM-DD')} 00:00:00`,
      commercial_value_usd: values.commercial_value_usd,
      status: 'Speculated',
      on_the_water: values.on_the_water ? 1 : 0,
    }

    let response 

    if (id) {

      response = await updateGoodsInRecord({ id, data })

    } else {

      response = await createGoodsInRecord({ data })

    }

    modalProps().onClose(response.data.data)

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
