import { message } from 'antd'
import { goBack } from 'connected-react-router'
import { deleteGoodsInRecord } from '../../../Api/goodsInRecords'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { goodsInRecord } = getState().screens.GoodsInRecord

    await deleteGoodsInRecord({ id: goodsInRecord.id })

    message.success(`Deleted Record "${goodsInRecord.id}"`)

    dispatch(goBack())

  } catch (error) {

    handleException(error)

  }
}