import { connect } from 'react-redux'
import Activity from './Activity'

const mapStateToProps = (state) => ({
  user: state.screens.User.user,
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Activity)
