import { connect } from 'react-redux'
import Advanced from './Advanced'

import onFormChange from './Handlers/onFormChange'
import onSubmit from './Handlers/onSubmit'
import onClickClose from './Handlers/onClickClose'

const mapStateToProps = (state) => ({
  advanced: state.modules.TransactionsTable.advanced,
  stockLocations: state.core.stockLocations,
  products: state.core.products,
})

const actionCreators = {
  onFormChange,
  onSubmit,
  onClickClose,
}

export default connect(mapStateToProps, actionCreators)(Advanced)
