import moment from 'moment'
import screenProps from '../../../screenProps'
import { findConsignment } from '../../../../../Api/consignments'
import { setInitialValues } from '../State/initialValues'
import { handleException } from 'Traqa/Packages'

export default (values = undefined) => async (dispatch) => {

  const { id } = screenProps().match.params

  if (id === 'new') {

    const initialValues = {
      date: moment(),
    }

    dispatch(setInitialValues(values || initialValues))

    return

  }

  try {

    const {
      data: {
        data: consignment,
      },
    } = await findConsignment({
      id,
    })

    const initialValues = {
      date: moment(consignment.date),
      stock_location_id: consignment.stock_location_id,
      customer_id: consignment.customer_id,
      customer_site_id: consignment.customer_site_id,

      courier_id: consignment.courier_id || 'null',
      courier_service_id: consignment.courier_service_id,
      reference: consignment.reference,
      booking_ref: consignment.booking_ref,
      courier_zone_id: consignment.courier_zone_id,

      pallet_full_qty: consignment.pallet_full_qty,
      pallet_half_qty: consignment.pallet_half_qty,
      pallet_quarter_qty: consignment.pallet_quarter_qty,
      pallet_micro_qty: consignment.pallet_micro_qty,
      parcel_qty: consignment.parcel_qty,
      total_kgs: consignment.total_kgs,
      total_cost: consignment.total_cost,
    }

    dispatch(setInitialValues(initialValues))

  } catch (error) {

    handleException(error)

  }

}
