import { combineReducers } from "redux"

import { reducer as initialized } from "./State/initialized"
import { reducer as adjustment } from "./State/adjustment"

import Editor from "./Components/Editor/reducer"

export default combineReducers({
  initialized,
  adjustment,
  Editor,
})
