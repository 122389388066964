import { combineReducers } from 'redux'

import { reducer as data } from './State/data'
import { reducer as fetching } from './State/fetching'
import { reducer as submitting } from './State/submitting'

export default combineReducers({
  data,
  fetching,
  submitting,
})
