import React from 'react'
import PropTypes from 'prop-types'
import { Popover } from 'antd'
import numeral from 'numeral'
import styles from './styles.css'

const ProductAssessment = (props) => {

  const {
    productAssessment,
    children,
  } = props

  return (

    <Popover
      trigger={['hover', 'click']}
      content={(
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Stock Location</th>
              <th>Quantity</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {productAssessment.stock_locations.map(row => (
              <tr key={row.id}>
                <td>
                  <div style={{ fontWeight: 500 }}>{row.name}</div>
                </td>
                <td>
                  <div style={{ fontWeight: 500 }}>{numeral(Math.floor(row.quantity)).format('0,0')}</div>
                </td>
                <td>
                  <div style={{ fontWeight: 500 }}>{`£${numeral(row.value).format('0,0')}`}</div>
                </td>
              </tr>
            ))}
          </tbody>

        </table>

      )}
    >
      {children}
    </Popover>

  )
  
}

ProductAssessment.propTypes = {
  productAssessment: PropTypes.object.isRequired,
  children: PropTypes.node,
}

export default ProductAssessment
