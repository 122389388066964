import { goBack } from 'connected-react-router'
import { defer } from 'lodash'
import formService from '../Components/Editor/formService'

export default (isNew) => async (dispatch, getState) => {

  if (isNew) {

    dispatch(goBack())

  } else {

    const { initialValues } = getState().screens.Consignment.Editor

    defer(() => formService().initialize(initialValues))

  }

}
