import { createAction, handleActions } from 'redux-actions'

const initialized = createAction("SCREENS/TRANSFER/INITIALIZED")
const resetInitialized = createAction("SCREENS/TRANSFER/RESET_INITIALIZED")

const defaultState = false

const reducer = handleActions(
  {
    [initialized]: () => true,
    [resetInitialized]: () => defaultState,
  },
  defaultState
)

export { initialized, resetInitialized, reducer }
