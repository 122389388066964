import { createAction, handleActions } from 'redux-actions'

const setData = createAction('SCREENS/RECONCILIATION/REPORT/SET_DATA')
const resetData = createAction('SCREENS/RECONCILIATION/REPORT/RESET_DATA')

const defaultState = {
  products: [],
  variance_value: 0,
}

const reducer = handleActions(
  {
    [setData]: (state, action) => action.payload,
    [resetData]: () => defaultState,
  },
  defaultState
)

export { setData, resetData, reducer }