import React from 'react'
import PropTypes from 'prop-types'
import { Input, Radio, Icon, Tooltip, Select } from 'antd'
import classNames from 'classnames'
import { Module, LinkButton } from 'Traqa/Packages'
import styles from './Header.css'

const Header = (props) => {

  const {
    activeTab,
    searchTerm,
    hideInSage,
    onChangeTab,
    onChangeSearch,
    onSearch,
    onClickAdvanced,
    onChangeHideInSage,
  } = props

  const renderLeft = () => (

    <React.Fragment>

      <Radio.Group onChange={onChangeTab} value={activeTab}>
        <Radio.Button value="Week" className={styles.tab}>
          <Icon type="calendar" style={{ marginRight: '5px' }} />
          This Week
        </Radio.Button>
        <Radio.Button value="Overdue" className={classNames(styles.tab, styles.overdue)}>
          <Icon type="exclamation-circle" style={{ marginRight: '5px' }} />
          Overdue
        </Radio.Button>

        <Radio.Button value="Speculated" className={styles.tab} style={{ marginLeft: '20px' }}>
          <Icon type="clock-circle" style={{ marginRight: '5px', color: '#ffa500' }} />
          Scheduled
        </Radio.Button>
        <Radio.Button value="Expected" className={styles.tab}>
          <Icon type="check-circle" style={{ marginRight: '5px', color: '#1890ff' }} />
          Booked
        </Radio.Button>
        <Radio.Button value="Completed" className={styles.tab}>
          <Icon type="check-circle" style={{ marginRight: '5px', color: '#32cd32' }} />
          Received
        </Radio.Button>
        <Radio.Button value="Returned" className={styles.tab}>
          <Icon type="undo" style={{ marginRight: '5px' }} />
          Returned
        </Radio.Button>
        <Radio.Button value="Draft" className={styles.tab}>
          <Icon type="edit" style={{ marginRight: '5px' }} />
          Draft
        </Radio.Button>
        <Radio.Button value="All" className={styles.tab}>
          <Icon type="unordered-list" style={{ marginRight: '5px' }} />
          All
        </Radio.Button>
      </Radio.Group>

      <Select
        value={hideInSage ? 1 : 0}
        onChange={value => onChangeHideInSage(value)}
        optionFilterProp='children'
        style={{ width: '150px', marginLeft: '20px' }}
      >
        <Select.Option key={1} value={1}>
          Hide In Sage
        </Select.Option>
        <Select.Option key={2} value={0}>
          Include In Sage
        </Select.Option>
      </Select>

    </React.Fragment>

  )

  const renderRight = () => (

    <React.Fragment>

      <LinkButton
        type='primary'
        onClick={onClickAdvanced}
      >
        Advanced
      </LinkButton>

      <div style={{ width: 16 }} />

      <Tooltip title='Search by PO # or product code' defaultVisible>
        <Input.Search
          value={searchTerm}
          onChange={onChangeSearch}
          onSearch={onSearch}
          placeholder="Search"
          style={{ width: 200 }}
        />
      </Tooltip>
    
    </React.Fragment>

  )
  
  return (

    <Module.Header
      renderLeft={renderLeft}
      renderRight={renderRight}
    />

  )
}

Header.propTypes = {
  activeTab: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  hideInSage: PropTypes.bool.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onClickAdvanced: PropTypes.func.isRequired,
  onChangeHideInSage: PropTypes.func.isRequired,
}

export default Header