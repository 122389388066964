import { connect } from "react-redux"

import Component from "./Component"

import componentDidMount from "./Handlers/componentDidMount"
import onClickNew from "./Handlers/onClickNew"
import onCloseProductCategoryModal from "./Handlers/onCloseProductCategoryModal"

const mapStateToProps = (state) => ({
  productCategoryModal: state.screens.ProductCategories.productCategoryModal,
})

const actionCreators = {
  componentDidMount,
  onClickNew,
  onCloseProductCategoryModal,
}

export default connect(mapStateToProps, actionCreators)(Component)
