import { message } from 'antd'
import { archiveCustomerSite } from '../../../Api/customerSites'
import fetch from './fetch'
import fetchCustomerSitesCore from '../../../Core/Services/fetchCustomerSites'
import { handleException } from 'Traqa/Packages'

export default (customerSite) => async (dispatch) => {

  try {

    await archiveCustomerSite({ id: customerSite.id })

    await dispatch(fetch())

    message.success(`Archived Site "${customerSite.name}"`)
    
    dispatch(fetchCustomerSitesCore({ force: true }))

  } catch (error) {

    handleException(error)

  }
}