import React from 'react'
import PropTypes from 'prop-types'
import { Tag } from 'antd'
import styles from './GroupHeader.css'

const GroupHeader = (props) => {

  const {
    product,
  } = props
  
  return (

    <div className={styles.wrapper}>

      <div className={styles.name}>
        {`${product.code} - ${product.name}`}
        <Tag color='volcano' style={{ marginLeft: 5 }}>{product.form}</Tag>
      </div>
      
    </div>

  )
}

GroupHeader.propTypes = {
  product: PropTypes.object.isRequired,
}

export default GroupHeader