import { push } from 'connected-react-router'
import { defer, omit } from 'lodash'
import moment from 'moment'
import chance from 'chance'
import { setInitialValues } from '../Components/Editor/State/initialValues'
import formService from '../Components/Editor/formService'
import { getTeamUrl } from 'Traqa/Helpers'

export default () => async (dispatch, getState) => {

  const { values } = getState().screens.PurchaseOrder.Editor.formState

  const { user: { id: userId } } = getState().app

  dispatch(push(getTeamUrl(`purchase_orders/new`)))

  const initialValues = {
    ...omit(values, [
      'id',
      'number',
      'total_pending',
      'total_speculated',
      'total_expected',
      'total_completed',
      'pending_percentage',
      'fulfilled_percentage',
      'complete_percentage',
    ]),
    ...{
      date: moment(),
      user_id: userId,
      purchase_order_items: values.purchase_order_items.map(item => ({
        guid: chance().guid(),
        ...omit(item, ['guid', 'id', 'pending', 'speculated', 'expected', 'completed']),
      })),
    },
  }

  dispatch(setInitialValues(initialValues))

  defer(() => formService().initialize(initialValues))

}
