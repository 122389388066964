import { connect } from "react-redux"

import Component from "./Component"

import componentDidMount from "./Handlers/componentDidMount"
import onClickNew from "./Handlers/onClickNew"
import onCloseUserModal from './Handlers/onCloseUserModal'

const mapStateToProps = (state) => ({
  userModal: state.screens.Users.userModal,
})

const actionCreators = {
  componentDidMount,
  onClickNew,
  onCloseUserModal,
}

export default connect(mapStateToProps, actionCreators)(Component)
