import { createSelector } from 'reselect'
import { get } from 'lodash'

const itemCountSelector = state => get(state.screens.Adjustment.Editor.formState.values, 'adjustment_items.length', 0)
const selectedItemCountSelector = state => state.screens.Adjustment.Editor.selectedItems.length

const selectToggleState = createSelector(
  itemCountSelector,
  selectedItemCountSelector,
  (itemCount, selectedItemCount) => ({
    checked: selectedItemCount > 0,
    indeterminate: selectedItemCount > 0 && selectedItemCount < itemCount,
  })
)

export default selectToggleState