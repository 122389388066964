import { message } from 'antd'
import { goBack } from 'connected-react-router'
import { deleteGoodsOutRecord } from '../../../Api/goodsOutRecords'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { goodsOutRecord } = getState().screens.GoodsOutRecord

    await deleteGoodsOutRecord({ id: goodsOutRecord.id })

    message.success(`Deleted Record '${goodsOutRecord.id}'`)

    dispatch(goBack())

  } catch (error) {

    handleException(error)

  }

}
