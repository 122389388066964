import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'

import Form from './Components/Form'

export default class extends React.PureComponent {

  static propTypes = {
    id: PropTypes.number,
    submitting: PropTypes.bool.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    id: null,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {

    const {
      id,
      submitting,
      onSubmit,
      onClose,
    } = this.props

    return (
        
      <Modal
        visible  
        title={id ? 'Courier' : 'New Courier'}
        width={500}
        okText={id ? 'Update' : 'Create'}
        okButtonProps={{ loading: submitting }}
        cancelButtonProps={{ disabled: submitting }}
        onOk={onSubmit}
        onCancel={onClose}
      >
        
        <Form />

      </Modal>
      
    )

  }

}
