import React from 'react'
import { get, has } from 'lodash'
import { Modal } from 'antd'
import * as Sentry from '@sentry/browser'

const isHttpError = (error) => (error && has(error,'response.status'))

export default (error) => {
    
  if (isHttpError(error) && error.response.status === 409) {

    const { title, messages } = error.response.data.error

    Modal.warning({
      title,
      content: (

        <div>

          {messages.length === 1 && (
            <div>
              {messages[0]}
            </div>
          )}

          {messages.length > 1 && messages.map(message => (
            <div>
              {`• ${message}`}
            </div>
          ))}

        </div>
      )
    })

  } else if (isHttpError(error) && error.response.status === 404) {
    
    Modal.error({
      title: 'Oops! There was a problem behind the scenes',
      content: 'Please contact support if this problem persists.',
      width: 500
    })
    
  } else if (isHttpError(error) && error.response.status === 500) {

    Modal.error({
      title: 'Oops! There was a problem behind the scenes',
      content: 'Please contact support if this problem persists.',
      width: 500
    })
    
  } else {

    if (get(window, 'env.APP_ENV') === 'local') {

      throw error

    }
      
    Sentry.captureException(error)

    if (error.message.includes("Cannot read properties of undefined (reading 'match')")) return

    Modal.confirm({
      title: "Oops! Something didn't go quite right...",
      content: (
        <div>
          If you have a moment, it would be great if you could explain what you were trying to do.
          It might just help us fix the problem quicker :)
        </div>
      ),
      width: 500,
      icon: 'warning',
      cancelText: "Dismiss",
      okText: "Submit Report",
      onOk: () => Sentry.showReportDialog()
    })
    
  }
  
}