import screenProps from '../screenProps'
import { startFetching, finishFetching } from '../State/isFetching'
import { getProduct, getProductYearSummary, getProductLiveAssessment } from '../../../Api/products'
import { loadProduct } from '../State/product'
import { setYearSummary } from '../State/yearSummary'
import { setLiveAssessment } from '../State/liveAssessment'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    const params = {

      include: [
        'product_categories',
        'divides_into_products',
        'division_of_products',
        'product_values.created_by_user',
        'product_values.updated_by_user',
      ].join(),
      
    }

    dispatch(startFetching())

    const getProductResponse = await getProduct({ id, params })

    const getProductYearSummaryResponse = await getProductYearSummary({ id })

    const getProductLiveAssessmentResponse = await getProductLiveAssessment({ id })

    dispatch(loadProduct(getProductResponse.data.data))

    dispatch(setYearSummary(getProductYearSummaryResponse.data))

    dispatch(setLiveAssessment(getProductLiveAssessmentResponse.data))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }
}