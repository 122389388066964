import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import storage from 'redux-persist/lib/storage'

import { reducer as ready } from "./State/ready"
import { reducer as showSplashscreen } from "./State/showSplashscreen"
import { reducer as token } from "./State/token"
import { reducer as user } from "./State/user"

const reducer = combineReducers({
  ready,
  showSplashscreen,
  token,
  user,
})

const persistConfig = {
  key: 'app',
  storage,
  whitelist: ['token', 'user'],
}

export default persistReducer(persistConfig, reducer)