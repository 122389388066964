import { initialized } from '../State/initialized'
import fetchProductCategories from '../../../Core/Services/fetchProductCategories'
import fetchProducts from '../../../Core/Services/fetchProducts'
import initializeForm from '../Components/Form/Services/initialize'

export default () => async (dispatch) => {
  
  await Promise.all([
    dispatch(fetchProductCategories()),
    dispatch(fetchProducts()),
    dispatch(initializeForm()),
  ])

  dispatch(initialized())

}