import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openSupplierModal = createAction('SCREENS/SUPPLIERS/OPEN_CREATE_SUPPLIER_MODAL')
const closeSupplierModal = createAction('SCREENS/SUPPLIERS/CLOSE_CREATE_SUPPLIER_MODAL')

const defaultState = {
  show: false,
  supplierId: null,
}

const reducer = handleActions(
  {
    [openSupplierModal]: (state, action) => Object.assign({}, state, {
      show: true,
      supplierId: get(action, 'payload.supplierId', null),
    }),
    [closeSupplierModal]: () => Object.assign({}, defaultState),
  },
  defaultState
)

export { openSupplierModal, closeSupplierModal, reducer }