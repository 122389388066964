import { createSelector } from 'reselect'

const selectIncludeExpected = state => state.screens.StockAdjustments.Report.formState.values.includeExpected
const selectTotals = state => state.screens.StockAdjustments.Report.totals

export default createSelector(
  selectIncludeExpected,
  selectTotals,
  (includeExpected, totals) => {

    const key = includeExpected ? 'expected' : 'actual'

    return {
      in: totals[key].in,
      in_value: totals[key].in_value,
      out: totals[key].out,
      out_value: totals[key].out_value,
    }

  },
)
