import { getDashboardMovementByProduct } from '../../../../../Api/dashboard'
import { setMovement } from '../State/movement'
import { startFetchingMovement, finishFetchingMovement } from '../State/isFetchingMovement'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  const { movementControls } = getState().screens.Dashboard.ColumnCharts

  const params = {
    owner_type: movementControls.ownerType,
    start_date: movementControls.startDate.format('YYYY-MM-DD'),
    end_date: movementControls.endDate.format('YYYY-MM-DD'),
    stock_location_id: movementControls.stockLocationId,
    product_category_id: movementControls.productCategoryId,
    division_type: movementControls.divisionType,
  }

  dispatch(startFetchingMovement())

  try {

    const response = await getDashboardMovementByProduct({ params })

    dispatch(setMovement(response.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetchingMovement())
    
  }

}
