import { createSelector } from 'reselect'

const selectGoodsInRecords = state => state.screens.PlasticTax.Report.data.goods_in_records

export default createSelector(
  selectGoodsInRecords,
  (goodsInRecords) => {

    return goodsInRecords

  },
)
