import { connect } from "react-redux"
import Editor from "./Editor"

import onFormChange from './Handlers/onFormChange'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'

const mapStateToProps = (state) => ({
  initialValues: state.screens.Reading.Editor.initialValues,
})

const actionCreators = {
  onFormChange,
  onSubmit,
  validate,
}

export default connect(mapStateToProps, actionCreators)(Editor)
