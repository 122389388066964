import { createAction, handleActions } from 'redux-actions'
import { includes, without, concat } from 'lodash'

const selectItem = createAction("SCREENS/GOODS_IN_RECORD/EDITOR/SELECT_ITEM")
const deselectItem = createAction("SCREENS/GOODS_IN_RECORD/EDITOR/DESELECT_ITEM")
const toggleItem = createAction("SCREENS/GOODS_IN_RECORD/EDITOR/TOGGLE_ITEM")
const setSelectedItems = createAction("SCREENS/GOODS_IN_RECORD/EDITOR/SET_SELECTED_ITEMS")
const resetSelectedItems = createAction("SCREENS/GOODS_IN_RECORD/EDITOR/RESET_SELECTED_ITEMS")

const defaultState = []

const reducer = handleActions(
  {
    [selectItem]: (state, action) => !includes(state, action.payload) ? concat(state, action.payload) : state,
    [deselectItem]: (state, action) => includes(state, action.payload) ? without(state, action.payload) : state,
    [toggleItem]: (state, action) => includes(state, action.payload) ? without(state, action.payload) : concat(state, action.payload),
    [setSelectedItems]: (state, action) => action.payload,
    [resetSelectedItems]: () => [],
  },
  defaultState
)

export { selectItem, deselectItem, toggleItem, setSelectedItems, resetSelectedItems, reducer }
