import { createAction, handleActions } from 'redux-actions'

const setMovement = createAction('SCREENS/DASHBOARD/COLUMN_CHARTS/SET_MOVEMENT')
const resetMovement = createAction('SCREENS/DASHBOARD/COLUMN_CHARTS/RESET_MOVEMENT')

const defaultState = {
  stock_locations: [],
  products: [],
}

const reducer = handleActions(
  {
    [setMovement]: (state, action) => action.payload,
    [resetMovement]: () => defaultState,
  },
  defaultState,
)

export { setMovement, resetMovement, reducer }
