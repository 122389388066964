import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field, FormSpy } from 'react-final-form'
import { Form as AntdForm, Typography, InputNumber, Input, Switch } from 'antd'
import { find } from 'lodash'
import pluralize from 'pluralize'
import { ExposeFormToHelper } from 'Traqa/Packages'
import formService from './formService'
import styles from './styles.css'

const Form = (props) => {

  const {
    stockLocations,
    stockLocationId,
    product,
    period,
    onChangeFormState,
    onSubmit,
    validate,
  } = props

  const initialValues = {
    year_duplicate: true,
  }

  return (

    <FormProvider
      validate={validate}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, submitting }) => (

        <AntdForm
          onSubmit={handleSubmit}
          className={styles.form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          autoComplete='off'
        >

          <ExposeFormToHelper helper={formService} />

          <FormSpy subscription={{ values: true, submitting: true }} onChange={onChangeFormState} />

          <AntdForm.Item label='Location'>
            <Typography.Text strong>
              {find(stockLocations, { id: stockLocationId }).name}
            </Typography.Text>
          </AntdForm.Item>

          <AntdForm.Item label='Product'>
            <Typography.Text strong>
              {`${product.code} - ${product.name} [${product.form}]`}
            </Typography.Text>
          </AntdForm.Item>

          <AntdForm.Item label='Period'>
            <Typography.Text strong>{period.display}</Typography.Text>
          </AntdForm.Item>

          <Field name='quantity'>
            {({ input, meta }) => (
              <AntdForm.Item
                label={pluralize(`${product.form}`)}
                required
                validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
              >
                <InputNumber
                  {...input}
                  disabled={submitting}
                  style={{ width: 150 }}
                  min={0}
                  step={1}
                  precision={0}
                  autoFocus
                />
              </AntdForm.Item>
            )}
          </Field>

          <Field name='notes'>
            {({ input }) => (
              <AntdForm.Item
                label='Notes'
              >
                <Input.TextArea
                  {...input}
                  disabled={submitting}
                  style={{ width: 300 }}
                />
              </AntdForm.Item>
            )}
          </Field>

          <Field name='locked'>
            {({ input }) => (
              <AntdForm.Item
                label='Locked'
              >
                <Switch
                  {...input}
                  checked={input.value || false}
                />
                <span style={{ marginLeft: 5 }}>Will not be affected by bulk updates</span>
              </AntdForm.Item>
            )}
          </Field>

          <Field name='year_duplicate'>
            {({ input }) => (
              <AntdForm.Item
                label='Duplicate'
              >
                <Switch
                  {...input}
                  checked={input.value}
                />
                <span style={{ marginLeft: 5 }}>Over next 12 months</span>
              </AntdForm.Item>
            )}
          </Field>

        </AntdForm>

      )}

    </FormProvider>

  )

}

Form.propTypes = {
  product: PropTypes.object.isRequired,
  period: PropTypes.object.isRequired,
  manualRunRate: PropTypes.object,
  onChangeFormState: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
}

export default Form
