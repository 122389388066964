import React from 'react'
import PropTypes from 'prop-types'
import { Tabs } from 'antd'
import { Module } from 'Traqa/Packages'

import Holding from './Components/Holding'
import Movement from './Components/Movement'

const ColumnCharts = props => {

  const {
    activeTab,
    onChangeActiveTab,
  } = props

  return (

    <Module>
      
      <Module.Header
        renderLeft={() => (
          <Tabs
            activeKey={activeTab}
            onChange={onChangeActiveTab}
          >
            <Tabs.TabPane key='1' tab='Holding' />
            <Tabs.TabPane key='2' tab='Movement' />
          </Tabs>
        )}
      />

      {activeTab === '1' && <Holding />}
      
      {activeTab === '2' && <Movement />}

    </Module>

  )

}

ColumnCharts.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onChangeActiveTab: PropTypes.func.isRequired,
}

export default ColumnCharts
