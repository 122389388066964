import getParams from './getParams'
import { getTransactions } from '../../../Api/transactions'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { setPagination } from '../State/pagination'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  const params = getParams(getState)

  const { pagination } = getState().modules.TransactionsTable

  params.include = [
    'stock_location',
    'product',
  ].join()

  params.append = [
    'owner_type_formatted',
    'owner_type_slug',
    'quantity_formatted',
    'details',
  ]

  params['page[number]'] = pagination.pageNumber
  params['page[size]'] = pagination.pageSize

  dispatch(startFetching())

  try {

    const response = await getTransactions({ params })

    dispatch(setData(response.data.data))
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
    }))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }

}
