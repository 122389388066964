import React from "react"
import PropTypes from "prop-types"
import { Button } from 'antd'
import Screen from '../../Components/Screen'
import ProductsTable from "./Components/ProductsTable"

export default class _Products_ extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  renderHeaderRight = () => ([

    <Button key="NEW" type="primary" onClick={this.props.onClickNew}>
      New Product
    </Button>
    
  ])

  render () {

    return (

      <Screen
        title="Products"
        headerRight={this.renderHeaderRight()}
      >

        <ProductsTable />

      </Screen>

    )
  }
}
