import { createAction, handleActions } from 'redux-actions'

const setYearSummary = createAction('SCREENS/PRODUCT/SET_YEAR_SUMMARY')
const resetYearSummary = createAction('SCREENS/PRODUCT/RESET_YEAR_SUMMARY')

const defaultState = []

const reducer = handleActions(
  {
    [setYearSummary]: (state, action) => action.payload,
    [resetYearSummary]: () => defaultState,
  },
  defaultState
)

export { setYearSummary, resetYearSummary, reducer }
