import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { Comments as CommentsModule } from '../../Modules'

const Comments = props => (

  <Modal
    visible
    title='Comments'
    width={500}
    footer={null}
    onCancel={props.onClose}
    bodyStyle={{ padding: 0 }}
  >

    <CommentsModule
      modelType={props.modelType}
      modelId={props.id}
      useHeader={false}
    />

  </Modal>

)

Comments.propTypes = {
  modelType: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Comments
