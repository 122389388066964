import { connect } from 'react-redux'
import MoreMenu from './MoreMenu'

import onDelete from './Handlers/onDelete'
import onDuplicate from './Handlers/onDuplicate'

const mapStateToProps = () => ({})

const actionCreators = {
  onDelete,
  onDuplicate,
}

export default connect(mapStateToProps, actionCreators)(MoreMenu)
