import { push } from 'connected-react-router'
import screenProps from '../screenProps'
import { getTeamUrl } from 'Traqa/Helpers'

export default () => (dispatch) => {

  const { id } = screenProps().match.params

  dispatch(push(getTeamUrl(`stocktakes/${id}`)))

}