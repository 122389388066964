import React from "react"
import PropTypes from "prop-types"
import { Button } from 'antd'
import Screen from '../../Components/Screen'
import SuppliersTable from "./Components/SuppliersTable"

import Supplier from '../../Modals/Supplier'

export default class SuppliersScreen extends React.PureComponent {

  static propTypes = {
    supplierModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onCloseSupplierModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  renderHeaderRight = () => (

    <Button type="primary" onClick={this.props.onClickNew}>
      New Supplier
    </Button>
    
  )

  render () {

    const {
      supplierModal,
      onCloseSupplierModal,
    } = this.props

    return (

      <Screen
        title="Suppliers"
        headerRight={this.renderHeaderRight()}
      >

        <SuppliersTable />

        {supplierModal.show && (
          <Supplier
            onClose={onCloseSupplierModal}
            supplierId={supplierModal.supplierId}
          />
        )}

      </Screen>

    )
  }
}
