import { connect } from 'react-redux'
import Header from './Header'

import onChangeStatus from './Handlers/onChangeStatus'
import onChangeIsPrinted from './Handlers/onChangeIsPrinted'
import onLinkConsignment from './Handlers/onLinkConsignment'
import onGoToConsignment from './Handlers/onGoToConsignment'
import onUnlinkConsignment from './Handlers/onUnlinkConsignment'

const mapStateToProps = (state) => ({
  goodsOutRecord: state.screens.GoodsOutRecord.goodsOutRecord,
})

const actionCreators = {
  onChangeStatus,
  onChangeIsPrinted,
  onLinkConsignment,
  onGoToConsignment,
  onUnlinkConsignment,
}

export default connect(mapStateToProps, actionCreators)(Header)
