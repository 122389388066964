import { createAction, handleActions } from 'redux-actions'

const setCourier = createAction('SCREENS/COURIER/SET_COURIER')
const resetCourier = createAction('SCREENS/COURIER/RESET_COURIER')

const defaultState = null

const reducer = handleActions(
  {
    [setCourier]: (state, action) => action.payload,
    [resetCourier]: () => defaultState,
  },
  defaultState,
)

export { setCourier, resetCourier, reducer }
