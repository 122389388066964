import moment from 'moment'
import { getSalesOrders } from '../../Api/salesOrders'
import { loadSalesOrders } from '../State/salesOrders'

let lastGetTimestamp = null

const cacheTTL = 60000

export default ({ force } = {}) => async (dispatch) => {

  try {

    if (!force && lastGetTimestamp) {

      const now = Number(moment().format('x'))

      if (now < (lastGetTimestamp + cacheTTL)) return
    }
      
    lastGetTimestamp = Number(moment().format('x'))

    const params = {
      'fields[sales_orders]': 'id,number',
      sort: 'id'
    }

    const response = await getSalesOrders({ params })

    dispatch(loadSalesOrders(response.data.data))

  } catch (error) {
    
    throw error

  }
}