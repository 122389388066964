import { message } from 'antd'
import modalProps from '../modalProps'
import { deleteCourierRate } from '../../../Api/courierRates'
import { handleException } from 'Traqa/Packages'

export default () => async () => {

  const { id } = modalProps()

  try {

    await deleteCourierRate({ id })

    modalProps().onClose(true)

    message.success('Deleted Rate')

  } catch (error) {

    handleException(error)

  }
  
}
