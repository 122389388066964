import { connect } from "react-redux"
import Component from "./Component"

import onToggleRead from './Handlers/onToggleRead'
import onClickReply from './Handlers/onClickReply'

const mapStateToProps = (state) => ({
  filter: state.modules.NotificationPopover.filter,
  notifications: state.modules.NotificationPopover.notifications,
  users: state.core.users,
})

const actionCreators = {
  onToggleRead,
  onClickReply,
}

export default connect(mapStateToProps, actionCreators)(Component)
