import { connect } from "react-redux"
import Details from "./Details"

const mapStateToProps = (state) => ({
  suppliers: state.core.suppliers,
  users: state.core.users,
  isNew: !state.screens.PurchaseOrder.purchaseOrder,
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Details)
