import { createAction, handleActions } from 'redux-actions'

const setPurchaseOrder = createAction('SCREENS/PURCHASE_ORDER/SET_PURCHASE_ORDER')
const resetPurchaseOrder = createAction('SCREENS/PURCHASE_ORDER/RESET_PURCHASE_ORDER')

const defaultState = null

const reducer = handleActions(
  {
    [setPurchaseOrder]: (state, action) => action.payload,
    [resetPurchaseOrder]: () => null,
  },
  defaultState
)

export { setPurchaseOrder, resetPurchaseOrder, reducer }