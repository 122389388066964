import { createAction, handleActions } from 'redux-actions'

const setPagination = createAction('SCREENS/GOODS_IN_RECORDS/GOODS_IN_RECORDS_TABLE/SET_PAGINATION')
const firstPage = createAction('SCREENS/GOODS_IN_RECORDS/GOODS_IN_RECORDS_TABLE/FIRST_PAGE')
const resetPagination = createAction('SCREENS/GOODS_IN_RECORDS/GOODS_IN_RECORDS_TABLE/RESET_PAGINATION')

const defaultState = {
  pageNumber: 1,
  pageSize: 10,
  total: 0,
}

const reducer = handleActions(
  {
    [setPagination]: (state, action) => ({ ...state, ...action.payload }),
    [firstPage]: (state) => ({ ...state, pageNumber: 1 }),
    [resetPagination]: () => ({ ...defaultState }),
  },
  defaultState
)

export { setPagination, firstPage, resetPagination, reducer }