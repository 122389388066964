import { stockshotsOverview } from '../../../Api/stockshots'
import { setData } from '../State/data'
import { setFetching } from '../State/fetching'

export default () => async (dispatch) => {

  dispatch(setFetching(true))

  try {

    const response = await stockshotsOverview()

    dispatch(setData(response.data))

  } catch (error) {
    
    throw error

  } finally {

    dispatch(setFetching(false))

  }

}
