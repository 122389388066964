import React from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import { Field, FormSpy } from 'react-final-form'
import { Form, Row, Col, Select, Input, Progress, Typography, Icon, Tag } from 'antd'
import styles from './Item.css'

const Item = (props) => {

  const {
    field,
    index,
    products,
    availability,
  } = props

  const truncate = (input) => input.length > 45 ? `${input.substring(0, 45)}...` : input
  
  return (

    <FormSpy subscription={{ submitting: true }}>

      {({ submitting }) => (

        <div className={styles.wrapper}>

          <Row gutter={8}>

            <Col span={8}>

              <Field name={`${field}.product_id`} allowNull>
                {({ input, meta }) => (
                  <Form.Item
                    label={index === 0 ? 'Product' : undefined}
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Select
                      {...input}
                      disabled
                      optionFilterProp="children"
                      showSearch
                      allowClear
                      dropdownMatchSelectWidth={false}
                    >

                      <Select.OptGroup label="Products">
                        {products.filter(product => !product.archived_at).map(product => (
                          <Select.Option key={product.id} value={product.id}>
                            {truncate(`${product.code} - ${product.name}`)}
                            <Tag color='volcano' style={{ marginLeft: 5 }}>{product.form}</Tag>
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                      
                      <Select.OptGroup label="Archived">
                        {products.filter(product => product.archived_at).map(product => (
                          <Select.Option key={product.id} value={product.id} style={{ color: '#999' }}>
                            {truncate(`${product.code} - ${product.name}`)}
                            <Tag color='volcano' style={{ marginLeft: 5 }}>{product.form}</Tag>
                          </Select.Option>
                        ))}
                      </Select.OptGroup>

                    </Select>
                  </Form.Item>
                )}
              </Field>

            </Col>

            <Col span={4}>

              <Field name={`${field}.batch_no`}>
                {({ input, meta }) => (
                  <Form.Item
                    label={index === 0 ? 'Batch No.' : undefined}
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Input
                      {...input}
                      disabled={submitting}
                    />
                  </Form.Item>
                )}
              </Field>

            </Col>

            <Col span={2}>

              <Field name={`${field}.quantity`}>
                {({ input, meta }) => (
                  <Form.Item
                    label={index === 0 ? 'Qty' : undefined}
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <div style={{ paddingRight: '10%' }}>
                      <Input
                        {...input}
                        disabled
                        style={{ width: '100%' }}
                      />
                    </div>
                  </Form.Item>
                )}
              </Field>

            </Col>
            
            {!!availability && (
              <Col span={2}>
                  
                <Form.Item
                  label={index === 0 ? 'Availability' : undefined}
                >
                  
                  <Progress
                    percent={Math.round((availability.global_availability / availability.required) * 100)}
                    successPercent={Math.round((availability.local_availability / availability.required) * 100)}
                    style={{ marginTop: 4 }}
                  />
                  
                </Form.Item>

              </Col>
            )}
            
            {!!availability && (
              <Col span={8}>
                  
                <Form.Item
                  label={index === 0 ? (<span>&nbsp;</span>) : undefined}
                >
                  
                  <div style={{ paddingLeft: 20, marginTop: 5 }}>

                    {(!availability.is_available_locally && !availability.is_available_globally) && (
                      <Typography.Text type="secondary">
                        <Typography.Text type="danger"><Icon type="close-circle" theme="filled" /></Typography.Text>
                        {` ${numeral(availability.required - availability.local_availability).format('0,0')} more required.`}
                        {(availability.global_availability - availability.local_availability) > 0 && ` ${numeral(availability.global_availability - availability.local_availability).format('0,0')} found in other locations.`}
                      </Typography.Text>
                    )}
                    
                    {(!availability.is_available_locally && availability.is_available_globally) && (
                      <Typography.Text type="secondary">
                        <Typography.Text type="warning"><Icon type="check-circle" theme="filled" /></Typography.Text>
                        {` ${numeral(availability.required - availability.local_availability).format('0,0')} more required.`}
                        {(availability.global_availability - availability.local_availability) > 0 && ` ${numeral(availability.global_availability - availability.local_availability).format('0,0')} found in other locations.`}
                      </Typography.Text>
                    )}

                  </div>
                  
                </Form.Item>

              </Col>
            )}

          </Row>

        </div>
            
      )}

    </FormSpy>

  )
}

Item.propTypes = {
  field: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  guid: PropTypes.string.isRequired,
  productId: PropTypes.any,
  products: PropTypes.array.isRequired,
  availability: PropTypes.object,
}

Item.defaultProps = {
  productId: null,
  availability: null,
}

export default Item