import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Field, FormSpy } from 'react-final-form'
import { Form, Row, Col, Dropdown, Menu, Select, Input, InputNumber, DatePicker, Checkbox, Button, Tag, Icon } from 'antd'
import { getTeamUrl } from 'Traqa/Helpers'
import styles from './styles.css'

const Item = (props) => {

  const {
    field,
    index,
    guid,
    salesOrderId,
    isNew,
    isSelected,
    users,
    products,
    sourceProducts,
    salesOrders,
    onToggle,
  } = props

  const truncate = (input) => input.length > 50 ? `${input.substring(0, 50)}...` : input
  
  return (

    <FormSpy subscription={{ submitting: true }}>

      {({ submitting, form }) => (

        <div>

          <Row gutter={8}>

            <Col span={1}>

              <Row type='flex' align='middle' className={classNames(styles.checkboxWrapper, {[styles.firstRow]: index === 0})}>

                <Col span={12}>

                  <Checkbox
                    checked={isSelected}
                    onChange={() => onToggle(guid)}
                    disabled={submitting}
                  />
                
                </Col>

                <Col span={12}>

                  <span className={styles.itemNumber}>{index + 1}</span>
                
                </Col>
              
              </Row>

            </Col>

            <Col span={8}>

              <Field name={`${field}.product_id`} allowNull>
                {({ input, meta }) => (
                  <Form.Item
                    label={index === 0 ? 'Product' : undefined}
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Select
                      {...input}
                      value={input.value === null ? undefined : input.value}
                      onChange={value => {
                        input.onChange(value === undefined ? null : value)
                        form.change(`${field}.source_product_id`, null)
                      }}
                      disabled={submitting}
                      optionFilterProp='children'
                      showSearch
                      allowClear
                      dropdownMatchSelectWidth={false}
                    >

                      <Select.OptGroup label='Products'>
                        {products.filter(product => !product.archived_at).map(product => (
                          <Select.Option key={product.id} value={product.id}>
                            {truncate(`${product.code} - ${product.name}`)}
                            <Tag color='volcano' style={{ marginLeft: 5 }}>{product.form}</Tag>
                          </Select.Option>
                        ))}
                      </Select.OptGroup>

                      {!isNew && (

                        <Select.OptGroup label="Archived">
                          {products.filter(product => product.archived_at).map(product => (
                            <Select.Option key={product.id} value={product.id} style={{ color: '#999' }}>
                              {truncate(`${product.code} - ${product.name}`)}
                              <Tag color='volcano' style={{ marginLeft: 5 }}>{product.form}</Tag>
                            </Select.Option>
                          ))}
                        </Select.OptGroup>

                      )}

                    </Select>
                  </Form.Item>
                )}
              </Field>

            </Col>

            <Col span={3}>

              <Field name={`${field}.batch_no`}>
                {({ input, meta }) => (
                  <Form.Item
                    label={index === 0 ? 'Batch No.' : undefined}
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Input
                      {...input}
                      disabled={submitting}
                    />
                  </Form.Item>
                )}
              </Field>

            </Col>

            <Col span={3}>

              <Field name={`${field}.expiry_date`} allowNull>
                {({ input, meta }) => (
                  <Form.Item
                    label={index === 0 ? 'Expiry Date' : undefined}
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <DatePicker
                      {...input}
                      disabled={submitting}
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                )}
              </Field>

            </Col>

            <Col span={sourceProducts.length ? 2: 3}>

              <Field name={`${field}.quantity`}>
                {({ input, meta }) => (
                  <Form.Item
                    label={index === 0 ? 'Qty' : undefined}
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <InputNumber
                      {...input}
                      disabled={submitting}
                      min={0}
                      step={1}
                      precision={0}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )}
              </Field>

            </Col>

            {!!sourceProducts.length && (

              <Col span={1}>

                <FormSpy subscription={{ values: true }}>

                  {({ values }) => (

                    <Dropdown
                      overlay={(
                        <Menu onClick={({ key }) => form.change(`${field}.source_product_id`, key !== 'null' ? Number(key) : null)}>
                          <div style={{ padding: '5px 12px', fontWeight: 500 }}>Taken From</div>
                          <Menu.Divider />
                          {sourceProducts.map(sourceProduct => (
                            <Menu.Item key={sourceProduct.id}>
                              {sourceProduct.id === values.goods_out_record_items[index].source_product_id && (
                                <Icon type='check' style={{ marginRight: 5 }} />
                              )}
                              {truncate(`${sourceProduct.code} - ${sourceProduct.name}`)}
                              <Tag color='volcano' style={{ marginLeft: 5 }}>{sourceProduct.form}</Tag>
                            </Menu.Item>
                          ))}
                          <Menu.Item key='null'>
                            {!values.goods_out_record_items[index].source_product_id && <Icon type='check' style={{ marginRight: 5 }} />}
                            None
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button
                        type={values.goods_out_record_items[index].source_product_id ? 'primary' : 'default'}
                        icon='fullscreen'
                        style={{ marginTop: index === 0 ? 29 : 0, width: '100%' }}
                      />
                    </Dropdown>

                  )}

                </FormSpy>

              </Col>

            )}

            <Col span={3}>

              <Field name={`${field}.picked_by_user_id`}>
                {({ input, meta }) => (
                  <Form.Item
                    label={index === 0 ? 'Picked By' : undefined}
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Select
                      {...input}
                      disabled={submitting}
                      optionFilterProp='children'
                      showSearch
                      allowClear
                    >
                      {users.filter(user => !isNew || !user.deactivated_at).map(user => (
                        <Select.Option key={user.id} value={user.id}>
                          {user.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Field>

            </Col>

            <Col span={2}>

              <Field name={`${field}.sales_order_id`}>
                {({ input, meta }) => (
                  <Form.Item
                    label={index === 0 ? 'SO No.' : undefined}
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Select
                      {...input}
                      disabled={submitting}
                      optionFilterProp='children'
                      showSearch
                      allowClear
                    >
                      {salesOrders.map(salesOrder => (
                        <Select.Option key={salesOrder.id} value={salesOrder.id}>
                          {salesOrder.number}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Field>

            </Col>

            <Col span={1} style={{ marginTop: index === 0 ? '34px' : '5px' }}>

              {salesOrderId && (

                <NavLink to={getTeamUrl(`sales_orders/${salesOrderId}`)}>
                  &#x2192; SO
                </NavLink>

              )}

            </Col>

          </Row>

        </div>
            
      )}

    </FormSpy>

  )

}

Item.propTypes = {
  field: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  guid: PropTypes.string.isRequired,
  salesOrderId: PropTypes.any,
  isNew: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  sourceProducts: PropTypes.array.isRequired,
  salesOrders: PropTypes.array.isRequired,
  onToggle: PropTypes.func.isRequired,
}

export default Item
