export default (values) => () => {

  const errors = {}

  if (!values.quantity) errors.quantity = true

  if (!values.stock_location_id) errors.stock_location_id = true

  if (!values.date) errors.date = true

  if (!values.time) errors.time = true

  if (!values.user_id) errors.user_id = true

  return errors

}