import { createSelector } from 'reselect'
import moment from 'moment'

const selectYearSummary = (state) => state.screens.Product.yearSummary

export default createSelector(
  selectYearSummary,
  (yearSummary) => yearSummary.map(period => ({
    name: moment(period.start_date_time).format('MMM'),
    In: period.actual.in,
    Out: period.actual.out,
  }))
)
