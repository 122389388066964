import { push } from 'connected-react-router'
import { closeUserModal } from '../State/userModal'
import { getTeamUrl } from 'Traqa/Helpers'

export default (user = null) => (dispatch) => {
  
  dispatch(closeUserModal())
  
  if (user) dispatch(push(getTeamUrl(`users/${user.id}`)))
  
}
