import screenProps from '../screenProps'
import { getSalesOrder } from '../../../Api/salesOrders'
import { setSalesOrder } from '../State/salesOrder'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    if (id === 'new') return

    const params = {
      include: 'sales_order_items',
    }

    const response = await getSalesOrder({ id, params })

    dispatch(setSalesOrder(response.data.data))

  } catch (error) {

    handleException(error)

  }

}
