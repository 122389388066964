import React from 'react'
import PropTypes from 'prop-types'
import { List, Avatar, Icon } from 'antd'
import { get } from 'lodash'
import moment from 'moment'

export default class extends React.PureComponent {

  static propTypes = {
    activity: PropTypes.object.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
  }

  render() {

    const {
      activity,
      isExpanded,
      onToggle,
    } = this.props

    const qty = activity.logs.length
    const isOne = qty === 1

    const showOrHideLogs = () => (
      <div style={{ width: 150 }}>
        <Icon
          type={isExpanded ? 'down' : 'right'}
          style={{ marginRight: 5 }}
        />
        {`${qty} Log${isOne ? '' : 's'}`}
      </div>
    )

    return (
      
      <List.Item
        actions={[<a onClick={() => onToggle(activity.key)}>{showOrHideLogs()}</a>]}
      >
        <List.Item.Meta
          avatar={<Avatar size='large' icon={activity.causer ? 'user' : 'tool'} />}
          title={get(activity.causer, 'name', 'System')}
          description={`Caused activity on ${moment(activity.timestamp).format('MMMM Do YYYY, h:mm:ss a')}`}
        />
        <div>{moment(activity.timestamp).fromNow()}</div>
      </List.Item>

    )

  }

}
