import chance from 'chance'
import { pick } from 'lodash'
import formService from '../formService'
import { resetSelectedItems } from '../State/selectedItems'

export default () => (dispatch, getState) => {

  const { formState, selectedItems } = getState().screens.GoodsOutRecord.Editor

  const items = []

  formState.values.goods_out_record_items.forEach(item => {
    items.push(item)
    if (selectedItems.includes(item.guid)) {
      const guid = chance().guid()
      items.push({
        ...pick(item, [
          'goods_out_record_id',
          'product_id',
          'batch_no',
          'expiry_date',
          'quantity',
          'picked_by_user_id',
          'sales_order_id',
        ]),
        guid,
      })
    }
  })

  formService().change('goods_out_record_items', items)

  dispatch(resetSelectedItems())

}
