import { getGoodsInRecords } from '../../../../../Api/goodsInRecords'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { setPagination } from '../State/pagination'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { advanced, activeTab, searchTerm, sorting, pagination, hideInSage } = getState().screens.GoodsInRecords.Table

    const params = {}

    params.include = [
      'supplier',
      'stock_location',
      'user',
    ].join()

    if (advanced.show) {

      const {
        keywords,
        type,
        dateRange,
        countryCodes,
        supplierIds,
        stockLocationIds,
      } = advanced.values

      if (keywords) params.search = keywords

      if (type) params['filter[type]'] = type

      if (dateRange.length > 0) params['filter[date_between]'] = dateRange.map(moment => moment.format('YYYY-MM-DD')).join(',')

      if (countryCodes.length > 0) params['filter[country_code]'] = countryCodes.join(',')

      if (supplierIds.length > 0) params['filter[supplier_id]'] = supplierIds.join(',')

      if (stockLocationIds.length > 0) params['filter[stock_location_id]'] = stockLocationIds.join(',')

    } else {

      params['filter[type]'] = activeTab
      
      if (searchTerm) params.search = searchTerm

      if (hideInSage) params['filter[in_sage]'] = false

    }

    params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : '-date_time'
    
    params['page[number]'] = pagination.pageNumber
    params['page[size]'] = pagination.pageSize

    dispatch(startFetching())

    const response = await getGoodsInRecords({ params })

    dispatch(setData(response.data.data))
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
    }))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }
}