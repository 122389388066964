import React from 'react'
import PropTypes from 'prop-types'
import { Table as TableProvider, Typography, Tag } from 'antd'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import classNames from 'classnames'
import { getTeamUrl } from 'Traqa/Helpers'
import styles from './styles.css'

const formatDateTime = dateTime => moment(dateTime).calendar(null, {
  sameDay: '[Today], h:mm a',
  nextDay: '[Tomorrow], h:mm a',
  lastDay: '[Yesterday], h:mm a',
  nextWeek: 'D MMM YYYY [at] h:mm a',
  lastWeek: 'D MMM YYYY [at] h:mm a',
  sameElse: 'D MMM YYYY [at] h:mm a',
})

const Table = props => {

  const {
    data,
    isFetching,
  } = props

  return (

    <TableProvider
      dataSource={data}
      rowKey='id'
      size='small'
      loading={isFetching}
      onRow={record => ({
        className: classNames({
          [styles.isExpectation]: record.is_expectation,
        }),
      })}
      pagination={false}
      scroll={{ y: 240 }}
    >

      <TableProvider.Column
        key='owner_type'
        title='Type'
        width={150}
        render={(text, record) => (
          <NavLink to={getTeamUrl(`${record.owner_type_slug}/${record.owner_id}`)}>
            {record.owner_type_formatted}
          </NavLink>
        )}
      />

      <TableProvider.Column
        key='product'
        title='Product'
        width={350}
        render={(text, record) => (
          <div>
            {`${record.product.code} - ${record.product.name}`}
            <Tag color='volcano' style={{ marginLeft: 5 }}>{record.product.form}</Tag>
          </div>
        )}
      />

      <TableProvider.Column
        key='stock_location.name'
        title='Location'
        width={180}
        render={(text, record) => record.stock_location.name}
      />

      <TableProvider.Column
        key='quantity'
        title='Qty'
        width={100}
        className={styles.numeric}
        render={(text, record) => record.quantity_formatted}
      />

      <TableProvider.Column
        key='details'
        title='Details'
        width={250}
        render={(text, record) => <Typography.Text type='secondary'>{record.details}</Typography.Text>}
      />

      <TableProvider.Column
        key='batch_no'
        title='Batch No.'
        width={200}
        render={(text, record) => <Typography.Text type='secondary'>{record.batch_no}</Typography.Text>}
      />

      <TableProvider.Column
        key='transaction_at'
        title='Date / Time'
        width={250}
        render={(text, record) => formatDateTime(record.transaction_at)}
      />

    </TableProvider>

  )

}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
}

export default Table
