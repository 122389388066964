import { createAction, handleActions } from 'redux-actions'

const loadProductCategories = createAction("CORE/PRODUCT_CATEGORIES/LOAD_PRODUCT_CATEGORIES")

const defaultState = []

const reducer = handleActions(
  {
    [loadProductCategories]: (state, action) => action.payload,
  },
  defaultState
)

export { loadProductCategories, reducer }