import React from "react"
import PropTypes from "prop-types"
import { Dropdown, Menu, Icon } from "antd"
import styles from './Header.css'

const Header = (props) => {

  const {
    assembly,
    onChangeStatus,
  } = props

  const statusOptions = [
    { label: "Draft", value: "Draft", icon: "edit", color: 'grey' },
    { label: "Scheduled", value: "Expected", icon: "check-circle", color: 'orange' },
    { label: "Assembled", value: "Completed", icon: "check-circle", color: 'limegreen' },
  ]

  const currentStatus = statusOptions.filter(option => option.value === assembly.status)[0]

  const statusMenu = (

    <Menu style={{ minWidth: 120 }} onClick={({ key }) => onChangeStatus(key)}>

      {statusOptions.map(option => (

        <Menu.Item key={option.value}>
          <Icon type={option.icon} className={styles.icon} style={{ color: option.color }} />
          {option.label}
        </Menu.Item>

      ))}

    </Menu>
  )

  return (

    <div className={styles.wrapper}>

      <Dropdown
        trigger={["click"]}
        placement="bottomLeft"
        overlay={statusMenu}
      >

        <div className={styles.select} tabIndex={-1}>
          <Icon type={currentStatus.icon} className={styles.icon} style={{ color: currentStatus.color }} />
          {currentStatus.label}
          <Icon type="caret-down" className={styles.caret} />
        </div>

      </Dropdown>

    </div>
  )
}

Header.propTypes = {
  assembly: PropTypes.object.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
}

export default Header