import modalProps from '../../../modalProps'
import { createGoodsInRecordItem, updateGoodsInRecordItem } from '../../../../../Api/goodsInRecordItems'
import { handleException } from 'Traqa/Packages'

export default (values) => async () => {

  try {

    const { goodsInRecord, product, goodsInRecordItem } = modalProps()

    const data = {
      goods_in_record_id: goodsInRecord.id,
      product_id: product.id,
      quantity_per_pallet: values.quantity_per_pallet,
      pallet_quantity: values.pallet_quantity,
      quantity: values.quantity,
      blend: values.blend || null,
      recycled_perc: values.recycled_perc,
      weight_kgs: values.weight_kgs,
    }

    if (goodsInRecordItem.id) {

      await updateGoodsInRecordItem({ id: goodsInRecordItem.id, data })

    } else {

      await createGoodsInRecordItem({ data })

    }

    modalProps().onClose(true)

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
