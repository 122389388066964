import { createAction, handleActions } from 'redux-actions'

const setReading = createAction('SCREENS/READING/SET_READING')
const resetReading = createAction('SCREENS/READING/RESET_READING')

const defaultState = null

const reducer = handleActions(
  {
    [setReading]: (state, action) => action.payload,
    [resetReading]: () => null,
  },
  defaultState
)

export { setReading, resetReading, reducer }