import React from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import { Field, FormSpy } from 'react-final-form'
import { Form, Select, Input, InputNumber } from 'antd'
import { LinkButton } from 'Traqa/Packages'
import styles from './GroupItem.css'

const GroupItem = (props) => {

  const {
    product,
    stocktakeItem,
    groupIndex,
    onDeleteItem,
  } = props

  const field = `stocktake_items[${stocktakeItem.index}]`
  
  return (

    <FormSpy subscription={{ submitting: true }}>

      {({ submitting }) => (

        <div className={styles.wrapper}>

          <div style={{ width: 150 }}>

            <Field name={`${field}.position`}>
              {({ input, meta }) => (
                <Form.Item
                  label={groupIndex === 0 ? 'Position' : undefined}
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Input
                    {...input}
                    disabled={submitting}
                  />
                </Form.Item>
              )}
            </Field>

          </div>

          <div style={{ width: 8 }} />

          <div style={{ width: 100 }}>

            <Field name={`${field}.quantity`}>
              {({ input, meta }) => (
                <Form.Item
                  label={groupIndex === 0 ? 'Qty' : undefined}
                  required
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <InputNumber
                    {...input}
                    disabled={submitting}
                    min={0}
                    precision={0}
                    step={1}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              )}
            </Field>

          </div>

          <div style={{ width: 8 }} />

          <div style={{ width: 150 }}>

            <Field name={`${field}.value`}>
              {({ input, meta }) => (
                <Form.Item
                  label={groupIndex === 0 ? 'Value' : undefined}
                  required
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Input
                    {...input}
                    disabled
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              )}
            </Field>

          </div>

          <div style={{ width: 8 }} />

          {groupIndex !== 0 && (

            <div style={{ marginTop: 5 }}>

              <LinkButton
                type="danger"
                icon="delete"
                onClick={() => onDeleteItem(stocktakeItem.index)}
              />

            </div>

          )}

        </div>
    
      )}

    </FormSpy>

  )
}

GroupItem.propTypes = {
  product: PropTypes.object.isRequired,
  stocktakeItem: PropTypes.object.isRequired,
  groupIndex: PropTypes.number.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
}

export default GroupItem