import screenProps from '../screenProps'
import { updateGoodsOutRecordStatus } from '../../../Api/goodsOutRecords'
import { setGoodsOutRecord } from '../State/goodsOutRecord'
import { handleException } from 'Traqa/Packages'
import fetch from './fetch'
//import fetchActivity from '../../../Modules/Activity/Services/fetch'

export default (status) => async (dispatch, getState) => {

  try {

    dispatch(setGoodsOutRecord({
      ...getState().screens.GoodsOutRecord.goodsOutRecord,
      status
    }))

    const { id } = screenProps().match.params

    const data = { status }

    await updateGoodsOutRecordStatus({ id, data })

    await dispatch(fetch())

    //dispatch(fetchActivity())

  } catch (error) {

    handleException(error)

  }

}
