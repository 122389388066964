import React from "react"
import PropTypes from "prop-types"
import { Button } from 'antd'
import Screen from '../../Components/Screen'
import Table from "./Components/Table"

import CreateAssembly from '../../Modals/CreateAssembly'

export default class AssembliesScreen extends React.PureComponent {

  static propTypes = {
    createAssemblyModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onCloseCreateAssemblyModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  renderHeaderRight = () => ([

    <Button key="NEW" type="primary" onClick={this.props.onClickNew}>
      New Assembly
    </Button>
    
  ])

  render () {

    const {
      createAssemblyModal,
      onCloseCreateAssemblyModal,
    } = this.props

    return (

      <Screen
        title="Assemblies"
        headerRight={this.renderHeaderRight()}
      >

        <Table />

        {createAssemblyModal.show && (
          <CreateAssembly
            onClose={onCloseCreateAssemblyModal}
          />
        )}

      </Screen>

    )
  }
}
