import React from 'react'
import PropTypes from 'prop-types'
import { Table as AntTable, Icon, Popover, Tag } from 'antd'
import { NavLink } from 'react-router-dom'
import { find, last, orderBy } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import { getTeamUrl } from 'Traqa/Helpers'

const Table = (props) => {

  const {
    data,
    isFetching,
    onChange,
  } = props
  
  return (

    <AntTable
      dataSource={data}
      loading={isFetching}
      rowKey="id"
      size="small"
      pagination={false}
      onChange={onChange}
      style={{ margin: '-1px' }}
    >

      <AntTable.Column
        key='code'
        title="Code"
        sorter
        render={(text, record) => (
          <NavLink key={record.id} to={getTeamUrl(`products/${record.id}`)}>
            {record.code}
          </NavLink>
        )}
      />

      <AntTable.Column
        key='name'
        title="Name"
        sorter
        render={(text, record) => record.name}
      />

      <AntTable.Column
        key='form'
        title="Form"
        sorter
        render={(text, record) => <Tag color='volcano' style={{ marginLeft: 5 }}>{record.form}</Tag>}
      />

      <AntTable.Column
        key='product_categories'
        title="Categories"
        render={(text, record) => record.product_categories.map(productCategory => productCategory.name).join(', ')}
      />

      <AntTable.Column
        key='product_value'
        title="Value"
        render={(text, record) => {
          const today = moment().format('YYYY-MM-DD')
          const productValues = orderBy(record.product_values, 'effective_date', 'desc')
          let productValue = find(productValues, row => row.effective_date <= today)
          if (!productValue) {
            productValue = last(productValues)
          }
          return (
            <Popover
              title='Values'
              content={(
                <div style={{ maxHeight: 300, overflow: 'scroll' }}>
                  {productValues.map(row => (
                    <div key={row.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div>{`${moment(row.effective_date).format('DD/MM/YYYY')}`}</div>
                      <div style={{ width: 15, textAlign: 'center' }}> - </div>
                      <div>{`£${numeral(row.value).format('0,0.00[000]')}`}</div>
                    </div>
                  ))}
                </div>
              )}
            >
              {`£${numeral(productValue.value).format('0,0.00[000]')} as of ${moment(productValue.effective_date).format('DD/MM/YYYY')}`}
            </Popover>
          )
        }}
      />

      <AntTable.Column
        key='archived_at'
        width={150}
        render={(text, record) => record.archived_at ? (
          <div style={{ color: '#999' }}>
            <Icon type="eye-invisible" />
            {' Archived'}
          </div>
        ) : null}
      />

    </AntTable>

  )
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Table