import screenProps from '../screenProps'
import { updateSalesOrderStatus } from '../../../Api/salesOrders'
import { setSalesOrder } from '../State/salesOrder'
import fetch from './fetch'
//import fetchActivity from '../../../Modules/Activity/Services/fetch'
import { handleException } from 'Traqa/Packages'

export default (status) => async (dispatch, getState) => {

  try {

    dispatch(setSalesOrder({
      ...getState().screens.SalesOrder.salesOrder,
      status
    }))

    const { id } = screenProps().match.params

    const data = { status }

    await updateSalesOrderStatus({ id, data })

    await dispatch(fetch())

    //dispatch(fetchActivity())

  } catch (error) {

    handleException(error)

  }

}
