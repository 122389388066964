import { message } from 'antd'
import { deleteProductValue } from '../../../Api/productValues'
import { handleException } from 'Traqa/Packages'
import modalProps from '../modalProps'

export default () => async () => {

  try {

    const { id } = modalProps().productValue

    await deleteProductValue({ id })

    modalProps().onClose(true)

    message.success(`Deleted Product Value`)

  } catch (error) {

    handleException(error)

  }
  
}
