import chance from 'chance'
import formService from '../formService'

export default (index) => () => {

  const item = {
    guid: chance().guid(),
  }

  if (index === undefined) {
    formService().mutators.push('sales_order_items', item)
  } else {
    formService().mutators.insert('sales_order_items', index+1, item)
  }

}
