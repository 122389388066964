import screenProps from '../../../screenProps'
import { getConsignmentPickingStats } from '../../../../../Api/consignments'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    if (id === 'new') return

    dispatch(startFetching())

    const response = await getConsignmentPickingStats({ id })

    dispatch(setData(response.data.data))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }

}
