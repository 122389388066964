import { getTeamUrl } from 'Traqa/Helpers'

export default () => async (dispatch, getState) => {

  const { monthYear, searchTerm, filters, sorting } = getState().screens.Consignments.Table

  const params = {
    search: searchTerm,
  }

  if (monthYear) {
    params['filter[month_year]'] = monthYear.format('YYYY-MM')
  }

  Object.keys(filters).forEach(filter => {
    params[`filter[${filter}]`] = filters[filter].join(',')
  })

  params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : '-date'

  let path = `consignments/export?`

  Object.keys(params).forEach(key => {
    path = `${path}&${key}=${params[key]}`
  })

  window.location = getTeamUrl(path, 'exports')

}
