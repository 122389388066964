import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Menu, Icon, Button } from 'antd'

const MoreMenu = (props) => {

  const {
    salesOrder,
    onCreateGoodsOut,
    onExport,
    onArchive,
    onUnarchive,
    onDelete,
    onDuplicate,
  } = props

  const menu = salesOrder && (
    
    <Menu style={{ minWidth: 180 }}>

      <Menu.Item onClick={onCreateGoodsOut}>
        <Icon type='form' />
        Create Goods Out
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item onClick={onExport}>
        <Icon type='cloud-download' />
        Export CSV
      </Menu.Item>

      <Menu.Divider />

      {!salesOrder.archived_at && (
        <Menu.Item onClick={onArchive}>
          <Icon type="eye-invisible" />
          Archive
        </Menu.Item>
      )}

      {!!salesOrder.archived_at && (
        <Menu.Item onClick={onUnarchive}>
          <Icon type="eye" />
          Unarchive
        </Menu.Item>
      )}

      <Menu.Item onClick={onDelete}>
        <Icon type='delete' />
        Delete
      </Menu.Item>

      <Menu.Item onClick={onDuplicate}>
        <Icon type="copy" />
        Duplicate
      </Menu.Item>

    </Menu>

  )

  return (

    <Dropdown
      trigger={['click']}
      placement='bottomRight'
      overlay={menu}
    >
      <Button icon='ellipsis' shape='circle' />
    </Dropdown>

  )

}

MoreMenu.propTypes = {
  salesOrder: PropTypes.object,
  onCreateGoodsOut: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
  onUnarchive: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
}

MoreMenu.defaultProps = {
  salesOrder: null,
}

export default MoreMenu
