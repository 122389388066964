export default (values) => () => {

  const errors = {}

  if (!values.origin_id) errors.origin_id = true

  if (!values.destination_id) errors.destination_id = true

  if (values.origin_id === values.destination_id) {
    errors.origin_id = true
    errors.destination_id = true
  }

  if (!values.dispatched_date) errors.dispatched_date = true
  if (!values.dispatched_time) errors.dispatched_time = true

  if (!values.completed_date) errors.completed_date = true
  if (!values.completed_time) errors.completed_time = true

  if (!values.user_id) errors.user_id = true

  if (Array.isArray(values.transfer_items)) {

    errors.transfer_items = values.transfer_items.map(item => {

      const itemErrors = {}

      if (!item.product_id) itemErrors.product_id = true
      
      if (!item.quantity) itemErrors.quantity = true

      return itemErrors
      
    })
  }

  return errors

}