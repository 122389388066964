import { getTeamUrl } from 'Traqa/Helpers'

export default () => async (dispatch, getState) => {

  const { advanced, activeTab, searchTerm, sorting, hideInSage } = getState().screens.Transfers.Table

  let path = `transfers/export?`

  if (advanced.show) {

    const {
      keywords,
      status,
      dispatchedBetween,
      completedBetween,
      originIds,
      destinationIds,
    } = advanced.values

    if (keywords) {
      path = `${path}&search=${keywords}`
    }

    if (status) {
      path = `${path}&filter[status]=${status}`
    }

    if (dispatchedBetween.length > 0) {
      path = `${path}&filter[dispatched_at_between]=${dispatchedBetween.map(moment => moment.format('YYYY-MM-DD HH:mm:ss')).join(',')}`
    }

    if (completedBetween.length > 0) {
      path = `${path}&filter[completed_at_between]=${completedBetween.map(moment => moment.format('YYYY-MM-DD HH:mm:ss')).join(',')}`
    }

    if (originIds.length > 0) {
      path = `${path}&filter[origin_id]=${originIds.join(',')}`
    }

    if (destinationIds.length > 0) {
      path = `${path}&filter[destination_id]=${destinationIds.join(',')}`
    }

  } else {

    if (activeTab !== 'All') {
      path = `${path}&filter[status]=${activeTab}`
    }

    if (searchTerm) {
      path = `${path}&search=${searchTerm}`
    }

    if (hideInSage) {
      path = `${path}&filter[in_sage]=false`
    }

  }

  path = `${path}&sort=${sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : '-completed_at'}`

  window.location = getTeamUrl(path, 'exports')

}
