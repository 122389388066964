import modalProps from '../../../modalProps'
import { createSalesOrderGoodsOutRecord } from '../../../../../Api/salesOrders'
import { handleException } from 'Traqa/Packages'

export default (values) => async () => {

  try {

    const { salesOrderId, salesOrderItemIds } = modalProps()

    const data = {
      stock_location_id: values.stock_location_id,
      date_time: `${values.date.format('YYYY-MM-DD')} ${values.time.format('HH:mm')}:00`,
      user_id: values.user_id,
      status: values.status,
      sales_order_item_ids: salesOrderItemIds,
    }

    const response = await createSalesOrderGoodsOutRecord({ id: salesOrderId, data })

    modalProps().onClose(response.data.data)

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
