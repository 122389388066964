import { connect } from 'react-redux'
import { get } from 'lodash'
import User from './User'

import selectActiveTab from './Selectors/selectActiveTab'

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onCloseUserModal from './Handlers/onCloseUserModal'
import onClosePasswordResetModal from './Handlers/onClosePasswordResetModal'

const mapStateToProps = (state) => ({
  id: get(state.screens.User.user, 'id'),
  initialized: state.screens.User.initialized,
  activeTab: selectActiveTab(state),
  userModal: state.screens.User.userModal,
  passwordResetModal: state.screens.User.passwordResetModal,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onCloseUserModal,
  onClosePasswordResetModal,
}

export default connect(mapStateToProps, actionCreators)(User)
