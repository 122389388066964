import chance from 'chance'
import formService from '../formService'

export default (index) => () => {

  const item = {
    guid: chance().guid(),
    expiry_date: null,
  }

  if (index === undefined) {
    formService().mutators.push('goods_out_record_items', item)
  } else {
    formService().mutators.insert('goods_out_record_items', index+1, item)
  }

}
