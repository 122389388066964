import { createAction, handleActions } from 'redux-actions'

const loadStockLocations = createAction("CORE/STOCK_LOCATIONS/LOAD_STOCK_LOCATIONS")

const defaultState = []

const reducer = handleActions(
  {
    [loadStockLocations]: (state, action) => action.payload,
  },
  defaultState
)

export { loadStockLocations, reducer }