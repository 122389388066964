import React from 'react'
import PropTypes from 'prop-types'
import { List, Icon } from 'antd'
import { Module } from 'Traqa/Packages'

import Activity from './Components/Activity'
import Log from './Components/Log'
import Properties from './Components/Properties'

export default class extends React.PureComponent {

  static propTypes = {
    listRows: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    initialized: PropTypes.bool.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onLoadActivity: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render() {

    const {
      listRows,
      data,
      isFetching,
      initialized,
      isExpanded,
      onLoadActivity,
      onToggle,
    } = this.props

    const renderRight = () => (
      <React.Fragment>
        {initialized && (
          <a onClick={onToggle}>
            <Icon
              type={isExpanded ? 'down' : 'right'}
              style={{ marginRight: 5 }}
            />
            {`${data.length} ${data.length === 1 ? 'Activity' : 'Activities'}`}
          </a>
        )}
        {!initialized && (
          <a onClick={onLoadActivity}>
            <Icon
              type={isFetching ? 'loading' : 'reload'}
              style={{ marginRight: 5 }}
            />
            Load Activity
          </a>
        )}
      </React.Fragment>
    )

    return (

      <Module>
        
        <Module.Header title='Activity' renderRight={renderRight} />

        {isExpanded && (

          <Module.Section
            contentStyle={{ padding: 0 }}
          >

            <div id='scroll' style={{ maxHeight: '500px', padding: '15px 30px 15px 30px', overflow: 'scroll' }}>

              <List
                dataSource={listRows}
                renderItem={listRow => {
                  if (listRow.type === 'ACTIVITY') return <Activity {...listRow} />
                  if (listRow.type === 'LOG') return <Log {...listRow} />
                  if (listRow.type === 'PROPERTIES') return <Properties {...listRow} />
                  return false
                }}
              />

            </div>

          </Module.Section>

        )}

      </Module>

    )

  }

}
