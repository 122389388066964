import { push } from 'connected-react-router'
import modalProps from '../../../modalProps'
import { createAssembly } from '../../../../../Api/assemblies'
import { handleException } from 'Traqa/Packages'
import { getTeamUrl } from 'Traqa/Helpers'

export default (values) => async (dispatch) => {

  try {

    const data = {
      product_id: values.product_id,
      quantity: values.quantity,
      stock_location_id: values.stock_location_id,
      date_time: `${values.date.format('YYYY-MM-DD')} ${values.time.format('HH:mm')}:00`,
      user_id: values.user_id,
    }

    const response = await createAssembly({ data })

    modalProps().onClose(true)

    dispatch(push(getTeamUrl(`assemblies/${response.data.data.id}`)))

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}