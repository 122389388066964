import { combineReducers } from "redux"

import { reducer as customer } from './State/customer'
import { reducer as customerSiteModal } from './State/customerSiteModal'

import Form from './Components/Form/reducer'

export default combineReducers({
  customer,
  customerSiteModal,

  Form,
})
