import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { Module } from 'Traqa/Packages'

const Footer = (props) => {

  const {
    onClickExport,
  } = props

  const renderLeft = () => (

    <Button
      type='primary'
      icon='download'
      onClick={onClickExport}
    >
      Export
    </Button>

  )

  return (

    <Module.Footer
      renderLeft={renderLeft}
      border
      style={{ marginTop: '-1px' }}
    />

  )
  
}

Footer.propTypes = {
  onClickExport: PropTypes.func.isRequired,
}

export default Footer
