import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Drawer, Tag } from 'antd'

import Table from './Components/Table'

const TransactionsDrawer = props => {

  const {
    componentDidMount,
    componentWillUnmount,
    onClose,
  } = props

  useEffect(() => {
    componentDidMount(props)
    return () => componentWillUnmount()
  }, [])

  return (

    <Drawer
      key='transactionsDrawer'
      title={(
        <div style={{ display: 'flex' }}>
          <div>Transactions</div>
          <Tag style={{ marginLeft: 8, backgroundColor: 'white' }}>Adjustment</Tag>
          <Tag color='#f7bc4d'>Scheduled</Tag>
        </div>
      )}
      placement='bottom'
      visible
      height={350}
      closable={false}
      onClose={onClose}
      bodyStyle={{ paddingTop: 0 }}
    >

      <Table />

    </Drawer>

  )

}

TransactionsDrawer.propTypes = {
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default TransactionsDrawer
