import fetchStockLocations from '../../../Core/Services/fetchStockLocations'
import fetchSuppliers from '../../../Core/Services/fetchSuppliers'
import fetchCustomers from '../../../Core/Services/fetchCustomers'
import fetchCustomerSites from '../../../Core/Services/fetchCustomerSites'
import fetchProductCategories from '../../../Core/Services/fetchProductCategories'
import fetchProducts from '../../../Core/Services/fetchProducts'

export default () => (dispatch) => {

  dispatch(fetchStockLocations())
  dispatch(fetchSuppliers())
  dispatch(fetchCustomers())
  dispatch(fetchCustomerSites())
  dispatch(fetchProductCategories())
  dispatch(fetchProducts())
  
}
