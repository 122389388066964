import { createAction, handleActions } from 'redux-actions'

const setPreviousStocktakes = createAction('SCREENS/RECONCILIATION/REPORT/SET_PREVIOUS_STOCKTAKES')
const resetPreviousStocktakes = createAction('SCREENS/RECONCILIATION/REPORT/RESET_PREVIOUS_STOCKTAKES')

const defaultState = []

const reducer = handleActions(
  {
    [setPreviousStocktakes]: (state, action) => action.payload,
    [resetPreviousStocktakes]: () => defaultState,
  },
  defaultState
)

export { setPreviousStocktakes, resetPreviousStocktakes, reducer }