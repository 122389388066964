import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, FormSpy, Field } from 'react-final-form'
import { Form, Input, Select, Row, Col, Button, Tag } from 'antd'
import { Module, LinkButton, ExposeFormToHelper } from 'Traqa/Packages'
import formHelper from './formHelper'
import styles from './Advanced.css'

const Advanced = (props) => {

  const {
    advanced,
    products,
    stockLocations,
    users,
    onClickClose,
    onFormChange,
    onSubmit,
  } = props

  const truncate = (input) => input.length > 30 ? `${input.substring(0, 30)}...` : input

  const renderHeaderRight = () => (

    <LinkButton
      type="default"
      icon="close"
      onClick={onClickClose}
      style={{ fontSize: 18 }}
    />

  )

  return (

    <Module.Section
      title="Advanced Search"
      renderHeaderRight={renderHeaderRight}
      borderBottom
    >

      <FormProvider
        initialValues={advanced.values}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, submitting }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            autoComplete="off"
          >

            <ExposeFormToHelper helper={formHelper} />

            <FormSpy subscription={{ values: true }} onChange={onFormChange} />

            <Field name="stockLocationIds">
              {({ input }) => (
                <Form.Item label="Locations">
                  <Select
                    {...input}
                    disabled={submitting}
                    mode="multiple"
                    optionFilterProp="children"
                  >
                    <Select.OptGroup label='Active'>
                      {stockLocations.filter(stockLocation => !stockLocation.archived_at).map(stockLocation => (
                        <Select.Option key={stockLocation.id} value={stockLocation.id}>
                          {stockLocation.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                    <Select.OptGroup label='Archived'>
                      {stockLocations.filter(stockLocation => !!stockLocation.archived_at).map(stockLocation => (
                        <Select.Option key={stockLocation.id} value={stockLocation.id} style={{ color: '#999' }}>
                          {stockLocation.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name="productId">
              {({ input }) => (
                <Form.Item label="Product">
                  <Select
                    {...input}
                    disabled={submitting}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                  >
                    
                    <Select.OptGroup label="Products">
                      {products.filter(product => !product.archived_at).map(product => (
                        <Select.Option key={product.id} value={product.id}>
                          {truncate(`${product.code} - ${product.name}`)}
                          <Tag color='volcano' style={{ marginLeft: 5 }}>{product.form}</Tag>
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                    
                    <Select.OptGroup label="Archived">
                      {products.filter(product => product.archived_at).map(product => (
                        <Select.Option key={product.id} value={product.id} style={{ color: '#999' }}>
                          {truncate(`${product.code} - ${product.name}`)}
                          <Tag color='volcano' style={{ marginLeft: 5 }}>{product.form}</Tag>
                        </Select.Option>
                      ))}
                    </Select.OptGroup>

                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name="userIds">
              {({ input }) => (
                <Form.Item label="Owners">
                  <Select
                    {...input}
                    disabled={submitting}
                    mode="multiple"
                    optionFilterProp="children"
                  >
                    {users.map(user => (
                      <Select.Option
                        key={user.id}
                        value={user.id}
                      >
                        {user.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name="batchNo">
              {({ input }) => (
                <Form.Item label="Batch No.">
                  <Input
                    {...input}
                    disabled={submitting}
                  />
                </Form.Item>
              )}
            </Field>

            <Row>
              <Col offset={7}>

                <Button
                  type="primary"
                  onClick={handleSubmit}
                >
                  Search
                </Button>

              </Col>
            </Row>

          </Form>
        
        )}
      
      </FormProvider>

    </Module.Section>

  )
}

Advanced.propTypes = {
  advanced: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  stockLocations: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  onClickClose: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default Advanced