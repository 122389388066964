import fetchStockLocations from "../../../Core/Services/fetchStockLocations"
import fetchSuppliers from '../../../Core/Services/fetchSuppliers'
import fetchUsers from '../../../Core/Services/fetchUsers'
import fetchTableData from '../Components/Table/Services/fetch'

export default () => (dispatch) => {
  
  dispatch(fetchSuppliers())

  dispatch(fetchStockLocations())

  dispatch(fetchUsers())

  dispatch(fetchTableData())
  
}
