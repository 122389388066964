import React from 'react'
import PropTypes from 'prop-types'
import { Popover } from 'antd'
import styles from './styles.css'

import Header from './Components/Header'
import Content from './Components/Content'

export default class StockshotsPopover extends React.PureComponent {

  static propTypes = {
    children: PropTypes.node.isRequired,
    onVisibleChange: PropTypes.func.isRequired,
  }
  
  render () {
    
    const {
      children,
      onVisibleChange,
    } = this.props
    
    return (
      
      <Popover
        content={(
          <React.Fragment>
            <Header />
            <Content />
          </React.Fragment>
        )}
        placement='bottomRight'
        trigger={['click']}
        overlayClassName={styles.popover}
        align={{ offset: [-5, -18] }}
        onVisibleChange={onVisibleChange}
      >
        {children}
      </Popover>

    )

  }

}
