import modalProps from '../../../modalProps'
import { createProductValue, updateProductValue } from '../../../../../Api/productValues'
import { handleException } from 'Traqa/Packages'

export default (values) => async () => {

  try {

    const { productId, productValue } = modalProps()

    const data = {
      effective_date: values.effective_date.format('YYYY-MM-DD'),
      value: values.value,
    }

    if (productValue) {

      await updateProductValue({ id: productValue.id, data })

    } else {

      data.product_id = productId

      await createProductValue({ data })

    }

    modalProps().onClose(true)

    return true

  } catch (error) {

    handleException(error)

    throw error

  }

}
