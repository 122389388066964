import { message } from 'antd'
import modalProps from '../../../modalProps'
import { updateUser } from '../../../../../../../Api/users'
import { handleException } from 'Traqa/Packages'

export default (values) => async () => {

  const { id } = modalProps()

  const data = {
    password: values.password,
  }

  try {

    await updateUser({ id, data })
    
    modalProps().onClose()

    message.success(`Updated Password`)

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
