import { createSelector } from 'reselect'

const selectCustomers = state => state.screens.Consignments.Table.filterOptions.customers
const selectCouriers = state => state.core.couriers
const selectCourierServices = state => state.core.courierServices
const selectCourierZones = state => state.screens.Consignments.Table.filterOptions.courierZones
const selectStockLocations = state => state.core.stockLocations

export default createSelector(
  selectCustomers,
  selectCouriers,
  selectCourierServices,
  selectCourierZones,
  selectStockLocations,
  (customers, couriers, courierServices, courierZones, stockLocations) => ({
    customers: customers.map(customer => ({
      text: customer.name,
      value: customer.id,
    })),
    couriers: [
      ...couriers.map(courier => ({
        text: courier.name,
        value: courier.id,
      })),
      ...[{
        text: 'Collection',
        value: 'null',
      }],
    ],
    courierServices: courierServices.map(courierService => ({
      text: courierService.name,
      value: courierService.id,
    })),
    courierZones: courierZones.map(courierZone => ({
      text: courierZone.code,
      value: courierZone.id,
    })),
    stockLocations: stockLocations.map(stockLocation => ({
      text: stockLocation.name,
      value: stockLocation.id,
    })),
  })
)
