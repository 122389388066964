import screenProps from '../screenProps'
import { updateAssemblyStatus } from '../../../Api/assemblies'
import { setAssembly } from '../State/assembly'
import fetch from './fetch'
//import fetchActivity from '../../../Modules/Activity/Services/fetch'
import { handleException } from 'Traqa/Packages'

export default (status) => async (dispatch, getState) => {

  try {

    dispatch(setAssembly({
      ...getState().screens.Assembly.assembly,
      status
    }))

    const { id } = screenProps().match.params

    const data = { status }

    await updateAssemblyStatus({ id, data })

    await dispatch(fetch())

    //dispatch(fetchActivity())

  } catch (error) {

    handleException(error)

  }
}