import React from 'react'
import PropTypes from 'prop-types'
import { groupBy, keys } from 'lodash'
import moment from 'moment'
import { Form as FormProvider, FormSpy, Field } from 'react-final-form'
import { Form, Select, Checkbox } from 'antd'
import { Module, ExposeFormToHelper } from 'Traqa/Packages'
import formService from './formService'
import styles from './Controls.css'

const Controls = (props) => {

  const {
    stocktake,
    formState,
    previousStocktakes,
    onChangeForm,
    onSubmit,
    validate,
  } = props

  const stocktakesByYear = groupBy(previousStocktakes, item => moment(item.date_time).format('YYYY'))

  return (

    <Module.Section
      title={`${stocktake.stock_location.name}: ${stocktake.date_time_formatted}`}
      borderBottom
    >

      <FormProvider
        initialValues={formState.values}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ handleSubmit, submitting }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            autoComplete="off"
          >

            <ExposeFormToHelper helper={formService} />

            <FormSpy subscription={{ values: true, submitting: true }} onChange={onChangeForm} />

            <Field name="openingStocktakeId">
              {({ input, meta }) => (
                <Form.Item label="Opening" validateStatus={meta.touched && meta.error ? 'error' : undefined}>
                  <Select
                    {...input}
                    onChange={value => {
                      input.onChange(value === undefined ? null : value)
                      handleSubmit()
                    }}
                    disabled={submitting}
                  >
                    {keys(stocktakesByYear).reverse().map(year => (
                      <Select.OptGroup key={year} label={year}>
                        {stocktakesByYear[year].map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.date_time_formatted}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name="options">
              {({ input }) => (
                <Form.Item label=" " colon={false}>
                  <Checkbox.Group
                    {...input}
                    onChange={value => {
                      const reload = value.includes('sort_by_variance') === formState.values.options.includes('sort_by_variance')
                      input.onChange(value)
                      if (reload) handleSubmit()
                    }}
                    disabled={submitting}
                    options={[
                      {
                        value: 'sort_by_variance',
                        label: 'Sort by variance',
                      },
                      {
                        value: 'outers_only',
                        label: 'Show outer products only',
                      },
                      {
                        value: 'aggregate',
                        label: 'Combine inner & outer stock data',
                      },
                    ]}
                    className={styles.options}
                  />
                </Form.Item>
              )}
            </Field>

          </Form>
        
        )}
      
      </FormProvider>

    </Module.Section>

  )
}

Controls.propTypes = {
  stocktake: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  previousStocktakes: PropTypes.array.isRequired,
  onChangeForm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
}

export default Controls