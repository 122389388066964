import React from 'react'
import { createSelector } from 'reselect'
import { NavLink } from 'react-router-dom'
import { Tag, Icon } from 'antd'
import { orderBy } from 'lodash'
import numeral from 'numeral'
import { getTeamUrl } from 'Traqa/Helpers'

const selectIncludeExpected = state => state.screens.StockAdjustments.Report.formState.values.includeExpected
const selectHideZeros = state => state.screens.StockAdjustments.Report.formState.values.hideZeros
const selectSortBy = state => state.screens.StockAdjustments.Report.formState.values.sortBy
const selectSortDirection = state => state.screens.StockAdjustments.Report.formState.values.sortDirection
const selectData = state => state.screens.StockAdjustments.Report.data

const selectTableRows = createSelector(
  selectIncludeExpected,
  selectHideZeros,
  selectSortBy,
  selectSortDirection,
  selectData,
  (includeExpected, hideZeros, sortBy, sortDirection, data) => {

    const key = includeExpected ? 'expected' : 'actual'

    const products = []

    data.products.forEach(product => {

      if (hideZeros && !product[key].in && !product[key].out) return

      products.push({
        ...product,
        key: product.id,
        type: 'product',
        title: (
          <div style={{ display: 'inline-block' }}>
            <NavLink to={getTeamUrl(`products/${product.id}`)}>
              {`${product.code} - ${product.name}`}
            </NavLink>
            <Tag color='volcano' style={{ marginLeft: 5 }}>{product.form}</Tag>
            {!!product.archived_at && <Icon type='eye-invisible' style={{ color: '#999' }} />}
          </div>
        ),
        in: product[key].in,
        in_value: product[key].in_value,
        out: product[key].out,
        out_value: product[key].out_value,
        children: product.stock_locations.map(stockLocation => ({
          ...stockLocation,
          key: `${stockLocation.id}|${product.id}`,
          type: 'stock-location',
          title: stockLocation.name,
          in: stockLocation[key].in,
          in_value: stockLocation[key].in_value,
          out: stockLocation[key].out,
          out_value: stockLocation[key].out_value,
          product,
        })),
      })

    })

    return orderBy(products, sortBy, sortDirection)

  },
)

export default selectTableRows
