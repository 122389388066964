export default (values) => () => {

  const errors = {}

  if (!values.stock_location_id) errors.stock_location_id = true

  if (!values.customer_id) errors.customer_id = true

  if (!values.customer_site_id) errors.customer_site_id = true

  if (!values.date) errors.date = true

  if (!values.time) errors.time = true

  if (!values.user_id) errors.user_id = true

  if (Array.isArray(values.goods_out_record_items)) {

    errors.goods_out_record_items = values.goods_out_record_items.map(item => {

      const itemErrors = {}

      if (!item.product_id) itemErrors.product_id = true
      
      if (!item.quantity) itemErrors.quantity = true

      return itemErrors
      
    })

  }

  return errors

}
