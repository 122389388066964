import { push } from 'connected-react-router'
import propsHelper from '../../../propsHelper'
import { createProduct, updateProduct } from '../../../../../Api/products'
import { getTeamUrl } from 'Traqa/Helpers'
import { handleException } from 'Traqa/Packages'

export default (values) => async (dispatch) => {

  try {

    const { id } = propsHelper().match.params

    const data = {
      code: values.code,
      name: values.name,
      form: values.form,
      hs_code: values.hs_code || null,
      valid_from: values.valid_from ? values.valid_from.format('YYYY-MM-DD') : null,
      valid_to: values.valid_to ? values.valid_to.format('YYYY-MM-DD') : null,
      product_categories: values.product_categories,
      value: values.value,
      bill_of_materials: values.bill_of_materials,
    }

    if (!id) {

      const { data: { data: { id: newId } } } = await createProduct({ data })

      dispatch(push(getTeamUrl(`products/${newId}`)))

    } else {

      await updateProduct({ id, data })

      dispatch(push(getTeamUrl(`products/${id}`)))

    }

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}