import moment from 'moment'
import { getPlasticTaxRates } from '../../Api/plasticTaxRates'
import { loadPlasticTaxRates } from '../State/plasticTaxRates'

let lastGetTimestamp = null

const cacheTTL = 60000

export default ({ force } = {}) => async (dispatch) => {

  try {

    if (!force && lastGetTimestamp) {

      const now = Number(moment().format('x'))

      if (now < (lastGetTimestamp + cacheTTL)) return

    }
      
    lastGetTimestamp = Number(moment().format('x'))

    const params = {
      sort: 'effective_from',
    }

    const response = await getPlasticTaxRates({ params })

    dispatch(loadPlasticTaxRates(response.data.data))

  } catch (error) {
    
    throw error

  }

}
