import { combineReducers } from "redux"

import { reducer as initialValues } from "./State/initialValues"
import { reducer as formState } from "./State/formState"
import { reducer as selectedItems } from "./State/selectedItems"

export default combineReducers({
  initialValues,
  formState,
  selectedItems,
})
