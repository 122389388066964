import { combineReducers } from 'redux'

import { reducer as controlsModal } from './State/controlsModal'
import { reducer as manualRunRateModal } from './State/manualRunRateModal'
import { reducer as transactionsDrawer } from './State/transactionsDrawer'

import Report from './Components/Report/reducer'

export default combineReducers({
  controlsModal,
  manualRunRateModal,
  transactionsDrawer,

  Report,
})
