import React from 'react'
import PropTypes from 'prop-types'
import { Table as TableProvider, Icon } from 'antd'
import numeral from 'numeral'
import classNames from 'classnames'
import styles from './styles.css'

const Table = props => {

  const {
    products,
    isFetching,
    onClickRow,
  } = props

  return (
    
    <TableProvider
      dataSource={products}
      rowKey='key'
      size='small'
      bordered
      loading={isFetching}
      className={styles.table}
      onRow={record => ({
        onClick: () => onClickRow(record),
      })}
      pagination={{
        defaultPageSize: 20,
        style: {
          paddingRight: 15,
          marginBottom: -65,
        },
      }}
    >

      <TableProvider.Column
        key='title'
        title='Product'
        onCell={record => ({
          className: classNames({
            [styles.stockLocation]: record.type === 'stock-location',
          }),
        })}
        render={(text, record) => record.title}
      />

      <TableProvider.ColumnGroup
        title={(
          <div>
            <Icon
              type='arrow-up'
              style={{ color: '#32a852' }}
            />
            {' In'}
          </div>
        )}
      >

        <TableProvider.Column
          key='in'
          title='Qty'
          onHeaderCell={() => ({
            className: styles.numeric,
          })}
          onCell={record => ({
            className: classNames({
              [styles.stockLocation]: record.type === 'stock-location',
              [styles.numeric]: true,
            }),
          })}
          render={(text, record) => numeral(Math.floor(record.in)).format('0,0')}
        />

        <TableProvider.Column
          key='in_value'
          title='Value'
          onHeaderCell={() => ({
            className: styles.numeric,
          })}
          onCell={record => ({
            className: classNames({
              [styles.stockLocation]: record.type === 'stock-location',
              [styles.bold]: record.type === 'product',
              [styles.numeric]: true,
            }),
          })}
          render={(text, record) => `£${numeral(record.in_value).format('0,0')}`}
        />

      </TableProvider.ColumnGroup>

      <TableProvider.ColumnGroup
        title={(
          <div>
            <Icon
              type='arrow-down'
              style={{ color: '#d62424' }}
            />
            {' Out'}
          </div>
        )}
      >

        <TableProvider.Column
          key='out'
          title='Qty'
          onHeaderCell={() => ({
            className: styles.numeric,
          })}
          onCell={record => ({
            className: classNames({
              [styles.stockLocation]: record.type === 'stock-location',
              [styles.numeric]: true,
            }),
          })}
          render={(text, record) => numeral(Math.floor(record.out)).format('0,0')}
        />

        <TableProvider.Column
          key='out_value'
          title='Value'
          onHeaderCell={() => ({
            className: styles.numeric,
          })}
          onCell={record => ({
            className: classNames({
              [styles.stockLocation]: record.type === 'stock-location',
              [styles.bold]: record.type === 'product',
              [styles.numeric]: true,
            }),
          })}
          render={(text, record) => `£${numeral(record.out_value).format('0,0')}`}
        />

      </TableProvider.ColumnGroup>

    </TableProvider>

  )
  
}

Table.propTypes = {
  products: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onClickRow: PropTypes.func.isRequired,
}

export default Table
