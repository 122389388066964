import { get } from 'lodash'
import screenProps from '../../../screenProps'
import { getStocktakeReconciliation } from '../../../../../Api/stocktakes'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { id } = screenProps().match.params

    const { values } = getState().screens.Reconciliation.Report.formState

    const options = get(values, 'options', [])

    const outersOnly = options.includes('outers_only')
    const aggregate = options.includes('aggregate')

    const params = {
      opening_stocktake_id: values.openingStocktakeId,
      outers_only: outersOnly ? 1 : 0,
      aggregate: aggregate ? 1 : 0,
    }

    dispatch(startFetching())

    const response = await getStocktakeReconciliation({ id, params })

    dispatch(setData(response.data))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }
}