import { combineReducers } from "redux"

import { reducer as initialized } from "./State/initialized"
import { reducer as goodsInRecord } from "./State/goodsInRecord"

import Editor from "./Components/Editor/reducer"

export default combineReducers({
  initialized,
  goodsInRecord,
  Editor,
})
