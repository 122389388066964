import { connect } from "react-redux"
import Component from "./Component"

import componentDidMount from "./Handlers/componentDidMount"
import componentDidUpdate from "./Handlers/componentDidUpdate"
import componentWillUnmount from "./Handlers/componentWillUnmount"
import onBack from "./Handlers/onBack"
import onClickEdit from "./Handlers/onClickEdit"
import onChangeActiveTab from "./Handlers/onChangeActiveTab"
import onCloseProductValueModal from "./Handlers/onCloseProductValueModal"

const mapStateToProps = (state, props) => ({
  id: Number(props.match.params.id),
  isFetching: state.screens.Product.isFetching,
  product: state.screens.Product.product,
  activeTab: state.screens.Product.activeTab,
  productValueModal: state.screens.Product.productValueModal,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onBack,
  onClickEdit,
  onChangeActiveTab,
  onCloseProductValueModal,
}

export default connect(mapStateToProps, actionCreators)(Component)
