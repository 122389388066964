import { resetInitialized } from '../State/initialized'
import { resetAdjustment } from '../State/adjustment'
import resetEditor from '../Components/Editor/Services/reset'

export default () => async (dispatch) => {

  dispatch(resetInitialized())
  dispatch(resetAdjustment())
  dispatch(resetEditor())
  
}