import { combineReducers } from 'redux'

import { reducer as data } from './State/data'
import { reducer as isFetching } from './State/isFetching'
import { reducer as interaction } from './State/interaction'
import { reducer as filters } from './State/filters'
import { reducer as plasticTaxRateModal } from './State/plasticTaxRateModal'

export default combineReducers({
  data,
  isFetching,
  interaction,
  filters,
  plasticTaxRateModal,
})
