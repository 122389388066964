import { createAction, handleActions } from 'redux-actions'

const openCreateStocktakeModal = createAction('SCREENS/STOCKTAKES/OPEN_CREATE_STOCKTAKE_MODAL')
const closeCreateStocktakeModal = createAction('SCREENS/STOCKTAKES/CLOSE_CREATE_STOCKTAKE_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openCreateStocktakeModal]: (state) => Object.assign({}, state, {
      show: true,
    }),
    [closeCreateStocktakeModal]: () => Object.assign({}, defaultState),
  },
  defaultState
)

export { openCreateStocktakeModal, closeCreateStocktakeModal, reducer }