import { createAction, handleActions } from 'redux-actions'

const setGoodsOutRecord = createAction('SCREENS/GOODS_OUT_RECORD/SET_GOODS_OUT_RECORD')
const resetGoodsOutRecord = createAction('SCREENS/GOODS_OUT_RECORD/RESET_GOODS_OUT_RECORD')

const defaultState = null

const reducer = handleActions(
  {
    [setGoodsOutRecord]: (state, action) => action.payload,
    [resetGoodsOutRecord]: () => null,
  },
  defaultState,
)

export { setGoodsOutRecord, resetGoodsOutRecord, reducer }
