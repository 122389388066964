import { connect } from 'react-redux'
import MoreMenu from './MoreMenu'

import onCreateGoodsOut from './Handlers/onCreateGoodsOut'
import onExport from './Handlers/onExport'
import onArchive from './Handlers/onArchive'
import onUnarchive from './Handlers/onUnarchive'
import onDelete from './Handlers/onDelete'
import onDuplicate from './Handlers/onDuplicate'

const mapStateToProps = (state) => ({
  salesOrder: state.screens.SalesOrder.salesOrder,
})

const actionCreators = {
  onCreateGoodsOut,
  onExport,
  onArchive,
  onUnarchive,
  onDelete,
  onDuplicate,
}

export default connect(mapStateToProps, actionCreators)(MoreMenu)
