import React from 'react'
import PropTypes from 'prop-types'

import { Activity as ActivityModule } from '../../../../Modules'

const Activity = (props) => {

  const {
    user,
  } = props

  if (!user) return null

  return (

    <div style={{ paddingTop: 20 }}>

      <ActivityModule
        modelType='User'
        modelId={user.id}
      />

    </div>

  )

}

Activity.propTypes = {
  user: PropTypes.object,
}

Activity.defaultProps = {
  user: null,
}

export default React.memo(Activity)
