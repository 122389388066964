import React from "react"
import PropTypes from "prop-types"
import { Button, Icon } from 'antd'
import Screen from '../../Components/Screen'

import MoreMenu from './Components/MoreMenu'
import Editor from './Components/Editor'
import { Activity } from '../../Modules'

export default class StocktakeScreen extends React.PureComponent {

  static propTypes = {
    id: PropTypes.string.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    initialized: PropTypes.bool.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onReconcile: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  getTitle = () => 'Stocktake'

  renderHeaderLeft = () => {

    const {
      isEditing,
      onReconcile,
    } = this.props

    return (

      <div>

        {!isEditing && (

          <Button type="default" style={{ marginLeft: 16 }} onClick={onReconcile}>
            <Icon type="sync" />
            Reconcile
          </Button>

        )}
      
      </div>

    )
  }

  renderHeaderRight = () => {

    const {
      isEditing,
      isSubmitting,
      onSave,
      onCancel,
    } = this.props

    return (

      <div>

        {!isEditing && (
        
          <MoreMenu />

        )}

        {isEditing && (
          
          <React.Fragment>

            <Button type="link" disabled={isSubmitting} onClick={onCancel}>
              Cancel
            </Button>

            <Button type="primary" loading={isSubmitting} onClick={onSave}>
              Save Changes
            </Button>

          </React.Fragment>

        )}
      
      </div>

    )
  }

  render () {

    const {
      id,
      initialized,
      onBack,
    } = this.props

    return (

      <Screen
        title={this.getTitle()}
        headerLeft={this.renderHeaderLeft()}
        headerRight={this.renderHeaderRight()}
        contentLoading={!initialized}
        showBackButton
        onBack={onBack}
        backToTop
      >

        <Editor />

        <br />

        <Activity
          modelType='Stocktake'
          modelId={id}
        />

      </Screen>

    )
  }
}
