import { createAction, handleActions } from 'redux-actions'

const openProductValueModal = createAction('SCREENS/PRODUCT/OPEN_PRODUCT_VALUE_MODAL')
const closeProductValueModal = createAction('SCREENS/PRODUCT/CLOSE_PRODUCT_VALUE_MODAL')

const defaultState = {
  show: false,
  productId: null,
  productValue: null,
}

const reducer = handleActions(
  {
    [openProductValueModal]: (state, action) => Object.assign({}, state, {
      show: true,
      productId: action.payload.productId,
      productValue: action.payload.productValue,
    }),
    [closeProductValueModal]: () => Object.assign({}, defaultState),
  },
  defaultState
)

export { openProductValueModal, closeProductValueModal, reducer }
