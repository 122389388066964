export default (values) => () => {

  const errors = {}

  if (!values.stock_location_id) errors.stock_location_id = "Required"

  if (!values.date) errors.date = "Required"

  if (!values.time) errors.time = "Required"

  if (!values.user_id) errors.user_id = "Required"

  if (Array.isArray(values.stocktake_items)) {

    errors.stocktake_items = values.stocktake_items.map(item => {

      const itemErrors = {}

      if (!item.product_id) itemErrors.product_id = 'Required'
      
      if (!item.quantity && Number(item.quantity) !== 0) itemErrors.quantity = 'Required'

      return itemErrors
      
    })
  }

  return errors

}