import React from 'react'
import PropTypes from 'prop-types'
import { Table, Button } from 'antd'
import { orderBy } from 'lodash'
import numeral from 'numeral'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    data: PropTypes.array.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onClickRow: PropTypes.func.isRequired,
  }

  render() {

    const {
      data,
      onClickNew,
      onClickRow,
    } = this.props

    return (

      <div>

        <div style={{ height: '60px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button type='primary' onClick={onClickNew}>New Rate</Button>
        </div>

        <Table
          dataSource={orderBy(data, ['courier_service.name', 'courier_zone.group'])}
          rowKey='id'
          pagination={false}
          onRow={(record) => ({
            onClick: () => onClickRow(record),
            style: { cursor: 'pointer' },
          })}
        >

          <Table.Column
            key='courier_service.name'
            title='Service'
            render={(text, record) => record.courier_service.name}
          />

          <Table.Column
            key='courier_zone.code'
            title='Group'
            render={(text, record) => record.courier_zone ? record.courier_zone.code : null}
          />

          <Table.Column
            key='quantity'
            title='Qty'
            className={styles.numericColumn}
            render={(text, record) => record.quantity ? numeral(record.quantity).format('0,0') : null}
          />

          <Table.Column
            key='pallet_full_rate'
            title='Pallet'
            className={styles.numericColumn}
            render={(text, record) => record.pallet_full_rate ? `£${numeral(record.pallet_full_rate).format('0,0.00')}` : null}
          />

          <Table.Column
            key='pallet_half_rate'
            title='1/2 Pallet'
            className={styles.numericColumn}
            render={(text, record) => record.pallet_half_rate ? `£${numeral(record.pallet_half_rate).format('0,0.00')}` : null}
          />

          <Table.Column
            key='pallet_quarter_rate'
            title='1/4 Pallet'
            className={styles.numericColumn}
            render={(text, record) => record.pallet_quarter_rate ? `£${numeral(record.pallet_quarter_rate).format('0,0.00')}` : null}
          />

          <Table.Column
            key='pallet_micro_rate'
            title='Micro Pallet'
            className={styles.numericColumn}
            render={(text, record) => record.pallet_micro_rate ? `£${numeral(record.pallet_micro_rate).format('0,0.00')}` : null}
          />

          <Table.Column
            key='parcel_rate'
            title='Parcel'
            className={styles.numericColumn}
            render={(text, record) => record.parcel_rate ? `£${numeral(record.parcel_rate).format('0,0.00')}` : null}
          />

        </Table>

      </div>

    )

  }

}
