import { connect } from 'react-redux'
import Component from './Component'

import onClickNew from './Handlers/onClickNew'
import onRowClick from './Handlers/onRowClick'

const mapStateToProps = (state) => ({
  product: state.screens.Product.product,
  isFetching: state.screens.Product.isFetching,
})

const actionCreators = {
  onClickNew,
  onRowClick,
}

export default connect(mapStateToProps, actionCreators)(Component)
