import { createAction, handleActions } from 'redux-actions'

const setInteractionId = createAction('SCREENS/PLASTIC_TAX/REPORT/SET_INTERACTION_ID')
const resetInteraction = createAction('SCREENS/PLASTIC_TAX/REPORT/RESET_INTERACTION')

const defaultState = {
  id: null,
}

const reducer = handleActions(
  {
    [setInteractionId]: (state, { payload }) => ({ ...state, id: payload }),
    [resetInteraction]: () => defaultState,
  },
  defaultState,
)

export { setInteractionId, resetInteraction, reducer }
