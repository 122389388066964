import { connect } from 'react-redux'
import Demand from './Demand'

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onCloseControlsModal from './Handlers/onCloseControlsModal'
import onCloseManualRunRateModal from './Handlers/onCloseManualRunRateModal'
import onCloseTransactionsDrawer from './Handlers/onCloseTransactionsDrawer'

const mapStateToProps = (state) => ({
  controlsModal: state.screens.Demand.controlsModal,
  manualRunRateModal: state.screens.Demand.manualRunRateModal,
  transactionsDrawer: state.screens.Demand.transactionsDrawer,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onCloseControlsModal,
  onCloseManualRunRateModal,
  onCloseTransactionsDrawer,
}

export default connect(mapStateToProps, actionCreators)(Demand)
