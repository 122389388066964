import { connect } from 'react-redux'
import Footer from './Footer'

import onClickExport from './Handlers/onClickExport'

const mapStateToProps = (state) => ({
  totals: state.screens.PlasticTax.Report.data.totals,
})

const actionCreators = {
  onClickExport,
}

export default connect(mapStateToProps, actionCreators)(Footer)
