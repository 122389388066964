import { createAction, handleActions } from 'redux-actions'

const openTransactionsDrawer = createAction('SCREENS/STOCK_ADJUSTMENTS/OPEN_TRANSACTIONS_DRAWER')
const closeTransactionsDrawer = createAction('SCREENS/STOCK_ADJUSTMENTS/CLOSE_TRANSACTIONS_DRAWER')

const defaultState = {
  show: false,
  product: null,
  stockLocation: null,
}

const reducer = handleActions(
  {
    [openTransactionsDrawer]: (state, action) => ({
      show: true,
      product: action.payload.product,
      stockLocation: action.payload.stockLocation,
    }),
    [closeTransactionsDrawer]: () => defaultState,
  },
  defaultState,
)

export { openTransactionsDrawer, closeTransactionsDrawer, reducer }
