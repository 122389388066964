import { createAction, handleActions } from 'redux-actions'

const setInitialValues = createAction('SCREENS/PRODUCT_EDITOR/FORM/SET_INITIAL_VALUES')
const resetInitialValues = createAction('SCREENS/PRODUCT_EDITOR/FORM/RESET_INITIAL_VALUES')

const defaultState = {}

const reducer = handleActions(
  {
    [setInitialValues]: (state, action) => action.payload,
    [resetInitialValues]: () => {},
  },
  defaultState
)

export { setInitialValues, resetInitialValues, reducer }