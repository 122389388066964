import moment from 'moment'
import { defer } from 'lodash'
import { getUser } from 'Traqa/Helpers'
import formService from '../formService'

export default () => async () => {

  const initialValues = {
    date: moment(),
    time: moment(),
    user_id: getUser().id,
  }

  defer(() => formService().initialize(initialValues))

}