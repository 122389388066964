import { createSelector } from 'reselect'
import { find } from 'lodash'
import pluralize from 'pluralize'

const selectOwnerType = state => state.screens.Dashboard.ColumnCharts.movementControls.ownerType
const selectMovement = state => state.screens.Dashboard.ColumnCharts.movement
const selectIsFetchingMovement = state => state.screens.Dashboard.ColumnCharts.isFetchingMovement

const ownerTypeDirections = {
  'GoodsInRecord': 'in',
  'GoodsOutRecord': 'out',
  'Assembly-Out': 'out',
  'Assembly-In': 'in',
}

export default createSelector(
  selectOwnerType,
  selectMovement,
  selectIsFetchingMovement,
  (ownerType, movement, isFetchingMovement) => {

    const { stock_locations: stockLocations, products } = movement

    const direction = ownerTypeDirections[ownerType]

    return {
      chart: {
        type: 'column',
        width: 1120,
        height: 350,
        events: {
          load() {
            // eslint-disable-next-line no-unused-expressions
            isFetchingMovement ? this.showLoading() : this.hideLoading()
          },
        },
      },
      title: {
        text: 'Movement by Qty',
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      xAxis: {
        categories: products.map(product => product.code),
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Qty',
        },
        stackLabels: {
          enabled: true,
          format: `{total:,.0f}`,
        },
      },
      tooltip: {
        pointFormat: '<b>{series.name}</b><br />{point.form}: <b>{point.y:,.0f}</b>',
      },
      series: stockLocations.map(stockLocation => ({
        name: stockLocation.name,
        data: products.map(product => {
          const { actual } = find(product.stock_locations, row => row.id === stockLocation.id)
          return {
            y: actual[direction] || false,
            form: pluralize(product.form),
          }
        }),
        showInLegend: !!products.filter(product => find(product.stock_locations, row => row.id === stockLocation.id).actual[direction] > 0).length
      })),
    }
    
  },
)
