import { getConsignments } from '../../../../../Api/consignments'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { setPagination } from '../State/pagination'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  const { monthYear, searchTerm, filters, sorting, pagination } = getState().screens.Consignments.Table

  const params = {
    search: searchTerm,
  }

  if (monthYear) {
    params['filter[month_year]'] = monthYear.format('YYYY-MM')
  }

  Object.keys(filters).forEach(filter => {
    params[`filter[${filter}]`] = filters[filter].join(',')
  })

  params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : '-date'

  params.include = [
    'courier',
    'courier_service',
    'courier_zone',
    'stock_location',
    'customer',
    'customer_site',
    'goods_out_records',
  ].join()

  params['page[number]'] = pagination.pageNumber
  params['page[size]'] = pagination.pageSize

  dispatch(startFetching())

  try {

    const response = await getConsignments({ params })

    dispatch(setData(response.data.data))
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
    }))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }
  
}
