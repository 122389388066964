import modalProps from '../modalProps'
import fetchStockLocations from '../../../Core/Services/fetchStockLocations'
import fetchUsers from '../../../Core/Services/fetchUsers'
import initializeForm from '../Components/Form/Services/initialize'

export default (props) => (dispatch) => {

  modalProps(props)

  dispatch(fetchStockLocations())
  dispatch(fetchUsers())
  
  dispatch(initializeForm())
  
}
