import { getTeamUrl } from 'Traqa/Helpers'
import getParams from '../Utils/getParams'

export default (type) => (dispatch, getState) => {

  const params = getParams(getState())

  params.type = type

  let path = 'reports/plastic_tax/export?'

  Object.keys(params).forEach(key => {
    if (params[key] === undefined) return
    path = `${path}&${key}=${params[key]}`
  })

  window.open(getTeamUrl(path, 'exports'))

}
