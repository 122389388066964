import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'

import Form from './Components/Form'

export default class extends React.PureComponent {

  static propTypes = {
    productValue: PropTypes.object,
    submitting: PropTypes.bool.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    productValue: null,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {

    const {
      productValue,
      submitting,
      onSubmit,
      onDelete,
      onClose,
    } = this.props

    const footer = []

    if (productValue) {
      footer.push(
        <Button key="delete" style={{ float: 'left' }} type="danger" loading={submitting} onClick={onDelete}>
          Delete
        </Button>
      )
    }

    footer.push([
      <Button key='cancel' disabled={submitting} onClick={() => onClose()}>
        Cancel
      </Button>,
      <Button key='save' type='primary' loading={submitting} onClick={onSubmit}>
        {productValue ? 'Update' : 'Create'}
      </Button>,
    ])

    return (
        
      <Modal
        visible
        title={productValue ? 'Product Value' : 'New Product Value'}
        width={500}
        footer={footer}
        onCancel={() => onClose()}
      >
        
        <Form />

      </Modal>
      
    )

  }

}
