import React from 'react'
import { Module } from 'Traqa/Packages'

const Empty = () => (

  <Module.Empty
    title="Add a Product to get started"
  />

)

export default Empty