import { defer } from 'lodash'
import modalProps from '../../../modalProps'
import { findCourierCharge } from '../../../../../Api/courierCharges'
import formService from '../formService'
import { handleException } from 'Traqa/Packages'

export default () => async () => {

  const { id } = modalProps()

  if (!id) return

  try {

    const response = await findCourierCharge({ id })

    const courierCharge = response.data.data

    const initialValues = {
      courier_id: courierCharge.courier_id,
      courier_service_id: courierCharge.courier_service_id,
      type: courierCharge.type,
      quantity: courierCharge.quantity,
      charge: courierCharge.charge,
    }

    defer(() => formService().initialize(initialValues))

  } catch (error) {

    handleException(error)

  }

}
