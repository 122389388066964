import modalProps from '../../../modalProps'
import { createPurchaseOrderGoodsInRecord } from '../../../../../Api/purchaseOrders'
import { handleException } from 'Traqa/Packages'

export default (values) => async () => {

  try {

    const { purchaseOrderId, purchaseOrderItemIds } = modalProps()

    const data = {
      stock_location_id: values.stock_location_id,
      date_time: `${values.date.format('YYYY-MM-DD')} ${values.time.format('HH:mm')}:00`,
      user_id: values.user_id,
      status: values.status,
      purchase_order_item_ids: purchaseOrderItemIds,
    }

    const response = await createPurchaseOrderGoodsInRecord({ id: purchaseOrderId, data })

    modalProps().onClose(response.data.data)

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
