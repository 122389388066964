import { message } from 'antd'
import { push } from 'connected-react-router'
import { deleteTransfer } from '../../../Api/transfers'
import { getTeamUrl } from 'Traqa/Helpers'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { transfer } = getState().screens.Transfer

    await deleteTransfer({ id: transfer.id })

    message.success(`Deleted Transfer "${transfer.id}"`)

    dispatch(push(getTeamUrl(`transfers`)))

  } catch (error) {

    handleException(error)

  }
}