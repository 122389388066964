import { message } from 'antd'
import { deleteGoodsInRecord } from '../../../../../Api/goodsInRecords'
import { setColumnOffset } from '../State/columnOffset'
import fetch from './fetch'
import { handleException } from 'Traqa/Packages'

export default (id) => async (dispatch, getState) => {

  try {

    const { data, formState, columnOffset } = getState().screens.PurchasePlanner.Report

    const { total } = data

    const { columnCount } = formState.values

    await deleteGoodsInRecord({ id })

    if ((total - 1) < columnOffset + columnCount) {
      dispatch(setColumnOffset(columnOffset - 1))
    }

    dispatch(fetch())

    message.success('Deleted')

  } catch (error) {

    handleException(error)

  }
  
}
