import { closeStockLocationModal } from '../State/stockLocationModal'
import fetchStockLocationsTable from '../Components/StockLocationsTable/Services/fetch'
import fetchStockLocationsCore from '../../../Core/Services/fetchStockLocations'

export default (shouldReload = false) => (dispatch) => {
  
  dispatch(closeStockLocationModal())

  if (shouldReload === true) {
    dispatch(fetchStockLocationsTable())
    dispatch(fetchStockLocationsCore({ force: true }))
  }
  
}