import { defer } from 'lodash'
import modalProps from '../../../modalProps'
import formService from '../formService'
import { getStockLocation } from '../../../../../Api/stockLocations'
import { handleException } from 'Traqa/Packages'

export default () => async () => {

  try {

    const { stockLocationId } = modalProps()

    if (stockLocationId) {

      const response = await getStockLocation({ id: stockLocationId })

      const stockLocation = response.data.data

      const initialValues = {
        name: stockLocation.name
      }
    
      defer(() => formService().initialize(initialValues))

    } else {

      const initialValues = {}
    
      defer(() => formService().initialize(initialValues))

    }

  } catch (error) {

    handleException(error)
  }

}