import { connect } from 'react-redux'
import GoodsOutRecords from './GoodsOutRecords'

const mapStateToProps = (state) => ({
  empty: state.screens.Consignment.GoodsOutRecords.data.length === 0,
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(GoodsOutRecords)
