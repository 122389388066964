import fetch from './fetch'
import { initialized } from '../State/initialized'

export default () => async (dispatch) => {

  await dispatch(fetch())

  dispatch(initialized())
  
}
