import { connect } from 'react-redux'
import Activity from './Activity'

import selectListRows from './Selectors/selectListRows'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onLoadActivity from './Handlers/onLoadActivity'
import onToggle from './Handlers/onToggle'

const mapStateToProps = (state) => ({
  listRows: selectListRows(state),
  data: state.modules.Activity.data,
  isFetching: state.modules.Activity.isFetching,
  initialized: state.modules.Activity.initialized,
  isExpanded: state.modules.Activity.isExpanded,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onLoadActivity,
  onToggle,
}

export default connect(mapStateToProps, actionCreators)(Activity)
