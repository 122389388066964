import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field, FormSpy } from 'react-final-form'
import { Form, Select, InputNumber, DatePicker, TimePicker, Icon, Tag } from 'antd'
import { ExposeFormToHelper } from 'Traqa/Packages'
import formService from './formService'
import styles from './Component.css'

export default class _Form_ extends React.PureComponent {

  static propTypes = {
    kits: PropTypes.array.isRequired,
    stockLocations: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  render () {

    const {
      kits,
      stockLocations,
      users,
      onChangeFormState,
      onSubmit,
      validate,
    } = this.props

    const truncate = (input) => input.length > 40 ? `${input.substring(0, 40)}...` : input

    const initialValues = {
      date: null,
      time: null,
    }

    const statuses = [
      { key: 'Draft', label: 'Draft', icon: 'edit', color: 'grey' },
      { key: 'Expected', label: 'Scheduled', icon: 'check-circle', color: 'orange' },
      { key: 'Completed', label: 'Assembled', icon: 'check-circle', color: 'limegreen' },
    ]
    
    return (

      <FormProvider
        validate={validate}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, submitting }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 17 }}
            autoComplete='off'
            colon={false}
          >

            <ExposeFormToHelper helper={formService} />

            <FormSpy subscription={{ submitting: true }} onChange={onChangeFormState} />

            <Field name='product_id'>
              {({ input, meta }) => (
                <Form.Item
                  label='Product'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Select
                    {...input}
                    disabled={submitting}
                    optionFilterProp='children'
                    showSearch
                    allowClear
                    dropdownMatchSelectWidth
                  >

                    <Select.OptGroup label='Products'>
                      {kits.filter(product => !product.archived_at).map(product => (
                        <Select.Option key={product.id} value={product.id}>
                          {truncate(`${product.code} - ${product.name}`)}
                          <Tag color='volcano' style={{ marginLeft: 5 }}>{product.form}</Tag>
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                    
                    <Select.OptGroup label='Archived'>
                      {kits.filter(product => product.archived_at).map(product => (
                        <Select.Option key={product.id} value={product.id} style={{ color: '#999' }}>
                          {truncate(`${product.code} - ${product.name}`)}
                          <Tag color='volcano' style={{ marginLeft: 5 }}>{product.form}</Tag>
                        </Select.Option>
                      ))}
                    </Select.OptGroup>

                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name='quantity' allowNull>
              {({ input, meta }) => (
                <Form.Item
                  label='Qty'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <InputNumber
                    {...input}
                    disabled={submitting}
                    min={0}
                    step={1}
                    precision={0}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='stock_location_id'>
              {({ input, meta }) => (
                <Form.Item
                  label='Location'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Select
                    {...input}
                    disabled={submitting}
                    optionFilterProp='children'
                    showSearch
                    allowClear
                  >
                    <Select.OptGroup label='Active'>
                      {stockLocations.filter(stockLocation => !stockLocation.archived_at).map(stockLocation => (
                        <Select.Option key={stockLocation.id} value={stockLocation.id}>
                          {stockLocation.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                    <Select.OptGroup label='Archived'>
                      {stockLocations.filter(stockLocation => !!stockLocation.archived_at).map(stockLocation => (
                        <Select.Option key={stockLocation.id} value={stockLocation.id} style={{ color: '#999' }}>
                          {stockLocation.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name='date' allowNull>
              {({ input, meta }) => (
                <Form.Item
                  label='Date'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <DatePicker
                    {...input}
                    disabled={submitting}
                    format='DD/MM/YYYY'
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='time' allowNull>
              {({ input, meta }) => (
                <Form.Item
                  label='Time'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <TimePicker
                    {...input}
                    disabled={submitting}
                    format='HH:mm'
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='user_id'>
              {({ input, meta }) => (
                <Form.Item
                  label='User'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Select
                    {...input}
                    disabled={submitting}
                    optionFilterProp='children'
                    showSearch
                    allowClear
                  >
                    {users.map(user => (
                      <Select.Option key={user.id} value={user.id}>
                        {user.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name='status'>
              {({ input, meta }) => (
                <Form.Item
                  label='Status'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Select
                    {...input}
                    disabled={submitting}
                    optionFilterProp='children'
                    showSearch
                    allowClear
                  >
                    {statuses.map(status => (
                      <Select.Option key={status.key} value={status.key}>
                        <Icon type={status.icon} className={styles.icon} style={{ color: status.color }} />
                        <span style={{ marginLeft: '5px' }}>{status.label}</span>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Field>

          </Form>

        )}

      </FormProvider>

    )
  }
}