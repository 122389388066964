import { setSelectedItems, resetSelectedItems } from '../State/selectedItems'

export default () => (dispatch, getState) => {

  const items = getState().screens.SalesOrder.Editor.formState.values.sales_order_items

  const selectedItemCount = getState().screens.SalesOrder.Editor.selectedItems

  if (selectedItemCount < items.length) {

    dispatch(setSelectedItems(items.map(item => item.guid)))

  } else {

    dispatch(resetSelectedItems())

  }

}
