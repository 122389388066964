import { getDashboardGoodsInRecordsByMonth } from '../../../../../Api/dashboard'
import { setData } from '../State/data'
import { startFetching, finishFetching } from '../State/isFetching'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch) => {

  dispatch(startFetching())

  try {

    const response = await getDashboardGoodsInRecordsByMonth()

    dispatch(setData(response.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetching())

  }

}
