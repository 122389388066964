import { connect } from "react-redux"
import ItemsArray from "./ItemsArray"

import selectIsNew from './Selectors/selectIsNew'
import selectToggleState from './Selectors/selectToggleState'

import onToggle from './Handlers/onToggle'
import onAddItem from './Handlers/onAddItem'
import onDeleteItems from './Handlers/onDeleteItems'
import onCreateGoodsIn from './Handlers/onCreateGoodsIn'

const mapStateToProps = (state) => ({
  isNew: selectIsNew(state),
  toggleState: selectToggleState(state),
})

const actionCreators = {
  onToggle,
  onAddItem,
  onDeleteItems,
  onCreateGoodsIn,
}

export default connect(mapStateToProps, actionCreators)(ItemsArray)
