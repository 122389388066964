import { createSelector } from 'reselect'
import { find, get } from 'lodash'
import selectCustomerSites from './selectCustomerSites'

const selectCustomerSiteId = (state) => get(state.screens.GoodsOutRecord.Editor.formState, 'values.customer_site_id', null)

export default createSelector(
  selectCustomerSites,
  selectCustomerSiteId,
  (customerSites, customerSiteId) => find(customerSites, { id: customerSiteId }),
)
