import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Menu, Icon } from 'antd'
import styles from './styles.css'

const Header = (props) => {

  const {
    goodsOutRecord,
    onChangeStatus,
    onChangeIsPrinted,
    onLinkConsignment,
    onGoToConsignment,
    onUnlinkConsignment,
  } = props

  const statusOptions = [
    { label: 'Scheduled', value: 'Expected', icon: 'clock-circle', color: 'orange' },
    { label: 'Picked', value: 'Picked', icon: 'check-circle', color: '#1890ff' },
    { label: 'Dispatched', value: 'Completed', icon: 'check-circle', color: 'limegreen' },
  ]

  const isPrintedOptions = [
    { label: 'Not Printed', value: 0, icon: 'stop', color: 'grey' },
    { label: 'Printed', value: 1, icon: 'printer', color: 'limegreen' },
  ]

  const currentStatus = statusOptions.filter(option => option.value === goodsOutRecord.status)[0]

  // eslint-disable-next-line eqeqeq
  const currentIsPrinted = isPrintedOptions.filter(option => option.value == goodsOutRecord.is_printed)[0]

  const statusMenu = (

    <Menu style={{ minWidth: 120 }} onClick={({ key }) => onChangeStatus(key)}>

      {statusOptions.map(option => (

        <Menu.Item key={option.value} className={styles.menuItem}>
          <Icon type={option.icon} className={styles.icon} style={{ color: option.color }} />
          {option.label}
        </Menu.Item>

      ))}

    </Menu>

  )

  const isPrintedMenu = (

    <Menu style={{ minWidth: 120 }} onClick={({ key }) => onChangeIsPrinted(key)}>

      {isPrintedOptions.map(option => (

        <Menu.Item key={option.value} className={styles.menuItem}>
          <Icon type={option.icon} className={styles.icon} style={{ color: option.color }} />
          {option.label}
        </Menu.Item>

      ))}

    </Menu>

  )

  return (

    <div className={styles.wrapper}>

      <Dropdown
        trigger={['click']}
        placement='bottomLeft'
        overlay={statusMenu}
      >

        <div className={styles.select} tabIndex={-1}>
          <Icon type={currentStatus.icon} className={styles.icon} style={{ color: currentStatus.color }} />
          {currentStatus.label}
          <Icon type='caret-down' className={styles.caret} />
        </div>

      </Dropdown>

      <div style={{ width: 30 }} />

      <Dropdown
        trigger={['click']}
        placement='bottomLeft'
        overlay={isPrintedMenu}
      >

        <div className={styles.select} tabIndex={-1}>
          <Icon type={currentIsPrinted.icon} className={styles.icon} style={{ color: currentIsPrinted.color }} />
          {currentIsPrinted.label}
          <Icon type='caret-down' className={styles.caret} />
        </div>

      </Dropdown>

      <div style={{ width: 30 }} />

      {!goodsOutRecord.consignment && (

        <Dropdown
          trigger={['click']}
          placement='bottomLeft'
          overlay={(
            <Menu style={{ minWidth: 120 }}>
              <Menu.Item key={1} onClick={() => onLinkConsignment(goodsOutRecord)} className={styles.menuItem}>
                <Icon type='link' className={styles.icon} />
                Create or link to a Consignment
              </Menu.Item>
            </Menu>
          )}
        >

          <div className={styles.select} tabIndex={-1}>
            <Icon type='close-circle' className={styles.icon} />
            No Consignment
            <Icon type='caret-down' className={styles.caret} />
          </div>

        </Dropdown>

      )}

      {!!goodsOutRecord.consignment && (

        <Dropdown
          trigger={['click']}
          placement='bottomLeft'
          overlay={(
            <Menu style={{ minWidth: 120 }}>
              <Menu.Item key={1} onClick={() => onGoToConsignment(goodsOutRecord.consignment.id)} className={styles.menuItem}>
                <Icon type='arrow-right' className={styles.icon} />
                Go to Consignment
              </Menu.Item>
              <Menu.Item key={2} onClick={() => onUnlinkConsignment(goodsOutRecord.consignment.id)} className={styles.menuItem}>
                <Icon type='disconnect' className={styles.icon} />
                Unlink from Consignment
              </Menu.Item>
            </Menu>
          )}
        >

          <div className={styles.select} tabIndex={-1}>
            <Icon type='link' className={styles.icon} style={{ color: 'grey' }} />
            {`Consignment ${goodsOutRecord.consignment.id}`}
            <Icon type='caret-down' className={styles.caret} />
          </div>

        </Dropdown>

      )}

    </div>
  )
}

Header.propTypes = {
  goodsOutRecord: PropTypes.object.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onChangeIsPrinted: PropTypes.func.isRequired,
  onLinkConsignment: PropTypes.func.isRequired,
  onGoToConsignment: PropTypes.func.isRequired,
  onUnlinkConsignment: PropTypes.func.isRequired,
}

export default Header
