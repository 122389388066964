import { closeSupplierModal } from '../State/supplierModal'
import fetchSuppliersTable from '../Components/SuppliersTable/Services/fetch'
import fetchSuppliersCore from '../../../Core/Services/fetchSuppliers'

export default (shouldReload = false) => (dispatch) => {
  
  dispatch(closeSupplierModal())

  if (shouldReload === true) {
    dispatch(fetchSuppliersTable())
    dispatch(fetchSuppliersCore({ force: true }))
  }
  
}