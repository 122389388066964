import { message } from 'antd'
import { uploadMedia } from '../../../Api/media'
import upload from '../../../Core/Services/upload'
import fetch from './fetch'
import modalProps from '../modalProps'

export default (modelType, modelId, files) => (dispatch) => {

  const validTypes = [
    'image/jpeg',
    'image/jpg',
    'image/png',
  ]

  const images = files.filter(file => validTypes.indexOf(file.type) !== -1)

  const data = {
    modelType,
    modelId,
    collection: 'images',
  }

  const onSuccess = (response) => () => {

    dispatch(fetch())

    modalProps().onSubmit()

    message.success(`Uploaded ${response.data.name}`)
  }

  const onFailure = (error, file) => () => message.error(`Failed to upload ${file.name}`)

  const uploads = images.map(image => ({
    file: image,
    data,
    request: uploadMedia,
    onSuccess,
    onFailure,
  }))

  dispatch(upload(uploads))

}
