import { connect } from "react-redux"

import Component from "./Component"

import componentDidMount from "./Handlers/componentDidMount"
import onChangeActiveTab from "./Handlers/onChangeActiveTab"

const mapStateToProps = (state) => ({
  activeTab: state.screens.Transactions.activeTab,
})

const actionCreators = {
  componentDidMount,
  onChangeActiveTab,
}

export default connect(mapStateToProps, actionCreators)(Component)
