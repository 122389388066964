import { createAction, handleActions } from 'redux-actions'

const loadProduct = createAction('SCREENS/PRODUCT/LOAD_PRODUCT')
const clearProduct = createAction('SCREENS/PRODUCT/CLEAR_PRODUCT')

const defaultState = null

const reducer = handleActions(
  {
    [loadProduct]: (state, action) => action.payload,
    [clearProduct]: () => null,
  },
  defaultState
)

export { loadProduct, clearProduct, reducer }