import React from 'react'
import PropTypes from 'prop-types'
import GroupHeader from '../GroupHeader'
import GroupItem from '../GroupItem'
import GroupFooter from '../GroupFooter'

const Row = (props) => {

  const {
    index,
    style,
    component,
  } = props

  const components = {
    GroupHeader,
    GroupItem,
    GroupFooter,
  }

  const Component = components[component]

  return (
    <div style={style}>
      <Component index={index} />
    </div>
  )
}

Row.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  component: PropTypes.string.isRequired,
}

export default Row