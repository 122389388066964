import { connect } from "react-redux"
import Details from "./Details"

const mapStateToProps = (state) => ({
  stockLocations: state.core.stockLocations,
  users: state.core.users,
})

const actionCreators = {
  
}

export default connect(mapStateToProps, actionCreators)(Details)
