import { connect } from 'react-redux'
import Table from './Table'

import selectProducts from '../../Selectors/selectProducts'

import onClickRow from './Handlers/onClickRow'

const mapStateToProps = (state) => ({
  products: selectProducts(state),
  isFetching: state.screens.StockAssessment.Report.isFetching,
})

const actionCreators = {
  onClickRow,
}

export default connect(mapStateToProps, actionCreators)(Table)
