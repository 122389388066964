import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import numeral from 'numeral'
import { Table as AntTable, Tooltip, Icon } from 'antd'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { getTeamUrl } from 'Traqa/Helpers'
import styles from './styles.css'

import FulfilmentProgress from './Components/FulfillmentProgress'

const makeCustomerSiteString = (record) => {
  if (record.customer.name === record.customer_site.name) {
    return record.customer.name
  }
  return `${record.customer.name} / ${record.customer_site.name}`
}

const Table = (props) => {

  const {
    data,
    isFetching,
    onChange,
  } = props
  
  return (

    <AntTable
      dataSource={data}
      loading={isFetching}
      rowKey='id'
      pagination={false}
      onChange={onChange}
    >

      <AntTable.Column
        key='number'
        title='No.'
        sorter
        render={(text, record) => (
          <NavLink key={record.id} to={getTeamUrl(`sales_orders/${record.id}`)}>
            {record.number}
          </NavLink>
        )}
      />

      <AntTable.Column
        key='customer.name'
        title='Customer / Site'
        sorter
        render={(text, record) => (
          <div className={styles.customerSite}>
            {!!record.is_export && (
              <Tooltip title='Export'>
                <Icon type='export' className={styles.exportedIcon} />
              </Tooltip>
            )}
            {makeCustomerSiteString(record)}
          </div>
        )}
      />

      <AntTable.Column
        key='description'
        width={50}
        render={(text, record) => record.description ? (
          <div className={styles.description}>
            <Tooltip title={record.description}>
              <Icon type='info-circle' style={{ color: '#1990FF', fontSize: 18 }} />
            </Tooltip>
          </div>
        ) : null}
      />

      <AntTable.Column
        key='date'
        title='Order Date'
        width={130}
        sorter
        render={(text, record) => moment(record.date).format('D MMM YY')}
      />

      <AntTable.Column
        key='due_date'
        title='Due Date'
        width={130}
        sorter
        render={(text, record) => record.due_date ? (
          <span
            className={classNames({
              [styles.isOverdue]: record.is_overdue,
            })}
          >
            {record.is_overdue && <Icon type='exclamation-circle' style={{ marginRight: '5px' }} />}
            {moment(record.due_date).calendar(null, {
              sameDay: '[Today]',
              nextDay: '[Tomorrow]',
              lastDay: '[Yesterday]',
              nextWeek: 'D MMM YY',
              lastWeek: 'D MMM YY',
              sameElse: 'D MMM YY',
            })}
          </span>
        ) : null}
      />

      <AntTable.Column
        key='total_price'
        title='Total Price'
        sorter
        render={(text, record) => `£${numeral(record.total_price).format('0,0.00')}`}
        className={styles.numericColumn}
      />

      <AntTable.Column
        key='status'
        title='Status'
        width={130}
        sorter
        render={(text, record) => (
          <div className={styles.status}>
            {record.status === 'Proforma' && <Icon type='hourglass' className={classNames(styles.statusIcon, styles.proforma)} />}
            {record.status === 'Proforma' && 'Proforma'}
            {record.status === 'Raised' && <Icon type='check-circle' className={classNames(styles.statusIcon, styles.raised)} />}
            {record.status === 'Raised' && 'Raised'}
          </div>
        )}
      />

      <AntTable.Column
        key='fulfilled_percentage'
        title='Fulfillment'
        width={350}
        sorter
        render={(text, record) => (
          <div className={styles.fulfillment}>
            <FulfilmentProgress salesOrder={record} />
          </div>
        )}
      />

    </AntTable>

  )
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Table
