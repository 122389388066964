import { createSelector } from 'reselect'
import pluralize from 'pluralize'

import selectProducts from '../../../Selectors/selectProducts'

const selectIsFetching = state => state.screens.StockAdjustments.Report.isFetching
const selectSortBy = state => state.screens.StockAdjustments.Report.formState.values.sortBy

const config = {
  in: {
    title: 'In',
    format: '{total:,.0f}',
    pointFormat: '<b>{series.name}</b><br />{point.form}: <b>{point.y:,.0f}</b><br />Value: <b>£{point.in_value:,.0f}</b>',
    series: 'In',
    alternateKey: 'in_value',
  },
  in_value: {
    title: 'In (£)',
    format: '£{total:,.0f}',
    pointFormat: '<b>{series.name}</b><br />{point.form}: <b>{point.in:,.0f}</b><br />Value: <b>£{point.y:,.0f}</b>',
    series: 'In',
    alternateKey: 'in',
  },
  out: {
    title: 'Out',
    format: '{total:,.0f}',
    pointFormat: '<b>{series.name}</b><br />{point.form}: <b>{point.y:,.0f}</b><br />Value: <b>£{point.out_value:,.0f}</b>',
    series: 'Out',
    alternateKey: 'out_value',
  },
  out_value: {
    title: 'Out (£)',
    format: '£{total:,.0f}',
    pointFormat: '<b>{series.name}</b><br />{point.form}: <b>{point.out:,.0f}</b><br />Value: <b>£{point.y:,.0f}</b>',
    series: 'Out',
    alternateKey: 'out',
  },
}

export default createSelector(
  selectProducts,
  selectIsFetching,
  selectSortBy,
  (products, isFetching, sortBy) => {

    if (sortBy === 'code') return {}

    const selection = products.slice(0, 15)

    return {
      chart: {
        type: 'column',
        height: 300,
        events: {
          load() {
            // eslint-disable-next-line no-unused-expressions
            isFetching ? this.showLoading() : this.hideLoading()
          },
        },
      },
      title: {
        enabled: true,
        text: config[sortBy].title,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      xAxis: {
        categories: selection.map(product => product.code),
      },
      yAxis: {
        title: {
          enabled: false,
        },
        stackLabels: {
          enabled: true,
          format: config[sortBy].format,
        },
      },
      tooltip: {
        pointFormat: config[sortBy].pointFormat,
      },
      series: [
        {
          name: config[sortBy].series,
          data: selection.map(product => ({
            y: product[sortBy],
            form: pluralize(product.form),
            [config[sortBy].alternateKey]: product[config[sortBy].alternateKey],
          })),
          showInLegend: false,
        },
      ],
    }

  },
)
