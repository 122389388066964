import { combineReducers } from 'redux'

import { reducer as activeTab } from './State/activeTab'
import { reducer as holdingValue } from './State/holdingValue'
import { reducer as isFetchingHoldingValue } from './State/isFetchingHoldingValue'

export default combineReducers({
  activeTab,
  holdingValue,
  isFetchingHoldingValue,
})
