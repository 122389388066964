import { resetData } from '../State/data'
import { resetFetching } from '../State/isFetching'
import { resetSorting } from '../State/sorting'

export default () => (dispatch) => {

  dispatch(resetData())
  dispatch(resetFetching())
  dispatch(resetSorting())

}