import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import Screen from '../../Components/Screen'
import Table from './Components/Table'

export default class _SalesOrders_ extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  renderHeaderRight = () => ([

    <Button key='new' type='primary' onClick={this.props.onClickNew}>
      New Order
    </Button>
    
  ])

  render () {

    return (

      <Screen
        title='Sales Orders'
        headerRight={this.renderHeaderRight()}
      >

        <Table />

      </Screen>

    )

  }

}
