import { connect } from 'react-redux'
import Advanced from './Advanced'

import onClickClose from './Handlers/onClickClose'
import onFormChange from './Handlers/onFormChange'
import onSubmit from './Handlers/onSubmit'

const mapStateToProps = (state) => ({
  advanced: state.screens.GoodsOutRecords.Table.advanced,
  stockLocations: state.core.stockLocations,
  customers: state.core.customers,
  customerSites: state.core.customerSites,
})

const actionCreators = {
  onClickClose,
  onFormChange,
  onSubmit,
}

export default connect(mapStateToProps, actionCreators)(Advanced)