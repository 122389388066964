import fetchStockLocations from '../../../Core/Services/fetchStockLocations'
import fetchProductCategories from '../../../Core/Services/fetchProductCategories'

import fetchPieChartsHoldingValue from '../Components/PieCharts/Services/fetchHoldingValue'

import fetchGoodsInRecords from '../Components/GoodsInRecords/Services/fetch'

import fetchColumnChartsHoldingValue from '../Components/ColumnCharts/Services/fetchHoldingValue'
import fetchColumnChartsHoldingWeeks from '../Components/ColumnCharts/Services/fetchHoldingWeeks'
import fetchColumnChartsMovement from '../Components/ColumnCharts/Services/fetchMovement'

export default () => async (dispatch) => {

  dispatch(fetchStockLocations())
  dispatch(fetchProductCategories())

  dispatch(fetchPieChartsHoldingValue())

  dispatch(fetchGoodsInRecords())

  await dispatch(fetchColumnChartsHoldingValue())
  dispatch(fetchColumnChartsHoldingWeeks())
  dispatch(fetchColumnChartsMovement())

}
