import { showAdvanced, setValues } from '../../../State/advanced'
import { setSearchTerm } from '../../../State/searchTerm'

export default () => async (dispatch, getState) => {

  dispatch(showAdvanced())

  const { activeTab, searchTerm } = getState().screens.GoodsInRecords.Table

  dispatch(setValues({
    type: activeTab,
    keywords: searchTerm,
  }))

  dispatch(setSearchTerm(''))
  
}