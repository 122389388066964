import { message } from 'antd'
import { push } from 'connected-react-router'
import { deletePurchaseOrder } from '../../../Api/purchaseOrders'
import { getTeamUrl } from 'Traqa/Helpers'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { purchaseOrder } = getState().screens.PurchaseOrder

    await deletePurchaseOrder({ id: purchaseOrder.id })

    message.success(`Deleted Order "${purchaseOrder.number}"`)

    dispatch(push(getTeamUrl(`purchase_orders`)))

  } catch (error) {

    handleException(error)

  }
}