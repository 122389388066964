import React from 'react'
import PropTypes from 'prop-types'
import { Field, FormSpy } from 'react-final-form'
import { Form, Row, Col, InputNumber, Typography } from 'antd'
import numeral from 'numeral'
import { Module } from 'Traqa/Packages'

const ContentDetails = (props) => {

  const {
    calculatedCost,
  } = props
  
  return (

    <Module.Section
      contentStyle={{ paddingBottom: 15 }}
    >

      <FormSpy subscription={{ values: true, submitting: true }}>

        {({ values, submitting }) => (

          <div>

            <Row gutter={8}>

              <Col span={6}>

                <Field name='pallet_full_qty'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Pallets'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        value={input.value || 0}
                        disabled={submitting}
                        min={0}
                        step={1}
                        precision={0}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={6}>

                <Field name='pallet_half_qty'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='1/2 Pallets'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        value={input.value || 0}
                        disabled={submitting}
                        min={0}
                        step={1}
                        precision={0}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={6}>

                <Field name='pallet_quarter_qty'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='1/4 Pallets'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        value={input.value || 0}
                        disabled={submitting}
                        min={0}
                        step={1}
                        precision={0}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={6}>

                <Field name='pallet_micro_qty'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Micro Pallets'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        value={input.value || 0}
                        disabled={submitting}
                        min={0}
                        step={1}
                        precision={0}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

            </Row>

            <Row gutter={8}>

              <Col span={6}>

                <Field name='parcel_qty'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Parcels'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        value={input.value || 0}
                        disabled={submitting}
                        min={0}
                        step={1}
                        precision={0}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={9}>

                <Field name='total_kgs'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Weight (kg)'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        disabled={submitting}
                        min={0}
                        step={1}
                        precision={1}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              {values.courier_id !== 'null' && (

                <Col span={9}>

                  <Field name='total_cost'>
                    {({ input, meta }) => (
                      <Form.Item
                        label='Cost'
                        validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                      >
                        <InputNumber
                          {...input}
                          formatter={value => `£${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/£?|(,*)/g, '')}
                          disabled={submitting}
                          min={0}
                          step={1}
                          precision={2}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    )}
                  </Field>

                </Col>

              )}

            </Row>

            {values.courier_id !== 'null' && (

              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                <Field name='suggested_total_cost'>
                  {() => (
                    <Form.Item
                      label='&nbsp;'
                    >
                      <Typography.Text>{`Calculated Cost: ${calculatedCost !== null ? `£${numeral(calculatedCost).format('0,0.00')}` : 'N/A'}`}</Typography.Text>
                    </Form.Item>
                  )}
                </Field>

              </div>

            )}

          </div>
      
        )}

      </FormSpy>
  
    </Module.Section>

  )
  
}

ContentDetails.propTypes = {
  calculatedCost: PropTypes.number,
}

export default ContentDetails
