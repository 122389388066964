import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Icon, Badge } from 'antd'
import { AppBar, Menu } from 'Traqa/Packages'
import { StockshotsPopover, NotificationPopover } from '../Modules'
import styles from './AppBar.css'
import logo from 'Traqa/Assets/logo.png'
import { getUser, getCurrentTeam, getTeams, getTeamUrl, isWarehouse } from 'Traqa/Helpers'

class _AppBar_ extends React.PureComponent {

  static propTypes = {
    hasUnreadNotifications: PropTypes.bool.isRequired,
    pathname: PropTypes.string.isRequired,
  }

  render () {

    const {
      hasUnreadNotifications,
      pathname,
    } = this.props

    const user = getUser()
    const currentTeam = getCurrentTeam()
    const teams = getTeams()
    const warehouse = isWarehouse()

    const logout = () => {
      window.location = '/logout'
    }

    const goToTeam = (team) => {
      window.location = `/app/teams/${team.slug}`
    }

    const inventoryMenu = () => (

      <Menu style={{ minWidth: 200 }}>

        {!warehouse && <Menu.Item href={getTeamUrl(`products`)}>All Products</Menu.Item>}

        <Menu.Item href={getTeamUrl(`stock_assessment`)}>Stock Assessment</Menu.Item>

        {!warehouse && <Menu.Item href={getTeamUrl(`stock_adjustments`)}>Stock Adjustments</Menu.Item>}

      </Menu>

    )

    const stockControlMenu = () => (

      <Menu style={{ minWidth: 200 }}>

        {!warehouse && <Menu.Item href={getTeamUrl(`purchase_orders`)}>Purchase Orders</Menu.Item>}
          
        <Menu.Item href={getTeamUrl(`goods_in_records`)}>Goods In</Menu.Item>

        <Menu.Divider />

        {!warehouse && <Menu.Item href={getTeamUrl(`sales_orders`)}>Sales Orders</Menu.Item>}
        
        <Menu.Item href={getTeamUrl(`goods_out_records`)}>Goods Out</Menu.Item>
        
        <Menu.Item href={getTeamUrl(`consignments`)}>Consignments</Menu.Item>

        {!warehouse && <Menu.Divider />}

        <Menu.Item href={getTeamUrl(`transfers`)}>Transfers</Menu.Item>

        {!warehouse && <Menu.Item href={getTeamUrl(`assemblies`)}>Assemblies</Menu.Item>}

        {!warehouse && <Menu.Item href={getTeamUrl(`stocktakes`)}>Stocktakes</Menu.Item>}

        {!warehouse && <Menu.Divider />}

        {!warehouse && <Menu.Item href={getTeamUrl(`transactions`)}>Transactions</Menu.Item>}

      </Menu>

    )

    const forecastingMenu = () => (

      <Menu style={{ minWidth: 200 }}>

        <Menu.Item href={getTeamUrl(`purchase_planner`)}>Purchase Planner</Menu.Item>

        <Menu.Item href={getTeamUrl(`plastic_tax`)}>Plastic Tax</Menu.Item>

        <Menu.Item href={getTeamUrl(`demand`)}>Demand</Menu.Item>

      </Menu>

    )

    const manageMenu = () => (

      <Menu style={{ minWidth: 200 }}>

        <Menu.Item href={getTeamUrl(`users`)}>Users</Menu.Item>

        <Menu.Item href={getTeamUrl(`customers`)}>Customers</Menu.Item>

        <Menu.Item href={getTeamUrl(`suppliers`)}>Suppliers</Menu.Item>

        <Menu.Item href={getTeamUrl(`product_categories`)}>Product Categories</Menu.Item>

        <Menu.Item href={getTeamUrl(`stock_locations`)}>Stock Locations</Menu.Item>

        <Menu.Item href={getTeamUrl(`couriers`)}>Couriers</Menu.Item>

      </Menu>

    )

    const createMenu = () => (

      <Menu style={{ minWidth: 200 }}>

        <Menu.Item href={getTeamUrl(`stock_summary`)}>Purchase Order</Menu.Item>

        <Menu.Item href={getTeamUrl(`stock_summary`)}>Sales Order</Menu.Item>

        <Menu.Item href={getTeamUrl(`stock_summary`)}>Goods In</Menu.Item>

        <Menu.Item href={getTeamUrl(`stock_summary`)}>Goods Out</Menu.Item>

        <Menu.Item href={getTeamUrl(`stock_summary`)}>Transfer</Menu.Item>

      </Menu>

    )

    const userMenu = () => (

      <Menu>

        <div className={styles.user}>
          <div className={styles.avatar}>
            {(`${user.first_name.substr(0,1)}${user.last_name.substr(0,1)}`).toUpperCase()}
          </div>
          <div className={styles.details}>
            <div className={styles.name}>
              {`${user.name}`}
            </div>
            <div className={styles.email}>
              {`${user.email}`}
            </div>
          </div>
        </div>

        <Menu.Divider />

        {teams.map(team => (
          <Menu.Item key={team.id} onClick={() => goToTeam(team)}>{`${team.name} ${currentTeam.id === team.id ? '✔' : ''}`}</Menu.Item>
        ))}

        <Menu.Divider />

        <Menu.Item onClick={logout}>Logout</Menu.Item>

      </Menu>
    )

    const inDashboard = [
      getTeamUrl('dashboard'),
    ].filter(path => pathname && pathname.startsWith(path)).length > 0

    const inInventory = [
      getTeamUrl('products'),
      getTeamUrl('assessed_stock'),
      getTeamUrl('stock_summary'),
      getTeamUrl('customer_sales'),
    ].filter(path => pathname && pathname.startsWith(path)).length > 0

    const inStockControl = [
      getTeamUrl('purchase_orders'),
      getTeamUrl('goods_in_records'),
      getTeamUrl('sales_orders'),
      getTeamUrl('goods_out_records'),
      getTeamUrl('consignments'),
      getTeamUrl('stocktakes'),
      getTeamUrl('transfers'),
      getTeamUrl('assemblies'),
      getTeamUrl('transactions'),
    ].filter(path => pathname && pathname.startsWith(path)).length > 0

    const inForecasting = [
      getTeamUrl('purchase_planner'),
      getTeamUrl('plastic_tax'),
      getTeamUrl('demand'),
    ].filter(path => pathname && pathname.startsWith(path)).length > 0

    const inManagement = [
      getTeamUrl('customers'),
      getTeamUrl('suppliers'),
      getTeamUrl('users'),
      getTeamUrl('product_categories'),
      getTeamUrl('stock_locations'),
      getTeamUrl('couriers'),
    ].filter(path => pathname && pathname.startsWith(path)).length > 0

    return (

      <AppBar>


        <AppBar.Left>

          <img src={logo} className={styles.logo} alt="logo" />

          {!warehouse && (

            <AppBar.Button
              href={getTeamUrl(`dashboard`)}
              highlight={inDashboard}
            >
              Dashboard
            </AppBar.Button>

          )}

          <AppBar.Button
            dropdown={inventoryMenu}
            highlight={inInventory}
          >
            Inventory
          </AppBar.Button>

          <AppBar.Button
            dropdown={stockControlMenu}
            highlight={inStockControl}
          >
            Stock Control
          </AppBar.Button>

          {!warehouse && (

            <React.Fragment>

              <AppBar.Button
                dropdown={forecastingMenu}
                highlight={inForecasting}
              >
                Forecasting
              </AppBar.Button>

              <AppBar.Button
                dropdown={manageMenu}
                highlight={inManagement}
              >
                Management
              </AppBar.Button>

            </React.Fragment>

          )}

        </AppBar.Left>


        <AppBar.Right style={{ paddingRight: 20 }}>
          
          <AppBar.Button
            PopoverComponent={StockshotsPopover}
          >
            <Icon type="pound" style={{ fontSize: 20 }} />
          </AppBar.Button>
          
          <AppBar.Button
            dropdown={createMenu}
            dropdownPlacement="right"
          >
            <Icon type="plus" style={{ fontSize: 20 }} />
          </AppBar.Button>
          
          <AppBar.Button
            PopoverComponent={NotificationPopover}
          >
            <Badge dot={hasUnreadNotifications}>
              <Icon type="bell" theme={hasUnreadNotifications ? "filled" : "outlined"} style={{ fontSize: 20 }} />
            </Badge>
          </AppBar.Button>

          <AppBar.Button
            dropdown={userMenu}
            dropdownPlacement="right"
          >
            <Icon type="user" style={{ fontSize: 20 }} />
          </AppBar.Button>

        </AppBar.Right>
        

      </AppBar>

    )
  }
}

const mapStateToProps = (state) => ({
  hasUnreadNotifications: state.modules.NotificationPopover.hasUnread,
  pathname: state.router.location.pathname,
})

export default connect(mapStateToProps)(_AppBar_)