import { push } from 'connected-react-router'
import { message } from 'antd'
import screenProps from '../../../screenProps'
import { createAdjustment, updateAdjustment } from '../../../../../Api/adjustments'
import initialize from './initialize'
import fetch from '../../../Services/fetch'
//import fetchActivity from '../../../../../Modules/Activity/Services/fetch'
import { handleException } from 'Traqa/Packages'
import { getTeamUrl } from 'Traqa/Helpers'

export default (values) => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    const data = {
      stock_location_id: values.stock_location_id,
      description: values.description,
      direction: values.direction,
      date_time: `${values.date.format('YYYY-MM-DD')} ${values.time.format('HH:mm')}:00`,
      adjustment_items: values.adjustment_items.map(item => ({
        id: item.id,
        product_id: item.product_id,
        landed_cost: values.direction === 'Up' ? item.landed_cost : null,
        batch_no: item.batch_no || null,
        expiry_date: values.direction === 'Up' && item.expiry_date ? item.expiry_date.format('YYYY-MM-DD') : null,
        quantity: item.quantity,
        source_product_id: values.direction === 'Down' && item.source_product_id ? item.source_product_id : null,
      })),
    }

    if (id === 'new') {

      const { data: { data: { id: newId } } } = await createAdjustment({ data })

      dispatch(push(getTeamUrl(`adjustments/${newId}`)))

      message.success("Saved")

    } else {

      await updateAdjustment({ id, data })

      await dispatch(fetch())

      await dispatch(initialize())

      //dispatch(fetchActivity())

      message.success("Saved")

    }

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}