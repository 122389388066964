import { connect } from 'react-redux'
import Table from './Table'

import selectTableRows from './Selectors/selectTableRows'

const mapStateToProps = (state) => ({
  tableRows: selectTableRows(state),
  isFetching: state.screens.PlasticTax.Report.isFetching,
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Table)
