import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Table, Icon, Tooltip, Typography } from 'antd'
import moment from 'moment'
import classNames from 'classnames'
import { getTeamUrl } from 'Traqa/Helpers'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    data: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  render() {

    const {
      data,
      isFetching,
      onChange,
    } = this.props

    const formatDateTime = (dateTime) => (
      moment(dateTime).calendar(null, {
        sameDay: '[Today], h:mm a',
        nextDay: '[Tomorrow], h:mm a',
        lastDay: '[Yesterday], h:mm a',
        nextWeek: 'ddd D MMM YYYY [at] h:mm a',
        lastWeek: 'ddd D MMM YYYY [at] h:mm a',
        sameElse: 'ddd D MMM YYYY [at] h:mm a',
      })
    )

    return (

      <Table
        dataSource={data}
        loading={isFetching}
        rowKey='id'
        pagination={false}
        onChange={onChange}
      >

        <Table.Column
          key='id'
          title='No.'
          width={90}
          sorter
          render={(text, record) => (
            <NavLink key={record.id} to={getTeamUrl(`goods_out_records/${record.id}`)}>
              {record.id}
            </NavLink>
          )}
        />

        <Table.Column
          key='stock_location.name'
          title='Stock Location'
          sorter
          render={(text, record) => record.stock_location.name}
        />

        <Table.Column
          key='customer.name'
          title='Customer / Site'
          sorter
          render={(text, record) => {
            if (record.customer.name === record.customer_site.name) {
              return record.customer.name
            }
            return `${record.customer.name} / ${record.customer_site.name}`
          }}
        />

        <Table.Column
          key='is_return'
          width={50}
          render={(text, record) => record.is_return ? (
            <Tooltip title='Return'><Icon type='undo' className={styles.returnIcon} /></Tooltip>
          ) : null}
        />

        <Table.Column
          key='is_printed'
          width={50}
          render={(text, record) => record.is_printed ? (
            <Tooltip title='Printed'><Icon type='printer' className={styles.printerIcon} /></Tooltip>
          ): null}
        />

        <Table.Column
          key='date_time'
          title='Date / Time'
          sorter
          render={(text, record) => (
            <span
              className={classNames({
                [styles.isOverdue]: record.is_overdue,
              })}
            >
              {record.is_overdue && <Icon type='exclamation-circle' style={{ marginRight: '5px' }} />}
              {formatDateTime(record.date_time)}
            </span>
          )}
        />

        <Table.Column
          key='status'
          title='Status'
          width={140}
          sorter
          render={(text, record) => (
            <div className={styles.status}>
              {record.status === 'Draft' && <Icon type='edit' className={classNames(styles.statusIcon, styles.draftIcon)} />}
              {record.status === 'Draft' && 'Draft'}
              {record.status === 'Expected' && <Icon type='clock-circle' className={classNames(styles.statusIcon, styles.expectedIcon)} />}
              {record.status === 'Expected' && 'Scheduled'}
              {record.status === 'Completed' && <Icon type='check-circle' className={classNames(styles.statusIcon, styles.completedIcon)} />}
              {record.status === 'Completed' && 'Dispatched'}
            </div>
          )}
        />

        <Table.Column
          key='invoice_no'
          title='Invoice No.'
          sorter
          render={(text, record) => <Typography.Text type='secondary'>{record.invoice_no}</Typography.Text>}
        />

      </Table>

    )

  }

}
