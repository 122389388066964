import modalProps from '../../../modalProps'
import { createStockLocation, updateStockLocation } from '../../../../../Api/stockLocations'
import { handleException } from 'Traqa/Packages'

export default (values) => async () => {

  try {

    const { stockLocationId } = modalProps()

    const data = {
      name: values.name,
    }

    if (stockLocationId) {

      await updateStockLocation({ id: stockLocationId, data })

    } else {

      await createStockLocation({ data })

    }
    
    modalProps().onClose(true)

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}