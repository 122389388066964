import { connect } from 'react-redux'
import CourierZones from './CourierZones'

import onClickNew from './Handlers/onClickNew'
import onClickRow from './Handlers/onClickRow'

const mapStateToProps = (state) => ({
  data: state.screens.Courier.courier.courier_zones.filter(courierZone => courierZone.courier_zone_id),
})

const actionCreators = {
  onClickNew,
  onClickRow,
}

export default connect(mapStateToProps, actionCreators)(CourierZones)
