import { connect } from 'react-redux'
import ItemsArray from './ItemsArray'

import selectIsNew from './Selectors/selectIsNew'
import selectToggleState from './Selectors/selectToggleState'

import onToggle from './Handlers/onToggle'
import onAddItem from './Handlers/onAddItem'
import onDeleteItems from './Handlers/onDeleteItems'
import onCreateGoodsOut from './Handlers/onCreateGoodsOut'

const mapStateToProps = (state) => ({
  isNew: selectIsNew(state),
  toggleState: selectToggleState(state),
})

const actionCreators = {
  onToggle,
  onAddItem,
  onDeleteItems,
  onCreateGoodsOut,
}

export default connect(mapStateToProps, actionCreators)(ItemsArray)
