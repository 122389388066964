import { createAction, handleActions } from 'redux-actions'

const startFetchingHoldingValue = createAction('SCREENS/DASHBOARD/PIE_CHARTS/START_FETCHING_HOLDING_VALUE')
const finishFetchingHoldingValue = createAction('SCREENS/DASHBOARD/PIE_CHARTS/FINISH_FETCHING_HOLDING_VALUE')

const defaultState = false

const reducer = handleActions(
  {
    [startFetchingHoldingValue]: () => true,
    [finishFetchingHoldingValue]: () => defaultState,
  },
  defaultState,
)

export { startFetchingHoldingValue, finishFetchingHoldingValue, reducer }
