import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, FormSpy, Field } from 'react-final-form'
import { Form, Input, Select, DatePicker, Row, Col, Button } from 'antd'
import { Module, LinkButton, ExposeFormToHelper } from 'Traqa/Packages'
import formHelper from './formHelper'
import styles from './Advanced.css'

const Advanced = (props) => {

  const {
    advanced,
    stockLocations,
    onClickClose,
    onFormChange,
    onSubmit,
  } = props

  const renderHeaderRight = () => (

    <LinkButton
      type="default"
      icon="close"
      onClick={onClickClose}
      style={{ fontSize: 18 }}
    />

  )

  return (

    <Module.Section
      title="Advanced Search"
      renderHeaderRight={renderHeaderRight}
      borderBottom
    >

      <FormProvider
        initialValues={advanced.values}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, submitting }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            autoComplete="off"
          >

            <ExposeFormToHelper helper={formHelper} />

            <FormSpy subscription={{ values: true }} onChange={onFormChange} />

            <Field name="status">
              {({ input }) => (
                <Form.Item label="Status">
                  <Select
                    {...input}
                    disabled={submitting}
                    allowClear
                  >
                    <Select.Option value="Draft">
                      Draft
                    </Select.Option>
                    <Select.Option value="Expected">
                      Scheduled
                    </Select.Option>
                    <Select.Option value="Completed">
                      Completed
                    </Select.Option>
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name="keywords">
              {({ input }) => (
                <Form.Item label="Keywords">
                  <Input.Search
                    {...input}
                    disabled={submitting}
                    onSearch={handleSubmit}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name="originIds">
              {({ input }) => (
                <Form.Item label="Origins">
                  <Select
                    {...input}
                    disabled={submitting}
                    mode="multiple"
                    optionFilterProp="children"
                  >
                    {stockLocations.map(stockLocation => (
                      <Select.Option
                        key={stockLocation.id}
                        value={stockLocation.id}
                      >
                        {stockLocation.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name="destinationIds">
              {({ input }) => (
                <Form.Item label="Destinations">
                  <Select
                    {...input}
                    disabled={submitting}
                    mode="multiple"
                    optionFilterProp="children"
                  >
                    {stockLocations.map(stockLocation => (
                      <Select.Option
                        key={stockLocation.id}
                        value={stockLocation.id}
                      >
                        {stockLocation.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name="dispatchedBetween">
              {({ input }) => (
                <Form.Item label="Dispatched">
                  <DatePicker.RangePicker
                    {...input}
                    disabled={submitting}
                    format="DD/MM/YYYY HH:mm:ss"
                    showTime
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name="completedBetween">
              {({ input }) => (
                <Form.Item label="Completed">
                  <DatePicker.RangePicker
                    {...input}
                    disabled={submitting}
                    format="DD/MM/YYYY HH:mm:ss"
                    showTime
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              )}
            </Field>

            <Row>
              <Col offset={7}>

                <Button
                  type="primary"
                  onClick={handleSubmit}
                >
                  Search
                </Button>

              </Col>
            </Row>

          </Form>
        
        )}
      
      </FormProvider>

    </Module.Section>

  )
}

Advanced.propTypes = {
  advanced: PropTypes.object.isRequired,
  stockLocations: PropTypes.array.isRequired,
  onClickClose: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default Advanced