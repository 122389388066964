import { connect } from 'react-redux'
import Table from './Table'

const mapStateToProps = () => ({
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Table)
