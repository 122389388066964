import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field, FormSpy } from 'react-final-form'
import { Form, Select, InputNumber } from 'antd'
import { ExposeFormToHelper } from 'Traqa/Packages'
import formService from './formService'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    courierId: PropTypes.number.isRequired,
    courierServices: PropTypes.array.isRequired,
    courierZones: PropTypes.array.isRequired,
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  render () {

    const {
      courierId,
      courierServices,
      courierZones,
      onChangeFormState,
      onSubmit,
      validate,
    } = this.props
    
    return (

      <FormProvider
        validate={validate}
        onSubmit={onSubmit}
      >

        {({ handleSubmit, submitting }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            autoComplete='off'
          >

            <ExposeFormToHelper helper={formService} />

            <FormSpy subscription={{ submitting: true }} onChange={onChangeFormState} />

            <Field name='courier_service_id'>
              {({ input, meta }) => (
                <Form.Item
                  label='Service'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Select
                    {...input}
                    disabled={submitting}
                    allowClear
                    showSearch
                    optionFilterProp='children'
                  >
                    {courierServices.filter(courierService => courierService.courier_id === courierId).map(courierService => (
                      <Select.Option key={courierService.id} value={courierService.id}>
                        {courierService.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name='courier_zone_id'>
              {({ input, meta }) => (
                <Form.Item
                  label='Group'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Select
                    {...input}
                    disabled={submitting}
                    allowClear
                    showSearch
                    optionFilterProp='children'
                  >
                    {courierZones.filter(courierZone => courierZone.courier_id === courierId && !courierZone.courier_zone_id).map(courierZone => (
                      <Select.Option key={courierZone.id} value={courierZone.id}>
                        {courierZone.code}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name='quantity'>
              {({ input, meta }) => (
                <Form.Item
                  label='Qty'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <InputNumber
                    {...input}
                    disabled={submitting}
                    min={0}
                    step={1}
                    style={{ width: '40%' }}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='pallet_full_rate'>
              {({ input, meta }) => (
                <Form.Item
                  label='Pallet'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <InputNumber
                    {...input}
                    formatter={value => `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/£\s?|(,*)/g, '')}
                    disabled={submitting}
                    min={0}
                    step={1}
                    precision={2}
                    style={{ width: '40%' }}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='pallet_half_rate'>
              {({ input, meta }) => (
                <Form.Item
                  label='1/2 Pallet'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <InputNumber
                    {...input}
                    formatter={value => `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/£\s?|(,*)/g, '')}
                    disabled={submitting}
                    min={0}
                    step={1}
                    precision={2}
                    style={{ width: '40%' }}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='pallet_quarter_rate'>
              {({ input, meta }) => (
                <Form.Item
                  label='1/4 Pallet'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <InputNumber
                    {...input}
                    formatter={value => `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/£\s?|(,*)/g, '')}
                    disabled={submitting}
                    min={0}
                    step={1}
                    precision={2}
                    style={{ width: '40%' }}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='pallet_micro_rate'>
              {({ input, meta }) => (
                <Form.Item
                  label='Micro Pallet'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <InputNumber
                    {...input}
                    formatter={value => `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/£\s?|(,*)/g, '')}
                    disabled={submitting}
                    min={0}
                    step={1}
                    precision={2}
                    style={{ width: '40%' }}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='parcel_rate'>
              {({ input, meta }) => (
                <Form.Item
                  label='Parcel'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <InputNumber
                    {...input}
                    formatter={value => `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/£\s?|(,*)/g, '')}
                    disabled={submitting}
                    min={0}
                    step={1}
                    precision={2}
                    style={{ width: '40%' }}
                  />
                </Form.Item>
              )}
            </Field>

          </Form>

        )}

      </FormProvider>

    )
    
  }

}
