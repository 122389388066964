import { createSelector } from 'reselect'
import { find } from 'lodash'

const selectStatus = state => state.screens.Assembly.assembly.status
const selectAvailabilities = state => state.screens.Assembly.assembly.availabilities
const selectProductId = (state, props) => props.productId

const createAvailabilitySelector = () => createSelector(
  selectStatus,
  selectAvailabilities,
  selectProductId,
  (status, availabilities, productId) => {
    
    if (status !== 'Draft') return null
    if (!availabilities || availabilities.length === 0) return null
    if (!productId) return null
    
    return find(availabilities, { product_id: Number(productId) })
  }
)

export default createAvailabilitySelector