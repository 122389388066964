import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Screen from '../../Components/Screen'

import Report from './Components/Report'
import TransactionsDrawer from './Components/TransactionsDrawer'

const StockAdjustments = props => {

  const {
    transactionsDrawer,
    componentDidMount,
    componentWillUnmount,
    onCloseTransactionsDrawer,
  } = props

  useEffect(() => {
    componentDidMount()
    return () => componentWillUnmount()
  }, [])

  return (

    <Screen
      title='Stock Adjustments'
    >

      <Report />

      {transactionsDrawer.show && (

        <TransactionsDrawer
          {...transactionsDrawer}
          onClose={onCloseTransactionsDrawer}
        />

      )}

    </Screen>

  )

}

StockAdjustments.propTypes = {
  transactionsDrawer: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
  onCloseTransactionsDrawer: PropTypes.func.isRequired,
}

export default StockAdjustments
