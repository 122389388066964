import { resetData } from '../State/data'
import { resetFetching } from '../State/isFetching'

export default () => (dispatch) => {
  
  dispatch(resetData())
  
  dispatch(resetFetching())

}
