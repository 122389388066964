import { getProducts } from '../../../../../Api/products'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { setPagination } from '../State/pagination'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { advanced, activeTab, searchTerm, sorting, pagination } = getState().screens.Products.ProductsTable

    const params = {}

    params.include = [
      'product_categories',
      'product_values',
    ].join()

    if (advanced.show) {

      const {
        keywords,
        productCategoryIds,
        productValueChangedSince,
        withArchived,
      } = advanced.values

      if (keywords) params.search = keywords

      if (productCategoryIds) params['filter[product_category_id]'] = productCategoryIds.join(',')

      if (productValueChangedSince) params['filter[product_value_changed_since]'] = productValueChangedSince.format('YYYY-MM-DD')

      if (!withArchived) params['filter[unarchived_outers_only]'] = 1

    } else {

      if (activeTab === 'Active') params['filter[unarchived_outers_only]'] = 1
  
      if (activeTab === 'Archived') params['filter[archived_outers_only]'] = 1

      if (searchTerm) params.search = searchTerm

    }

    params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : 'code'
    
    params['page[number]'] = pagination.pageNumber
    params['page[size]'] = pagination.pageSize

    dispatch(startFetching())

    const response = await getProducts({ params })

    dispatch(setData(response.data.data))
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
    }))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }
}