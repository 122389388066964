import React from 'react'
import { Modal, Icon } from 'antd'
import duplicateGoodsInRecord from '../../../Services/duplicateGoodsInRecord'

export default (id) => async (dispatch) => {

  Modal.confirm({
    title: 'Duplicate Planned Purchase?',
    content: 'This includes all of its products.',
    icon: <Icon type='copy' style={{ color: '#1890ff' }} />,
    okType: 'primary',
    okText: 'Duplicate',
    onOk: () => dispatch(duplicateGoodsInRecord(id)),
  })

}
