import { push } from 'connected-react-router'
import { message } from 'antd'
import screenProps from '../../../screenProps'
import { createGoodsInRecord, updateGoodsInRecord } from '../../../../../Api/goodsInRecords'
import initialize from './initialize'
import fetch from '../../../Services/fetch'
//import fetchActivity from '../../../../../Modules/Activity/Services/fetch'
import { getTeamUrl } from 'Traqa/Helpers'
import { handleException } from 'Traqa/Packages'

export default (values) => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    const data = {
      supplier_id: values.supplier_id,
      stock_location_id: values.stock_location_id,
      date_time: `${values.date.format('YYYY-MM-DD')} ${values.time.format('HH:mm')}:00`,
      user_id: values.user_id,
      invoice_no: values.invoice_no || null,
      is_return: values.is_return,
      goods_in_record_items: values.goods_in_record_items.map(item => ({
        id: item.id,
        product_id: item.product_id,
        batch_no: item.batch_no || null,
        expiry_date: item.expiry_date ? item.expiry_date.format('YYYY-MM-DD') : null,
        quantity: item.quantity,
        blend: item.blend || null,
        recycled_perc: item.recycled_perc,
        weight_kgs: item.weight_kgs,
        purchase_order_id: item.purchase_order_id || null,
      })),
    }

    if (id === 'new') {

      const { data: { data: { id: newId } } } = await createGoodsInRecord({ data })

      dispatch(push(getTeamUrl(`goods_in_records/${newId}`)))

      message.success("Saved")

    } else {

      await updateGoodsInRecord({ id, data })

      await dispatch(fetch())

      await dispatch(initialize())

      //dispatch(fetchActivity())

      message.success("Saved")

    }

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}