import { closeCourierModal } from '../State/courierModal'
import fetch from '../Services/fetch'

export default (shouldReload = false) => (dispatch) => {
  
  dispatch(closeCourierModal())
  
  if (shouldReload) dispatch(fetch())
  
}
