import { closeGoodsInRecordItemModal } from '../State/goodsInRecordItemModal'
import fetch from '../Components/Report/Services/fetch'

export default (shouldReload = false) => (dispatch) => {
  
  dispatch(closeGoodsInRecordItemModal())

  if (shouldReload) {
    dispatch(fetch())
  }
  
}
