import chance from 'chance'
import formService from '../formService'

export default (productId) => () => {

  const item = {
    guid: chance().guid(),
    product_id: productId,
  }

  formService().mutators.push('stocktake_items', item)

}