import { find } from 'lodash'
import getTeams from './getTeams'
import getCurrentTeam from './getCurrentTeam'

const isWarehouse = () => {
  
  const team = find(getTeams(), (t) => t.id === getCurrentTeam().id)
  
  return team && !!team.pivot.warehouse
  
}

export default isWarehouse
