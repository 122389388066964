import modalProps from '../../../modalProps'
import { createCourierZone, updateCourierZone } from '../../../../../Api/courierZones'
import { handleException } from 'Traqa/Packages'

export default (values) => async () => {

  const { id, courierId } = modalProps()

  const data = {
    code: values.code,
    parent_courier_zone_code: values.parent_courier_zone_code,
  }

  try {

    if (id) {

      await updateCourierZone({ id, data })

    } else {

      data.courier_id = courierId

      await createCourierZone({ data })

    }
    
    modalProps().onClose(true)

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
