import { push } from 'connected-react-router'
import { message } from 'antd'
import screenProps from '../../../screenProps'
import { createTransfer, updateTransfer } from '../../../../../Api/transfers'
import initialize from './initialize'
import fetch from '../../../Services/fetch'
//import fetchActivity from '../../../../../Modules/Activity/Services/fetch'
import { getTeamUrl } from 'Traqa/Helpers'
import { handleException } from 'Traqa/Packages'

export default (values) => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    const data = {
      reference: values.reference,
      origin_id: values.origin_id,
      destination_id: values.destination_id,
      user_id: values.user_id,
      dispatched_at: `${values.dispatched_date.format('YYYY-MM-DD')} ${values.dispatched_time.format('HH:mm')}:00`,
      completed_at: `${values.completed_date.format('YYYY-MM-DD')} ${values.completed_time.format('HH:mm')}:00`,
      transfer_items: values.transfer_items.map(item => ({
        id: item.id,
        product_id: item.product_id,
        batch_no: item.batch_no || null,
        quantity: item.quantity,
        source_product_id: item.source_product_id || null,
      })),
    }

    if (id === 'new') {

      const { data: { data: { id: newId } } } = await createTransfer({ data })

      dispatch(push(getTeamUrl(`transfers/${newId}`)))

      message.success("Saved")

    } else {

      await updateTransfer({ id, data })

      await dispatch(fetch())

      await dispatch(initialize())

      //dispatch(fetchActivity())

      message.success("Saved")

    }

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}