import { createAction, handleActions } from 'redux-actions'

const setInitialValues = createAction("SCREENS/ADJUSTMENT/EDITOR/SET_INITIAL_VALUES")
const resetInitialValues = createAction("SCREENS/ADJUSTMENT/EDITOR/RESET_INITIAL_VALUES")

const defaultState = {}

const reducer = handleActions(
  {
    [setInitialValues]: (state, action) => action.payload,
    [resetInitialValues]: () => defaultState,
  },
  defaultState
)

export { setInitialValues, resetInitialValues, reducer }
