import React from 'react'
import { Tag } from 'antd'
import { find } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import { openTransactionsDrawer } from '../../../../../State/transactionsDrawer'

export default (record) => (dispatch, getState) => {

  const { stocktake } = getState().screens.Reconciliation

  const { formState: { values: { options } } } = getState().screens.Reconciliation.Report

  const { previousStocktakes, formState: { values: { openingStocktakeId } } } = getState().screens.Reconciliation.Report

  const openingStocktake = find(previousStocktakes, previousStocktake => previousStocktake.id === openingStocktakeId)

  const aggregate = options.includes('aggregate')

  const productId = aggregate ? [
    record.id,
    ...record.divides_into_products.map(row => row.id),
    ...record.division_of_products.map(row => row.id),
  ].join() : record.id

  const title = (
    <div>
      {`${record.code} - ${record.name}`}
      <Tag color='volcano' style={{ marginLeft: 5 }}>{record.form}</Tag>
    </div>
  )

  let subtitle = `${moment(openingStocktake.date_time).format('MMM Do YY')} - ${moment(stocktake.date_time).format('MMM Do YY')}`

  subtitle += ` | From: ${numeral(record.opening_quantity).format('0,0.[00]')} to ${numeral(record.expected_quantity).format('0,0.[00]')} | Closing: ${numeral(record.closing_quantity).format('0,0.[00]')}`

  const params = {
    'filter[is_allocation]': false,
    'filter[is_expectation]': false,
    'filter[is_reading]': false,
    'filter[stock_location_id]': stocktake.stock_location_id,
    'filter[product_id]': productId,
    'filter[transaction_at_in_between]': `${openingStocktake.date_time},${stocktake.date_time}`,
  }

  if (aggregate) {
    params['filter[is_breakout]'] = 0
  }
  
  dispatch(openTransactionsDrawer({ title, subtitle, withProductColumn: aggregate, params }))

}
