import React from 'react'
import PropTypes from 'prop-types'
import { LinkButton } from 'Traqa/Packages'
import styles from './Component.css'

const Header = (props) => {

  const {
    filter,
    onToggleFilter,
  } = props

  return (

    <div className={styles.bar}>
      
      <div className={styles.title}>Notifications</div>
      
      <LinkButton onClick={onToggleFilter} type="primary">
        {filter === 'UNREAD' ? 'Show all' : 'Filter by unread'}
      </LinkButton>
      
    </div>
    
  )
}

Header.propTypes = {
  filter: PropTypes.string.isRequired,
  onToggleFilter: PropTypes.func.isRequired,
}

export default Header