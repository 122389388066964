import { defer } from 'lodash'
import modalProps from '../../../modalProps'
import formService from '../formService'
import { getCustomerSite } from '../../../../../Api/customerSites'
import { handleException } from 'Traqa/Packages'

export default () => async () => {

  try {

    const { customerSiteId } = modalProps()

    if (customerSiteId) {

      const response = await getCustomerSite({ id: customerSiteId })

      const customerSite = response.data.data

      const initialValues = {
        name: customerSite.name,
        delivery_notes: customerSite.delivery_notes,
        requires_tail_lift: !!customerSite.requires_tail_lift,
      }
    
      defer(() => formService().initialize(initialValues))

    } else {

      const initialValues = {
        requires_tail_lift: false,
      }
    
      defer(() => formService().initialize(initialValues))

    }

  } catch (error) {

    handleException(error)
  }

}