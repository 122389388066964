import React from 'react'
import PropTypes from 'prop-types'
import { Table as AntdTable, Icon } from 'antd'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import styles from './Table.css'

const Table = (props) => {

  const {
    data,
    isFetching,
    onChange,
    onClickRow,
  } = props
  
  return (

    <AntdTable
      dataSource={data}
      loading={isFetching}
      rowKey='id'
      pagination={false}
      onChange={onChange}
      className={styles.table}
      onRow={(record) => ({
        onClick: () => onClickRow(record),
      })}
    >

      <AntdTable.Column
        key='id'
        title="No."
        width={90}
        sorter
        render={(text, record) => <a>{record.id}</a>}
      />

      <AntdTable.Column
        key='stock_location.name'
        title='Location'
        width={200}
        sorter
        render={(text, record) => record.stock_location.name}
      />

      <AntdTable.Column
        key='description'
        title='Description'
        sorter
        render={(text, record) => record.description}
      />

      <AntdTable.Column
        key='date_time'
        title='Date / Time'
        sorter
        render={(text, record) => moment(record.date_time).calendar(null, {
          sameDay: '[Today], h:mm a',
          nextDay: '[Tomorrow], h:mm a',
          lastDay: '[Yesterday], h:mm a',
          nextWeek: 'ddd D MMM YYYY [at] h:mm a',
          lastWeek: 'ddd D MMM YYYY [at] h:mm a',
          sameElse: 'ddd D MMM YYYY [at] h:mm a',
        })}
      />

      <AntdTable.Column
        key='updated_by_user.name'
        title='Last Updated By'
        sorter
        render={(text, record) => record.updated_by_user.name}
      />

    </AntdTable>

  )
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClickRow: PropTypes.func.isRequired,
}

export default Table