import screenProps from '../screenProps'
import { updatePurchaseOrderStatus } from '../../../Api/purchaseOrders'
import { setPurchaseOrder } from '../State/purchaseOrder'
import { handleException } from 'Traqa/Packages'
import fetch from './fetch'

export default (status) => async (dispatch, getState) => {

  try {

    dispatch(setPurchaseOrder({
      ...getState().screens.PurchaseOrder.purchaseOrder,
      status
    }))

    const { id } = screenProps().match.params

    const data = { status }

    await updatePurchaseOrderStatus({ id, data })

    await dispatch(fetch())

  } catch (error) {

    handleException(error)

  }
}