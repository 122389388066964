import { connect } from 'react-redux'
import LinkConsignment from './LinkConsignment'

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'

const mapStateToProps = () => ({
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
}

export default connect(mapStateToProps, actionCreators)(LinkConsignment)
