import { connect } from 'react-redux'
import CourierDetails from './CourierDetails'

import selectCourierServices from './Selectors/selectCourierServices'
import selectCourierZones from './Selectors/selectCourierZones'

const mapStateToProps = (state) => ({
  couriers: state.core.couriers,
  courierServices: selectCourierServices(state),
  courierZones: selectCourierZones(state),
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(CourierDetails)
