import { closePlasticTaxRateModal } from '../State/plasticTaxRateModal'
import fetchPlasticTaxRates from '../../../../../Core/Services/fetchPlasticTaxRates'
import fetch from '../Services/fetch'

export default (reload = false) => (dispatch) => {

  dispatch(closePlasticTaxRateModal())

  if (reload) {
    dispatch(fetchPlasticTaxRates({ force: true }))
    dispatch(fetch())
  }

}
