import { combineReducers } from "redux"

import { reducer as initialized } from "./State/initialized"
import { reducer as transfer } from "./State/transfer"

import Editor from "./Components/Editor/reducer"

export default combineReducers({
  initialized,
  transfer,
  Editor,
})
