import { combineReducers } from 'redux'

import { reducer as data } from './State/data'
import { reducer as isFetching } from './State/isFetching'
import { reducer as controls } from './State/controls'
import { reducer as interaction } from './State/interaction'

export default combineReducers({
  data,
  isFetching,
  controls,
  interaction,
})
