import { getTeamUrl } from 'Traqa/Helpers'

export default () => async (dispatch, getState) => {

  const { activeTab, searchTerm, sorting } = getState().screens.ProductCategories.ProductCategoriesTable

  let path = `product_categories/export?`

  if (activeTab === 'Active') {
    path = `${path}&filter[without_archived]=1`
  }

  if (activeTab === 'Archived') {
    path = `${path}&filter[only_archived]=1`
  }

  if (searchTerm) {
    path = `${path}&search=${searchTerm}`
  }

  path = `${path}&sort=${sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : 'name'}`

  window.location = getTeamUrl(path, 'exports')

}
