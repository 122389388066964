export default (values) => () => {

  const errors = {}

  if (!values.stock_location_id) errors.stock_location_id = 'Required'

  if (!values.date) errors.date = 'Required'

  if (!values.time) errors.time = 'Required'

  if (!values.user_id) errors.user_id = 'Required'

  if (!values.status) errors.status = 'Required'
  
  return errors

}
