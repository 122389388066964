import { find } from 'lodash'
import getTeams from './getTeams'
import getCurrentTeam from './getCurrentTeam'

const isAdmin = () => {
  
  const team = find(getTeams(), (t) => t.id === getCurrentTeam().id)
  
  return team.pivot.role === 'admin' || team.pivot.role === 'owner'
  
}

export default isAdmin
