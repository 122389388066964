import { combineReducers } from "redux"

import { reducer as initialized } from "./State/initialized"
import { reducer as imageViewerModal } from "./State/imageViewerModal"

import Form from './Components/Form/reducer'

export default combineReducers({
  initialized,
  imageViewerModal,
  
  Form,
})
