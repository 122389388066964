import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field, FormSpy } from 'react-final-form'
import { Form, Select, DatePicker, TimePicker } from 'antd'
import { ExposeFormToHelper } from 'Traqa/Packages'
import formService from './formService'
import styles from './Component.css'

export default class _Form_ extends React.PureComponent {

  static propTypes = {
    stockLocations: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  render () {

    const {
      stockLocations,
      users,
      onChangeFormState,
      onSubmit,
      validate,
    } = this.props

    const initialValues = {
      date: null,
      time: null,
    }
    
    return (

      <FormProvider
        validate={validate}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, submitting }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            autoComplete="off"
          >

            <ExposeFormToHelper helper={formService} />

            <FormSpy subscription={{ submitting: true }} onChange={onChangeFormState} />

            <Field name="stock_location_id">
              {({ input, meta }) => (
                <Form.Item
                  label="Location"
                  required
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Select
                    {...input}
                    disabled={submitting}
                    optionFilterProp="children"
                    showSearch
                    allowClear
                  >
                    <Select.OptGroup label='Active'>
                      {stockLocations.filter(stockLocation => !stockLocation.archived_at).map(stockLocation => (
                        <Select.Option key={stockLocation.id} value={stockLocation.id}>
                          {stockLocation.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                    <Select.OptGroup label='Archived'>
                      {stockLocations.filter(stockLocation => !!stockLocation.archived_at).map(stockLocation => (
                        <Select.Option key={stockLocation.id} value={stockLocation.id} style={{ color: '#999' }}>
                          {stockLocation.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name='date' allowNull>
              {({ input, meta }) => (
                <Form.Item
                  label='Date'
                  required
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <DatePicker
                    {...input}
                    disabled={submitting}
                    format='DD/MM/YYYY'
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='time' allowNull>
              {({ input, meta }) => (
                <Form.Item
                  label='Time'
                  required
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <TimePicker
                    {...input}
                    disabled={submitting}
                    format='HH:mm'
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name="user_id">
              {({ input, meta }) => (
                <Form.Item
                  label="User"
                  required
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Select
                    {...input}
                    disabled={submitting}
                    optionFilterProp="children"
                    showSearch
                    allowClear
                  >
                    {users.map(user => (
                      <Select.Option key={user.id} value={user.id}>
                        {user.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Field>

          </Form>

        )}

      </FormProvider>

    )
  }
}