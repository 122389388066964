export default (state) => {

  const { filters } = state.screens.PlasticTax.Report

  return {
    search: filters.search || undefined,
    'filter[supplier_id]': filters.supplierId.join(','),
    'filter[country_code]': filters.countryCode.join(','),
    'filter[status]': filters.status.join(','),
    'filter[overdue]': filters.overdue || undefined,
    'filter[date_between]': filters.dateBetween.map(date => date.format('YYYY-MM-DD')).join(','),
    sort: 'date_time',
  }

}
