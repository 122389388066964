import React from 'react'
import PropTypes from 'prop-types'
import { Table, Tag, Typography } from 'antd'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import { getTeamUrl } from 'Traqa/Helpers'
import styles from './styles.css'

const formatDateTime = (dateTime) => (
  moment(dateTime).calendar(null, {
    sameDay: '[Today], h:mm a',
    nextDay: '[Tomorrow], h:mm a',
    lastDay: '[Yesterday], h:mm a',
    nextWeek: 'ddd D MMM YYYY [at] h:mm a',
    lastWeek: 'ddd D MMM YYYY [at] h:mm a',
    sameElse: 'ddd D MMM YYYY [at] h:mm a',
  })
)

export default class extends React.PureComponent {

  static propTypes = {
    productId: PropTypes.number,
    withDivisionProducts: PropTypes.bool,
    data: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    withDivisionProducts: false,
  }

  render() {

    const {
      productId,
      withDivisionProducts,
      data,
      isFetching,
      onChange,
    } = this.props

    return (

      <Table
        dataSource={data}
        loading={isFetching}
        rowKey='id'
        pagination={false}
        onChange={onChange}
      >

        <Table.Column
          key='owner_type'
          title='Type'
          sorter
          render={(text, record) => (
            <NavLink to={getTeamUrl(`${record.owner_type_slug}/${record.owner_id}`)}>
              {record.owner_type_formatted}
            </NavLink>
          )}
        />

        <Table.Column
          key='stock_location.name'
          title='Location'
          sorter
          render={(text, record) => record.stock_location.name}
        />

        {(!productId || withDivisionProducts) && (

          <Table.Column
            key='product'
            title='Product'
            width={350}
            onHeaderCell={() => ({
              className: styles.headerCell,
            })}
            onCell={() => ({
              className: styles.cell,
            })}
            render={(text, record) => (
              <div>
                {`${record.product.code} - ${record.product.name}`}
                <Tag color='volcano' style={{ marginLeft: 5 }}>{record.product.form}</Tag>
              </div>
            )}
          />

        )}

        <Table.Column
          key='quantity'
          title='Qty'
          sorter
          className={styles.numericColumn}
          render={(text, record) => record.quantity_formatted}
        />

        <Table.Column
          key='details'
          title='Details'
          render={(text, record) => <Typography.Text type='secondary'>{record.details}</Typography.Text>}
        />

        <Table.Column
          key='batch_no'
          title='Batch No.'
          sorter
          render={(text, record) => <Typography.Text type='secondary'>{record.batch_no}</Typography.Text>}
        />

        <Table.Column
          key='transaction_at'
          title='Date / Time'
          width={250}
          sorter
          render={(text, record) => formatDateTime(record.transaction_at)}
        />

      </Table>

    )

  }
  
}
