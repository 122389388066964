import { connect } from 'react-redux'
import Details from './Details'

import selectCustomerSites from './Selectors/selectCustomerSites'

const mapStateToProps = (state) => ({
  customers: state.core.customers,
  customerSites: selectCustomerSites(state),
  users: state.core.users,
  isNew: !state.screens.SalesOrder.salesOrder,
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Details)
