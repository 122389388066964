import React from 'react'
import PropTypes from 'prop-types'
import { List, Icon } from 'antd'
import moment from 'moment'

export default class extends React.PureComponent {

  static propTypes = {
    data: PropTypes.array.isRequired,
    onClickLink: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
  }

  render () {

    const {
      data,
      onClickLink,
      onClickNew,
    } = this.props
    
    return (

      <div>

        <List
          itemLayout='horizontal'
          dataSource={data}
          bordered
          renderItem={consignment => (
            <List.Item
              actions={[<a onClick={() => onClickLink(consignment.id)}>Link</a>]}
            >
              <List.Item.Meta
                title={`Consignment ${consignment.id}`}
                description={(
                  <div>
                    <div>{`Date: ${moment(consignment.date).format('Do MMMM YYYY')}`}</div>
                    {!consignment.customer_site && (
                      <div>{`To: ${consignment.customer.name}`}</div>
                    )}
                    {consignment.customer_site && (
                      <div>{`To: ${consignment.customer.name}${consignment.customer_site.name !== consignment.customer.name ? ` / ${consignment.customer_site.name}` : ''}`}</div>
                    )}
                    <div>{`Courier: ${consignment.courier ? consignment.courier.name : 'Collection'}${consignment.courier_service ? ` / ${consignment.courier_service.name}` : ''}`}</div>
                  </div>
                )}
              />
            </List.Item>
          )}
        />

        <br />

        <List
          itemLayout='horizontal'
          bordered
        >
          <List.Item
            onClick={onClickNew}
            style={{ cursor: 'pointer' }}
          >
            <List.Item.Meta
              title={(
                <div
                  style={{ color: '#1890ff' }}
                >
                  <Icon type='plus' />
                  {' New Consignment'}
                </div>
              )}
            />
          </List.Item>
        </List>

      </div>

    )

  }

}
