import { createSelector } from 'reselect'

const selectData = state => state.modules.Activity.data
const selectExpandedActivities = state => state.modules.Activity.expandedActivities
const selectExpandedLogs = state => state.modules.Activity.expandedLogs

export default createSelector(
  selectData,
  selectExpandedActivities,
  selectExpandedLogs,
  (data, expandedActivities, expandedLogs) => {

    const listRows = []

    data.forEach(activity => {
      listRows.push({
        type: 'ACTIVITY',
        activity,
      })
      if (expandedActivities.indexOf(activity.key) !== -1) {
        activity.logs.forEach(log => {
          listRows.push({
            type: 'LOG',
            log,
          })
          if (expandedLogs.indexOf(log.id) !== -1) {
            listRows.push({
              type: 'PROPERTIES',
              log,
              properties: log.properties_formatted,
            })
          }
        })
      }
    })

    return listRows

  }

)
