import { teamApi as axios } from './axios';

export const getProducts = ({ params } = {}) => axios.get(`products`, { params });
export const getProduct = ({ params, id } = {}) => axios.get(`products/${id}`, { params });
export const createProduct = ({ data }) => axios.post(`products`, data);
export const updateProduct = ({ id, data }) => axios.put(`products/${id}`, data);
export const deleteProduct = ({ id }) => axios.delete(`products/${id}`);
export const archiveProduct = ({ id }) => axios.put(`products/${id}/archive`);
export const unarchiveProduct = ({ id }) => axios.put(`products/${id}/unarchive`);
export const getProductYearSummary = ({ id } = {}) => axios.get(`products/${id}/year_summary`);
export const getProductLiveAssessment = ({ id } = {}) => axios.get(`products/${id}/live_assessment`);
export const updateProductDivisionContext = ({ id, data }) => axios.put(`products/${id}/division_context`, data);