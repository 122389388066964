import { connect } from 'react-redux'
import Created from './Created'

const mapStateToProps = () => ({
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Created)
