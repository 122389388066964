import { combineReducers } from "redux"

import { reducer as userModal } from './State/userModal'

import UsersTable from './Components/UsersTable/reducer'

export default combineReducers({
  userModal,
  
  UsersTable,
})
