import { createAction, handleActions } from 'redux-actions'

const startFetchingTotals = createAction('SCREENS/STOCK_ASSESSMENT/REPORT/START_FETCHING_TOTALS')
const finishFetchingTotals = createAction('SCREENS/STOCK_ASSESSMENT/REPORT/FINISH_FETCHING_TOTALS')
const resetFetchingTotals = createAction('SCREENS/STOCK_ASSESSMENT/REPORT/RESET_FETCHING_TOTALS')

const defaultState = false

const reducer = handleActions(
  {
    [startFetchingTotals]: () => true,
    [finishFetchingTotals]: () => false,
    [resetFetchingTotals]: () => defaultState,
  },
  defaultState,
)

export { startFetchingTotals, finishFetchingTotals, resetFetchingTotals, reducer }
