import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'

import Form from './Components/Form'

const PlasticTaxRate = props => {

  const {
    plasticTaxRate,
    submitting,
    componentDidMount,
    componentWillUnmount,
    onSubmit,
    onDelete,
    onClose,
  } = props

  useEffect(() => {
    componentDidMount(props)
    return componentWillUnmount
  }, [])

  const footer = []

  if (plasticTaxRate) {
    footer.push(
      <Button key='delete' type='danger' onClick={onDelete} disabled={submitting} style={{ float: 'left' }}>
        Delete
      </Button>,
    )
  }

  footer.push(
    <Button key='cancel' onClick={() => onClose()} disabled={submitting}>
      Cancel
    </Button>,
    <Button key='save' type='primary' onClick={onSubmit} loading={submitting}>
      {plasticTaxRate ? 'Update' : 'Create'}
    </Button>,
  )

  return (

    <Modal
      title={plasticTaxRate ? 'New Tax Rate' : 'Tax Rate'}
      width={500}
      visible
      maskClosable={false}
      closable={!submitting}
      footer={footer}
      onCancel={() => onClose()}
    >
      
      <Form />

    </Modal>

  )

}

PlasticTaxRate.propTypes = {
  plasticTaxRate: PropTypes.object,
  submitting: PropTypes.bool.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

PlasticTaxRate.defaultProps = {
  plasticTaxRate: null,
}

export default PlasticTaxRate
