import { connect } from 'react-redux'
import Component from './Component'

import onChangeFormState from './Handlers/onChangeFormState'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'

const mapStateToProps = (state) => ({
  users: state.core.users,
})

const actionCreators = {
  onChangeFormState,
  onSubmit,
  validate,
}

export default connect(mapStateToProps, actionCreators)(Component)
