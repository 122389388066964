import moment from 'moment'
import { getCourierZones } from '../../Api/courierZones'
import { loadCourierZones } from '../State/courierZones'

let lastGetTimestamp = null

const cacheTTL = 60000

export default ({ force } = {}) => async (dispatch) => {

  try {

    if (!force && lastGetTimestamp) {

      const now = Number(moment().format('x'))

      if (now < (lastGetTimestamp + cacheTTL)) return
    }
      
    lastGetTimestamp = Number(moment().format('x'))

    const params = {
      sort: 'code',
    }

    const response = await getCourierZones({ params })

    dispatch(loadCourierZones(response.data.data))

  } catch (error) {
    
    throw error

  }

}
