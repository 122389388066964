import { connect } from 'react-redux'
import Header from './Header'

import onChangeTab from './Handlers/onChangeTab'
import onChangeSearch from './Handlers/onChangeSearch'
import onSearch from './Handlers/onSearch'
import onClickAdvanced from './Handlers/onClickAdvanced'
import onChangeHideInSage from './Handlers/onChangeHideInSage'

const mapStateToProps = (state) => ({
  activeTab: state.screens.GoodsInRecords.Table.activeTab,
  searchTerm: state.screens.GoodsInRecords.Table.searchTerm,
  hideInSage: state.screens.GoodsInRecords.Table.hideInSage,
})

const actionCreators = {
  onChangeTab,
  onChangeSearch,
  onSearch,
  onClickAdvanced,
  onChangeHideInSage,
}

export default connect(mapStateToProps, actionCreators)(Header)
