import modalProps from '../../../modalProps'
import { createProductCategory, updateProductCategory } from '../../../../../Api/productCategories'
import { handleException } from 'Traqa/Packages'

export default (values) => async () => {

  try {

    const { productCategoryId } = modalProps()

    const data = {
      name: values.name,
    }

    if (productCategoryId) {

      await updateProductCategory({ id: productCategoryId, data })

    } else {

      await createProductCategory({ data })

    }
    
    modalProps().onClose(true)

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}