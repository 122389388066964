import { push } from 'connected-react-router'
import { getTeamUrl } from 'Traqa/Helpers'

export default (tab) => (dispatch, getState) => {

  const { courier } = getState().screens.Courier

  dispatch(push(getTeamUrl(`couriers/${courier.id}?tab=${tab}`)))

}
