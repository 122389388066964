import screenProps from '../screenProps'
import { findConsignment } from '../../../Api/consignments'
import { setConsignment } from '../State/consignment'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    if (id === 'new') return

    const response = await findConsignment({ id })

    dispatch(setConsignment(response.data.data))

  } catch (error) {

    handleException(error)

  }

}
