import React from 'react'
import { Modal, Icon } from 'antd'
import duplicate from '../../../Services/duplicate'

export default () => async (dispatch) => {

  Modal.confirm({
    title: 'Duplicate PO?',
    content: 'This will copy all of the PO\'s details into a new one.',
    icon: <Icon type='copy' />,
    okType: 'primary',
    okText: 'Duplicate',
    onOk: () => dispatch(duplicate()),
  })

}
