import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'

import List from './Components/List'

export default class extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {

    const {
      onClose,
    } = this.props

    return (

      <Modal
        visible
        title='Select Consignment'
        width={600}
        onCancel={onClose}
        footer={false}
      >
        
        <List />

      </Modal>
      
    )

  }

}
