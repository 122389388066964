import chance from 'chance'
import moment from 'moment'
import { defer } from 'lodash'
import screenProps from '../../../screenProps'
import { getAssembly } from '../../../../../Api/assemblies'
import { setInitialValues } from '../State/initialValues'
import { handleException } from 'Traqa/Packages'
import { getUser } from 'Traqa/Helpers'
import formService from '../formService'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    if (id === 'new') {

      const initialValues = {
        date: null,
        time: null,
        user_id: getUser().id,
        assembly_items: []
      }

      dispatch(setInitialValues(initialValues))

      defer(() => formService().initialize(initialValues))

    } else {

      const {
        data: {
          data: assembly
        }
      } = await getAssembly({
        id,
        params: {
          include: 'assembly_items'
        }
      })

      const dateTime = moment(assembly.date_time)

      const initialValues = {
        product_id: assembly.product_id,
        quantity: assembly.quantity,
        stock_location_id: assembly.stock_location_id,
        date: dateTime,
        time: dateTime,
        user_id: assembly.user_id,
        batch_no: assembly.batch_no,
        expiry_date: assembly.expiry_date ? moment(assembly.expiry_date) : null,
        packing_cost: assembly.packing_cost,
        transfer_cost: assembly.transfer_cost,
        assembly_items: assembly.assembly_items.map(item => ({
          guid: chance().guid(),
          id: item.id,
          product_id: item.product_id,
          batch_no: item.batch_no,
          quantity: item.quantity,
        }))
      }

      dispatch(setInitialValues(initialValues))

      defer(() => formService().initialize(initialValues))

    } 

  } catch (error) {

    handleException(error)

  }
}