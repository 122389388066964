import { closeProductCategoryModal } from '../State/productCategoryModal'
import fetchProductCategoriesTable from '../Components/ProductCategoriesTable/Services/fetch'
import fetchProductCategoriesCore from '../../../Core/Services/fetchProductCategories'

export default (shouldReload = false) => (dispatch) => {
  
  dispatch(closeProductCategoryModal())

  if (shouldReload === true) {
    dispatch(fetchProductCategoriesTable())
    dispatch(fetchProductCategoriesCore({ force: true }))
  }
  
}