import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import prettyBytes from 'pretty-bytes'
import moment from 'moment'
import { last } from 'lodash'
import { Typography, Icon, Tooltip } from 'antd'
import styles from './Table.css'

const Table = (props) => {

  const {
    mediaItems,
    onDownload,
    onRename,
    onDelete,
  } = props

  const getIconType = (media) => {

    const extension = last(media.file_name.toLowerCase().split('.'))

    const map = {
      "jpeg": "file-image",
      "jpg": "file-image",
      "png": "file-image",
      "gif": "file-image",
      "pdf": "file-pdf",
      "doc": "file-word",
      "docx": "file-word",
      "xls": "file-excel",
      "xlsx": "file-excel",
      "xlsm": "file-excel",
      "csv": "file-excel",
      "ppt": "file-ppt",
      "eml": "file-text",
      "rtf": "file-text",
      "txt": "file-text",
    }

    return map[extension] || 'file'
  }

  const getIconColor = (media) => {

    const iconType = getIconType(media)

    const map = {
      "file-image": "#1990FF",
      "file-pdf": "#D4241C",
      "file-word": "#2C5791",
      "file-excel": "#396A45",
      "file-ppt": "#C05535",
      "file-text": "#999",
      "file": "#999",
    }

    return map[iconType]
  }

  return (

    <div className={styles.wrapper}>

      {mediaItems.map(media => (

        <div key={media.id} className={styles.item}>

          <div className={classNames(styles.col, styles.thumbCol)}>

            {media.thumb_url ? (
              <img src={media.thumb_url} className={styles.thumbImage} />
            ) : (
              <Icon
                type={getIconType(media)}
                theme='filled'
                style={{
                  color: getIconColor(media),
                  fontSize: 30,
                  lineHeight: '30px',
                }}
              />
            )}

          </div>

          <div className={classNames(styles.col, styles.detailsCol)}>
            
            <Typography.Text ellipsis editable={{ onChange: (name) => onRename(media, name) }}>
              {media.name}
            </Typography.Text>
            
            <div className={styles.meta}>
              <span>
                {`${prettyBytes(media.size)}`}
              </span>
              &nbsp;&nbsp;•&nbsp;&nbsp;
              <Tooltip title={moment(media.created_at).format('ddd, Do MMMM YYYY, h:mm a')} placement="bottom">
                {`uploaded ${moment(media.created_at).fromNow()}`}
              </Tooltip>
            </div>

          </div>

          <div className={classNames(styles.col, styles.buttonCol)}>

            <div className={styles.buttons}>

              <Tooltip title="Download">
                <div onClick={() => onDownload(media)} className={classNames(styles.button)}>
                  <Icon type="cloud-download" />
                </div>
              </Tooltip>

              <Tooltip title="Delete">
                <div onClick={() => onDelete(media)} className={classNames(styles.button, styles.danger)}>
                  <Icon type="delete" />
                </div>
              </Tooltip>

            </div>

          </div>

        </div>

      ))}

    </div>

  )
}

Table.propTypes = {
  mediaItems: PropTypes.array.isRequired,
  onDownload: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default Table