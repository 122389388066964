import { createAction, handleActions } from 'redux-actions'

const setData = createAction('SCREENS/STOCK_ADJUSTMENTS/REPORT/SET_DATA')
const resetData = createAction('SCREENS/STOCK_ADJUSTMENTS/REPORT/RESET_DATA')

const defaultState = {
  stock_locations: [],
  products: [],
  date_time: undefined,
}

const reducer = handleActions(
  {
    [setData]: (state, action) => action.payload,
    [resetData]: () => defaultState,
  },
  defaultState,
)

export { setData, resetData, reducer }
