import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field, FormSpy } from 'react-final-form'
import { Form, Typography, Divider, Select, Tag } from 'antd'
import { ExposeFormToHelper } from 'Traqa/Packages'
import formService from './formService'
import moment from 'moment'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    products: PropTypes.array.isRequired,
    goodsInRecord: PropTypes.object.isRequired,
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  render() {

    const {
      products,
      goodsInRecord,
      onChangeFormState,
      onSubmit,
      validate,
    } = this.props

    const truncate = (input) => input.length > 40 ? `${input.substring(0, 40)}...` : input

    return (

      <FormProvider
        onSubmit={onSubmit}
        validate={validate}
      >

        {({ handleSubmit, submitting }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            autoComplete='off'
          >

            <ExposeFormToHelper helper={formService} />

            <FormSpy subscription={{ submitting: true }} onChange={onChangeFormState} />

            <Form.Item label='Reference'>
              <Typography.Text strong>
                {`${goodsInRecord.invoice_no}`}
              </Typography.Text>
            </Form.Item>

            <Form.Item label='Supplier'>
              <Typography.Text strong>
                {`${goodsInRecord.supplier.name}`}
              </Typography.Text>
            </Form.Item>

            <Form.Item label='Location'>
              <Typography.Text strong>
                {`${goodsInRecord.stock_location.name}`}
              </Typography.Text>
            </Form.Item>

            <Form.Item label='Date'>
              <Typography.Text strong>
                {`${moment(goodsInRecord.date).format('Do MMM YYYY')}`}
              </Typography.Text>
            </Form.Item>

            <Divider />

            <Field name='product_id'>
              {({ input, meta }) => (
                <Form.Item
                  label='Product'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Select
                    {...input}
                    disabled={submitting}
                    optionFilterProp='children'
                    showSearch
                    allowClear
                  >
                    
                    <Select.OptGroup label="Products">
                      {products.filter(product => !product.archived_at).map(product => (
                        <Select.Option key={product.id} value={product.id}>
                          {truncate(`${product.code} - ${product.name}`)}
                          <Tag color='volcano' style={{ marginLeft: 5 }}>{product.form}</Tag>
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                    
                    <Select.OptGroup label="Archived">
                      {products.filter(product => product.archived_at).map(product => (
                        <Select.Option key={product.id} value={product.id} style={{ color: '#999' }}>
                          {truncate(`${product.code} - ${product.name}`)}
                          <Tag color='volcano' style={{ marginLeft: 5 }}>{product.form}</Tag>
                        </Select.Option>
                      ))}
                    </Select.OptGroup>

                  </Select>
                </Form.Item>
              )}
            </Field>

          </Form>

        )}

      </FormProvider>

    )

  }

}
