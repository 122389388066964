import { message } from 'antd'
import { push } from 'connected-react-router'
import { deleteSalesOrder } from '../../../Api/salesOrders'
import { getTeamUrl } from 'Traqa/Helpers'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { salesOrder } = getState().screens.SalesOrder

    await deleteSalesOrder({ id: salesOrder.id })

    message.success(`Deleted Order '${salesOrder.number}'`)

    dispatch(push(getTeamUrl(`sales_orders`)))

  } catch (error) {

    handleException(error)

  }

}
