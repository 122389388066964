/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field, FormSpy } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import { Card, Form, Input, InputNumber, Select, DatePicker, Row, Col, Button, Badge, Tag } from 'antd'
import { ExposeFormToHelper, LinkButton } from 'Traqa/Packages'
import formHelper from './formHelper'
import styles from './Component.css'

import BomErrorSpy from './Components/BomErrorSpy'

export default class _Form_ extends React.PureComponent {

  static propTypes = {
    id: PropTypes.number,
    isNew: PropTypes.bool.isRequired,
    productCategories: PropTypes.array.isRequired,
    products: PropTypes.array.isRequired,
    initialValues: PropTypes.object.isRequired,
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    onClickImages: PropTypes.func.isRequired,
  }

  render () {

    const {
      id,
      isNew,
      productCategories,
      products,
      initialValues,
      onChangeFormState,
      onSubmit,
      validate,
      onClickImages,
    } = this.props

    const truncate = (input) => input.length > 40 ? `${input.substring(0, 40)}...` : input

    return (

      <FormProvider
        mutators={{ ...arrayMutators }}
        initialValues={initialValues}
        validate={values => validate(values, id)}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, submitting, values }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 17 }}
            colon={false}
            autoComplete="off"
          >

            <ExposeFormToHelper helper={formHelper} />

            <FormSpy subscription={{ submitting: true, pristine: true, values: true }} onChange={onChangeFormState} />

            <Card title="Product Information" bodyStyle={{ display: 'flex', justifyContent: 'center' }}>

              <div style={{ width: 600 }}>


                <Field name="code">
                  {({ input, meta }) => (
                    <Form.Item
                      label="Code"
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                      help={meta.touched && meta.error}
                    >
                      <Input
                        {...input}
                        disabled={submitting}
                      />
                    </Form.Item>
                  )}
                </Field>


                <Field name="name">
                  {({ input, meta }) => (
                    <Form.Item
                      label="Name"
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                      help={meta.touched && meta.error}
                    >
                      <Input
                        {...input}
                        disabled={submitting}
                      />
                    </Form.Item>
                  )}
                </Field>


                <Field name="product_categories">
                  {({ input, meta }) => (
                    <Form.Item
                      label="Categories"
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                      help={meta.touched && meta.error}
                    >
                      <Select
                        {...input}
                        disabled={submitting}
                        mode="multiple"
                        optionFilterProp="children"
                      >
                        <Select.OptGroup label='Active'>
                          {productCategories.filter(productCategory => !productCategory.archived_at).map(productCategory => (
                            <Select.Option key={productCategory.id} value={productCategory.id}>
                              {productCategory.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                        <Select.OptGroup label='Archived'>
                          {productCategories.filter(productCategory => !!productCategory.archived_at).map(productCategory => (
                            <Select.Option key={productCategory.id} value={productCategory.id} style={{ color: '#999' }}>
                              {productCategory.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                      </Select>
                    </Form.Item>
                  )}
                </Field>

                <Field name="form">
                  {({ input, meta }) => (
                    <Form.Item
                      label="Form"
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                      help={meta.touched && meta.error}
                    >
                      <Input
                        {...input}
                        disabled={submitting}
                      />
                    </Form.Item>
                  )}
                </Field>

                <Field name="hs_code">
                  {({ input, meta }) => (
                    <Form.Item
                      label="HS Code"
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                      help={meta.touched && meta.error}
                    >
                      <Input
                        {...input}
                        disabled={submitting}
                      />
                    </Form.Item>
                  )}
                </Field>


                <Field name='valid_from' allowNull>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Valid From'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <DatePicker
                        {...input}
                        disabled={submitting}
                        format='DD/MM/YYYY'
                      />
                    </Form.Item>
                  )}
                </Field>


                <Field name='valid_to' allowNull>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Valid To'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <DatePicker
                        {...input}
                        disabled={submitting}
                        format='DD/MM/YYYY'
                      />
                    </Form.Item>
                  )}
                </Field>

                {isNew && (

                  <Field name="value">
                    {({ input, meta }) => (
                      <Form.Item
                        label='Value'
                        validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                      >
                        <InputNumber
                          {...input}
                          disabled={submitting}
                          min={0}
                          step={1}
                          precision={2}
                        />
                      </Form.Item>
                    )}
                  </Field>

                )}

                {!isNew && (

                  <Field name="images">
                    {() => (
                      <Form.Item label='Images'>
                        <Badge count={values.images.length} size='small' className={styles.badge}>
                          <Button
                            icon="camera"
                            onClick={() => onClickImages(id)}
                            block
                            style={{ width: 50 }}
                          />
                        </Badge>
                      </Form.Item>
                    )}
                  </Field>

                )}

              </div>

            </Card>

            <br />

            <Card title="Bill of Materials">

              <FieldArray name="bill_of_materials">
                {({ fields }) => (
                  
                  <div>

                    {fields.map((name, index) => (

                      <BomErrorSpy index={index} key={index}>
                        {(meta) => (

                          <Form.Item
                            label={index === 0 ? 'Comprises of' : ' '}
                            colon={index === 0}
                            validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                            help={meta.touched && meta.error}
                          >

                            <Row gutter={8}>

                              <Col span={18}>

                                <Input.Group compact>

                                  <Field name={`${name}.quantity`}>
                                    {({ input }) => (
                                      <InputNumber
                                        {...input}
                                        disabled={submitting}
                                        placeholder="Qty"
                                        style={{ width: '24%' }}
                                      />
                                    )}
                                  </Field>

                                  <Field name={`${name}.component_id`}>
                                    {({ input }) => (
                                      <Select
                                        {...input}
                                        disabled={submitting}
                                        placeholder="Product"
                                        style={{ width: '76%' }}
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                      >
                                        {products.map(product => (
                                          <Select.Option key={product.id} value={product.id}>
                                            {truncate(`${product.code} - ${product.name}`)}
                                            <Tag color='volcano' style={{ marginLeft: 5 }}>{product.form}</Tag>
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    )}
                                  </Field>

                                </Input.Group>

                              </Col>

                              <Col span={2}>

                                <Button
                                  icon="delete"
                                  onClick={() => fields.remove(index)}
                                  block
                                />

                              </Col>

                            </Row>

                          </Form.Item>

                        )}
                      </BomErrorSpy>

                    ))}

                    {fields.length === 0 && (

                      <Button
                        type="default"
                        icon="edit"
                        onClick={() => fields.push({ })}
                      >
                        Create BOMs
                      </Button>

                    )}

                    {fields.length > 0 && (

                      <Col offset={5}>

                        <LinkButton
                          type="primary"
                          icon="plus"
                          onClick={() => fields.push({})}
                        >
                          Add Component
                        </LinkButton>

                      </Col>

                    )}

                  </div>

                )}

              </FieldArray>

            </Card>

          </Form>

        )}

      </FormProvider>

    )
  }
}