import screenProps from '../screenProps'
import { getStocktake } from '../../../Api/stocktakes'
import { setStocktake } from '../State/stocktake'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch) => {

  try {

    const { data: { data: stocktake } } = await getStocktake({
      id: screenProps().match.params.id,
      params: {
        include: 'stock_location',
      }
    })

    dispatch(setStocktake(stocktake))

  } catch (error) {

    handleException(error)

  }
}