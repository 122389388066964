export default (values) => () => {

  function isEmptyNullOrUndefined(value) {
    if (value === '') return true
    if (value === null) return true
    if (value === undefined) return true
    return false
  }

  const errors = {}

  if (!values.number) errors.number = 'Required'

  if (!values.customer_id) errors.customer_id = 'Required'

  if (!values.customer_site_id) errors.customer_site_id = 'Required'

  if (!values.date) errors.date = 'Required'

  if (!values.due_date) errors.due_date = 'Required'

  if (!values.user_id) errors.user_id = 'Required'

  if (Array.isArray(values.sales_order_items)) {

    errors.sales_order_items = values.sales_order_items.map(item => {

      const itemErrors = {}

      if (!item.product_id) itemErrors.product_id = 'Required'

      if (isEmptyNullOrUndefined(item.price)) itemErrors.price = 'Required'
      
      if (!item.quantity) itemErrors.quantity = 'Required'

      return itemErrors
      
    })

  }

  return errors

}
