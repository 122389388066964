import screenProps from '../screenProps'
import { updateTransferStatus } from '../../../Api/transfers'
import { setTransfer } from '../State/transfer'
import fetch from './fetch'
//import fetchActivity from '../../../Modules/Activity/Services/fetch'
import { handleException } from 'Traqa/Packages'

export default (status) => async (dispatch, getState) => {

  try {

    dispatch(setTransfer({
      ...getState().screens.Transfer.transfer,
      status
    }))

    const { id } = screenProps().match.params

    const data = { status }

    await updateTransferStatus({ id, data })

    await dispatch(fetch())

    //dispatch(fetchActivity())

  } catch (error) {

    handleException(error)

  }
}