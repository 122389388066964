import { createAction, handleActions } from 'redux-actions'

const openCourierRateModal = createAction('SCREENS/COURIER/OPEN_COURIER_RATE_MODAL')
const closeCourierRateModal = createAction('SCREENS/COURIER/CLOSE_COURIER_RATE_MODAL')

const defaultState = {
  show: false,
  id: null,
  courierId: null,
}

const reducer = handleActions(
  {
    [openCourierRateModal]: (state, action) => Object.assign({}, state, {
      show: true,
      id: action.payload.id,
      courierId: action.payload.courierId,
    }),
    [closeCourierRateModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openCourierRateModal, closeCourierRateModal, reducer }
