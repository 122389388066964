import { message } from 'antd'
import { goBack } from 'connected-react-router'
import { deleteConsignment } from '../../../Api/consignments'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { consignment } = getState().screens.Consignment

    await deleteConsignment({ id: consignment.id })

    message.success(`Deleted Consignment '${consignment.reference}'`)

    dispatch(goBack())

  } catch (error) {

    handleException(error)

  }

}
