import chance from 'chance'
import formService from '../formService'

export default (index) => (_, getState) => {

  const { values } = getState().screens.PurchaseOrder.Editor.formState

  const item = {
    guid: chance().guid(),
    supplier_id: values.supplier_id,
  }

  if (index === undefined) {

    formService().mutators.push('purchase_order_items', item)

  } else {

    formService().mutators.insert('purchase_order_items', index+1, item)
    
  }

}