import { createAction, handleActions } from 'redux-actions'

const setData = createAction('SCREENS/PLASTIC_TAX/REPORT/SET_DATA')
const resetData = createAction('SCREENS/PLASTIC_TAX/REPORT/RESET_DATA')

const defaultState = {
  goods_in_records: [],
  totals: {
    weight_kgs: 0,
    recycled_kgs: 0,
  },
}

const reducer = handleActions(
  {
    [setData]: (state, { payload }) => payload,
    [resetData]: () => defaultState,
  },
  defaultState,
)

export { setData, resetData, reducer }
