import drawerProps from '../drawerProps'
import { getTransactions } from '../../../../../Api/transactions'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  const { product, stockLocation } = drawerProps()

  const { values } = getState().screens.StockAdjustments.Report.formState

  const {
    startDate,
    endDate,
    aggregate,
    includeExpected,
    transaction_batchNo: batchNo,
    transaction_supplierIds: supplierIds,
    transaction_customerIds: customerIds,
    transaction_customerSiteIds: customerSiteIds,
    transaction_ownerTypes: ownerTypes,
    transaction_exceptOwnerTypes: exceptOwnerTypes,
  } = values

  const productId = aggregate ? [
    product.id,
    ...product.divides_into_products.map(row => row.id),
    ...product.division_of_products.map(row => row.id),
  ].join() : product.id

  const dateRange = `${startDate.format('YYYY-MM-DD')} 00:00:00,${endDate.format('YYYY-MM-DD')} 23:59:59`

  const params = {
    'filter[owner_type]': ownerTypes.join(','),
    'filter[without_owner_type]': exceptOwnerTypes.join(','),
    'filter[is_allocation]': false,
    'filter[is_reading]': false,
    'filter[supplier_id]': supplierIds.join(','),
    'filter[customer_id]': customerIds.join(','),
    'filter[customer_site_id]': customerSiteIds.join(','),
    'filter[product_id]': productId,
    'filter[transaction_at_between]': dateRange,
  }

  if (!includeExpected) params['filter[is_expectation]'] = 0

  if (stockLocation) params['filter[stock_location_id]'] = stockLocation.id

  if (batchNo) params['filter[batch_no]'] = batchNo

  params.include = [
    'stock_location',
    'product',
  ].join()

  params.append = [
    'owner_type_formatted',
    'owner_type_slug',
    'quantity_formatted',
    'details',
  ].join()

  params.sort = '-transaction_at'

  dispatch(startFetching())

  try {

    const response = await getTransactions({ params })

    dispatch(setData(response.data.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetching())
    
  }

}
