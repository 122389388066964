import { createSelector } from 'reselect'
import { get, orderBy } from 'lodash'

const selectProducts = state => state.screens.Reconciliation.Report.data.products
const selectValues = state => state.screens.Reconciliation.Report.formState.values

export default createSelector(
  selectProducts,
  selectValues,
  (products, values) => {

    const options = get(values, 'options', [])

    const sortByVariance = options.includes('sort_by_variance')

    if (sortByVariance) {
      return orderBy(products, product => Math.abs(product.variance_value), 'desc')
    }

    return orderBy(products, 'code')

  }
)
