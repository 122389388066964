import { closeCustomerSiteModal } from '../State/customerSiteModal'
import fetch from '../Services/fetch'

export default (shouldReload = false) => (dispatch) => {

  dispatch(closeCustomerSiteModal())

  if (shouldReload) {
    dispatch(fetch())
  }

}
