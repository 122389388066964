import { push } from 'connected-react-router'
import { message } from 'antd'
import { deleteCourier } from '../../../Api/couriers'
import { getTeamUrl } from 'Traqa/Helpers'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  const { courier } = getState().screens.Courier

  try {

    await deleteCourier({ id: courier.id })

    message.success('Deleted Courier')

    dispatch(push(getTeamUrl(`couriers`)))

  } catch (error) {

    handleException(error)

  }

}
