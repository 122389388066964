import { createAction, handleActions } from 'redux-actions'

const setConsignment = createAction('SCREENS/CONSIGNMENT/SET_CONSIGNMENT')
const resetConsignment = createAction('SCREENS/CONSIGNMENT/RESET_CONSIGNMENT')

const defaultState = null

const reducer = handleActions(
  {
    [setConsignment]: (state, action) => action.payload,
    [resetConsignment]: () => null,
  },
  defaultState,
)

export { setConsignment, resetConsignment, reducer }
