import modalProps from '../../../modalProps'
import { createGoodsInRecordItem } from '../../../../../Api/goodsInRecordItems'
import { handleException } from 'Traqa/Packages'

export default (values) => async () => {

  try {

    const { goodsInRecord } = modalProps()

    const data = {
      goods_in_record_id: goodsInRecord.id,
      product_id: values.product_id,
    }

    await createGoodsInRecordItem({ data })

    modalProps().onClose(true)

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
