import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Icon, message } from 'antd'
import { Module } from 'Traqa/Packages'
import { getMedia, uploadMedia, renameMedia, deleteMedia } from '../../Api/media'
import Table from './Components/Table'

export default class MediaItems extends React.PureComponent {

  static _isMounted

  static propTypes = {
    modelType: PropTypes.string.isRequired,
    modelId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    upload: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onChangeCallback: PropTypes.func,
  }

  state = {
    mediaItems: [],
    isFetching: false,
    empty: true,
  }

  componentDidMount () {
    this._isMounted = true
    this.fetch()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  fetch = async () => {

    this.setState({ isFetching: true })

    const { data: { data: mediaItems }} = await getMedia({
      params: {
        'filter[model_type]': this.props.modelType,
        'filter[model_id]': this.props.modelId,
        'append': 'thumb_url',
      }
    })

    this.setState({
      mediaItems,
      isFetching: false,
      empty: mediaItems.length === 0,
    })

  }

  onDrop = (files) => {

    const data = {
      modelType: this.props.modelType,
      modelId: this.props.modelId,
    }

    const request = uploadMedia

    const onSuccess = () => () => {
      if (this._isMounted) {
        this.fetch()
        this.props.onChange(this.props.onChangeCallback)
      }
    }

    const onFailure = (error, file) => () => message.error(`Failed to upload ${file.name}`)
    
    const uploads = files.map(file => ({
      file,
      data,
      request,
      onSuccess,
      onFailure
    }))
  
    this.props.upload(uploads)
  }

  onDownload = async (media) => {

    const response = await getMedia({
      id: media.id,
      params: {
        append: 'url'
      }
    })

    window.open(response.data.data.url)

  }

  onDelete = (media) => {

    const onOk = async () => {

      await deleteMedia({
        id: media.id
      })

      await this.fetch()

      this.props.onChange(this.props.onChangeCallback)

    }

    Modal.confirm({
      title: 'Delete File?',
      content: 'This action cannot be undone.',
      icon: <Icon type="delete" style={{ color: '#FF4D4F' }} />,
      onOk,
      okType: 'danger',
      okText: 'Delete'
   })
    
  }

  onRename = async (media, name) => {

    await renameMedia({
      id: media.id,
      data: { name }
    })

    await this.fetch()

    this.props.onChange(this.props.onChangeCallback)

  }

  render () {

    const {
      mediaItems,
      isFetching,
      empty,
    } = this.state
    
    return (

      <Module
        dropzone
        dropzoneTitle="Files"
        dropzoneProps={{
          onDrop: this.onDrop
        }}
      >
        {({ openFileSelector }) => (

          <React.Fragment>

            {(empty) && (
              <Module.Empty
                title="Files"
                text={(
                  <span>
                    {'Drag a file from your desktop or '}
                    <a onClick={openFileSelector}>select a file</a>
                    {' to upload'}
                  </span>
                )}
              />
            )}

            {(!empty) && (
              <React.Fragment>

                <Module.Header title="Files" />

                <Table
                  mediaItems={mediaItems}
                  isFetching={isFetching}
                  onDownload={this.onDownload}
                  onRename={this.onRename}
                  onDelete={this.onDelete}
                />

              </React.Fragment>
            )}

          </React.Fragment>

        )}

      </Module>

    )
  }
}