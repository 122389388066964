import { createAction, handleActions } from 'redux-actions'

const setCustomer = createAction('MODALS/CUSTOMER/SET_CUSTOMER')
const resetCustomer = createAction('MODALS/CUSTOMER/RESET_CUSTOMER')

const defaultState = null

const reducer = handleActions(
  {
    [setCustomer]: (state, action) => action.payload,
    [resetCustomer]: () => defaultState,
  },
  defaultState
)

export { setCustomer, resetCustomer, reducer }