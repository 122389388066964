import { connect } from "react-redux"
import MoreMenu from "./MoreMenu"

import onArchive from './Handlers/onArchive'
import onUnarchive from './Handlers/onUnarchive'
import onDelete from './Handlers/onDelete'

const mapStateToProps = (state) => ({
  product: state.screens.Product.product,
})

const actionCreators = {
  onArchive,
  onUnarchive,
  onDelete,
}

export default connect(mapStateToProps, actionCreators)(MoreMenu)
