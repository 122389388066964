import chance from 'chance'
import moment from 'moment'
import screenProps from '../../../screenProps'
import { getGoodsOutRecord } from '../../../../../Api/goodsOutRecords'
import { setInitialValues } from '../State/initialValues'
import { getUser } from 'Traqa/Helpers'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    if (id === 'new') {

      const initialValues = {
        date: null,
        time: null,
        user_id: getUser().id,
        is_return: false,
        goods_out_record_items: [
          {
            guid: chance().guid(),
            expiry_date: null,
          },
        ],
      }

      dispatch(setInitialValues(initialValues))

    } else {

      const {
        data: {
          data: goodsOutRecord,
        },
      } = await getGoodsOutRecord({
        id,
        params: {
          include: 'goods_out_record_items',
        },
      })

      const dateTime = moment(goodsOutRecord.date_time)

      const initialValues = {
        stock_location_id: goodsOutRecord.stock_location_id,
        customer_id: goodsOutRecord.customer_id,
        customer_site_id: goodsOutRecord.customer_site_id,
        date: dateTime,
        time: dateTime,
        user_id: goodsOutRecord.user_id,
        invoice_no: goodsOutRecord.invoice_no,
        contact_details: goodsOutRecord.contact_details,
        picking_notes: goodsOutRecord.picking_notes,
        is_return: !!goodsOutRecord.is_return,
        goods_out_record_items: goodsOutRecord.goods_out_record_items.map(goodsOutRecordItem => ({
          guid: chance().guid(),
          id: goodsOutRecordItem.id,
          product_id: goodsOutRecordItem.product_id,
          batch_no: goodsOutRecordItem.batch_no,
          expiry_date: goodsOutRecordItem.expiry_date ? moment(goodsOutRecordItem.expiry_date) : null,
          quantity: goodsOutRecordItem.quantity,
          source_product_id: goodsOutRecordItem.source_product_id,
          picked_by_user_id: goodsOutRecordItem.picked_by_user_id,
          sales_order_id: goodsOutRecordItem.sales_order_id,
        }))
      }

      dispatch(setInitialValues(initialValues))

    }

  } catch (error) {

    handleException(error)

  }

}
