import React from 'react'
import PropTypes from 'prop-types'
import { WindowScroller, AutoSizer, List as VirtualList } from 'react-virtualized'
import Row from '../Row'

const List = (props) => {

  const {
    listRows,
  } = props
  
  return (

    <WindowScroller>
      {({ height, scrollTop }) => (

        <AutoSizer disableHeight>
          {({ width }) => (

            <VirtualList
              autoHeight
              height={height}
              width={width}
              scrollTop={scrollTop}
              overscanRowCount={5}
              rowHeight={({ index }) => listRows[index].height}
              rowRenderer={(rowProps) => <Row {...rowProps} component={listRows[rowProps.index].component} />}
              rowCount={listRows.length}
              style={{ outline: 'none', paddingLeft: 25, marginTop: 30 }}
            />

          )}
        </AutoSizer>
        
      )}
    </WindowScroller>

  )
}

List.propTypes = {
  listRows: PropTypes.array.isRequired,
}

export default List