import React from 'react'
import PropTypes from 'prop-types'
import ReactHighcharts from 'react-highcharts'

const Chart = props => {

  const {
    sortBy,
    config,
  } = props

  if (sortBy === 'code') return false

  return (
    
    <ReactHighcharts isPureConfig config={config} />

  )
  
}

Chart.propTypes = {
  sortBy: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
}

export default Chart
