import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import { Module, LinkButton } from 'Traqa/Packages'

const Header = (props) => {

  const {
    searchTerm,
    onChangeSearch,
    onSearch,
    onClickAdvanced,
  } = props

  const renderLeft = () => (

    <React.Fragment>

      <Input.Search
        value={searchTerm}
        onChange={onChangeSearch}
        onSearch={onSearch}
        placeholder="Search"
        style={{ width: 200 }}
      />

      <div style={{ width: 16 }} />

      <LinkButton
        type='primary'
        onClick={onClickAdvanced}
      >
        Advanced
      </LinkButton>
    
    </React.Fragment>

  )
  
  return (

    <Module.Header
      renderLeft={renderLeft}
    />

  )
}

Header.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onClickAdvanced: PropTypes.func.isRequired,
}

export default Header