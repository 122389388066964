import chance from 'chance'
import moment from 'moment'
import { defer } from 'lodash'
import screenProps from '../../../screenProps'
import { getGoodsInRecord } from '../../../../../Api/goodsInRecords'
import { setInitialValues } from '../State/initialValues'
import { getUser } from 'Traqa/Helpers'
import { handleException } from 'Traqa/Packages'
import formService from '../formService'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    if (id === 'new') {

      const initialValues = {
        date: null,
        time: null,
        user_id: getUser().id,
        is_return: false,
        goods_in_record_items: [
          {
            guid: chance().guid(),
            expiry_date: null,
          }
        ]
      }

      dispatch(setInitialValues(initialValues))

      defer(() => formService().initialize(initialValues))

    } else {

      const {
        data: {
          data: goodsInRecord
        }
      } = await getGoodsInRecord({
        id,
        params: {
          include: 'goods_in_record_items'
        }
      })

      const dateTime = moment(goodsInRecord.date_time)

      const initialValues = {
        supplier_id: goodsInRecord.supplier_id,
        stock_location_id: goodsInRecord.stock_location_id,
        date: dateTime,
        time: dateTime,
        user_id: goodsInRecord.user_id,
        invoice_no: goodsInRecord.invoice_no,
        is_return: !!goodsInRecord.is_return,
        goods_in_record_items: goodsInRecord.goods_in_record_items.map(item => ({
          guid: chance().guid(),
          id: item.id,
          product_id: item.product_id,
          batch_no: item.batch_no,
          expiry_date: item.expiry_date ? moment(item.expiry_date) : null,
          quantity: item.quantity,
          blend: item.blend,
          recycled_perc: item.recycled_perc,
          weight_kgs: item.weight_kgs,
          purchase_order_id: item.purchase_order_id,
        }))
      }

      dispatch(setInitialValues(initialValues))

      defer(() => formService().initialize(initialValues))

    } 

  } catch (error) {

    handleException(error)

  }
}