import { createSelector } from 'reselect'
import { get, find } from 'lodash'

const selectCustomers = state => state.core.customers
const selectCustomerId = (state) => get(state.screens.SalesOrder.Editor.formState, 'values.customer_id', null)

const selectCustomerSites = createSelector(
  selectCustomers,
  selectCustomerId,
  (customers, customerId) => {
    
    const customer = find(customers, { id: customerId })

    return customer ? customer.customer_sites : []

  },
)

export default selectCustomerSites
