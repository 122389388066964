import { connect } from "react-redux"
import ListHeader from "./ListHeader"

import onToggleShowItems from './Handlers/onToggleShowItems'
import onChangeSearchTerm from './Handlers/onChangeSearchTerm'
import onToggleHideZeros from './Handlers/onToggleHideZeros'

const mapStateToProps = (state) => ({
  showItems: state.screens.Stocktake.Editor.showItems,
  searchTerm: state.screens.Stocktake.Editor.searchTerm,
  hideZeros: state.screens.Stocktake.Editor.hideZeros,
})

const actionCreators = {
  onToggleShowItems,
  onChangeSearchTerm,
  onToggleHideZeros,
}

export default connect(mapStateToProps, actionCreators)(ListHeader)
