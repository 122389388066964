import { connect } from "react-redux"
import MoreMenu from "./MoreMenu"

import onReconcile from './Handlers/onReconcile'
import onImport from './Handlers/onImport'
import onExport from './Handlers/onExport'
import onDelete from './Handlers/onDelete'

const mapStateToProps = () => ({})

const actionCreators = {
  onReconcile,
  onImport,
  onExport,
  onDelete,
}

export default connect(mapStateToProps, actionCreators)(MoreMenu)
