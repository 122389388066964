import React from 'react'
import PropTypes from 'prop-types'
import { Field, FormSpy } from 'react-final-form'
import { Form, Row, Col, Input, Select, DatePicker, TimePicker } from 'antd'
import { Module } from 'Traqa/Packages'

const Details = (props) => {

  const {
    stockLocations,
    users,
  } = props
  
  return (

    <Module.Section
      contentStyle={{ paddingBottom: 15 }}
    >

      <FormSpy subscription={{ submitting: true }}>

        {({ submitting }) => (

          <React.Fragment>

            <Row gutter={8}>

              <Col span={4}>

                <Field name='reference'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Reference'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Input
                        {...input}
                        disabled={submitting}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={4}>

                <Field name="origin_id">
                  {({ input, meta }) => (
                    <Form.Item
                      label="Origin"
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        disabled={submitting}
                        optionFilterProp="children"
                        showSearch
                        allowClear
                      >
                        {stockLocations.map(stockLocation => (
                          <Select.Option key={stockLocation.id} value={stockLocation.id}>
                            {stockLocation.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={4}>

                <Field name="destination_id">
                  {({ input, meta }) => (
                    <Form.Item
                      label="Destination"
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        disabled={submitting}
                        optionFilterProp="children"
                        showSearch
                        allowClear
                      >
                        {stockLocations.map(stockLocation => (
                          <Select.Option key={stockLocation.id} value={stockLocation.id}>
                            {stockLocation.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={4}>

                <Field name="user_id">
                  {({ input, meta }) => (
                    <Form.Item
                      label="User"
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        disabled={submitting}
                        optionFilterProp="children"
                        showSearch
                        allowClear
                      >
                        {users.map(user => (
                          <Select.Option key={user.id} value={user.id}>
                            {user.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Field>

              </Col>

            </Row>

            <Row gutter={8}>

              <Col span={4}>

                <Field name='dispatched_date' allowNull>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Dispatched Date'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <DatePicker
                        {...input}
                        disabled={submitting}
                        format='DD/MM/YYYY'
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={4}>

                <Field name='dispatched_time' allowNull>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Dispatched Time'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <TimePicker
                        {...input}
                        disabled={submitting}
                        format='HH:mm'
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={4}>

                <Field name='completed_date' allowNull>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Completed Date'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <DatePicker
                        {...input}
                        disabled={submitting}
                        format='DD/MM/YYYY'
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={4}>

                <Field name='completed_time' allowNull>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Completed Time'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <TimePicker
                        {...input}
                        disabled={submitting}
                        format='HH:mm'
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

            </Row>

          </React.Fragment>
      
        )}

      </FormSpy>
  
    </Module.Section>

  )
}

Details.propTypes = {
  stockLocations: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
}

Details.defaultProps = {
  
}

export default Details