import React from 'react'
import PropTypes from 'prop-types'
import { LinkButton } from 'Traqa/Packages'
import styles from './Component.css'

const Footer = (props) => {

  const {
    hasUnread,
    onClickMarkAllRead,
  } = props
  
  if (!hasUnread) return null

  return (

    <div className={styles.bar}>
        
      <LinkButton onClick={onClickMarkAllRead} type="primary">
        Mark all as read
      </LinkButton>
      
    </div>
    
  )
}

Footer.propTypes = {
  hasUnread: PropTypes.bool.isRequired,
  onClickMarkAllRead: PropTypes.func.isRequired,
}

export default Footer