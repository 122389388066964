import chance from 'chance'
import moment from 'moment'
import { defer } from 'lodash'
import screenProps from '../../../screenProps'
import { getReading } from '../../../../../Api/readings'
import { setInitialValues } from '../State/initialValues'
import { handleException } from 'Traqa/Packages'
import formService from '../formService'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    if (id === 'new') {

      const initialValues = {
        date: moment(),
        time: moment(),
        reading_items: [
          {
            guid: chance().guid(),
          }
        ]
      }

      dispatch(setInitialValues(initialValues))

      defer(() => formService().initialize(initialValues))

    } else {

      const {
        data: {
          data: reading
        }
      } = await getReading({
        id,
        params: {
          include: 'reading_items'
        }
      })

      const dateTime = moment(reading.date_time)

      const initialValues = {
        stock_location_id: reading.stock_location_id,
        description: reading.description,
        date: dateTime,
        time: dateTime,
        created_by_user_id: reading.created_by_user_id,
        updated_by_user_id: reading.updated_by_user_id,
        reading_items: reading.reading_items.map(item => ({
          guid: chance().guid(),
          id: item.id,
          product_id: item.product_id,
          quantity: item.quantity,
        }))
      }

      dispatch(setInitialValues(initialValues))

      defer(() => formService().initialize(initialValues))

    } 

  } catch (error) {

    handleException(error)

  }
}