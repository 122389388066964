import modalProps from '../../../modalProps'
import { createSupplier, updateSupplier } from '../../../../../Api/suppliers'
import { handleException } from 'Traqa/Packages'

export default (values) => async () => {

  try {

    const { supplierId } = modalProps()

    const data = {
      name: values.name,
      telephone: values.telephone || null,
      email: values.email || null,
      country_code: values.country_code || null,
    }

    if (supplierId) {

      await updateSupplier({ id: supplierId, data })

    } else {

      await createSupplier({ data })

    }
    
    modalProps().onClose(true)

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}