import React from 'react'
import PropTypes from 'prop-types'
import { Button, Tooltip } from 'antd'
import classNames from 'classnames'
import { LinkButton } from 'Traqa/Packages'
import styles from './styles.css'

const Header = (props) => {

  const {
    courier,
    activeTab,
    onBack,
    onEdit,
    onDelete,
    onChangeTab,
  } = props
  
  if (!courier) return null
  
  return (

    <div className={styles.wrapper}>

      <div className={styles.main}>
        
        
        <div className={styles.section1}>
            
          <LinkButton onClick={onBack}>
            ← All Couriers
          </LinkButton>
          
          <div className={styles.title}>
            {courier.name}
          </div>
          
        </div>
        
        
        <div className={styles.section2}>
          
          <div className={styles.detail}>
            <div className={styles.detailLabel}>Services</div>
            <div className={styles.detailContent}>{courier.courier_services.length}</div>
          </div>
          
          <div className={styles.detail}>
            <div className={styles.detailLabel}>Zones</div>
            <div className={styles.detailContent}>{courier.courier_zones.filter(courierZone => !!courierZone.courier_zone_id).length}</div>
          </div>
          
          <div className={styles.detail}>
            <div className={styles.detailLabel}>Consignments</div>
            <div className={styles.detailContent}>{courier.consignments.length}</div>
          </div>
          
        </div>
        
        
        <div className={styles.buttons}>

          <Tooltip title='Edit' placement='bottom'>
            <Button
              shape='circle'
              icon='edit'
              size='large'
              className={styles.button}
              onClick={onEdit}
            />
          </Tooltip>

          <Tooltip title='Delete' placement='bottom'>
            <Button
              shape='circle'
              icon='delete'
              size='large'
              className={styles.button}
              onClick={onDelete}
            />
          </Tooltip>

        </div>
        
        
      </div>
      
      <div className={styles.tabs}>
        
        {[
          'Services',
          'Zones',
          'Rates',
          'Charges',
        ].map((tab, tabIndex) => (
          <div
            key={tab}
            className={classNames(styles.tab, { [styles.tabActive]: activeTab === tabIndex })}
            onClick={() => onChangeTab(tabIndex)}
          >
            {tab}
          </div>
        ))}
        
      </div>
      
    </div>

  )

}

Header.propTypes = {
  courier: PropTypes.object,
  activeTab: PropTypes.number.isRequired,
  onBack: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func.isRequired,
}

Header.defaultProps = {
  courier: null,
}

export default React.memo(Header)
