import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field, FormSpy } from 'react-final-form'
import { Form as AntdForm, Select, DatePicker, TimePicker, Icon } from 'antd'
import { ExposeFormToHelper } from 'Traqa/Packages'
import formService from './formService'
import styles from './styles.css'

const Form = (props) => {

  const {
    stockLocations,
    users,
    onChangeFormState,
    onSubmit,
    validate,
  } = props

  const initialValues = {
    date: null,
    time: null,
  }

  const statuses = [
    { label: 'Draft', value: 'Draft', icon: 'edit', color: 'grey' },
    { label: 'Scheduled', value: 'Speculated', icon: 'clock-circle', color: 'orange' },
    { label: 'Booked', value: 'Expected', icon: 'check-circle', color: '#1890ff' },
    { label: 'Received', value: 'Completed', icon: 'check-circle', color: 'limegreen' },
  ]

  return (

    <FormProvider
      validate={validate}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ handleSubmit, submitting }) => (

        <AntdForm
          onSubmit={handleSubmit}
          className={styles.form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          autoComplete='off'
        >

          <ExposeFormToHelper helper={formService} />

          <FormSpy subscription={{ submitting: true }} onChange={onChangeFormState} />

          <Field name='stock_location_id'>
            {({ input, meta }) => (
              <AntdForm.Item
                label='Location'
                validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
              >
                <Select
                  {...input}
                  disabled={submitting}
                  optionFilterProp='children'
                  showSearch
                  allowClear
                >
                  <Select.OptGroup label='Active'>
                    {stockLocations.filter(stockLocation => !stockLocation.archived_at).map(stockLocation => (
                      <Select.Option key={stockLocation.id} value={stockLocation.id}>
                        {stockLocation.name}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                  <Select.OptGroup label='Archived'>
                    {stockLocations.filter(stockLocation => !!stockLocation.archived_at).map(stockLocation => (
                      <Select.Option key={stockLocation.id} value={stockLocation.id} style={{ color: '#999' }}>
                        {stockLocation.name}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                </Select>
              </AntdForm.Item>
            )}
          </Field>

          <Field name='date' allowNull>
            {({ input, meta }) => (
              <AntdForm.Item
                label='Date'
                validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
              >
                <DatePicker
                  {...input}
                  disabled={submitting}
                  format='DD/MM/YYYY'
                  style={{ width: '100%' }}
                />
              </AntdForm.Item>
            )}
          </Field>

          <Field name='time' allowNull>
            {({ input, meta }) => (
              <AntdForm.Item
                label='Time'
                validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
              >
                <TimePicker
                  {...input}
                  disabled={submitting}
                  format='HH:mm'
                  style={{ width: '100%' }}
                />
              </AntdForm.Item>
            )}
          </Field>

          <Field name='user_id'>
            {({ input, meta }) => (
              <AntdForm.Item
                label='User'
                validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
              >
                <Select
                  {...input}
                  disabled={submitting}
                  optionFilterProp='children'
                  showSearch
                  allowClear
                >
                  {users.map(user => (
                    <Select.Option key={user.id} value={user.id}>
                      {user.name}
                    </Select.Option>
                  ))}
                </Select>
              </AntdForm.Item>
            )}
          </Field>

          <Field name='status'>
            {({ input, meta }) => (
              <AntdForm.Item
                label='Status'
                validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
              >
                <Select
                  {...input}
                  disabled={submitting}
                  optionFilterProp='children'
                  showSearch
                  allowClear
                >
                  {statuses.map(status => (
                    <Select.Option key={status.value} value={status.value}>
                      <Icon type={status.icon} className={styles.icon} style={{ color: status.color }} />
                      <span style={{ marginLeft: '5px' }}>{status.label}</span>
                    </Select.Option>
                  ))}
                </Select>
              </AntdForm.Item>
            )}
          </Field>

        </AntdForm>

      )}

    </FormProvider>

  )

}

Form.propTypes = {
  stockLocations: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  onChangeFormState: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
}

export default Form
