import React from 'react'
import PropTypes from 'prop-types'
import { Table, Menu, Dropdown, Icon } from 'antd'
import { LinkButton } from 'Traqa/Packages'
import styles from './styles.css'

export default class CustomerSites extends React.PureComponent {

  static propTypes = {
    customer: PropTypes.object,
    onRowClick: PropTypes.func.isRequired,
    onArchive: PropTypes.func.isRequired,
    onUnarchive: PropTypes.func.isRequired,
  }

  static defaultProps = {
    customer: null,
  }

  render () {

    const {
      customer,
      onRowClick,
      onArchive,
      onUnarchive,
    } = this.props

    if (!customer) return null

    const menu = (record) => (
  
      <Menu style={{ minWidth: 150 }}>

        {!record.archived_at && (
          <Menu.Item
            onClick={item => {
              item.domEvent.stopPropagation()
              onArchive(record)
            }}
          >
            <Icon type="eye-invisible" />
            Archive
          </Menu.Item>
        )}

        {!!record.archived_at && (
          <Menu.Item
            onClick={item => {
              item.domEvent.stopPropagation()
              onUnarchive(record)
            }}
          >
            <Icon type="eye" />
            Unarchive
          </Menu.Item>
        )}
  
      </Menu>
  
    )
    
    return (

      <Table
        dataSource={customer.customer_sites}
        rowKey='id'
        pagination={false}
        scroll={{ y: 240 }}
        className={styles.table}
        onRow={record => ({
          onClick: () => onRowClick(record.id),
        })}
      >

        <Table.Column
          key='name'
          title='Sites'
          render={(text, record) => record.name}
        />

        <Table.Column
          key='archived_at'
          render={(text, record) => record.archived_at ? (
            <div style={{ color: '#999' }}>
              <Icon type="eye-invisible" style={{ color: '#999' }} />
              {' Archived'}
            </div>
          ) : null}
        />
  
        <Table.Column
          key='options'
          render={(text, record) => (
            <Dropdown
              trigger={["click"]}
              placement="bottomRight"
              overlay={menu(record)}
              onClick={e => e.stopPropagation()}
            >
              <LinkButton iconRight="caret-down">Options</LinkButton>
            </Dropdown>
          )}
        />

      </Table>

    )

  }

}
