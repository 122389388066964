import { connect } from 'react-redux'
import Component from './Component'

const mapStateToProps = (state) => ({
  product: state.screens.Product.product,
})

const actionCreators = {}

export default connect(mapStateToProps, actionCreators)(Component)
