import { teamApi as axios } from './axios';

export const getSalesOrders = ({ params } = {}) => axios.get(`sales_orders`, { params });
export const getSalesOrder = ({ params, id } = {}) => axios.get(`sales_orders/${id}`, { params });
export const createSalesOrder = ({ data }) => axios.post(`sales_orders`, data);
export const updateSalesOrder = ({ id, data }) => axios.put(`sales_orders/${id}`, data);
export const deleteSalesOrder = ({ id }) => axios.delete(`sales_orders/${id}`);
export const updateSalesOrderStatus = ({ id, data }) => axios.put(`sales_orders/${id}/status`, data);
export const archiveSalesOrder = ({ id }) => axios.put(`sales_orders/${id}/archive`);
export const unarchiveSalesOrder = ({ id }) => axios.put(`sales_orders/${id}/unarchive`);
export const createSalesOrderGoodsOutRecord = ({ id, data }) => axios.post(`sales_orders/${id}/goods_out_record`, data);
