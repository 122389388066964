import fetch from './fetch'
import { initialized } from '../State/initialized'
import initializeEditor from '../Components/Editor/Services/initialize'
import fetchStockLocations from '../../../Core/Services/fetchStockLocations'
import fetchUsers from '../../../Core/Services/fetchUsers'
import fetchProducts from '../../../Core/Services/fetchProducts'

export default () => async (dispatch) => {

  await dispatch(fetch())

  await dispatch(initializeEditor())

  dispatch(initialized())

  dispatch(fetchStockLocations())
  dispatch(fetchUsers())
  dispatch(fetchProducts())

}