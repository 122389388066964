import { createAction, handleActions } from 'redux-actions'

const setFetching = createAction('MODULES/STOCKSHOTS_POPOVER/SET_FETCHING')
const resetFetching = createAction('MODULES/STOCKSHOTS_POPOVER/RESET_FETCHING')

const defaultState = false

const reducer = handleActions(
  {
    [setFetching]: (_, { payload }) => payload,
    [resetFetching]: () => defaultState,
  },
  defaultState
)

export { setFetching, resetFetching, reducer }
