import { createAction, handleActions } from 'redux-actions'

const loadProducts = createAction("CORE/PRODUCTS/LOAD_PRODUCTS")

const defaultState = []

const reducer = handleActions(
  {
    [loadProducts]: (state, action) => action.payload,
  },
  defaultState
)

export { loadProducts, reducer }