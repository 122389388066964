import { combineReducers } from "redux"

import Dashboard from "./Dashboard/reducer"

import Products from "./Products/reducer"
import Product from "./Product/reducer"
import ProductEditor from "./ProductEditor/reducer"

import StockAssessment from "./StockAssessment/reducer"
import StockAdjustments from "./StockAdjustments/reducer"

import PurchaseOrders from "./PurchaseOrders/reducer"
import PurchaseOrder from "./PurchaseOrder/reducer"

import GoodsInRecords from "./GoodsInRecords/reducer"
import GoodsInRecord from "./GoodsInRecord/reducer"

import SalesOrders from "./SalesOrders/reducer"
import SalesOrder from "./SalesOrder/reducer"

import GoodsOutRecords from "./GoodsOutRecords/reducer"
import GoodsOutRecord from "./GoodsOutRecord/reducer"

import Consignments from "./Consignments/reducer"
import Consignment from "./Consignment/reducer"

import Transfers from "./Transfers/reducer"
import Transfer from "./Transfer/reducer"

import Assemblies from "./Assemblies/reducer"
import Assembly from "./Assembly/reducer"

import Stocktakes from "./Stocktakes/reducer"
import Stocktake from "./Stocktake/reducer"
import Reconciliation from "./Reconciliation/reducer"

import Transactions from "./Transactions/reducer"
import Reading from "./Reading/reducer"
import Adjustment from "./Adjustment/reducer"

import Users from "./Users/reducer"
import User from "./User/reducer"

import Customers from "./Customers/reducer"

import Suppliers from "./Suppliers/reducer"

import ProductCategories from "./ProductCategories/reducer"

import StockLocations from "./StockLocations/reducer"

import Couriers from "./Couriers/reducer"
import Courier from "./Courier/reducer"

import PurchasePlanner from "./PurchasePlanner/reducer"

import PlasticTax from "./PlasticTax/reducer"

import Demand from "./Demand/reducer"

export default combineReducers({
  Dashboard,
  Products,
  Product,
  ProductEditor,
  StockAssessment,
  StockAdjustments,
  PurchaseOrders,
  PurchaseOrder,
  GoodsInRecords,
  GoodsInRecord,
  SalesOrders,
  SalesOrder,
  GoodsOutRecords,
  GoodsOutRecord,
  Consignments,
  Consignment,
  Transfers,
  Transfer,
  Assemblies,
  Assembly,
  Stocktakes,
  Stocktake,
  Reconciliation,
  Transactions,
  Reading,
  Adjustment,
  Users,
  User,
  Customers,
  Suppliers,
  ProductCategories,
  StockLocations,
  Couriers,
  Courier,
  PurchasePlanner,
  PlasticTax,
  Demand,
})
