import screenProps from '../screenProps'
import reset from '../Services/reset'

export default () => (dispatch) => {

  screenProps.reset()

  dispatch(reset())

}
