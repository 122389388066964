import { push } from 'connected-react-router'
import { defer } from 'lodash'
import moment from 'moment'
import { getTeamUrl } from 'Traqa/Helpers'
import modalProps from '../../../modalProps'
import initializeEditor from '../../../../../Screens/Consignment/Components/Editor/Services/initialize'

export default () => (dispatch) => {

  const { goodsOutRecord } = modalProps()
  
  dispatch(push(getTeamUrl('consignments/new')))

  const initialValues = {
    date: moment(goodsOutRecord.date_time),
    stock_location_id: goodsOutRecord.stock_location_id,
    customer_id: goodsOutRecord.customer_id,
    customer_site_id: goodsOutRecord.customer_site_id,
    goods_out_record_id: goodsOutRecord.id,
  }

  defer(() => {
    dispatch(initializeEditor(initialValues))
  })

}
