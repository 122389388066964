import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { isWarehouse } from 'Traqa/Helpers'

import Login from './Login'
import Home from './Home'
import Temp from './Temp'

import Dashboard from './Dashboard'

import Products from './Products'
import Product from './Product'
import ProductEditor from './ProductEditor'

import StockAssessment from './StockAssessment'
import StockAdjustments from './StockAdjustments'

import PurchaseOrders from './PurchaseOrders'
import PurchaseOrder from './PurchaseOrder'

import GoodsInRecords from './GoodsInRecords'
import GoodsInRecord from './GoodsInRecord'

import SalesOrders from './SalesOrders'
import SalesOrder from './SalesOrder'

import GoodsOutRecords from './GoodsOutRecords'
import GoodsOutRecord from './GoodsOutRecord'

import Consignments from './Consignments'
import Consignment from './Consignment'

import Transfers from './Transfers'
import Transfer from './Transfer'

import Assemblies from './Assemblies'
import Assembly from './Assembly'

import Stocktakes from './Stocktakes'
import Stocktake from './Stocktake'
import Reconciliation from './Reconciliation'

import Transactions from './Transactions'
import Reading from './Reading'
import Adjustment from './Adjustment'

import Users from './Users'
import User from './User'

import Customers from './Customers'

import Suppliers from './Suppliers'

import ProductCategories from './ProductCategories'

import StockLocations from './StockLocations'

import Couriers from './Couriers'
import Courier from './Courier'

import PurchasePlanner from './PurchasePlanner'

import PlasticTax from './PlasticTax'

import Demand from './Demand'

export default () => {

  const warehouse = isWarehouse()

  return (

    <Switch>

      <Route path="/login" exact component={Login} />

      <Route path="/app/teams/:slug" exact component={Home} />

      {!warehouse && <Route path="/app/teams/:slug/dashboard" exact component={Dashboard} />}

      {!warehouse && <Route path="/app/teams/:slug/products" exact component={Products} />}

      {!warehouse && <Route path="/app/teams/:slug/products/new" exact component={ProductEditor} />}

      {!warehouse && <Route path="/app/teams/:slug/products/:id" exact component={Product} />}

      {!warehouse && <Route path="/app/teams/:slug/products/:id/edit" exact component={ProductEditor} />}

      <Route path="/app/teams/:slug/stock_assessment" exact component={StockAssessment} />

      {!warehouse && <Route path="/app/teams/:slug/stock_adjustments" exact component={StockAdjustments} />}

      {!warehouse && <Route path="/app/teams/:slug/purchase_orders" exact component={PurchaseOrders} />}

      {!warehouse && <Route path="/app/teams/:slug/purchase_orders/:id" exact component={PurchaseOrder} />}

      {!warehouse && <Route path="/app/teams/:slug/sales_orders" exact component={SalesOrders} />}

      {!warehouse && <Route path="/app/teams/:slug/sales_orders/:id" exact component={SalesOrder} />}

      {!warehouse && <Route path="/app/teams/:slug/stocktakes" exact component={Stocktakes} />}

      {!warehouse && <Route path="/app/teams/:slug/stocktakes/:id" exact component={Stocktake} />}

      <Route path="/app/teams/:slug/goods_in_records" exact component={GoodsInRecords} />

      <Route path="/app/teams/:slug/goods_in_records/:id" exact component={GoodsInRecord} />

      <Route path="/app/teams/:slug/goods_out_records" exact component={GoodsOutRecords} />

      <Route path="/app/teams/:slug/goods_out_records/:id" exact component={GoodsOutRecord} />

      <Route path="/app/teams/:slug/consignments" exact component={Consignments} />

      <Route path="/app/teams/:slug/consignments/:id" exact component={Consignment} />

      <Route path="/app/teams/:slug/transfers" exact component={Transfers} />

      <Route path="/app/teams/:slug/transfers/:id" exact component={Transfer} />

      {!warehouse && <Route path="/app/teams/:slug/assemblies" exact component={Assemblies} />}

      {!warehouse && <Route path="/app/teams/:slug/assemblies/:id" exact component={Assembly} />}

      {!warehouse && <Route path="/app/teams/:slug/stocktakes/:id/reconciliation" exact component={Reconciliation} />}

      {!warehouse && <Route path="/app/teams/:slug/transactions" exact component={Transactions} />}

      {!warehouse && <Route path="/app/teams/:slug/readings/:id" exact component={Reading} />}

      {!warehouse && <Route path="/app/teams/:slug/adjustments/:id" exact component={Adjustment} />}

      {!warehouse && <Route path="/app/teams/:slug/users" exact component={Users} />}

      {!warehouse && <Route path="/app/teams/:slug/users/:id" exact component={User} />}

      {!warehouse && <Route path="/app/teams/:slug/customers" exact component={Customers} />}

      {!warehouse && <Route path="/app/teams/:slug/suppliers" exact component={Suppliers} />}

      {!warehouse && <Route path="/app/teams/:slug/product_categories" exact component={ProductCategories} />}

      {!warehouse && <Route path="/app/teams/:slug/stock_locations" exact component={StockLocations} />}

      {!warehouse && <Route path="/app/teams/:slug/couriers" exact component={Couriers} />}

      {!warehouse && <Route path="/app/teams/:slug/couriers/:id" exact component={Courier} />}

      {!warehouse && <Route path="/app/teams/:slug/purchase_planner" exact component={PurchasePlanner} />}

      {!warehouse && <Route path="/app/teams/:slug/planned_purchases/:id" exact component={PurchasePlanner} />}

      {!warehouse && <Route path="/app/teams/:slug/plastic_tax" exact component={PlasticTax} />}

      {!warehouse && <Route path="/app/teams/:slug/demand" exact component={Demand} />}

      <Route path="/app/teams/:slug/" component={Temp} />

    </Switch>

  )

}
