import { connect } from "react-redux"
import GroupFooter from "./GroupFooter"

import createComponentPropsSelector from '../../Selectors/createComponentPropsSelector'

import onAddItem from './Handlers/onAddItem'

const mapStateToProps = (state, props) => {
  
  const componentPropsSelector = createComponentPropsSelector()

  const componentProps = componentPropsSelector(state, props)

  return {
    ...componentProps
  }
}

const actionCreators = {
  onAddItem,
}

export default connect(mapStateToProps, actionCreators)(GroupFooter)
