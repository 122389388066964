import { combineReducers } from 'redux'

import { reducer as activeTab } from './State/activeTab'
import { reducer as holdingControls } from './State/holdingControls'
import { reducer as movementControls } from './State/movementControls'
import { reducer as holdingValue } from './State/holdingValue'
import { reducer as holdingWeeks } from './State/holdingWeeks'
import { reducer as movement } from './State/movement'
import { reducer as isFetchingHoldingValue } from './State/isFetchingHoldingValue'
import { reducer as isFetchingHoldingWeeks } from './State/isFetchingHoldingWeeks'
import { reducer as isFetchingMovement } from './State/isFetchingMovement'

export default combineReducers({
  activeTab,
  holdingControls,
  movementControls,
  holdingValue,
  holdingWeeks,
  movement,
  isFetchingHoldingValue,
  isFetchingHoldingWeeks,
  isFetchingMovement,
})
