import { connect } from "react-redux"
import MoreMenu from "./MoreMenu"

import onDelete from './Handlers/onDelete'

const mapStateToProps = () => ({
})

const actionCreators = {
  onDelete,
}

export default connect(mapStateToProps, actionCreators)(MoreMenu)
