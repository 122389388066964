import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'

import Form from './Components/Form'

export default class GoodsInRecordItem extends React.PureComponent {

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {

    const {
      submitting,
      onSubmit,
      onClose,
    } = this.props

    const footer = [
      <Button key='add' type='primary' loading={submitting} onClick={onSubmit}>
        Add
      </Button>,
    ]

    return (
        
      <Modal
        visible
        title='Add Product To Planned Purchase'
        width={650}
        okText='Save'
        footer={footer}
        onCancel={() => onClose()}
      >
        
        <Form />

      </Modal>
      
    )

  }

}
