import { connect } from 'react-redux'
import Activity from './Activity'

import onToggle from './Handlers/onToggle'

const mapStateToProps = (state, props) => ({
  isExpanded: state.modules.Activity.expandedActivities.indexOf(props.activity.key) !== -1,
})

const actionCreators = {
  onToggle,
}

export default connect(mapStateToProps, actionCreators)(Activity)
