import { firstPage } from '../../../State/pagination'
import { setActiveTab } from '../../../State/activeTab'
import fetch from '../../../Services/fetch'

export default () => (dispatch) => {

  dispatch(firstPage())

  dispatch(setActiveTab('All'))

  dispatch(fetch())
  
}