import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field, FormSpy } from 'react-final-form'
import { Form, Input, Select } from 'antd'
import { ExposeFormToHelper } from 'Traqa/Packages'
import formService from './formService'
import styles from './Component.css'

export default class _Form_ extends React.PureComponent {

  static propTypes = {
    users: PropTypes.array.isRequired,
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  render () {

    const {
      users,
      onChangeFormState,
      onSubmit,
      validate,
    } = this.props
    
    return (

      <FormProvider
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, submitting }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            autoComplete="off"
          >

            <ExposeFormToHelper helper={formService} />

            <FormSpy subscription={{ submitting: true }} onChange={onChangeFormState} />

            <Field name="name">
              {({ input, meta }) => (
                <Form.Item
                  label="Name"
                  required
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  help={(meta.touched && meta.error) ? meta.error : undefined}
                >
                  <Input
                    {...input}
                    disabled={submitting}
                  />
                </Form.Item>
              )}
            </Field>

          </Form>

        )}

      </FormProvider>

    )
  }
}