import { connect } from 'react-redux'
import StockshotsPopover from './StockshotsPopover'

import onVisibleChange from './Handlers/onVisibleChange'

const mapStateToProps = () => ({
  //
})

const actionCreators = {
  onVisibleChange,
}

export default connect(mapStateToProps, actionCreators)(StockshotsPopover)
