import { getTeamUrl } from 'Traqa/Helpers'
import getParams from './getParams'

export default () => async (dispatch, getState) => {

  const params = getParams(getState)

  let path = `transactions/export?`

  Object.keys(params).forEach(key => {
    path = `${path}&${key}=${params[key]}`
  })

  window.location = getTeamUrl(path, 'exports')

}
