import { connect } from 'react-redux'
import Footer from './Footer'

import onClickExport from './Handlers/onClickExport'
import onChangeControls from './Handlers/onChangeControls'

const mapStateToProps = (state) => ({
  controls: state.screens.Demand.Report.controls,
})

const actionCreators = {
  onClickExport,
  onChangeControls,
}

export default connect(mapStateToProps, actionCreators)(Footer)
