import { createSelector } from 'reselect'
import { find } from 'lodash'
import pluralize from 'pluralize'

const selectHoldingValue = state => state.screens.Dashboard.ColumnCharts.holdingValue
const selectIsFetchingHoldingValue = state => state.screens.Dashboard.ColumnCharts.isFetchingHoldingValue

export default createSelector(
  selectHoldingValue,
  selectIsFetchingHoldingValue,
  (holdingValue, isFetchingHoldingValue) => {

    const { stock_locations: stockLocations, products } = holdingValue

    return {
      chart: {
        type: 'column',
        width: 1120,
        height: 350,
        events: {
          load() {
            // eslint-disable-next-line no-unused-expressions
            isFetchingHoldingValue ? this.showLoading() : this.hideLoading()
          },
        },
      },
      title: {
        text: 'Holding by Value (£)',
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      xAxis: {
        categories: products.map(product => product.code),
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Value (£)',
        },
        stackLabels: {
          enabled: true,
          format: `£{total:,.0f}`,
        },
      },
      tooltip: {
        pointFormat: '<b>{series.name}</b><br />{point.form}: <b>{point.quantity:,.0f}</b><br />Value: <b>£{point.y:,.0f}</b>',
      },
      series: stockLocations.map(stockLocation => ({
        name: stockLocation.name,
        data: products.map(product => {
          const { actual } = find(product.stock_locations, row => row.id === stockLocation.id)
          return {
            y: actual.quantity_value || false,
            form: pluralize(product.form),
            quantity: actual.quantity,
            color: stockLocation.colour,
          }
        }),
        showInLegend: !!products.filter(product => find(product.stock_locations, row => row.id === stockLocation.id).actual.quantity_value > 0).length
      })),
    }
    
  },
)
