import { connect } from 'react-redux'
import Table from './Table'

import selectRows from './Selectors/selectRows'

import onClickRow from './Handlers/onClickRow'

const mapStateToProps = (state) => ({
  rows: selectRows(state),
  isFetching: state.screens.Reconciliation.Report.isFetching,
})

const actionCreators = {
  onClickRow,
}

export default connect(mapStateToProps, actionCreators)(Table)
