import { combineReducers } from 'redux'

import { reducer as initialValues } from './State/initialValues'
import { reducer as formState } from './State/formState'
import { reducer as selectedItems } from './State/selectedItems'
import { reducer as deliveryNotes } from './State/deliveryNotes'

export default combineReducers({
  initialValues,
  formState,
  selectedItems,
  deliveryNotes,
})
