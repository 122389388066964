import React from 'react'
import PropTypes from 'prop-types'
import { Button, Spin } from 'antd'
import numeral from 'numeral'
import { Module, Detail } from 'Traqa/Packages'
import styles from './styles.css'

const Footer = props => {

  const {
    totals,
    isFetchingTotals,
    onClickExport,
  } = props

  const renderLeft = () => (

    <div className={styles.wrapper}>

      <Button
        icon='download'
        onClick={onClickExport}
      >
        Export
      </Button>

      <Detail
        label='In Qty'
        className={styles.detail}
        contentClassName={styles.detailContent}
      >
        {isFetchingTotals && <Spin size='small' />}
        {!isFetchingTotals && numeral(totals.in).format('0,0')}
      </Detail>

      <Detail
        label='In Value'
        className={styles.detail}
        contentClassName={styles.detailContent}
      >
        {isFetchingTotals && <Spin size='small' />}
        {!isFetchingTotals && `£${numeral(totals.in_value).format('0,0')}`}
      </Detail>

      <Detail
        label='Out Qty'
        className={styles.detail}
        contentClassName={styles.detailContent}
      >
        {isFetchingTotals && <Spin size='small' />}
        {!isFetchingTotals && numeral(totals.out).format('0,0')}
      </Detail>

      <Detail
        label='Out Value'
        className={styles.detail}
        contentClassName={styles.detailContent}
      >
        {isFetchingTotals && <Spin size='small' />}
        {!isFetchingTotals && `£${numeral(totals.out_value).format('0,0')}`}
      </Detail>

    </div>

  )
  
  return (

    <Module.Footer
      renderLeft={renderLeft}
    />

  )

}

Footer.propTypes = {
  totals: PropTypes.object.isRequired,
  isFetchingTotals: PropTypes.bool.isRequired,
  onClickExport: PropTypes.func.isRequired,
}

export default Footer
