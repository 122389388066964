import { message } from 'antd'
import { unarchiveSalesOrder } from '../../../Api/salesOrders'
import fetch from './fetch'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { salesOrder } = getState().screens.SalesOrder

    await unarchiveSalesOrder({ id: salesOrder.id })

    await dispatch(fetch())

    message.success(`Unarchived Sales Order "${salesOrder.number}"`)

  } catch (error) {

    handleException(error)

  }
}