import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Table as AntTable, Icon } from 'antd'
import numeral from "numeral"
import moment from "moment"
import classNames from "classnames"
import { getTeamUrl } from 'Traqa/Helpers'
import styles from "./Table.css"

const Table = (props) => {

  const {
    data,
    isFetching,
    onChange,
  } = props
  
  return (

    <AntTable
      dataSource={data}
      loading={isFetching}
      rowKey="id"
      pagination={false}
      onChange={onChange}
    >

      <AntTable.Column
        key='id'
        title="No."
        sorter
        render={(text, record) => (
          <NavLink key={record.id} to={getTeamUrl(`stocktakes/${record.id}`)}>
            {record.id}
          </NavLink>
        )}
      />

      <AntTable.Column
        key='stock_location.name'
        title="Location"
        sorter
        render={(text, record) => record.stock_location.name}
      />

      <AntTable.Column
        key='date_time'
        title='Date / Time'
        sorter
        render={(text, record) => record.date_time ? moment(record.date_time).calendar(null, {
          sameDay: '[Today], h:mm a',
          nextDay: '[Tomorrow], h:mm a',
          lastDay: '[Yesterday], h:mm a',
          nextWeek: 'D MMM YYYY [at] h:mm a',
          lastWeek: 'D MMM YYYY [at] h:mm a',
          sameElse: 'D MMM YYYY [at] h:mm a',
        }) : null}
      />

      <AntTable.Column
        key='total_value'
        title="Total Value"
        sorter
        className={styles.numericColumn}
        render={(text, record) => `£${numeral(record.total_value).format('0,0')}`}
      />

      <AntTable.Column
        key='user.name'
        title="User"
        sorter
        render={(text, record) => record.user.name}
      />

      <AntTable.Column
        key='status'
        title="Status"
        sorter
        render={(text, record) => {

          if (record.status === 'Draft') {
            return (
              <div className={styles.status}>
                <Icon type="edit" className={classNames(styles.icon, styles.draft)} />
                Draft
              </div>
            )
          }

          if (record.status === 'Published') {
            return (
              <div className={styles.status}>
                <Icon type="check-circle" className={classNames(styles.icon, styles.completed)} />
                Published
              </div>
            )
          }

          return null
          
        }}
      />

    </AntTable>

  )
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Table