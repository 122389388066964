import React from 'react'
import PropTypes from 'prop-types'
import { Pagination } from 'antd'
import { Module } from 'Traqa/Packages'

const Footer = (props) => {

  const {
    pagination,
    onChangePagination,
  } = props

  const renderRight = () => (

    <Pagination
      current={pagination.pageNumber}
      pageSize={pagination.pageSize}
      total={pagination.total}
      showSizeChanger
      showTotal={total => `Total: ${total} Couriers`}
      pageSizeOptions={['10', '20', '30', '40', '50']}
      onChange={onChangePagination}
      onShowSizeChange={onChangePagination}
    />

  )
  
  return (

    <Module.Footer
      renderRight={renderRight}
    />

  )

}

Footer.propTypes = {
  pagination: PropTypes.object.isRequired,
  onChangePagination: PropTypes.func.isRequired,
}

export default Footer
