import { message } from 'antd'
import { deleteMedia } from '../../../Api/media'
import fetch from './fetch'
import modalProps from '../modalProps'
import { handleException } from 'Traqa/Packages'

export default (id, onDelete = null) => async (dispatch) => {

  try {

    await deleteMedia({ id })

    if (onDelete) onDelete()

    dispatch(fetch())

    modalProps().onSubmit()

    message.success('Image deleted')

  } catch (error) {

    handleException(error)

    throw error

  }

}
