import { createAction, handleActions } from 'redux-actions'

const loadCourierZones = createAction('CORE/COURIERS/LOAD_COURIER_ZONES')

const defaultState = []

const reducer = handleActions(
  {
    [loadCourierZones]: (state, action) => action.payload,
  },
  defaultState,
)

export { loadCourierZones, reducer }
