import React from "react"
import PropTypes from "prop-types"
import Screen from '../../Components/Screen'
import { Tabs } from 'antd'

import TransactionsTable from "../../Modules/TransactionsTable"
import Adjustments from "./Components/Adjustments"
import Readings from "./Components/Readings"

export default class extends React.PureComponent {

  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onChangeActiveTab: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  render () {

    const {
      activeTab,
      onChangeActiveTab,
    } = this.props

    return (

      <Screen title="Transactions">

        <Tabs
          activeKey={activeTab}
          type='card'
          onChange={onChangeActiveTab}
          style={{ height: '40px' }}
        >
          <Tabs.TabPane tab='Transactions' key='1' />
          <Tabs.TabPane tab='Allocations' key='2' />
          <Tabs.TabPane tab='Scheduled' key='3' />
          <Tabs.TabPane tab='Readings' key='4' />
          <Tabs.TabPane tab='Adjustments' key='5' />
        </Tabs>

        {activeTab === '1' && <TransactionsTable format='transactions' />}

        {activeTab === '2' && <TransactionsTable format='allocations' />}

        {activeTab === '3' && <TransactionsTable format='expectations' />}

        {activeTab === '4' && <Readings />}

        {activeTab === '5' && <Adjustments />}

      </Screen>

    )
  }
}
