import { createSelector } from 'reselect'

const selectUnallocatedOnly = state => state.screens.StockAssessment.Report.formState.values.unallocatedOnly
const selectIncludeExpected = state => state.screens.StockAssessment.Report.formState.values.includeExpected
const selectTotals = state => state.screens.StockAssessment.Report.totals

export default createSelector(
  selectUnallocatedOnly,
  selectIncludeExpected,
  selectTotals,
  (unallocatedOnly, includeExpected, totals) => {

    const key1 = includeExpected ? 'expected' : 'actual'
    const key2 = unallocatedOnly ? 'unallocated' : 'quantity'

    return {
      quantity: totals[key1][key2],
      value: totals[key1][`${key2}_value`],
    }

  },
)
