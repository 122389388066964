import { createAction, handleActions } from 'redux-actions'

const loadCourierServices = createAction('CORE/COURIERS/LOAD_COURIER_SERVICES')

const defaultState = []

const reducer = handleActions(
  {
    [loadCourierServices]: (state, action) => action.payload,
  },
  defaultState,
)

export { loadCourierServices, reducer }
