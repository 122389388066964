import React from 'react'
import PropTypes from 'prop-types'
import { Table as TableProvider } from 'antd'
import numeral from 'numeral'
import classNames from 'classnames'
import styles from './styles.css'

const Table = props => {

  const {
    products,
    isFetching,
    onClickRow,
  } = props

  return (
    
    <TableProvider
      dataSource={products}
      rowKey='key'
      size='small'
      loading={isFetching}
      onRow={record => ({
        onClick: () => onClickRow(record),
        className: classNames({
          [styles.clickable]: record.type !== 'landed-cost',
        }),
      })}
      pagination={{
        defaultPageSize: 20,
        style: {
          paddingRight: 15,
          marginBottom: -65,
        },
      }}
    >

      <TableProvider.Column
        key='title'
        title='Product'
        onCell={record => ({
          className: classNames({
            [styles.stockLocation]: record.type === 'stock-location',
          }),
        })}
        render={(text, record) => record.title}
      />

      <TableProvider.Column
        key='quantity'
        title='Qty'
        width={160}
        onHeaderCell={() => ({
          className: styles.numeric,
        })}
        onCell={record => ({
          className: classNames(styles.numeric, {
            [styles.stockLocation]: record.type === 'stock-location',
          }),
        })}
        render={(text, record) => numeral(Math.floor(record.quantity)).format('0,0')}
      />

      <TableProvider.Column
        key='average'
        title='Value'
        width={160}
        onHeaderCell={() => ({
          className: styles.numeric,
        })}
        onCell={record => ({
          className: classNames(styles.numeric, {
            [styles.stockLocation]: record.type === 'stock-location',
          }),
        })}
        render={(text, record) => `£${numeral(record.average).format('0,0.00')}`}
      />

      <TableProvider.Column
        key='value'
        title='Total Value'
        width={160}
        onHeaderCell={() => ({
          className: styles.numeric,
        })}
        onCell={record => ({
          className: classNames(styles.numeric, {
            [styles.stockLocation]: record.type === 'stock-location',
            [styles.bold]: record.type === 'product',
          }),
        })}
        render={(text, record) => `£${numeral(record.value).format('0,0.00')}`}
      />

    </TableProvider>

  )
  
}

Table.propTypes = {
  products: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onClickRow: PropTypes.func.isRequired,
}

export default Table
