import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Menu, Button } from 'antd'
import numeral from 'numeral'
import { Module, Detail } from 'Traqa/Packages'
import styles from './styles.css'

const Footer = (props) => {

  const {
    totals,
    onClickExport,
  } = props

  const renderLeft = () => (

    <div className={styles.wrapper}>

      <Dropdown
        trigger={['click']}
        overlay={(
          <Menu>
            <Menu.Item key='overview' onClick={() => onClickExport('overview')}>Overview</Menu.Item>
            <Menu.Item key='detailed' onClick={() => onClickExport('detailed')}>Detailed</Menu.Item>
          </Menu>
        )}
      >
        <Button
          icon='download'
        >
          Export
        </Button>
      </Dropdown>

    </div>

  )

  const renderRight = () => (

    <React.Fragment>

      <Detail
        label='Net'
        className={styles.detail}
        contentClassName={styles.content}
      >
        {`${numeral(totals.weight_kgs).format('0,0')} kg`}
      </Detail>

      <Detail
        label='Recycled'
        className={styles.detail}
        contentClassName={styles.content}
      >
        {`${numeral(totals.recycled_kgs).format('0,0')} kg`}
      </Detail>

      <Detail
        label='Taxable'
        className={styles.detail}
        contentClassName={styles.content}
      >
        {`${numeral(totals.taxable_kgs).format('0,0')} kg`}
      </Detail>

      <Detail
        label='Tax'
        className={styles.detail}
        contentClassName={styles.content}
      >
        {`£${numeral(totals.tax_amount).format('0,0.00')}`}
      </Detail>

    </React.Fragment>

  )

  return (

    <Module.Footer
      renderLeft={renderLeft}
      renderRight={renderRight}
      border
    />

  )
  
}

Footer.propTypes = {
  totals: PropTypes.object.isRequired,
  onClickExport: PropTypes.func.isRequired,
}

export default Footer
