import { createAction, handleActions } from 'redux-actions'

const openAddProductToGoodsInRecordModal = createAction('SCREENS/PURCHASE_PLANNER/OPEN_ADD_PRODUCT_TO_GOODS_IN_RECORD_MODAL')
const closeAddProductToGoodsInRecordModal = createAction('SCREENS/PURCHASE_PLANNER/CLOSE_ADD_PRODUCT_TO_GOODS_IN_RECORD_MODAL')

const defaultState = {
  show: false,
  goodsInRecord: null,
}

const reducer = handleActions(
  {
    [openAddProductToGoodsInRecordModal]: (state, action) => Object.assign({}, state, {
      show: true,
      goodsInRecord: action.payload.goodsInRecord,
    }),
    [closeAddProductToGoodsInRecordModal]: () => Object.assign({}, defaultState),
  },
  defaultState
)

export { openAddProductToGoodsInRecordModal, closeAddProductToGoodsInRecordModal, reducer }
