import fetchCustomers from '../../../Core/Services/fetchCustomers'
import fetchCustomerSites from '../../../Core/Services/fetchCustomerSites'
import fetchUsers from '../../../Core/Services/fetchUsers'
import fetchTableData from '../Components/Table/Services/fetch'

export default () => (dispatch) => {

  dispatch(fetchUsers())
  dispatch(fetchCustomers())
  dispatch(fetchCustomerSites())

  dispatch(fetchTableData())
  
}
