import { closeUserModal } from '../State/userModal'
import fetch from '../Services/fetch'

export default (shouldReload = false) => (dispatch) => {
  
  dispatch(closeUserModal())
  
  if (shouldReload) dispatch(fetch())
  
}
