import { createAction, handleActions } from 'redux-actions'

const openPlasticTaxRateModal = createAction('SCREENS/PLASTIC_TAX/REPORT/OPEN_PLASTIC_TAX_RATE_MODAL')
const closePlasticTaxRateModal = createAction('SCREENS/PLASTIC_TAX/REPORT/CLOSE_PLASTIC_TAX_RATE_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openPlasticTaxRateModal]: (state, { payload }) => ({
      show: true,
      ...payload,
    }),
    [closePlasticTaxRateModal]: () => defaultState,
  },
  defaultState,
)

export { openPlasticTaxRateModal, closePlasticTaxRateModal, reducer }
