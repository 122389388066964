import { getStocktakes } from '../../../../../Api/stocktakes'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { setPagination } from '../State/pagination'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { advanced, searchTerm, sorting, pagination } = getState().screens.Stocktakes.StocktakesTable

    const params = {}

    params.include = [
      'stock_location',
      'user',
    ].join()

    if (advanced.show) {

      const {
        keywords,
        stockLocationIds,
        userIds,
      } = advanced.values

      if (keywords) params.search = keywords

      if (stockLocationIds) params['filter[stock_location_id]'] = stockLocationIds.join(',')

      if (userIds) params['filter[user_id]'] = userIds.join(',')

    } else if (searchTerm) {
      
      params.search = searchTerm

    }

    params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : '-date_time'
    
    params['page[number]'] = pagination.pageNumber
    params['page[size]'] = pagination.pageSize

    dispatch(startFetching())

    const response = await getStocktakes({ params })

    dispatch(setData(response.data.data))
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
    }))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }
}