import screenProps from '../../../../../screenProps'
import { openGoodsInRecordModal } from '../../../../../State/goodsInRecordModal'

export default () => (dispatch, getState) => {

  const { id } = screenProps().match.params

  const { formState, selectedItems } = getState().screens.PurchaseOrder.Editor

  let purchaseOrderItems = formState.values.purchase_order_items;

  if (selectedItems.length > 0) purchaseOrderItems = purchaseOrderItems.filter(item => selectedItems.includes(item.guid))
  
  dispatch(openGoodsInRecordModal({
    purchaseOrderId: id,
    purchaseOrderItemIds: purchaseOrderItems.map(purchaseOrderItem => purchaseOrderItem.id),
  }))
  
}
