import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Input, Select, DatePicker, Switch, Popover, List, Button } from 'antd'
import moment from 'moment'
import countryList from 'react-select-country-list'
import { Module } from 'Traqa/Packages'
import styles from './styles.css'

const Box = props => <div className={styles.box}>{props.children}</div>

const Label = props => <div className={styles.label}>{props.text}</div>

const Controls = props => {

  const {
    suppliers,
    plasticTaxRates,
    filters,
    isFiltered,
    onChangeSearch,
    onSearch,
    onChangeFilter,
    onNewPlasticTaxRate,
    onClickPlasticTaxRate,
  } = props

  return (

    <Module.Section
      borderBottom
      contentStyle={{ padding: 15 }}
    >

      <Row gutter={12}>

        <Col span={4}>

          <Input.Search
            value={filters.search}
            onChange={e => onChangeSearch(e.target.value)}
            onSearch={onSearch}
            placeholder='Search..'
            style={{ width: '100%' }}
          />

        </Col>

        <Col span={1}>
          
          <Popover
            trigger={['click']}
            placement='bottom'
            content={(

              <div className={styles.filters}>

                <Box>
                  <Label text='Supplier' />
                  <Select
                    value={filters.supplierId}
                    onChange={value => onChangeFilter('supplierId', value)}
                    mode='multiple'
                    showSearch
                    optionFilterProp='children'
                    allowClear
                    placeholder='All Suppliers'
                    style={{ width: '100%' }}
                  >
                    {suppliers.map(({ id, name }) => (
                      <Select.Option key={id} value={id}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </Box>

                <Box>
                  <Label text='Country' />
                  <Select
                    value={filters.countryCode}
                    onChange={value => onChangeFilter('countryCode', value)}
                    mode='multiple'
                    showSearch
                    optionFilterProp='children'
                    allowClear
                    placeholder='All Countries'
                    style={{ width: '100%' }}
                  >
                    {countryList().getData().map(({ label, value }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    ))}
                  </Select>
                </Box>

                <Box>
                  <Label text='Status' />
                  <Select
                    value={filters.status}
                    onChange={value => onChangeFilter('status', value)}
                    mode='multiple'
                    showSearch
                    optionFilterProp='children'
                    allowClear
                    placeholder='All Statuses'
                    style={{ width: '100%' }}
                  >
                    <Select.Option key='Draft' value='Draft'>Draft</Select.Option>
                    <Select.Option key='Speculated' value='Speculated'>Scheduled</Select.Option>
                    <Select.Option key='Expected' value='Expected'>Expected</Select.Option>
                    <Select.Option key='Completed' value='Completed'>Completed</Select.Option>
                  </Select>
                </Box>

                <Box>
                  <Label text='Overdue' />
                  <Switch
                    value={filters.overdue}
                    onChange={value => onChangeFilter('overdue', value)}
                  />
                </Box>

              </div>

            )}
          >
            <Button type={isFiltered ? 'primary' : 'default'} icon='filter'>Filter</Button>
          </Popover>

        </Col>

        <Col span={1} offset={13}>
          <Popover
            trigger='hover'
            placement='bottom'
            title={(
              <div className={styles.title}>
                <div>Tax Rates</div>
                <a onClick={onNewPlasticTaxRate}>New</a>
              </div>
            )}
            overlayClassName={styles.rates}
            content={(
              <List
                className={styles.list}
                dataSource={plasticTaxRates}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={moment(item.effective_from).format('Do MMMM YYYY')}
                      description={`${item.recycled_perc}% recycled | £${item.rate_per_tonne} per tonne`}
                      onClick={() => onClickPlasticTaxRate(item)}
                    />
                  </List.Item>
                )}
              />
            )}
          >
            <Button type='dashed' icon='pound' style={{ width: '100%' }} />
          </Popover>
        </Col>

        <Col span={5}>

          <DatePicker.RangePicker
            value={filters.dateBetween}
            onChange={value => onChangeFilter('dateBetween', value)}
            format='DD/MM/YYYY'
            allowClear
            placeholder={['Start', 'End']}
            style={{ width: '100%' }}
          />

        </Col>

      </Row>

    </Module.Section>

  )
  
}

Box.propTypes = {
  children: PropTypes.node.isRequired,
}

Label.propTypes = {
  text: PropTypes.string.isRequired,
}

Controls.propTypes = {
  suppliers: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  isFiltered: PropTypes.bool.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onNewPlasticTaxRate: PropTypes.func.isRequired,
  onClickPlasticTaxRate: PropTypes.func.isRequired,
}

export default Controls
