import React from 'react'
import PropTypes from 'prop-types'
import { Field, FormSpy } from 'react-final-form'
import { Form, Row, Col, Select, Input, InputNumber, DatePicker } from 'antd'
import { Module } from 'Traqa/Packages'

const Details = (props) => {

  const {
    suppliers,
    users,
    isNew,
  } = props
  
  return (

    <Module.Section
      contentStyle={{ paddingBottom: 15 }}
    >

      <FormSpy subscription={{ submitting: true }}>

        {({ submitting }) => (

          <React.Fragment>

            <Row gutter={8}>

              <Col span={3}>

                <Field name="number">
                  {({ input, meta }) => (
                    <Form.Item
                      label="PO No."
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Input
                        {...input}
                        disabled={submitting}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={6}>

                <Field name="supplier_id">
                  {({ input, meta }) => (
                    <Form.Item
                      label="Supplier"
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        disabled={submitting}
                        optionFilterProp="children"
                        showSearch
                        allowClear
                        dropdownMatchSelectWidth={false}
                      >
                        <Select.OptGroup label='Active'>
                          {suppliers.filter(supplier => !supplier.archived_at).map(supplier => (
                            <Select.Option key={supplier.id} value={supplier.id}>
                              {supplier.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                        <Select.OptGroup label='Archived'>
                          {suppliers.filter(supplier => !!supplier.archived_at).map(supplier => (
                            <Select.Option key={supplier.id} value={supplier.id} style={{ color: '#999' }}>
                              {supplier.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                      </Select>
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={4}>

                <Field name="description">
                  {({ input, meta }) => (
                    <Form.Item
                      label="Description"
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Input
                        {...input}
                        disabled={submitting}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={3}>

                <Field name="date" allowNull>
                  {({ input, meta }) => (
                    <Form.Item
                      label="Date"
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <DatePicker
                        {...input}
                        disabled={submitting}
                        format="DD/MM/YYYY"
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={3}>

                <Field name='commercial_value_usd'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Value ($)'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        disabled={submitting}
                        min={0}
                        step={1}
                        precision={2}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={4}>

                <Field name="user_id">
                  {({ input, meta }) => (
                    <Form.Item
                      label="User"
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        disabled={submitting}
                        optionFilterProp="children"
                        showSearch
                        allowClear
                      >
                        {users.filter(user => !isNew || !user.deactivated_at).map(user => (
                          <Select.Option key={user.id} value={user.id}>
                            {user.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Field>

              </Col>

            </Row>

            <Row gutter={8}>

              <Col span={6}>

                <Field name="freight_id">
                  {({ input, meta }) => (
                    <Form.Item
                      label="Freight"
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        disabled={submitting}
                        optionFilterProp="children"
                        showSearch
                        allowClear
                        dropdownMatchSelectWidth={false}
                      >
                        <Select.OptGroup label='Active'>
                          {suppliers.filter(supplier => !supplier.archived_at).map(supplier => (
                            <Select.Option key={supplier.id} value={supplier.id}>
                              {supplier.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                        <Select.OptGroup label='Archived'>
                          {suppliers.filter(supplier => !!supplier.archived_at).map(supplier => (
                            <Select.Option key={supplier.id} value={supplier.id} style={{ color: '#999' }}>
                              {supplier.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                      </Select>
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={4}>

                <Field name="freight_po_number">
                  {({ input, meta }) => (
                    <Form.Item
                      label="Freight PO No."
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Input
                        {...input}
                        disabled={submitting}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={3}>

                <Field name='freight_cost'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Freight Cost'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        disabled={submitting}
                        min={0}
                        step={1}
                        precision={2}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={3}>

                <Field name='duty_cost'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Duty Cost'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        disabled={submitting}
                        min={0}
                        step={1}
                        precision={2}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={3}>

                <Field name='exchange_rate'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Exchange Rate'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        disabled={submitting}
                        min={0}
                        step={1}
                        precision={2}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

            </Row>

          </React.Fragment>
      
        )}

      </FormSpy>
  
    </Module.Section>

  )
}

Details.propTypes = {
  suppliers: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  isNew: PropTypes.bool.isRequired,
}

Details.defaultProps = {
  
}

export default Details