import React from 'react'
import PropTypes from 'prop-types'
import { LoginScreen } from 'Traqa/Packages'

export default class Login extends React.PureComponent {

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  }

  render = () => (
    
    <LoginScreen
      onSubmit={this.props.onSubmit}
      usernameIsEmail
      usernamePlaceholder="your.name@gvhealth.com"
    />

  )
}