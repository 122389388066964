import { delay } from 'lodash'
import fetchNotifications from '../Services/fetchNotifications'
import checkForNotifications from '../Services/checkForNotifications'
import { resetFilter } from '../State/filter'

export default (visible) => (dispatch) => {

  if (visible) {
    dispatch(fetchNotifications())
    dispatch(checkForNotifications())
  }
  
  if (!visible) {
    delay(() => {
      dispatch(resetFilter())
    }, 300)
  }
  
}