import { createAction, handleActions } from 'redux-actions'

const openGoodsOutRecordModal = createAction('SCREENS/SALES_ORDER/OPEN_GOODS_OUT_RECORD_MODAL')
const closeGoodsOutRecordModal = createAction('SCREENS/SALES_ORDER/CLOSE_GOODS_OUT_RECORD_MODAL')

const defaultState = {
  show: false,
  salesOrderId: null,
  salesOrderItemIds: [],
}

const reducer = handleActions(
  {
    [openGoodsOutRecordModal]: (state, action) => Object.assign({}, state, {
      show: true,
      salesOrderId: action.payload.salesOrderId,
      salesOrderItemIds: action.payload.salesOrderItemIds,
    }),
    [closeGoodsOutRecordModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openGoodsOutRecordModal, closeGoodsOutRecordModal, reducer }
