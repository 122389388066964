import { connect } from 'react-redux'
import Header from './Header'

const mapStateToProps = () => ({
  //
})

const actionCreators = {
  //
}

export default connect(mapStateToProps, actionCreators)(Header)
