import { showAdvanced, setValues } from '../../../State/advanced'
import { setSearchTerm } from '../../../State/searchTerm'

export default () => async (dispatch, getState) => {

  dispatch(showAdvanced())

  dispatch(setValues({ keywords: getState().screens.Stocktakes.StocktakesTable.searchTerm }))

  dispatch(setSearchTerm(''))
  
}