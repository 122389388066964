import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Table as AntTable, Icon, Tooltip, Typography } from 'antd'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import { getTeamUrl } from 'Traqa/Helpers'
import styles from './styles.css'

const Table = (props) => {

  const {
    data,
    isFetching,
    onChange,
  } = props

  const formatDateTime = (dateTime) => (
    moment(dateTime).calendar(null, {
      sameDay: '[Today], h:mm a',
      nextDay: '[Tomorrow], h:mm a',
      lastDay: '[Yesterday], h:mm a',
      nextWeek: 'ddd D MMM YYYY [at] h:mm a',
      lastWeek: 'ddd D MMM YYYY [at] h:mm a',
      sameElse: 'ddd D MMM YYYY [at] h:mm a',
    })
  )
  
  return (

    <AntTable
      dataSource={data}
      loading={isFetching}
      rowKey='id'
      pagination={false}
      onChange={onChange}
    >

      <AntTable.Column
        key='id'
        title='No.'
        width={90}
        sorter
        render={(text, record) => (
          <NavLink key={record.id} to={getTeamUrl(`goods_out_records/${record.id}`)}>
            {record.id}
          </NavLink>
        )}
      />

      <AntTable.Column
        key='stock_location.name'
        title='Location'
        sorter
        render={(text, record) => record.stock_location.name}
      />

      <AntTable.Column
        key='customer.name'
        title='Customer / Site'
        sorter
        render={(text, record) => {
          if (record.customer.name === record.customer_site.name) {
            return record.customer.name
          }
          return `${record.customer.name} / ${record.customer_site.name}`
        }}
      />

      <AntTable.Column
        key='is_return'
        width={50}
        render={(text, record) => record.is_return ? (
          <Tooltip title='Return'><Icon type='undo' className={styles.returnIcon} /></Tooltip>
        ) : null}
      />

      <AntTable.Column
        key='is_printed'
        width={50}
        render={(text, record) => record.is_printed ? (
          <Tooltip title='Printed'><Icon type='printer' className={styles.printerIcon} /></Tooltip>
        ) : null}
      />

      <AntTable.Column
        key='date_time'
        title='Date / Time'
        sorter
        render={(text, record) => (
          <span
            className={classNames({
              [styles.isOverdue]: record.is_overdue,
            })}
          >
            {record.is_overdue && <Icon type='exclamation-circle' style={{ marginRight: '5px' }} />}
            {formatDateTime(record.date_time)}
          </span>
        )}
      />

      <AntTable.Column
        key='status'
        title='Status'
        width={140}
        sorter
        render={(text, record) => (
          <div className={styles.status}>
            {record.status === 'Expected' && <Icon type='clock-circle' className={classNames(styles.statusIcon, styles.expected)} />}
            {record.status === 'Expected' && 'Scheduled'}
            {record.status === 'Picked' && <Icon type='check-circle' className={classNames(styles.statusIcon, styles.picked)} />}
            {record.status === 'Picked' && 'Picked'}
            {record.status === 'Completed' && <Icon type='check-circle' className={classNames(styles.statusIcon, styles.completed)} />}
            {record.status === 'Completed' && 'Dispatched'}
          </div>
        )}
      />

      <AntTable.Column
        key='invoice_no'
        title='Invoice'
        sorter
        render={(text, record) => <Typography.Text type='secondary' copyable={!!record.invoice_no}>{record.invoice_no}</Typography.Text>}
      />

      <AntTable.Column
        key='consignment.reference'
        title='Consignment'
        sorter
        render={(text, record) => record.consignment && <Typography.Text type='secondary' copyable={!!record.consignment.reference}>{record.consignment.reference}</Typography.Text>}
      />

    </AntTable>

  )
  
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Table
