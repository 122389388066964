import { getTeamUrl } from 'Traqa/Helpers'

export default () => async (dispatch, getState) => {

  const { advanced, searchTerm, sorting } = getState().screens.Stocktakes.StocktakesTable

  let path = `stocktakes/export?`

  if (advanced.show) {

    const {
      keywords,
      stockLocationIds,
      userIds,
    } = advanced.values

    if (keywords) {
      path = `${path}&search=${keywords}`
    }

    if (stockLocationIds) {
      path = `${path}&filter[stock_location_id]=${stockLocationIds.join(',')}`
    }

    if (userIds) {
      path = `${path}&filter[user_id]=${userIds.join(',')}`
    }

  } else if (searchTerm) {

    path = `${path}&search=${searchTerm}`

  }

  path = `${path}&sort=${sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : '-date_time'}`

  window.location = getTeamUrl(path, 'exports')

}
