import React from 'react'
import { Modal, Icon } from 'antd'
import deleteGoodsInRecord from '../../../Services/deleteGoodsInRecord'

export default (id) => async (dispatch) => {

  Modal.confirm({
    title: 'Delete Planned Purchase?',
    content: 'This action cannot be undone.',
    icon: <Icon type='delete' style={{ color: '#FF4D4F' }} />,
    okType: 'danger',
    okText: 'Delete',
    onOk: () => dispatch(deleteGoodsInRecord(id)),
  })

}
