import { connect } from 'react-redux'
import GoodsOutRecord from './GoodsOutRecord'

import selectIsNew from './Selectors/selectIsNew'
import selectIsEditing from './Selectors/selectIsEditing'
import selectIsSubmitting from './Selectors/selectIsSubmitting'

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onBack from './Handlers/onBack'
import onSave from './Handlers/onSave'
import onCancel from './Handlers/onCancel'
import onCloseLinkConsignmentModal from './Handlers/onCloseLinkConsignmentModal'
import onFileChanges from './Handlers/onFileChanges'

const mapStateToProps = (state, props) => ({
  id: props.match.params.id,
  isNew: selectIsNew(state, props),
  isEditing: selectIsEditing(state, props),
  isSubmitting: selectIsSubmitting(state, props),
  initialized: state.screens.GoodsOutRecord.initialized,
  goodsOutRecord: state.screens.GoodsOutRecord.goodsOutRecord,
  linkConsignmentModal: state.screens.GoodsOutRecord.linkConsignmentModal,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onBack,
  onSave,
  onCancel,
  onCloseLinkConsignmentModal,
  onFileChanges,
}

export default connect(mapStateToProps, actionCreators)(GoodsOutRecord)
