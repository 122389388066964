import { combineReducers } from "redux"

import { reducer as data } from "./State/data"
import { reducer as isFetching } from "./State/isFetching"
import { reducer as formState } from "./State/formState"
import { reducer as previousStocktakes } from "./State/previousStocktakes"

export default combineReducers({
  data,
  isFetching,
  formState,
  previousStocktakes,
})