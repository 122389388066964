import { createAction, handleActions } from 'redux-actions'
import { includes, without, concat } from 'lodash'

const expandLog = createAction('MODULES/LOG/EXPAND_LOG')
const closeLog = createAction('MODULES/LOG/CLOSE_LOG')
const toggleLog = createAction('MODULES/LOG/TOGGLE_LOG')
const setExpandedLogs = createAction('MODULES/LOG/SET_EXPANDED_LOGS')
const resetExpandedLogs = createAction('MODULES/LOG/RESET_EXPANDED_LOGS')

const defaultState = []

const reducer = handleActions(
  {
    [expandLog]: (state, action) => !includes(state, action.payload) ? concat(state, action.payload) : state,
    [closeLog]: (state, action) => includes(state, action.payload) ? without(state, action.payload) : state,
    [toggleLog]: (state, action) => includes(state, action.payload) ? without(state, action.payload) : concat(state, action.payload),
    [setExpandedLogs]: (state, action) => action.payload,
    [resetExpandedLogs]: () => [],
  },
  defaultState
)

export { expandLog, closeLog, toggleLog, setExpandedLogs, resetExpandedLogs, reducer }
