import screenProps from '../screenProps'
import { updateGoodsInRecordInSage } from '../../../Api/goodsInRecords'
import { setGoodsInRecord } from '../State/goodsInRecord'
import { handleException } from 'Traqa/Packages'
import fetch from './fetch'
//import fetchActivity from '../../../Modules/Activity/Services/fetch'

export default (inSage) => async (dispatch, getState) => {

  try {

    dispatch(setGoodsInRecord({
      ...getState().screens.GoodsInRecord.goodsInRecord,
      in_sage: inSage,
    }))

    const { id } = screenProps().match.params

    const data = { in_sage: inSage }

    await updateGoodsInRecordInSage({ id, data })

    await dispatch(fetch())

    //dispatch(fetchActivity())

  } catch (error) {

    handleException(error)

  }
}