import { getTeamUrl } from 'Traqa/Helpers'

export default () => async (dispatch, getState) => {

  const { purchaseOrder } = getState().screens.PurchaseOrder

  const path = `purchase_orders/${purchaseOrder.id}/export`

  window.location = getTeamUrl(path, 'exports')

}
