import { combineReducers } from "redux"

import { reducer as initialized } from "./State/initialized"
import { reducer as purchaseOrder } from "./State/purchaseOrder"
import { reducer as goodsInRecordModal } from "./State/goodsInRecordModal"

import Editor from "./Components/Editor/reducer"
import GoodsInRecordsTable from "./Components/GoodsInRecordsTable/reducer"

export default combineReducers({
  initialized,
  purchaseOrder,
  goodsInRecordModal,

  Editor,
  GoodsInRecordsTable,
})
