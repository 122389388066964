import { connect } from "react-redux"
import ItemsArray from "./ItemsArray"

import selectToggleState from './Selectors/selectToggleState'

import onToggle from './Handlers/onToggle'
import onAddItem from './Handlers/onAddItem'
import onDuplicateItems from './Handlers/onDuplicateItems'
import onDeleteItems from './Handlers/onDeleteItems'

const mapStateToProps = (state) => ({
  toggleState: selectToggleState(state)
})

const actionCreators = {
  onToggle,
  onAddItem,
  onDuplicateItems,
  onDeleteItems,
}

export default connect(mapStateToProps, actionCreators)(ItemsArray)
