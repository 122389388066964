import { connect } from 'react-redux'
import Component from './Component'

import selectYearSummary from './Selectors/selectYearSummary'

const mapStateToProps = (state) => ({
  product: state.screens.Product.product,
  yearSummary: selectYearSummary(state),
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Component)
