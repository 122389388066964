import React from 'react'
import PropTypes from 'prop-types'
import { Input, DatePicker, Tooltip } from 'antd'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { Module, LinkButton } from 'Traqa/Packages'

const Header = (props) => {

  const {
    monthYear,
    filters,
    searchTerm,
    onChangeMonthYear,
    onResetFilters,
    onChangeSearch,
    onSearch,
  } = props

  const renderLeft = () => (

    <React.Fragment>

      <DatePicker.MonthPicker
        value={monthYear}
        onChange={value => onChangeMonthYear(value)}
        format='MMM YYYY'
        placeholder='Select month'
        style={{ width: 200 }}
      />

      {!isEmpty(filters) && (

        <React.Fragment>

          <div style={{ width: 16 }} />

          <LinkButton
            type='primary'
            onClick={onResetFilters}
          >
            Reset filters
          </LinkButton>

        </React.Fragment>

      )}

    </React.Fragment>

  )

  const renderRight = () => (

    <React.Fragment>

      <Tooltip title='Search by SO # or product code' defaultVisible>
        <Input.Search
          value={searchTerm}
          onChange={onChangeSearch}
          onSearch={onSearch}
          placeholder='Search'
          style={{ width: 200 }}
        />
      </Tooltip>
    
    </React.Fragment>

  )
  
  return (

    <Module.Header
      renderLeft={renderLeft}
      renderRight={renderRight}
    />

  )
  
}

Header.propTypes = {
  monthYear: PropTypes.instanceOf(moment),
  filters: PropTypes.object.isRequired,
  searchTerm: PropTypes.string.isRequired,
  onChangeMonthYear: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
}

export default Header
