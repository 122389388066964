import { defer } from 'lodash'
import modalProps from '../../../modalProps'
import { findCourierRate } from '../../../../../Api/courierRates'
import formService from '../formService'
import { handleException } from 'Traqa/Packages'

export default () => async () => {

  const { id } = modalProps()

  if (!id) return

  try {

    const response = await findCourierRate({ id })

    const courierRate = response.data.data

    const initialValues = {
      courier_id: courierRate.courier_id,
      courier_service_id: courierRate.courier_service_id,
      courier_zone_id: courierRate.courier_zone_id,
      quantity: courierRate.quantity,
      pallet_full_rate: courierRate.pallet_full_rate,
      pallet_half_rate: courierRate.pallet_half_rate,
      pallet_quarter_rate: courierRate.pallet_quarter_rate,
      pallet_micro_rate: courierRate.pallet_micro_rate,
      parcel_rate: courierRate.parcel_rate,
    }

    defer(() => formService().initialize(initialValues))

  } catch (error) {

    handleException(error)

  }

}
