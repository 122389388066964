import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    properties: PropTypes.object.isRequired,
  }

  render() {

    const {
      properties,
    } = this.props

    const { attributes } = properties

    const formatValue = (value) => {
      if (value === null) return <div style={{ opacity: '25%' }}>None</div>
      if (value === true) return 'Yes'
      if (value === false) return 'No'
      return value
    }

    return (

      <div className={styles.wrapper}>

        <div className={styles.header}>Values</div>

        <br />

        {Object.keys(attributes).map(property => (

          <div key={property} className={styles.row}>

            <div className={styles.property}>{property}</div>
            <div className={styles.value}>{formatValue(attributes[property])}</div>

          </div>

        ))}

      </div>

    )

  }

}
