import fetchProducts from '../../../Core/Services/fetchProducts'
import fetchKits from '../../../Core/Services/fetchKits'
import fetchStockLocations from '../../../Core/Services/fetchStockLocations'
import fetchUsers from '../../../Core/Services/fetchUsers'
import fetchTableData from '../Components/Table/Services/fetch'

export default () => (dispatch) => {
  
  dispatch(fetchProducts())
  dispatch(fetchKits())
  dispatch(fetchStockLocations())
  dispatch(fetchUsers())

  dispatch(fetchTableData())
  
}