import screenProps from '../../../screenProps'
import { getGoodsOutRecords } from '../../../../../Api/goodsOutRecords'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { id: salesOrderId } = screenProps().match.params

    if (salesOrderId === 'new') return

    const { sorting } = getState().screens.SalesOrder.GoodsOutRecordsTable

    const params = {}

    params.include = [
      'stock_location',
      'customer',
      'customer_site',
      'user',
      'consignment',
    ].join()

    params['filter[sales_order_id]'] = salesOrderId

    params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : 'date_time'

    dispatch(startFetching())

    const response = await getGoodsOutRecords({ params })

    dispatch(setData(response.data.data))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }

}
