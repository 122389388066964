import { createAction, handleActions } from 'redux-actions'

const setAssembly = createAction('SCREENS/ASSEMBLY/SET_ASSEMBLY')
const resetAssembly = createAction('SCREENS/ASSEMBLY/RESET_ASSEMBLY')

const defaultState = null

const reducer = handleActions(
  {
    [setAssembly]: (state, action) => action.payload,
    [resetAssembly]: () => null,
  },
  defaultState
)

export { setAssembly, resetAssembly, reducer }