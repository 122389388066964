import { createAction, handleActions } from 'redux-actions'

const loadCustomerSites = createAction("CORE/CUSTOMER_SITES/LOAD_CUSTOMER_SITES")

const defaultState = []

const reducer = handleActions(
  {
    [loadCustomerSites]: (state, action) => action.payload,
  },
  defaultState
)

export { loadCustomerSites, reducer }