import { delay } from 'lodash'
import fetch from '../Services/fetch'
import { resetData } from '../State/data'
import { resetFetching } from '../State/fetching'
import { resetSubmitting } from '../State/submitting'

export default (visible) => (dispatch) => {

  if (visible) {
    dispatch(fetch())
  } else {
    delay(() => {
      dispatch(resetData())
      dispatch(resetFetching())
      dispatch(resetSubmitting())
    }, 300)
  }
  
}
