import { combineReducers } from 'redux'

import { reducer as data } from './State/data'
import { reducer as isFetching } from './State/isFetching'
import { reducer as formState } from './State/formState'
import { reducer as columnOffset } from './State/columnOffset'

export default combineReducers({
  data,
  isFetching,
  formState,
  columnOffset,
})
