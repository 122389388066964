import modalProps from '../modalProps'
import { getCustomer } from '../../../Api/customers'
import { setCustomer } from '../State/customer'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch) => {

  try {

    const { customerId } = modalProps()

    if (customerId) {

      const params = {
        include: 'customer_sites',
      }

      const response = await getCustomer({ id: customerId, params })

      const customer = response.data.data

      dispatch(setCustomer(customer))

    }

  } catch (error) {

    handleException(error)
  }

}