import { createAction, handleActions } from 'redux-actions'

const setHidePrinted = createAction('SCREENS/GOODS_OUT_RECORDS/GOODS_OUT_RECORDS_TABLE/SET_HIDE_PRINTED')
const resetHidePrinted = createAction('SCREENS/GOODS_OUT_RECORDS/GOODS_OUT_RECORDS_TABLE/RESET_HIDE_PRINTED')

const defaultState = false

const reducer = handleActions(
  {
    [setHidePrinted]: (state, action) => action.payload,
    [resetHidePrinted]: () => defaultState,
  },
  defaultState
)

export { setHidePrinted, resetHidePrinted, reducer }