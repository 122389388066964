import { setActiveTab } from '../State/activeTab'
import { resetHoldingValue } from '../State/holdingValue'
import fetchHoldingValue from '../Services/fetchHoldingValue'

export default (key) => (dispatch) => {

  dispatch(setActiveTab(key))

  dispatch(resetHoldingValue())

  dispatch(fetchHoldingValue())

}
