import { createAction, handleActions } from 'redux-actions'

const setAdjustment = createAction('SCREENS/ADJUSTMENT/SET_ADJUSTMENT')
const resetAdjustment = createAction('SCREENS/ADJUSTMENT/RESET_ADJUSTMENT')

const defaultState = null

const reducer = handleActions(
  {
    [setAdjustment]: (state, action) => action.payload,
    [resetAdjustment]: () => null,
  },
  defaultState
)

export { setAdjustment, resetAdjustment, reducer }