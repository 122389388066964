import { combineReducers } from 'redux'

import { reducer as transactionsDrawer } from './State/transactionsDrawer'

import Report from './Components/Report/reducer'
import TransactionsDrawer from './Components/TransactionsDrawer/reducer'

export default combineReducers({
  transactionsDrawer,
  
  Report,
  TransactionsDrawer,
})
