import { find } from 'lodash'
import getTeams from './getTeams'
import getCurrentTeam from './getCurrentTeam'

const isSupplyChain = () => {
  
  const team = find(getTeams(), (t) => t.id === getCurrentTeam().id)
  
  return team && (team.pivot.role === 'owner' || team.pivot.role === 'admin' || !!team.pivot.supply_chain)
  
}

export default isSupplyChain
