import { connect } from 'react-redux'
import { get } from 'lodash'
import Courier from './Courier'

import selectActiveTab from './Selectors/selectActiveTab'

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onCloseCourierModal from './Handlers/onCloseCourierModal'
import onCloseCourierServiceModal from './Handlers/onCloseCourierServiceModal'
import onCloseCourierZoneModal from './Handlers/onCloseCourierZoneModal'
import onCloseCourierRateModal from './Handlers/onCloseCourierRateModal'
import onCloseCourierChargeModal from './Handlers/onCloseCourierChargeModal'

const mapStateToProps = (state) => ({
  id: get(state.screens.Courier.courier, 'id'),
  initialized: state.screens.Courier.initialized,
  activeTab: selectActiveTab(state),
  courierModal: state.screens.Courier.courierModal,
  courierServiceModal: state.screens.Courier.courierServiceModal,
  courierZoneModal: state.screens.Courier.courierZoneModal,
  courierRateModal: state.screens.Courier.courierRateModal,
  courierChargeModal: state.screens.Courier.courierChargeModal,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onCloseCourierModal,
  onCloseCourierServiceModal,
  onCloseCourierZoneModal,
  onCloseCourierRateModal,
  onCloseCourierChargeModal,
}

export default connect(mapStateToProps, actionCreators)(Courier)
