import { teamApi as axios } from './axios'

export const getDashboardHoldingValueByStockLocation = ({ params }) => axios.get('dashboard/stock_locations/holding/value', { params })

export const getDashboardGoodsInRecordsByMonth = () => axios.get('dashboard/goods_in_records/months')

export const getDashboardHoldingValueByProduct = ({ params }) => axios.get('dashboard/products/holding/value', { params })
export const getDashboardHoldingWeeksByProduct = ({ params }) => axios.get('dashboard/products/holding/weeks', { params })

export const getDashboardMovementByProduct = ({ params }) => axios.get('dashboard/products/movement', { params })
