import { createAction, handleActions } from 'redux-actions'

const setTotals = createAction('SCREENS/STOCK_ASSESSMENT/REPORT/SET_TOTALS')
const resetTotals = createAction('SCREENS/STOCK_ASSESSMENT/REPORT/RESET_TOTALS')

const defaultState = {
  actual: {
    quantity: 0,
    quantity_value: 0,
    unallocated: 0,
    unallocated_value: 0,
  },
  expected: {
    quantity: 0,
    quantity_value: 0,
    unallocated: 0,
    unallocated_value: 0,
  },
}

const reducer = handleActions(
  {
    [setTotals]: (state, action) => action.payload,
    [resetTotals]: () => defaultState,
  },
  defaultState,
)

export { setTotals, resetTotals, reducer }
