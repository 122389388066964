import { message } from 'antd'
import { deleteGoodsInRecordItem } from '../../../Api/goodsInRecordItems'
import { handleException } from 'Traqa/Packages'
import modalProps from '../modalProps'

export default () => async () => {

  try {

    const { goodsInRecordItem } = modalProps()

    await deleteGoodsInRecordItem({ id: goodsInRecordItem.id })

    modalProps().onClose(true)

    message.success('Removed Product From Planned Purchase')

  } catch (error) {

    handleException(error)

  }
  
}
