import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Menu, Button, Pagination } from 'antd'
import { Module } from 'Traqa/Packages'

const Footer = (props) => {

  const {
    pagination,
    onClickExport,
    onChangePagination,
  } = props

  const renderLeft = () => (

    <Dropdown
      trigger={['click']}
      overlay={(
        <Menu>
          <Menu.Item onClick={() => onClickExport('customers')}>Export Customers</Menu.Item>
          <Menu.Item onClick={() => onClickExport('customer_sites')}>Export Sites</Menu.Item>
        </Menu>
      )}
    >
      <Button
        type="default"
        icon="download"
      >
        Export Data
      </Button>
    </Dropdown>

  )

  const renderRight = () => (

    <Pagination
      current={pagination.pageNumber}
      pageSize={pagination.pageSize}
      total={pagination.total}
      showSizeChanger
      showTotal={total => `Total: ${total} Customers`}
      pageSizeOptions={['10', '20', '30', '40', '50']}
      onChange={onChangePagination}
      onShowSizeChange={onChangePagination}
    />

  )
  
  return (

    <Module.Footer
      renderLeft={renderLeft}
      renderRight={renderRight}
    />

  )
}

Footer.propTypes = {
  pagination: PropTypes.object.isRequired,
  onClickExport: PropTypes.func.isRequired,
  onChangePagination: PropTypes.func.isRequired,
}

export default Footer