import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import { Tooltip, Progress } from 'antd'
import numeral from 'numeral'

const FulFillmentProgress = ({ field }) => {

  const quantity = useField(`${field}.quantity`).input.value || 0
  const speculated = useField(`${field}.speculated`).input.value || 0
  const expected = useField(`${field}.expected`).input.value || 0
  const completed = useField(`${field}.completed`).input.value || 0
  const pending = quantity - (speculated + expected + completed)

  const pendingPercentage = (pending / quantity) * 100
  const fulfilledPercentage = ((speculated + expected + completed) / quantity) * 100
  const completePercentage = (completed / quantity) * 100

  const formatTooltipLine = (value) => {
    if (quantity === 0) return `[${numeral(value).format('0,0')}]`
    const percentage = (value/quantity)*100
    return `${numeral(percentage).format('0,0.[00]')}% [${numeral(value).format('0,0')}]`
  }

  return (

    <Tooltip
      title={(
        <div>
          {completed > 0 && (<div>{`${formatTooltipLine(completed)} Received`}</div>)}
          {expected > 0 && (<div>{`${formatTooltipLine(expected)} Booked`}</div>)}
          {speculated > 0 && (<div>{`${formatTooltipLine(speculated)} Scheduled`}</div>)}
          {pending > 0 && (<div>{`${formatTooltipLine(pending)} Pending`}</div>)}
        </div>
      )}
    >
      <Progress
        size="small"
        percent={fulfilledPercentage}
        successPercent={completePercentage}
        strokeColor='#ffa500'
        status={(() => {
          if (quantity === 0) return 'exception'
          if (pending > 0) return 'exception'
          if (completed > quantity) return 'exception'
          if ((completed + expected + speculated) > quantity) return 'exception'
          if (completed === quantity) return 'success'
          if ((completed + expected + speculated) === quantity) return 'active'
          return null
        })()}
        format={() => {
          if (quantity === 0) return 'Invalid Quantity'
          if (pending > 0) return `${numeral(pendingPercentage).format('0,0.[00]')}% Pending`
          if (completed > quantity) return `${numeral(completePercentage - 100).format('0,0.[00]')}% Over Received`
          if ((completed + expected + speculated) > quantity) return `${numeral(fulfilledPercentage - 100).format('0,0.[00]')}% Over Fulfilled`
          if (completed === quantity) return '100% Received'
          if ((completed + expected + speculated) === quantity) return '100% Fulfilled'
          return null
        }}
      />
    </Tooltip>

  )

}

FulFillmentProgress.propTypes = {
  field: PropTypes.string.isRequired,
}

export default FulFillmentProgress