import { setActiveTab } from '../../../State/activeTab'
import { firstPage } from '../../../State/pagination'
import fetch from '../../../Services/fetch'

export default (event) => (dispatch) => {

  dispatch(setActiveTab(event.target.value))

  dispatch(firstPage())

  dispatch(fetch())

}
