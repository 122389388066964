import React from "react"
import PropTypes from "prop-types"
import Screen from '../../Components/Screen'

import Report from './Components/Report'
import ControlsModal from './Components/Report/Components/ControlsModal'
import ManualRunRateModal from '../../Modals/ManualRunRate'
import TransactionsDrawer from '../../Modules/TransactionsDrawer'

export default class Demand extends React.PureComponent {

  static propTypes = {
    controlsModal: PropTypes.object.isRequired,
    manualRunRateModal: PropTypes.object.isRequired,
    transactionsDrawer: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onCloseControlsModal: PropTypes.func.isRequired,
    onCloseManualRunRateModal: PropTypes.func.isRequired,
    onCloseTransactionsDrawer: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {

    const {
      controlsModal,
      manualRunRateModal,
      transactionsDrawer,
      onCloseControlsModal,
      onCloseManualRunRateModal,
      onCloseTransactionsDrawer,
    } = this.props

    return (

      <Screen
        title='Demand'
        maxScreenWidth={null}
        backToTop
      >

        <Report />

        {controlsModal.show && (
          <ControlsModal
            {...controlsModal}
            onClose={onCloseControlsModal}
          />
        )}

        {manualRunRateModal.show && (
          <ManualRunRateModal
            {...manualRunRateModal}
            onClose={onCloseManualRunRateModal}
          />
        )}

        {transactionsDrawer.show && (

          <TransactionsDrawer
            {...transactionsDrawer}
            onClose={onCloseTransactionsDrawer}
          />

        )}

      </Screen>

    )

  }
  
}
