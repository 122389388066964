import { combineReducers } from "redux"

import { reducer as activeTab } from './State/activeTab'

import Readings from './Components/Readings/reducer'
import Adjustments from './Components/Adjustments/reducer'

export default combineReducers({
  activeTab,
  
  Readings,
  Adjustments,
})
