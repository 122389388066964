import React from 'react'
import PropTypes from 'prop-types'
import { Table, Popover } from 'antd'
import moment from 'moment'
import { Module } from 'Traqa/Packages'
import styles from './styles.css'

const PieCharts = props => {

  const {
    data,
    isFetching,
  } = props

  return (

    <Module className={styles.wrapper}>

      <Module.Header
        title='Import'
      />

      <Module.Section
        contentClassName={styles.content}
      >
        
        <Table
          dataSource={data}
          rowKey='month'
          size='small'
          loading={isFetching}
          pagination={false}
          scroll={{ y: 210 }}
        >

          <Table.Column
            key='month'
            title='Month'
            render={(text, record) => (
              <Popover
                title={record.month}
                trigger={['hover']}
                content={!!record.goods_in_records.length && (
                  <table className={styles.popoverTable}>
                    <thead>
                      <tr>
                        <th className={styles.tableRow}>Reference</th>
                        <th>Supplier</th>
                        <th>Location</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {record.goods_in_records.map(goodsInRecord => (
                        <tr key={goodsInRecord.id}>
                          <td>{goodsInRecord.invoice_no}</td>
                          <td>{goodsInRecord.supplier.name}</td>
                          <td>{goodsInRecord.stock_location.name}</td>
                          <td>{moment(goodsInRecord.date_time).format('ll')}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              >
                <div style={{ width: '100%' }}>{record.month}</div>
              </Popover>
            )}
          />

          <Table.Column
            key='quantity'
            title='Qty'
            width={50}
            className={styles.numericColumn}
            render={(text, record) => record.goods_in_records.length}
          />

        </Table>

      </Module.Section>

    </Module>

  )

}

PieCharts.propTypes = {
  data: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
}

export default PieCharts
