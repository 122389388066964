import screenProps from '../screenProps'
import { getStocktake } from '../../../Api/stocktakes'
import { setStocktake } from '../State/stocktake'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    const params = {
      include: 'stock_location',
    }

    const response = await getStocktake({ id, params })

    dispatch(setStocktake(response.data.data))

  } catch (error) {

    handleException(error)

  }
}