import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Form, Radio, Select, Switch, Icon } from 'antd'
import ReactHighcharts from 'react-highcharts'
import { Module } from 'Traqa/Packages'

ReactHighcharts.Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
})

const Holding = props => {

  const {
    stockLocations,
    productCategories,
    holdingControls,
    valueConfig,
    weeksConfig,
    onChangeControl,
  } = props

  return (

    <Module.Section>

      <Row gutter={8}>

        <Col span={20}>

          {holdingControls.type === 'value' && <ReactHighcharts isPureConfig config={valueConfig} />}

          {holdingControls.type === 'weeks' && <ReactHighcharts isPureConfig config={weeksConfig} />}
        
        </Col>

        <Col span={4}>

          <h4>Controls</h4>

          <Form>

            <Form.Item>

              <Radio.Group
                value={holdingControls.type}
                onChange={event => onChangeControl('type', event.target.value)}
                options={[
                  { label: 'Value (£)', value: 'value' },
                  { label: 'Weeks', value: 'weeks' },
                ]}
              />

            </Form.Item>

            {holdingControls.type === 'value' && (

              <Form.Item>

                <Select
                  value={holdingControls.stockLocationId}
                  onChange={value => onChangeControl('stockLocationId', value)}
                  optionFilterProp='children'
                  allowClear
                  showSearch
                  placeholder='Stock location'
                  style={{ width: '100%' }}
                >
                  {stockLocations.map(stockLocation => (
                    <Select.Option
                      key={stockLocation.id}
                      value={stockLocation.id}
                    >
                      {stockLocation.name}
                    </Select.Option>
                  ))}
                </Select>

              </Form.Item>

            )}

            <Form.Item>

              <Select
                value={holdingControls.productCategoryId}
                onChange={value => onChangeControl('productCategoryId', value)}
                optionFilterProp='children'
                allowClear
                showSearch
                placeholder='Product category'
                style={{ width: '100%' }}
              >
                {productCategories.map(productCategory => (
                  <Select.Option
                    key={productCategory.id}
                    value={productCategory.id}
                  >
                    {productCategory.name}
                  </Select.Option>
                ))}
              </Select>

            </Form.Item>

            {holdingControls.type === 'value' && (

              <Form.Item>

                <Switch
                  checked={holdingControls.zeroRunRate}
                  onChange={value => onChangeControl('zeroRunRate', value)}
                  checkedChildren={<Icon type='check' />}
                />
                <span style={{ marginLeft: 10 }}>Zero run rate</span>

              </Form.Item>

            )}

            {holdingControls.type === 'weeks' && (

              <Form.Item>

                <Radio.Group
                  value={holdingControls.sortDirection}
                  onChange={event => onChangeControl('sortDirection', event.target.value)}
                  options={[
                    { label: 'Highest', value: 'desc' },
                    { label: 'Lowest', value: 'asc' },
                  ]}
                />

              </Form.Item>

            )}

          </Form>

        </Col>

      </Row>

    </Module.Section>

  )

}

Holding.propTypes = {
  stockLocations: PropTypes.array.isRequired,
  productCategories: PropTypes.array.isRequired,
  holdingControls: PropTypes.object.isRequired,
  valueConfig: PropTypes.object.isRequired,
  weeksConfig: PropTypes.object.isRequired,
  onChangeControl: PropTypes.func.isRequired,
}

export default Holding
