import React from 'react'
import PropTypes from 'prop-types'
import { Field, FormSpy } from 'react-final-form'
import { Form, Row, Col, Input, Select } from 'antd'
import { find } from 'lodash'
import { Module } from 'Traqa/Packages'

const CourierDetails = (props) => {

  const {
    couriers,
    courierServices,
    courierZones,
  } = props
  
  return (

    <Module.Section
      contentStyle={{ paddingBottom: 15 }}
    >

      <FormSpy subscription={{ values: true, submitting: true }}>

        {({ values, submitting, form }) => (

          <div>

            <Row gutter={8}>

              <Col span={12}>

                <Field name='courier_id'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Courier'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        onChange={value => {
                          input.onChange(value === undefined ? null : value)
                          form.change('courier_service_id', null)
                          form.change('courier_zone_id', null)
                          form.change('parent_courier_zone_code', null)
                        }}
                        disabled={submitting}
                        allowClear
                        showSearch
                        optionFilterProp='children'
                      >
                        {couriers.map(courier => (
                          <Select.Option key={courier.id} value={courier.id}>
                            {courier.name}
                          </Select.Option>
                        ))}
                        <Select.Option key='null' value='null'>
                          Collection
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  )}
                </Field>

              </Col>

              {values.courier_id !== 'null' && (

                <Col span={12}>

                  <Field name='courier_service_id'>
                    {({ input, meta }) => (
                      <Form.Item
                        label='Service'
                        validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                      >
                        <Select
                          {...input}
                          disabled={submitting}
                          allowClear
                          showSearch
                          optionFilterProp='children'
                        >
                          {courierServices.map(courierService => (
                            <Select.Option key={courierService.id} value={courierService.id}>
                              {courierService.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                  </Field>

                </Col>

              )}

            </Row>

            {values.courier_id !== 'null' && (

              <Row gutter={8}>

                <Col span={12}>

                  <Field name='reference'>
                    {({ input, meta }) => (
                      <Form.Item
                        label='Consignment Ref.'
                        validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                      >
                        <Input
                          {...input}
                          disabled={submitting}
                        />
                      </Form.Item>
                    )}
                  </Field>

                </Col>

                <Col span={12}>

                  <Field name='booking_ref'>
                    {({ input, meta }) => (
                      <Form.Item
                        label='Booking Ref.'
                        validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                      >
                        <Input
                          {...input}
                          disabled={submitting}
                        />
                      </Form.Item>
                    )}
                  </Field>

                </Col>

              </Row>
              
            )}

            {values.courier_id !== 'null' && (

              <Row gutter={8}>

                <Col span={12}>

                  <Field name='courier_zone_id'>
                    {({ input, meta }) => (
                      <Form.Item
                        label='Zone'
                        validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                      >
                        <Select
                          {...input}
                          disabled={submitting}
                          allowClear
                          showSearch
                          optionFilterProp='children'
                        >
                          {courierZones.filter(courierZone => !!courierZone.courier_zone_id).map(courierZone => (
                            <Select.Option key={courierZone.id} value={courierZone.id}>
                              {courierZone.code}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                  </Field>

                </Col>

                <Col span={12}>

                  <Field name='parent_courier_zone_code'>
                    {({ input }) => {
                      let code = null
                      if (values.courier_zone_id) {
                        const courierZone = find(courierZones, row => row.id === values.courier_zone_id)
                        const parentCourierZone = find(courierZones, row => row.id === courierZone.courier_zone_id)
                        // eslint-disable-next-line prefer-destructuring
                        code = parentCourierZone.code
                      }
                      return (
                        <Form.Item
                          label='Group'
                        >
                          <Input
                            {...input}
                            value={code}
                            disabled
                          />
                        </Form.Item>
                      )
                    }}
                  </Field>

                </Col>

              </Row>

            )}

          </div>
      
        )}

      </FormSpy>
  
    </Module.Section>

  )
  
}

CourierDetails.propTypes = {
  couriers: PropTypes.array.isRequired,
  courierServices: PropTypes.array.isRequired,
  courierZones: PropTypes.array.isRequired,
}

export default CourierDetails
