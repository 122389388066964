import { connect } from 'react-redux'
import PieCharts from './PieCharts'

import selectConfig from './Selectors/selectConfig'

import onChangeActiveTab from './Handlers/onChangeActiveTab'

const mapStateToProps = (state) => ({
  activeTab: state.screens.Dashboard.PieCharts.activeTab,
  totalValue: state.screens.Dashboard.PieCharts.holdingValue.total_value,
  config: selectConfig(state),
})

const actionCreators = {
  onChangeActiveTab,
}

export default connect(mapStateToProps, actionCreators)(PieCharts)
