import { connect } from "react-redux"

import Component from "./Component"

import componentDidMount from "./Handlers/componentDidMount"
import onClickNew from "./Handlers/onClickNew"
import onCloseCustomerModal from "./Handlers/onCloseCustomerModal"

const mapStateToProps = (state) => ({
  customerModal: state.screens.Customers.customerModal,
})

const actionCreators = {
  componentDidMount,
  onClickNew,
  onCloseCustomerModal,
}

export default connect(mapStateToProps, actionCreators)(Component)
