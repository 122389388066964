export default (values, id = null) => () => {

  const errors = {}

  if (!values.code) errors.code = "Enter a code for the product"

  if (!values.name) errors.name = "Enter a name for the product"

  if (values.product_categories.length === 0) errors.product_categories = "Select at least one category"

  if (!values.form) errors.form = "Describe the form of the product (e.g. Case, Roll etc)"

  if (!id && !values.value) errors.value = "Enter a value"

  if (Array.isArray(values.bill_of_materials)) {

    const componentIds = []

    errors.bill_of_materials = values.bill_of_materials.map(bom => {

      const bomErrors = {}

      if (!bom.quantity) bomErrors.quantity = 'Enter a quantity for this component'

      if (!bom.component_id) bomErrors.component_id = 'Select a product for this component'

      if (componentIds.includes(bom.component_id)) bomErrors.component_id = 'Component already included'

      componentIds.push(bom.component_id)

      return bomErrors
      
    })

  }

  return errors

}