import { animateScroll } from 'react-scroll'
import { toggleIsExpanded } from '../State/isExpanded'
import { resetExpandedActivities } from '../State/expandedActivities'
import { resetExpandedLogs } from '../State/expandedLogs'

export default () => (dispatch) => {

  dispatch(toggleIsExpanded())
  
  dispatch(resetExpandedActivities())
  
  dispatch(resetExpandedLogs())

  setTimeout(() => {
    animateScroll.scrollToBottom({
      containerId: 'scroll',
    })
  })
  
}
