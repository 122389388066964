import { connect } from "react-redux"
import List from "./List"

import selectListRows from '../../Selectors/selectListRows'

const mapStateToProps = (state) => ({
  listRows: selectListRows(state),
})

const actionCreators = {}

export default connect(mapStateToProps, actionCreators)(List)
