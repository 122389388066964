import { createAction, handleActions } from 'redux-actions'

const setGoodsInRecord = createAction('SCREENS/GOODS_IN_RECORD/SET_GOODS_IN_RECORD')
const resetGoodsInRecord = createAction('SCREENS/GOODS_IN_RECORD/RESET_GOODS_IN_RECORD')

const defaultState = null

const reducer = handleActions(
  {
    [setGoodsInRecord]: (state, action) => action.payload,
    [resetGoodsInRecord]: () => null,
  },
  defaultState
)

export { setGoodsInRecord, resetGoodsInRecord, reducer }