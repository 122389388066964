import { connect } from 'react-redux'
import Table from './Table'

const mapStateToProps = (state) => ({
  data: state.screens.Consignment.PickingStats.data,
  isFetching: state.screens.Consignment.PickingStats.isFetching,
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Table)
