import React from 'react'
import { Module } from 'Traqa/Packages'

import Controls from './Components/Controls'
import Chart from './Components/Chart'
import Table from './Components/Table'
import Footer from './Components/Footer'

const Report = () => (

  <Module>

    <Controls />

    <Chart />

    <Table />

    <Footer />

  </Module>

)

export default Report
