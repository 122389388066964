import { combineReducers } from "redux"

import { reducer as customerModal } from "./State/customerModal"

import CustomersTable from './Components/CustomersTable/reducer'

export default combineReducers({
  customerModal,
  CustomersTable,
})
