export default (values) => () => {

  const errors = {}

  if (!values.effective_from) errors.effective_from = true
  if (!values.recycled_perc) errors.recycled_perc = true
  if (!values.rate_per_tonne) errors.rate_per_tonne = true
  
  return errors

}
