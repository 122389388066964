import { combineReducers } from 'redux'

import { reducer as data } from './State/data'
import { reducer as isFetching } from './State/isFetching'
import { reducer as sorting } from './State/sorting'

export default combineReducers({
  data,
  isFetching,
  sorting,
})
