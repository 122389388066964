import { resetFilters } from '../../../State/filters'
import { firstPage } from '../../../State/pagination'
import fetch from '../../../Services/fetch'

export default () => (dispatch) => {

  dispatch(resetFilters())

  dispatch(firstPage())

  dispatch(fetch())
  
}
