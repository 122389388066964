import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input, Select, Switch, Icon } from 'antd'
import { upperFirst } from 'lodash'

export default class ControlsModal extends React.PureComponent {

  static propTypes = {
    productCategories: PropTypes.array.isRequired,
    stockLocations: PropTypes.array.isRequired,
    controls: PropTypes.object.isRequired,
    onChangeControls: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  state = {
    reload: false,
  }

  render() {

    const {
      productCategories,
      stockLocations,
      controls,
      onChangeControls,
      onClose,
    } = this.props

    const periodTypeFormatted = upperFirst(controls.periodType)

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
      },
    }

    return (
        
      <Modal
        title='All Filters'
        visible
        width={450}
        okText='Apply'
        onOk={() => onClose(this.state.reload)}
        onCancel={() => onClose(this.state.reload)}
        cancelButtonProps={{ style: { display: 'none' } }}
        maskClosable={!this.state.reload}
        closable={!this.state.reload}
      >
        
        <Form colon={false}>

          <Form.Item label='Search' {...formItemLayout}>
            <Input.Search
              value={controls.search}
              onChange={e => {
                onChangeControls('search', e.target.value)
                this.setState({ reload: true })
              }}
              style={{ width: '100%' }}
            />
          </Form.Item>

          <Form.Item label='Category' {...formItemLayout}>
            <Select
              value={controls.productCategoryId || undefined}
              onChange={value => {
                onChangeControls('productCategoryId', value)
                this.setState({ reload: true })
              }}
              showSearch
              allowClear
              placeholder='All Categories'
              optionFilterProp='children'
            >
              {productCategories.map(productCategory => (
                <Select.Option key={productCategory.id} value={productCategory.id}>
                  {productCategory.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label='Location' {...formItemLayout}>
            <Select
              value={controls.stockLocationId || undefined}
              onChange={value => {
                onChangeControls('stockLocationId', value)
                this.setState({ reload: true })
              }}
              showSearch
              allowClear
              placeholder='All Locations'
              optionFilterProp='children'
            >
              {stockLocations.map(stockLocation => (
                <Select.Option key={stockLocation.id} value={stockLocation.id}>
                  {stockLocation.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label='Run Rate' {...formItemLayout}>
            <Select
              value={controls.runRateSource}
              onChange={value => {
                onChangeControls('runRateSource', value)
                this.setState({ reload: true })
              }}
            >
              <Select.OptGroup label='Source'>
                <Select.Option value='demand'>Demand</Select.Option>
                <Select.Option value='outgoing'>Outgoing</Select.Option>
              </Select.OptGroup>
            </Select>
          </Form.Item>

          <Form.Item label=' ' {...formItemLayout}>
            <Select
              value={controls.runRateSetting}
              onChange={value => {
                onChangeControls('runRateSetting', value)
                this.setState({ reload: true })
              }}
            >
              <Select.OptGroup label='Mode'>
                <Select.Option value='expected'>Scheduled</Select.Option>
                <Select.Option value='manual'>Official Run Rates</Select.Option>
                {controls.periodType !== 'week' && (
                  <Select.Option value='week'>Weekly Run Rates</Select.Option>
                )}
                {controls.periodType !== 'month' && (
                  <Select.Option value='month'>Monthly Run Rates</Select.Option>
                )}
                <Select.Option value={1}>{`1 ${periodTypeFormatted} Average`}</Select.Option>
                <Select.Option value={3}>{`3 ${periodTypeFormatted} Average`}</Select.Option>
                <Select.Option value={6}>{`6 ${periodTypeFormatted} Average`}</Select.Option>
                <Select.Option value={12}>{`12 ${periodTypeFormatted} Average`}</Select.Option>
              </Select.OptGroup>
            </Select>
          </Form.Item>

          <Form.Item label='Hide Zeros' {...formItemLayout}>
            <Switch
              checked={controls.hideZeros}
              onChange={value => onChangeControls('hideZeros', value)}
              checkedChildren={<Icon type='check' />}
            />
          </Form.Item>

          <Form.Item label='Booked Only' {...formItemLayout}>
            <Switch
              checked={controls.expectedOnly}
              onChange={value => {
                onChangeControls('expectedOnly', value)
                this.setState({ reload: true })
              }}
              checkedChildren={<Icon type='check' />}
            />
          </Form.Item>

          <Form.Item label='Back Orders' {...formItemLayout}>
            <Switch
              checked={controls.backOrders}
              onChange={value => {
                onChangeControls('backOrders', value)
                this.setState({ reload: true })
              }}
              checkedChildren={<Icon type='check' />}
            />
          </Form.Item>

          <Form.Item label='Outers Only' {...formItemLayout}>
            <Switch
              checked={controls.outersOnly}
              onChange={value => {
                onChangeControls('outersOnly', value)
                this.setState({ reload: true })
              }}
              checkedChildren={<Icon type='check' />}
            />
          </Form.Item>

          <Form.Item label='Aggregate' {...formItemLayout}>
            <Switch
              checked={controls.aggregate}
              onChange={value => {
                onChangeControls('aggregate', value)
                this.setState({ reload: true })
              }}
              checkedChildren={<Icon type='check' />}
            />
          </Form.Item>

        </Form>

      </Modal>
      
    )

  }

}
