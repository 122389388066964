import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Table, Tag } from 'antd'
import numeral from 'numeral'
import { getTeamUrl } from 'Traqa/Helpers'
import styles from './styles.css'

const rightAlign = {
  onHeaderCell: () => ({
    style: { textAlign: 'right' },
  }),
  onCell: () => ({
    style: { textAlign: 'right' },
  })
}

const truncate = (input) => input.length > 80 ? `${input.substring(0, 80)}...` : input

const SubTable = props => {

  const {
    tableRows,
  } = props

  return (

    <Table
      dataSource={tableRows}
      rowKey='id'
      size='small'
      pagination={false}
      className={styles.table}
      onRow={record => ({
        style: { color: record.is_exempt ? '#999999' : undefined },
      })}
    >

      <Table.Column
        key='product.code'
        title='Product'
        render={(text, record) => (
          <React.Fragment>
            <NavLink to={getTeamUrl(`products/${record.product.id}`)}>
              {truncate(`${record.product.code} - ${record.product.name}`)}
            </NavLink>
            <Tag color='volcano' style={{ marginLeft: 5 }}>{record.product.form}</Tag>
          </React.Fragment>
        )}
      />

      <Table.Column
        key='blend'
        title='Blend'
        render={(text, record) => record.blend || '-'}
      />

      <Table.Column
        key='recycled_perc'
        title='Recycled'
        width={120}
        {...rightAlign}
        render={(text, record) => record.recycled_perc !== null ? `${numeral(record.recycled_perc).format('0.[00]')}%` : '-'}
      />

      <Table.Column
        key='quantity'
        title='Qty'
        width={120}
        {...rightAlign}
        render={(text, record) => numeral(record.quantity).format('0,0')}
      />

      <Table.Column
        key='weight_kgs'
        title='Net (KG)'
        width={120}
        {...rightAlign}
        render={(text, record) => record.weight_kgs !== null ? numeral(record.weight_kgs).format('0,0.00') : '-'}
      />

      <Table.Column
        key='tax_amount'
        title='Tax'
        width={120}
        {...rightAlign}
        render={(text, record) => record.tax_amount !== null ? `£${numeral(record.tax_amount).format('0,0.00')}` : '-'}
      />

    </Table>

  )
  
}

SubTable.propTypes = {
  tableRows: PropTypes.array.isRequired,
}

export default SubTable
