import { connect } from 'react-redux'
import Table from './Table'

const mapStateToProps = (state) => ({
  showAdvanced: state.screens.SalesOrders.Table.advanced.show,
})

const actionCreators = {}

export default connect(mapStateToProps, actionCreators)(Table)
