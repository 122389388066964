import { createAction, handleActions } from 'redux-actions'

const toggleFilter = createAction('MODULES/NOTIFICATION_POPOVER/TOGGLE_FILTER')
const resetFilter = createAction('MODULES/NOTIFICATION_POPOVER/RESET_FILTER')

const defaultState = 'ALL'

const reducer = handleActions(
  {
    [toggleFilter]: (state) => state === 'UNREAD' ? 'ALL' : 'UNREAD',
    [resetFilter]: () => defaultState,
  },
  defaultState
)

export { toggleFilter, resetFilter, reducer }
