import { createAction, handleActions } from 'redux-actions'

const toggleAdvanced = createAction('SCREENS/GOODS_OUT_RECORDS/GOODS_OUT_RECORDS_TABLE/TOGGLE_ADVANCED')
const showAdvanced = createAction('SCREENS/GOODS_OUT_RECORDS/GOODS_OUT_RECORDS_TABLE/SHOW_ADVANCED')
const hideAdvanced = createAction('SCREENS/GOODS_OUT_RECORDS/GOODS_OUT_RECORDS_TABLE/HIDE_ADVANCED')
const setValues = createAction('SCREENS/GOODS_OUT_RECORDS/GOODS_OUT_RECORDS_TABLE/SET_VALUES')
const resetValues = createAction('SCREENS/GOODS_OUT_RECORDS/GOODS_OUT_RECORDS_TABLE/RESET_VALUES')
const resetAdvanced = createAction('SCREENS/GOODS_OUT_RECORDS/GOODS_OUT_RECORDS_TABLE/RESET_ADVANCED')

const defaultState = {
  show: false,
  values: {
    keywords: '',
    type: null,
    dateTimeRange: [],
    stockLocationIds: [],
    customerIds: [],
    customerSiteIds: [],
  },
}

const reducer = handleActions(
  {
    [toggleAdvanced]: (state) => ({ ...state, show: !state.show }),
    [showAdvanced]: (state) => ({ ...state, show: true }),
    [hideAdvanced]: (state) => ({ ...state, show: false }),
    [setValues]: (state, action) => ({
      ...state,
      values: {
        keywords: action.payload.keywords || '',
        type: action.payload.type || null,
        dateTimeRange: action.payload.dateTimeRange || [],
        stockLocationIds: action.payload.stockLocationIds || [],
        customerIds: action.payload.customerIds || [],
        customerSiteIds: action.payload.customerSiteIds || [],
      }
    }),
    [resetValues]: (state) => ({ ...state, values: { ...defaultState.values } }),
    [resetAdvanced]: () => ({ ...defaultState }),
  },
  defaultState
)

export { toggleAdvanced, showAdvanced, hideAdvanced, setValues, resetValues, resetAdvanced, reducer }