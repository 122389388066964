import fetchStockLocations from '../../../Core/Services/fetchStockLocations'
import fetchSuppliers from '../../../Core/Services/fetchSuppliers'
import initializeReport from '../Components/Report/Services/initialize'
import { initialized } from '../State/initialized'

export default () => async (dispatch, getState) => {
  
  if (!getState().screens.PurchasePlanner.initialized) {
    
    await Promise.all([
      dispatch(fetchStockLocations()),
      dispatch(fetchSuppliers()),
    ])
    
    await dispatch(initializeReport())

    dispatch(initialized())
    
  } else {

    dispatch(fetchStockLocations())
    dispatch(fetchSuppliers())
    
    dispatch(initializeReport())
    
  }

}
