import { createAction, handleActions } from 'redux-actions'

const setSalesOrder = createAction('SCREENS/SALES_ORDER/SET_SALES_ORDER')
const resetSalesOrder = createAction('SCREENS/SALES_ORDER/RESET_SALES_ORDER')

const defaultState = null

const reducer = handleActions(
  {
    [setSalesOrder]: (state, action) => action.payload,
    [resetSalesOrder]: () => null,
  },
  defaultState,
)

export { setSalesOrder, resetSalesOrder, reducer }
