import React from "react"
import PropTypes from "prop-types"
import { Dropdown, Menu, Icon, Button } from "antd"

const MoreMenu = (props) => {

  const {
    product,
    onArchive,
    onUnarchive,
    onDelete,
  } = props

  const menu = product && (
    <Menu style={{ minWidth: 180 }}>

      {!product.archived_at && (
      <Menu.Item onClick={onArchive}>
        <Icon type="eye-invisible" />
        Archive Product
      </Menu.Item>
      )}

      {!!product.archived_at && (
      <Menu.Item onClick={onUnarchive}>
        <Icon type="eye" />
        Unarchive Product
      </Menu.Item>
      )}

      <Menu.Item onClick={onDelete}>
        <Icon type="delete" />
        Delete Product
      </Menu.Item>

    </Menu>
  )

  return (

    <Dropdown
      trigger={["click"]}
      placement="bottomRight"
      overlay={menu}
    >
      <Button icon="ellipsis" shape="circle" />
    </Dropdown>

  )
}

MoreMenu.propTypes = {
  product: PropTypes.object,
  onArchive: PropTypes.func.isRequired,
  onUnarchive: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

MoreMenu.defaultProps = {
  product: null,
}

export default MoreMenu