import { connect } from "react-redux"
import Component from "./Component"

import onClickMarkAllRead from './Handlers/onClickMarkAllRead'

const mapStateToProps = (state) => ({
  hasUnread: state.modules.NotificationPopover.hasUnread,
})

const actionCreators = {
  onClickMarkAllRead,
}

export default connect(mapStateToProps, actionCreators)(Component)
