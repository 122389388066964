import { createAction, handleActions } from 'redux-actions'

const setManualRunRate = createAction('MODALS/MANUAL_RUN_RATE/SET_MANUAL_RUN_RATE')
const resetManualRunRate = createAction('MODALS/MANUAL_RUN_RATE/RESET_MANUAL_RUN_RATE')

const defaultState = null

const reducer = handleActions(
  {
    [setManualRunRate]: (state, action) => action.payload,
    [resetManualRunRate]: () => defaultState,
  },
  defaultState,
)

export { setManualRunRate, resetManualRunRate, reducer }
