import { connect } from 'react-redux'
import TransactionsTable from './TransactionsTable'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'

const mapStateToProps = (state) => ({
  showAdvanced: state.modules.TransactionsTable.advanced.show,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
}

export default connect(mapStateToProps, actionCreators)(TransactionsTable)
