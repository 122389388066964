import { getTeamUrl } from 'Traqa/Helpers'

export default () => async (dispatch, getState) => {

  const { advanced, activeTab, searchTerm, sorting } = getState().screens.Assemblies.Table

  let path = `assemblies/export?`

  if (advanced.show) {

    const {
      productId,
      stockLocationIds,
      userIds,
      batchNo,
    } = advanced.values

    if (productId) {
      path = `${path}&filter[product_id]=${productId}`
    }

    if (stockLocationIds) {
      path = `${path}&filter[stock_location_id]=${stockLocationIds.join(',')}`
    }

    if (userIds) {
      path = `${path}&filter[user_id]=${userIds.join(',')}`
    }

    if (batchNo) {
      path = `${path}&filter[batch_no]=${batchNo}`
    }

  } else {

    if (activeTab !== 'All') {
      path = `${path}&filter[status]=${activeTab}`
    }

    if (searchTerm) {
      path = `${path}&search=${searchTerm}`
    }

  }

  path = `${path}&sort=${sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : '-date_time'}`

  window.location = getTeamUrl(path, 'exports')

}
