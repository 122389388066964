import { connect } from 'react-redux'
import Deleted from './Deleted'

const mapStateToProps = () => ({
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Deleted)
