import chance from 'chance'
import moment from 'moment'
import { defer } from 'lodash'
import screenProps from '../../../screenProps'
import { getPurchaseOrder } from '../../../../../Api/purchaseOrders'
import { setInitialValues } from '../State/initialValues'
import { handleException } from 'Traqa/Packages'
import { getUser } from 'Traqa/Helpers'
import formService from '../formService'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    if (id === 'new') {

      const initialValues = {
        date: moment(),
        user_id: getUser().id,
        purchase_order_items: [
          {
            guid: chance().guid()
          }
        ]
      }

      dispatch(setInitialValues(initialValues))

      if (formService()) defer(() => formService().initialize(initialValues))

    } else {

      const {
        data: {
          data: purchaseOrder
        }
      } = await getPurchaseOrder({
        id,
        params: {
          include: 'purchase_order_items'
        }
      })

      const initialValues = {
        number: purchaseOrder.number,
        supplier_id: purchaseOrder.supplier_id,
        description: purchaseOrder.description,
        date: moment(purchaseOrder.date),
        commercial_value_usd: purchaseOrder.commercial_value_usd,
        user_id: purchaseOrder.user_id,
        freight_id: purchaseOrder.freight_id,
        freight_po_number: purchaseOrder.freight_po_number,
        freight_cost: purchaseOrder.freight_cost,
        duty_cost: purchaseOrder.duty_cost,
        exchange_rate: purchaseOrder.exchange_rate,
        purchase_order_items: purchaseOrder.purchase_order_items.map(item => ({
          guid: chance().guid(),
          id: item.id,
          product_id: item.product_id,
          supplier_id: item.supplier_id,
          landed_cost: item.landed_cost,
          quantity: item.quantity,
          pending: item.pending,
          speculated: item.speculated,
          expected: item.expected,
          completed: item.completed,
        }))
      }

      dispatch(setInitialValues(initialValues))

      if (formService()) defer(() => formService().initialize(initialValues))

    } 

  } catch (error) {

    handleException(error)

  }
}