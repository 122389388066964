import { createAction, handleActions } from 'redux-actions'
import moment from 'moment'

const setMovementControls = createAction('SCREENS/DASHBOARD/COLUMN_CHARTS/MOVEMENT/SET_MOVEMENT_CONTROLS')
const resetMovementControls = createAction('SCREENS/DASHBOARD/COLUMN_CHARTS/MOVEMENT/RESET_MOVEMENT_CONTROLS')

const defaultState = {
  ownerType: 'GoodsOutRecord',
  startDate: moment().subtract(6, 'months'),
  endDate: moment(),
  stockLocationId: undefined,
  productCategoryId: undefined,
  divisionType: 'Outers',
}

const reducer = handleActions(
  {
    [setMovementControls]: (state, action) => ({ ...state, ...action.payload }),
    [resetMovementControls]: () => defaultState,
  },
  defaultState,
)

export { setMovementControls, resetMovementControls, reducer }
