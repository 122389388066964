import { animateScroll } from 'react-scroll'
import moduleProps from '../moduleProps'
import { getActivity } from '../../../Api/activity'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { initialized } from '../State/initialized'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  const { modelType, modelId } = moduleProps()

  const { isExpanded } = getState().modules.Activity

  const params = {}

  if (modelType === 'User') {

    params['filter[causer_type_and_id]'] = `App\\Models\\${modelType},${modelId}`

  } else {

    params['filter[subject_type_and_id]'] = `App\\Models\\${modelType},${modelId}`

  }

  dispatch(startFetching())

  try {

    const response = await getActivity({ params })

    dispatch(setData(response.data.data))

    dispatch(finishFetching())

    dispatch(initialized())

    if (!isExpanded) return

    animateScroll.scrollToBottom({
      containerId: 'scroll',
    })

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }

}
