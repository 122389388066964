import { connect } from "react-redux"

import Component from "./Component"

import componentDidMount from "./Handlers/componentDidMount"
import componentDidUpdate from "./Handlers/componentDidUpdate"
import componentWillUnmount from "./Handlers/componentWillUnmount"
import onSave from "./Handlers/onSave"
import onCancel from "./Handlers/onCancel"
import onSubmitImageViewerModal from "./Handlers/onSubmitImageViewerModal"
import onCloseImageViewerModal from "./Handlers/onCloseImageViewerModal"

const mapStateToProps = (state, props) => ({
  isNew: props.match.params.id === undefined,
  isSubmitting: !!state.screens.ProductEditor.Form.status.submitting,
  initialized: state.screens.ProductEditor.initialized,
  imageViewerModal: state.screens.ProductEditor.imageViewerModal,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onSave,
  onCancel,
  onSubmitImageViewerModal,
  onCloseImageViewerModal,
}

export default connect(mapStateToProps, actionCreators)(Component)
