import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'react-final-form-arrays'
import { Row, Col, Checkbox } from 'antd'
import { Module, LinkButton } from 'Traqa/Packages'
import styles from './ItemsArray.css'

import Item from '../Item'

const ItemsArray = (props) => {

  const {
    isNew,
    toggleState,
    onToggle,
    onAddItem,
    onDeleteItems,
    onCreateGoodsIn,
  } = props
  
  return (

    <Module.Section
      title="Items"
      contentStyle={{ paddingTop: 15 }}
      borderTop
    >

      <FieldArray name="purchase_order_items">

        {({ fields }) => (
          
          <div>

            {fields.map((field, index) => (

              <Item
                key={fields.value[index].guid}
                field={field}
                index={index}
                guid={fields.value[index].guid}
                productId={fields.value[index].product_id}
              />

            ))}

            <Row gutter={8}>

              <Col span={1}>

                <div className={styles.toggle}>

                  <Checkbox
                    checked={toggleState.checked}
                    indeterminate={toggleState.indeterminate}
                    onChange={onToggle}
                  />

                </div>

              </Col>

              <Col span={10}>

                <div className={styles.actions}>

                  <LinkButton
                    type="primary"
                    icon="plus"
                    onClick={() => onAddItem()}
                  >
                    Add Item
                  </LinkButton>

                  {toggleState.checked && (

                    <React.Fragment>

                      <div style={{ width: 32 }} />

                      <LinkButton
                        type="danger"
                        icon="delete"
                        onClick={() => onDeleteItems()}
                      >
                        Delete
                      </LinkButton>

                    </React.Fragment>

                  )}

                  {(toggleState.checked && !isNew) && (

                    <React.Fragment>

                      <div style={{ width: 32 }} />

                      <LinkButton
                        type="default"
                        icon="form"
                        onClick={() => onCreateGoodsIn()}
                      >
                        Goods In
                      </LinkButton>

                    </React.Fragment>

                  )}

                </div>
              
              </Col>

            </Row>
            
          </div>

        )}

      </FieldArray>
  
    </Module.Section>

  )
}

ItemsArray.propTypes = {
  isNew: PropTypes.bool.isRequired,
  toggleState: PropTypes.object.isRequired,
  onToggle: PropTypes.func.isRequired,
  onAddItem: PropTypes.func.isRequired,
  onDeleteItems: PropTypes.func.isRequired,
  onCreateGoodsIn: PropTypes.func.isRequired,
}

ItemsArray.defaultProps = {
  
}

export default ItemsArray