import { combineReducers } from "redux"

import { reducer as createStocktakeModal } from "./State/createStocktakeModal"

import StocktakesTable from './Components/StocktakesTable/reducer'

export default combineReducers({
  createStocktakeModal,
  StocktakesTable,
})
