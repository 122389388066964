import { closeManualRunRateModal } from '../State/manualRunRateModal'
import fetch from '../Components/Report/Services/fetch'

export default (manualRunRate = undefined) => (dispatch) => {
  
  dispatch(closeManualRunRateModal())

  if (manualRunRate) {
    dispatch(fetch(false, true))
  }
  
}
