import React from 'react'
import PropTypes from 'prop-types'
import arrayMutators from 'final-form-arrays'
import { Form as FormProvider, FormSpy } from 'react-final-form'
import { Form } from 'antd'
import numeral from 'numeral'
import { Module } from 'Traqa/Packages'
import formService from './formService'

import Details from './Components/Details'
import ListHeader from './Components/ListHeader'
import List from './Components/List'

const Editor = (props) => {

  const {
    totalValue,
    initialValues,
    showItems,
    onSubmit,
    onFormChange,
    validate,
  } = props

  const Total = () => (
    <div>
      {`Total Value: £${numeral(totalValue).format('0,0')}`}
    </div>
  )
  
  return (

    <FormProvider
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmit}
      validate={validate}
      subscription={{ submitting: true, pristine: true }}
    >

      {({ handleSubmit, form }) => {
        
        formService(form)
        
        return (

          <Form
            onSubmit={handleSubmit}
            autoComplete="off"
            layout="vertical"
          >

            <FormSpy
              subscription={{ values: true, submitting: true, pristine: true }}
              onChange={onFormChange}
            />

            <Module>

              <Module.Header
                title="Stocktake"
                renderRight={Total}
              />

              <Details />

              <Module.Section borderTop>

                <ListHeader />

                {showItems && <List />}

              </Module.Section>
          
            </Module>

          </Form>

        )
      
      }}
    
    </FormProvider>
  )
}

Editor.propTypes = {
  totalValue: PropTypes.number,
  initialValues: PropTypes.object.isRequired,
  showItems: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
}

Editor.defaultProps = {
  totalValue: 0,
}

export default Editor