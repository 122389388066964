import { defer } from 'lodash'
import modalProps from '../../../modalProps'
import { findCourierService } from '../../../../../Api/courierServices'
import formService from '../formService'
import { handleException } from 'Traqa/Packages'

export default () => async () => {

  const { id } = modalProps()

  if (!id) return

  try {

    const response = await findCourierService({ id })

    const courierService = response.data.data

    const initialValues = {
      name: courierService.name,
    }

    defer(() => formService().initialize(initialValues))

  } catch (error) {

    handleException(error)

  }

}
