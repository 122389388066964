import { connect } from 'react-redux'
import ProductAssessment from './ProductAssessment'

const mapStateToProps = () => ({
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(ProductAssessment)
