import React from 'react'
import PropTypes from 'prop-types'
import { Module } from 'Traqa/Packages'

import Header from './Components/Header'
import Advanced from './Components/Advanced'
import Table from './Components/Table'
import Footer from './Components/Footer'

export default class extends React.PureComponent {

  static propTypes = {
    showAdvanced: PropTypes.bool.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render() {

    const {
      showAdvanced,
    } = this.props

    return (

      <Module style={{ borderTop: 'none' }}>

        {!showAdvanced && <Header {...this.props} />}

        {showAdvanced && <Advanced {...this.props} />}

        <Table {...this.props} />

        <Footer />

      </Module>

    )

  }

}
