import { connect } from 'react-redux'
import Header from './Header'

import onChangeStatus from './Handlers/onChangeStatus'

const mapStateToProps = (state) => ({
  salesOrder: state.screens.SalesOrder.salesOrder,
  formValues: state.screens.SalesOrder.Editor.formState.values,
})

const actionCreators = {
  onChangeStatus,
}

export default connect(mapStateToProps, actionCreators)(Header)
