import { createSelector } from 'reselect'
import { get } from 'lodash'

const selectItems = state => get(state.screens.SalesOrder.Editor.formState.values, 'sales_order_items', [])

const selectTotal = createSelector(
  selectItems,
  (items) => items.reduce((total, item) => total + (Number(item.price || 0) * Number(item.quantity || 0)), 0)
)

export default selectTotal
