import { createAction, handleActions } from 'redux-actions'

const setHasUnread = createAction('MODULES/NOTIFICATION_POPOVER/SET_HAS_UNREAD')
const resetHasUnread = createAction('MODULES/NOTIFICATION_POPOVER/RESET_HAS_UNREAD')

const defaultState = false

const reducer = handleActions(
  {
    [setHasUnread]: (state, action) => action.payload,
    [resetHasUnread]: () => defaultState,
  },
  defaultState
)

export { setHasUnread, resetHasUnread, reducer }
