import React from 'react'
import { createSelector } from 'reselect'
import { NavLink } from 'react-router-dom'
import { Tag, Icon } from 'antd'
import { orderBy } from 'lodash'
import moment from 'moment'
import { getTeamUrl } from 'Traqa/Helpers'

const selectUnallocatedOnly = state => state.screens.StockAssessment.Report.formState.values.unallocatedOnly
const selectIncludeExpected = state => state.screens.StockAssessment.Report.formState.values.includeExpected
const selectHideZeros = state => state.screens.StockAssessment.Report.formState.values.hideZeros
const selectSortBy = state => state.screens.StockAssessment.Report.formState.values.sortBy
const selectSortDirection = state => state.screens.StockAssessment.Report.formState.values.sortDirection
const selectData = state => state.screens.StockAssessment.Report.data

const makeTitle = (landedCost) => {

  const sourceTypes = {
    'App\\Models\\Stocktake': ['stocktakes', 'Stocktake (Surplus)'],
    'App\\Models\\GoodsInRecord': ['goods_in_records', 'Goods In Record'],
    'App\\Models\\GoodsOutRecord': ['goods_in_records', 'Goods Out Record (Return)'],
    'App\\Models\\Transfer': ['transfers', 'Transfer (In)'],
    'App\\Models\\Assembly': ['assemblies', 'Assembly (In)'],
    'App\\Models\\Reading': ['readings', 'Reading (Surplus)'],
    'App\\Models\\Adjustment': ['adjustments', 'Adjustment (In)'],
  }

  if (!sourceTypes[landedCost.source_type]) return 'Product Value'

  const [domain, label] = sourceTypes[landedCost.source_type]
  
  return (

    <a
      onClick={e => {
        window.open(getTeamUrl(`${domain}/${landedCost.source_id}`))
        e.stopPropagation()
      }}
    >
      <span>
        {moment(landedCost.at).format('Do MMMM YYYY')}
      </span>
      {' - '}
      {label}
    </a>

  )

}

const selectTableRows = createSelector(
  selectUnallocatedOnly,
  selectIncludeExpected,
  selectHideZeros,
  selectSortBy,
  selectSortDirection,
  selectData,
  (unallocatedOnly, includeExpected, hideZeros, sortBy, sortDirection, data) => {

    const key1 = includeExpected ? 'expected' : 'actual'
    const key2 = unallocatedOnly ? 'unallocated' : 'quantity'

    const products = []

    data.products.forEach(product => {

      const quantity = product[key1][key2]
      const value = product[key1][`${key2}_value`]

      if (hideZeros && !quantity) return

      const stockLocations = product.stock_locations.map(stockLocation => {
        const $quantity = stockLocation[key1][key2]
        const $value = stockLocation[key1][`${key2}_value`]
        
        return {
          ...stockLocation,
          key: `${stockLocation.id}|${product.id}`,
          type: 'stock-location',
          title: stockLocation.name,
          quantity: $quantity,
          value: $value,
          average: $quantity > 0 ? $value / $quantity : 0,
          product,
          children: stockLocation.landed_costs.map((landedCost, index) => ({
            key: `${product.id}|${stockLocation.id}|${index}`,
            title: makeTitle(landedCost),
            type: 'landed-cost',
            quantity: landedCost.quantity,
            value: landedCost.quantity * landedCost.value,
            average: landedCost.value,
          })),
        }
      })

      products.push({
        ...product,
        key: product.id,
        type: 'product',
        title: (
          <div style={{ display: 'inline-block' }}>
            <NavLink to={getTeamUrl(`products/${product.id}`)}>
              {`${product.code} - ${product.name}`}
            </NavLink>
            <Tag color='volcano' style={{ marginLeft: 5 }}>{product.form}</Tag>
            {!!product.archived_at && <Icon type='eye-invisible' style={{ color: '#999' }} />}
          </div>
        ),
        quantity,
        value,
        average: quantity > 0 ? value / quantity : 0,
        stock_locations: stockLocations,
        children: orderBy(stockLocations.filter((stockLocation) => stockLocation.quantity > 0), 'value', 'desc'),
      })

    })

    return orderBy(products, sortBy, sortDirection)

  },
)

export default selectTableRows
