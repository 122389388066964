import { createAction, handleActions } from 'redux-actions'

const setInteractionId = createAction('SCREENS/STOCK_ADJUSTMENTS/REPORT/SET_INTERACTION_ID')
const setInteractionString = createAction('SCREENS/STOCK_ADJUSTMENTS/REPORT/SET_INTERACTION_STRING')
const setInteractionTotalsId = createAction('SCREENS/STOCK_ADJUSTMENTS/REPORT/SET_INTERACTION_TOTALS_ID')
const resetInteraction = createAction('SCREENS/STOCK_ADJUSTMENTS/REPORT/RESET_INTERACTION')

const defaultState = {
  id: null,
  string: null,
  totalsId: null,
}

const reducer = handleActions(
  {
    [setInteractionId]: (state, action) => ({ ...state, id: action.payload }),
    [setInteractionString]: (state, action) => ({ ...state, string: action.payload }),
    [setInteractionTotalsId]: (state, action) => ({ ...state, totalsId: action.payload }),
    [resetInteraction]: () => defaultState,
  },
  defaultState,
)

export { setInteractionId, setInteractionString, setInteractionTotalsId, resetInteraction, reducer }
