import React from 'react'
import PropTypes from 'prop-types'
import { Field, FormSpy } from 'react-final-form'
import { Form, Row, Col, Select, Input, DatePicker, Switch, Icon, TimePicker, Alert, Typography } from 'antd'
import { get } from 'lodash'
import { Module } from 'Traqa/Packages'
import classNames from 'classnames'
import styles from './styles.css'

const Details = (props) => {

  const {
    stockLocations,
    customers,
    customerSites,
    users,
    isNew,
    customerSite,
  } = props
  
  return (

    <Module.Section
      contentStyle={{ paddingBottom: 15 }}
    >

      <FormSpy subscription={{ submitting: true }}>

        {({ submitting, form }) => (

          <div>

            <Row gutter={8}>

              <Col span={4}>

                <Field name='stock_location_id'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Location'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        disabled={submitting}
                        optionFilterProp='children'
                        showSearch
                        allowClear
                      >
                        <Select.OptGroup label='Active'>
                          {stockLocations.filter(stockLocation => !stockLocation.archived_at).map(stockLocation => (
                            <Select.Option key={stockLocation.id} value={stockLocation.id}>
                              {stockLocation.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                        <Select.OptGroup label='Archived'>
                          {stockLocations.filter(stockLocation => !!stockLocation.archived_at).map(stockLocation => (
                            <Select.Option key={stockLocation.id} value={stockLocation.id} style={{ color: '#999' }}>
                              {stockLocation.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                      </Select>
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={7}>

                <Field name='customer_id'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Customer'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        onChange={value => {
                          input.onChange(value === undefined ? null : value)
                          form.change('customer_site_id', null)
                        }}
                        disabled={submitting}
                        optionFilterProp='children'
                        showSearch
                        allowClear
                        dropdownMatchSelectWidth={false}
                      >
                        <Select.OptGroup label='Active'>
                          {customers.filter(customer => !customer.archived_at).map(customer => (
                            <Select.Option key={customer.id} value={customer.id}>
                              {customer.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                        <Select.OptGroup label='Archived'>
                          {customers.filter(customer => !!customer.archived_at).map(customer => (
                            <Select.Option key={customer.id} value={customer.id} style={{ color: '#999' }}>
                              {customer.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                      </Select>
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={7}>

                <Field name='customer_site_id'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Site'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        onChange={value => input.onChange(value === undefined ? null : value)}
                        disabled={submitting}
                        optionFilterProp='children'
                        showSearch
                        allowClear
                        dropdownMatchSelectWidth={false}
                      >
                        <Select.OptGroup label='Active'>
                          {customerSites.filter($customerSite => !$customerSite.archived_at).map($customerSite => (
                            <Select.Option key={$customerSite.id} value={$customerSite.id}>
                              {$customerSite.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                        <Select.OptGroup label='Archived'>
                          {customerSites.filter($customerSite => !!$customerSite.archived_at).map($customerSite => (
                            <Select.Option key={$customerSite.id} value={$customerSite.id} style={{ color: '#999' }}>
                              {$customerSite.name}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                      </Select>
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={3}>

                <Field name='date' allowNull>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Date'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <DatePicker
                        {...input}
                        disabled={submitting}
                        format='DD/MM/YYYY'
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={3}>

                <Field name='time' allowNull>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Time'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <TimePicker
                        {...input}
                        disabled={submitting}
                        format='HH:mm'
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

            </Row>

            <Row gutter={8}>

              <Col span={4}>

                <Field name='user_id'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='User'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        disabled={submitting}
                        optionFilterProp='children'
                        showSearch
                        allowClear
                      >
                        {users.filter(user => !isNew || !user.deactivated_at).map(user => (
                          <Select.Option key={user.id} value={user.id}>
                            {user.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={3}>

                <Field name='invoice_no'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Invoice No.'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Input
                        {...input}
                        disabled={submitting}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={4}>

                <Field name='contact_details'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Contact Details'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Input
                        {...input}
                        disabled={submitting}
                        className={classNames({
                          [styles.highlight]: !!input.value,
                        })}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={10}>

                <Field name='picking_notes'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Picking Notes'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Input
                        {...input}
                        disabled={submitting}
                        className={classNames({
                          [styles.highlight]: !!input.value,
                        })}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

              <Col span={2}>

                <Field name='is_return'>
                  {({ input }) => (
                    <Form.Item
                      label='Return'
                    >
                      <Switch
                        {...input}
                        checked={input.value}
                        disabled={submitting}
                        checkedChildren={<Icon type='check' />}
                        style={{ marginTop: 4 }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </Col>

            </Row>

            {!!get(customerSite, 'delivery_notes') && (

              <Alert
                message='Delivery Notes'
                description={<Typography.Text copyable>{customerSite.delivery_notes}</Typography.Text>}
                type='warning'
                showIcon
                closable
              />

            )}

            {!!get(customerSite, 'delivery_notes') && !!get(customerSite, 'requires_tail_lift') && <br />}

            {!!get(customerSite, 'requires_tail_lift') && (

              <Alert
                message='Requires Tail Lift'
                description={`${customerSite.name} requires a tail lift`}
                type='warning'
                showIcon
                closable
              />

            )}

          </div>
      
        )}

      </FormSpy>
  
    </Module.Section>

  )
  
}

Details.propTypes = {
  stockLocations: PropTypes.array.isRequired,
  customers: PropTypes.array.isRequired,
  customerSites: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  isNew: PropTypes.bool.isRequired,
  customerSite: PropTypes.object,
}

Details.defaultProps = {
  customerSite: null,
}

export default Details
