import { createAction, handleActions } from 'redux-actions'

const openManualRunRateModal = createAction('SCREENS/DEMAND/OPEN_MANUAL_RUN_RATE_MODAL')
const closeManualRunRateModal = createAction('SCREENS/DEMAND/CLOSE_MANUAL_RUN_RATE_MODAL')

const defaultState = {
  show: false,
  stockLocationId: null,
  product: null,
  periodType: null,
  period: null,
}

const reducer = handleActions(
  {
    [openManualRunRateModal]: (state, action) => Object.assign({}, state, {
      show: true,
      stockLocationId: action.payload.stockLocationId,
      product: action.payload.product,
      periodType: action.payload.periodType,
      period: action.payload.period,
    }),
    [closeManualRunRateModal]: () => Object.assign({}, defaultState),
  },
  defaultState
)

export { openManualRunRateModal, closeManualRunRateModal, reducer }
