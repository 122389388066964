import moment from 'moment'
import { getCouriers } from '../../Api/couriers'
import { loadCouriers } from '../State/couriers'

let lastGetTimestamp = null

const cacheTTL = 60000

export default ({ force } = {}) => async (dispatch) => {

  try {

    if (!force && lastGetTimestamp) {

      const now = Number(moment().format('x'))

      if (now < (lastGetTimestamp + cacheTTL)) return
    }
      
    lastGetTimestamp = Number(moment().format('x'))

    const params = {
      sort: 'name',
      include: [
        'courier_services',
        'courier_zones.courier_zone',
        'courier_rates',
        'courier_charges',
      ],
    }

    const response = await getCouriers({ params })

    dispatch(loadCouriers(response.data.data))

  } catch (error) {
    
    throw error

  }

}
