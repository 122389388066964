import React from 'react'
import PropTypes from 'prop-types'
import { Drawer } from'antd'
import styles from './styles.css'

import Table from './Components/Table'

export default class extends React.PureComponent {

  static propTypes = {
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]).isRequired,
    subtitle: PropTypes.string.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render() {

    const {
      title,
      subtitle,
      onClose,
    } = this.props

    return (

      <Drawer
        title={(
          <div className={styles.titleWrapper}>
            <div className={styles.title}>{title}</div>
            <div className={styles.subtitle}>{subtitle}</div>
          </div>
        )}
        placement='bottom'
        closable={false}
        onClose={onClose}
        // eslint-disable-next-line react/jsx-boolean-value
        visible={true}
        key='transactionsDrawer'
        bodyStyle={{ paddingTop: 0 }}
      >
        
        <Table />

      </Drawer>

    )
    
  }

}
