import { createAction, handleActions } from 'redux-actions'

const toggleAdvanced = createAction('MODULES/TRANSACTIONS_TABLE/TOGGLE_ADVANCED')
const showAdvanced = createAction('MODULES/TRANSACTIONS_TABLE/SHOW_ADVANCED')
const hideAdvanced = createAction('MODULES/TRANSACTIONS_TABLE/HIDE_ADVANCED')
const setValues = createAction('MODULES/TRANSACTIONS_TABLE/SET_VALUES')
const resetValues = createAction('MODULES/TRANSACTIONS_TABLE/RESET_VALUES')
const resetAdvanced = createAction('MODULES/TRANSACTIONS_TABLE/RESET_ADVANCED')

const defaultState = {
  show: false,
  values: {
    ownerType: null,
    searchTerm: null,
    stockLocationId: null,
    productId: null,
    batchNo: null,
    transactionAtBetween: [],
  },
}

const reducer = handleActions(
  {
    [toggleAdvanced]: (state) => ({ ...state, show: !state.show }),
    [showAdvanced]: (state) => ({ ...state, show: true }),
    [hideAdvanced]: (state) => ({ ...state, show: false }),
    [setValues]: (state, action) => ({
      ...state,
      values: {
        ownerType: action.payload.ownerType,
        searchTerm: action.payload.searchTerm,
        stockLocationId: action.payload.stockLocationId,
        productId: action.payload.productId,
        batchNo: action.payload.batchNo,
        transactionAtBetween: action.payload.transactionAtBetween || [],
      }
    }),
    [resetValues]: (state) => ({ ...state, values: { ...defaultState.values } }),
    [resetAdvanced]: () => ({ ...defaultState }),
  },
  defaultState,
)

export { toggleAdvanced, showAdvanced, hideAdvanced, setValues, resetValues, resetAdvanced, reducer }
