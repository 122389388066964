import { push } from 'connected-react-router'
import { message } from 'antd'
import screenProps from '../../../screenProps'
import { createReading, updateReading } from '../../../../../Api/readings'
import initialize from './initialize'
import fetch from '../../../Services/fetch'
//import fetchActivity from '../../../../../Modules/Activity/Services/fetch'
import { getTeamUrl } from 'Traqa/Helpers'
import { handleException } from 'Traqa/Packages'

export default (values) => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    const data = {
      stock_location_id: values.stock_location_id,
      description: values.description,
      date_time: `${values.date.format('YYYY-MM-DD')} ${values.time.format('HH:mm')}:00`,
      reading_items: values.reading_items.map(item => ({
        id: item.id,
        product_id: item.product_id,
        quantity: item.quantity,
      })),
    }

    if (id === 'new') {

      const { data: { data: { id: newId } } } = await createReading({ data })

      dispatch(push(getTeamUrl(`readings/${newId}`)))

      message.success("Saved")

    } else {

      await updateReading({ id, data })

      await dispatch(fetch())

      await dispatch(initialize())

      //dispatch(fetchActivity())

      message.success("Saved")

    }

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}