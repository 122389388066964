import modalProps from '../modalProps'
import fetchCourierServices from '../../../Core/Services/fetchCourierServices'
import fetchCourierZones from '../../../Core/Services/fetchCourierZones'
import initializeForm from '../Components/Form/Services/initialize'

export default (props) => (dispatch) => {

  modalProps(props)

  dispatch(fetchCourierServices())
  dispatch(fetchCourierZones())
  
  dispatch(initializeForm())
  
}
