import { connect } from "react-redux"
import { get } from 'lodash'
import Editor from "./Editor"

import onFormChange from './Handlers/onFormChange'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'

const mapStateToProps = (state) => ({
  totalValue: get(state.screens.Stocktake.stocktake, 'total_value'),
  initialValues: state.screens.Stocktake.Editor.initialValues,
  showItems: state.screens.Stocktake.Editor.showItems,
})

const actionCreators = {
  onFormChange,
  onSubmit,
  validate,
}

export default connect(mapStateToProps, actionCreators)(Editor)
