import modalProps from '../modalProps'
import fetchStockLocations from '../../../Core/Services/fetchStockLocations'
import initialize from '../Services/initialize'

export default (props) => (dispatch) => {

  modalProps(props)

  dispatch(fetchStockLocations())
  
  dispatch(initialize())
  
}
