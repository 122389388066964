import { createAction, handleActions } from 'redux-actions'

const loadCouriers = createAction('CORE/COURIERS/LOAD_COURIERS')

const defaultState = []

const reducer = handleActions(
  {
    [loadCouriers]: (state, action) => action.payload,
  },
  defaultState,
)

export { loadCouriers, reducer }
