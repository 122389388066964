import { push } from 'connected-react-router'
import { message } from 'antd'
import screenProps from '../../../screenProps'
import { createPurchaseOrder, updatePurchaseOrder } from '../../../../../Api/purchaseOrders'
import initialize from './initialize'
import fetch from '../../../Services/fetch'
//import fetchActivity from '../../../../../Modules/Activity/Services/fetch'
import { getTeamUrl } from 'Traqa/Helpers'
import { handleException } from 'Traqa/Packages'

export default (values) => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    const data = {
      number: values.number,
      supplier_id: values.supplier_id,
      description: values.description || null,
      date: values.date.format('YYYY-MM-DD'),
      commercial_value_usd: values.commercial_value_usd,
      user_id: values.user_id,
      freight_id: values.freight_id || null,
      freight_po_number: values.freight_po_number || null,
      freight_cost: values.freight_cost,
      duty_cost: values.duty_cost,
      exchange_rate: values.exchange_rate,
      purchase_order_items: values.purchase_order_items.map(item => ({
        id: item.id,
        product_id: item.product_id,
        supplier_id: item.supplier_id,
        landed_cost: item.landed_cost,
        quantity: item.quantity,
      })),
    }

    if (id === 'new') {

      const { data: { data: { id: newId } } } = await createPurchaseOrder({ data })

      dispatch(push(getTeamUrl(`purchase_orders/${newId}`)))

      message.success("Saved")

    } else {

      await updatePurchaseOrder({ id, data })

      await dispatch(fetch())

      await dispatch(initialize())

      //dispatch(fetchActivity())

      message.success("Saved")

    }

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}