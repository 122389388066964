import { createAction, handleActions } from 'redux-actions'

const setHoldingWeeks = createAction('SCREENS/DASHBOARD/COLUMN_CHARTS/SET_HOLDING_WEEKS')
const resetHoldingWeeks = createAction('SCREENS/DASHBOARD/COLUMN_CHARTS/RESET_HOLDING_WEEKS')

const defaultState = {
  products: {
    asc: [],
    desc: [],
  },
}

const reducer = handleActions(
  {
    [setHoldingWeeks]: (state, action) => action.payload,
    [resetHoldingWeeks]: () => defaultState,
  },
  defaultState,
)

export { setHoldingWeeks, resetHoldingWeeks, reducer }
