export default (values) => () => {

  const errors = {}

  if (!values.stock_location_id) errors.stock_location_id = true

  if (!values.description) errors.description = true

  if (!values.date) errors.date = true

  if (!values.time) errors.time = true

  if (Array.isArray(values.reading_items)) {

    errors.reading_items = values.reading_items.map(item => {

      const itemErrors = {}

      if (!item.product_id) itemErrors.product_id = true
      
      if (!item.quantity) itemErrors.quantity = true

      return itemErrors
      
    })
  }

  return errors

}