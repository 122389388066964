import { combineReducers } from "redux"

import { reducer as initialized } from "./State/initialized"
import { reducer as assembly } from "./State/assembly"

import Editor from "./Components/Editor/reducer"

export default combineReducers({
  initialized,
  assembly,
  Editor,
})
