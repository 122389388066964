import { createSelector } from 'reselect'

const selectSelectedItems = state => state.screens.Adjustment.Editor.selectedItems
const selectUuid = (state, props) => props.guid

const createIsSelectedSelector = () => createSelector(
  selectSelectedItems,
  selectUuid,
  (selectedItems, guid) => selectedItems.includes(guid)
)

export default createIsSelectedSelector