import { setMonthYear } from '../../../State/monthYear'
import { firstPage } from '../../../State/pagination'
import fetch from '../../../Services/fetch'

export default () => (dispatch) => {

  dispatch(setMonthYear(null))

  dispatch(firstPage())

  dispatch(fetch())
  
}
