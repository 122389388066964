import { combineReducers } from 'redux'

import { reducer as initialized } from './State/initialized'
import { reducer as goodsOutRecord } from './State/goodsOutRecord'
import { reducer as linkConsignmentModal } from './State/linkConsignmentModal'

import Editor from './Components/Editor/reducer'

export default combineReducers({
  initialized,
  goodsOutRecord,
  linkConsignmentModal,
  
  Editor,
})
