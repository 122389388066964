import modalProps from '../../../modalProps'
import { createCourierRate, updateCourierRate } from '../../../../../Api/courierRates'
import { handleException } from 'Traqa/Packages'

export default (values) => async () => {

  const { id, courierId } = modalProps()

  const data = {
    courier_id: values.courier_id,
    courier_service_id: values.courier_service_id,
    courier_zone_id: values.courier_zone_id || null,
    quantity: values.quantity,
    pallet_full_rate: values.pallet_full_rate,
    pallet_half_rate: values.pallet_half_rate,
    pallet_quarter_rate: values.pallet_quarter_rate,
    pallet_micro_rate: values.pallet_micro_rate,
    parcel_rate: values.parcel_rate,
  }

  try {

    if (id) {

      await updateCourierRate({ id, data })

    } else {

      data.courier_id = courierId

      await createCourierRate({ data })

    }
    
    modalProps().onClose(true)

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
