import React from "react"
import PropTypes from "prop-types"
import styles from './Header.css'

import FulfilmentProgress from './Components/FulfillmentProgress'

const Header = (props) => (

  <div className={styles.wrapper}>

    <FulfilmentProgress {...props} />

  </div>

)

Header.propTypes = {
  purchaseOrder: PropTypes.object.isRequired,
}

export default Header
