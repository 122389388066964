import { createAction, handleActions } from 'redux-actions'

const setTransfer = createAction('SCREENS/TRANSFER/SET_TRANSFER')
const resetTransfer = createAction('SCREENS/TRANSFER/RESET_TRANSFER')

const defaultState = null

const reducer = handleActions(
  {
    [setTransfer]: (state, action) => action.payload,
    [resetTransfer]: () => null,
  },
  defaultState
)

export { setTransfer, resetTransfer, reducer }