import { closeAddProductToGoodsInRecordModal } from '../State/addProductToGoodsInRecordModal'
import fetch from '../Components/Report/Services/fetch'

export default (shouldReload = false) => (dispatch) => {
  
  dispatch(closeAddProductToGoodsInRecordModal())

  if (shouldReload) {
    dispatch(fetch())
  }
  
}
