import fetchStockLocations from '../../../Core/Services/fetchStockLocations'
import fetchUsers from '../../../Core/Services/fetchUsers'
import fetchSuppliersTableData from '../Components/SuppliersTable/Services/fetch'

export default () => (dispatch) => {
  
  dispatch(fetchStockLocations())
  dispatch(fetchUsers())

  dispatch(fetchSuppliersTableData())
  
}