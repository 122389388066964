import screenProps from '../../../screenProps'
import { getGoodsInRecords } from '../../../../../Api/goodsInRecords'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { id: purchaseOrderId } = screenProps().match.params

    if (purchaseOrderId === 'new') return

    const { sorting } = getState().screens.PurchaseOrder.GoodsInRecordsTable

    const params = {}

    params.include = [
      'supplier',
      'stock_location',
      'user',
    ].join()

    params['filter[purchase_order_id]'] = purchaseOrderId

    params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : 'date_time'

    dispatch(startFetching())

    const response = await getGoodsInRecords({ params })

    dispatch(setData(response.data.data))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }
}