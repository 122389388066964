import moduleProps from '../moduleProps'
import { resetData } from '../State/data'
import { resetInitialized } from '../State/initialized'
import { resetIsExpanded } from '../State/isExpanded'
import { resetExpandedActivities } from '../State/expandedActivities'
import { resetExpandedLogs } from '../State/expandedLogs'

export default () => (dispatch) => {

  moduleProps.reset()

  dispatch(resetData())
  dispatch(resetInitialized())
  dispatch(resetIsExpanded())
  dispatch(resetExpandedActivities())
  dispatch(resetExpandedLogs())

}
