import { createAction, handleActions } from 'redux-actions'

const setData = createAction('SCREENS/PURCHASE_PLANNER/REPORT/SET_DATA')
const resetData = createAction('SCREENS/PURCHASE_PLANNER/REPORT/RESET_DATA')

const defaultState = {
  goods_in_records: [],
  products: [],
  total: 0,
}

const reducer = handleActions(
  {
    [setData]: (state, action) => action.payload,
    [resetData]: () => defaultState,
  },
  defaultState,
)

export { setData, resetData, reducer }
