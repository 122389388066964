import moduleProps from '../moduleProps'

export default (getState) => {

  const { productId, withDivisionProducts, format } = moduleProps()

  const { activeTab, advanced, searchTerm, sorting } = getState().modules.TransactionsTable

  const params = {}

  if (productId) {
    if (withDivisionProducts) {
      params['filter[with_division_products]'] = productId
    } else {
      params['filter[product_id]'] = productId
    }
  }

  if (format === 'transactions') {
    params['filter[is_allocation]'] = false
    params['filter[is_expectation]'] = false
  }

  if (format === 'allocations') {
    params['filter[is_allocation]'] = true
    params['filter[is_expectation]'] = false
  }

  if (format === 'expectations') {
    params['filter[is_allocation]'] = false
    params['filter[is_expectation]'] = true
  }

  if (advanced.show) {

    const {
      ownerType,
      stockLocationId,
      batchNo,
      transactionAtBetween,
    } = advanced.values

    if (ownerType) params['filter[owner_type]'] = ownerType

    if (advanced.values.searchTerm) params.search = advanced.values.searchTerm

    if (stockLocationId) params['filter[stock_location_id]'] = stockLocationId

    if (productId) params['filter[product_id]'] = productId

    if (advanced.values.productId) params['filter[product_id]'] = advanced.values.productId

    if (batchNo) params['filter[batch_no]'] = batchNo

    if (transactionAtBetween.length > 0) params['filter[transaction_at_between]'] = `${transactionAtBetween[0].format('YYYY-MM-DD')} 00:00:00,${transactionAtBetween[1].format('YYYY-MM-DD')} 23:59:59`

  } else {

    if (activeTab !== 'All') params['filter[owner_type]'] = activeTab

    if (searchTerm) params.search = searchTerm

  }

  params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : '-transaction_at_id'

  return params

}
