import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field, FormSpy } from 'react-final-form'
import { Form, Select, DatePicker, Input, InputNumber, Switch, Icon } from 'antd'
import { ExposeFormToHelper } from 'Traqa/Packages'
import formService from './formService'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    suppliers: PropTypes.array.isRequired,
    stockLocations: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  render() {

    const {
      suppliers,
      stockLocations,
      users,
      onChangeFormState,
      onSubmit,
      validate,
    } = this.props

    const initialValues = {
      date_time: null,
      on_the_water: false,
    }

    return (

      <FormProvider
        validate={validate}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >

        {({ handleSubmit, submitting }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            autoComplete='off'
          >

            <ExposeFormToHelper helper={formService} />

            <FormSpy subscription={{ submitting: true }} onChange={onChangeFormState} />

            <Field name='invoice_no'>
              {({ input, meta }) => (
                <Form.Item
                  label='Reference'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Input
                    {...input}
                    disabled={submitting}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='supplier_id'>
              {({ input, meta }) => (
                <Form.Item
                  label='Supplier'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Select
                    {...input}
                    disabled={submitting}
                    optionFilterProp='children'
                    showSearch
                    allowClear
                  >
                    <Select.OptGroup label='Active'>
                      {suppliers.filter(supplier => !supplier.archived_at).map(supplier => (
                        <Select.Option key={supplier.id} value={supplier.id}>
                          {supplier.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                    <Select.OptGroup label='Archived'>
                      {suppliers.filter(supplier => !!supplier.archived_at).map(supplier => (
                        <Select.Option key={supplier.id} value={supplier.id} style={{ color: '#999' }}>
                          {supplier.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name='stock_location_id'>
              {({ input, meta }) => (
                <Form.Item
                  label='Location'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Select
                    {...input}
                    disabled={submitting}
                    optionFilterProp='children'
                    showSearch
                    allowClear
                  >
                    <Select.OptGroup label='Active'>
                      {stockLocations.filter(stockLocation => !stockLocation.archived_at).map(stockLocation => (
                        <Select.Option key={stockLocation.id} value={stockLocation.id}>
                          {stockLocation.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                    <Select.OptGroup label='Archived'>
                      {stockLocations.filter(stockLocation => !!stockLocation.archived_at).map(stockLocation => (
                        <Select.Option key={stockLocation.id} value={stockLocation.id} style={{ color: '#999' }}>
                          {stockLocation.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name='user_id'>
              {({ input, meta }) => (
                <Form.Item
                  label='User'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Select
                    {...input}
                    disabled={submitting}
                    optionFilterProp='children'
                    showSearch
                    allowClear
                  >
                    {users.map(user => (
                      <Select.Option key={user.id} value={user.id}>
                        {user.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name='date_time' allowNull>
              {({ input, meta }) => (
                <Form.Item
                  label='Date'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <DatePicker
                    {...input}
                    disabled={submitting}
                    format='DD/MM/YYYY'
                    style={{ width: 150 }}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='commercial_value_usd'>
              {({ input, meta }) => (
                <Form.Item
                  label='Value ($)'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <InputNumber
                    {...input}
                    disabled={submitting}
                    min={0}
                    step={1}
                    precision={2}
                    style={{ width: '40%' }}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='on_the_water'>
              {({ input }) => (
                <Form.Item
                  label='On The Water'
                >
                  <Switch
                    {...input}
                    checked={input.value}
                    disabled={submitting}
                    checkedChildren={<Icon type='check' />}
                    style={{ marginTop: 4 }}
                  />
                </Form.Item>
              )}
            </Field>

          </Form>

        )}

      </FormProvider>

    )

  }

}
