import { resetInitialized } from '../State/initialized'
import { resetGoodsOutRecord } from '../State/goodsOutRecord'
import { closeLinkConsignmentModal } from '../State/linkConsignmentModal'
import resetEditor from '../Components/Editor/Services/reset'

export default () => async (dispatch) => {

  dispatch(resetInitialized())
  dispatch(resetGoodsOutRecord())
  dispatch(closeLinkConsignmentModal())
  
  dispatch(resetEditor())
  
}
