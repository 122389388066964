import { createAction, handleActions } from 'redux-actions'

const openCourierZoneModal = createAction('SCREENS/COURIER/OPEN_COURIER_ZONE_MODAL')
const closeCourierZoneModal = createAction('SCREENS/COURIER/CLOSE_COURIER_ZONE_MODAL')

const defaultState = {
  show: false,
  id: null,
  courierId: null,
}

const reducer = handleActions(
  {
    [openCourierZoneModal]: (state, action) => Object.assign({}, state, {
      show: true,
      id: action.payload.id,
      courierId: action.payload.courierId,
    }),
    [closeCourierZoneModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openCourierZoneModal, closeCourierZoneModal, reducer }
