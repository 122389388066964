import screenProps from '../screenProps'
import fetchStockLocations from '../../../Core/Services/fetchStockLocations'
import fetch from '../Services/fetch'

export default (props) => async (dispatch) => {

  screenProps(props)
  
  dispatch(fetchStockLocations())

  await dispatch(fetch())
  
}