import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'

export default class extends React.PureComponent {

  static propTypes = {
    data: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
  }

  render() {

    const {
      data,
      isFetching,
    } = this.props

    return (

      <Table
        dataSource={data}
        loading={isFetching}
        rowKey='user_id'
        pagination={false}
      >

        <Table.Column
          key='user_name'
          title='User'
          render={(text, record) => record.user_name}
        />

        <Table.Column
          key='lines_picked'
          title='Lines'
          render={(text, record) => record.lines_picked}
        />

      </Table>

    )

  }

}
