import { connect } from 'react-redux'
import StockAssessment from './StockAssessment'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onCloseTransactionsDrawer from './Handlers/onCloseTransactionsDrawer'

const mapStateToProps = (state) => ({
  transactionsDrawer: state.screens.StockAssessment.transactionsDrawer,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onCloseTransactionsDrawer,
}

export default connect(mapStateToProps, actionCreators)(StockAssessment)
