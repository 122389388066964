import fetchStockLocations from '../../../Core/Services/fetchStockLocations'
import fetchProductCategories from '../../../Core/Services/fetchProductCategories'
import fetchProducts from '../../../Core/Services/fetchProducts'

export default () => (dispatch) => {

  dispatch(fetchStockLocations())
  dispatch(fetchProductCategories())
  dispatch(fetchProducts())
  
}
