import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import Screen from '../../Components/Screen'

import PieCharts from './Components/PieCharts'
import GoodsInRecords from './Components/GoodsInRecords'
import ColumnCharts from './Components/ColumnCharts'

const Dashboard = props => {

  const {
    componentDidMount,
    componentWillUnmount,
  } = props

  useEffect(() => {
    componentDidMount()
    return () => componentWillUnmount()
  }, [])

  return (

    <Screen
      title='Dashboard'
    >

      <Row gutter={20}>

        <Col span={12}>

          <PieCharts />

        </Col>

        <Col span={12}>

          <GoodsInRecords />

        </Col>

      </Row>

      <br />

      <ColumnCharts />

    </Screen>

  )

}

Dashboard.propTypes = {
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
}

export default Dashboard
