import React from 'react'
import PropTypes from 'prop-types'
import { Radio } from 'antd'
import ReactHighcharts from 'react-highcharts'
import numeral from 'numeral'
import { Module } from 'Traqa/Packages'
import styles from './styles.css'

ReactHighcharts.Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
})

const PieCharts = props => {

  const {
    activeTab,
    totalValue,
    config,
    onChangeActiveTab,
  } = props

  return (

    <Module className={styles.wrapper}>

      <Module.Header
        title='Holding'
        renderRight={() => (
          <div className={styles.header}>
            <Radio.Group value={activeTab} onChange={event => onChangeActiveTab(event.target.value)} size='small'>
              <Radio.Button value='all'>All</Radio.Button>
              <Radio.Button value='zero_run_rate'>0 RR</Radio.Button>
            </Radio.Group>
            <div style={{ width: 10 }} />
            <div className={styles.totalValue}>{`Total: £${numeral(totalValue).format('0,0')}`}</div>
          </div>
        )}
      />

      <Module.Section
        contentClassName={styles.content}
      >

        <ReactHighcharts isPureConfig config={config} />

      </Module.Section>

    </Module>

  )

}

PieCharts.propTypes = {
  activeTab: PropTypes.string.isRequired,
  totalValue: PropTypes.number.isRequired,
  config: PropTypes.object.isRequired,
  onChangeActiveTab: PropTypes.func.isRequired,
}

export default PieCharts
