import React from 'react'
import PropTypes from 'prop-types'
import { App } from 'Traqa/Packages'
import Screens from '../Screens'

import 'antd/dist/antd.css'
import './global.css'

export default class _App_ extends React.PureComponent {

  static propTypes = {
    ready: PropTypes.bool.isRequired,
    showSplashscreen: PropTypes.bool.isRequired,
    componentDidMount: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  render () {

    const {
      ready,
      showSplashscreen,
    } = this.props

    return (

      <App
        Screens={Screens}
        showApp={ready}
        showSplashscreen={showSplashscreen}
      >

        <Screens />

      </App>

    )
  }
}