import moment from 'moment'
import { getStockLocations } from '../../Api/stockLocations'
import { loadStockLocations } from '../State/stockLocations'

let lastGetTimestamp = null

const cacheTTL = 60000

export default ({ force } = {}) => async (dispatch) => {

  try {

    if (!force && lastGetTimestamp) {

      const now = Number(moment().format('x'))

      if (now < (lastGetTimestamp + cacheTTL)) return
    }
      
    lastGetTimestamp = Number(moment().format('x'))

    const params = {}

    const response = await getStockLocations({ params })

    dispatch(loadStockLocations(response.data.data))

  } catch (error) {
    
    throw error

  }
}