import { createSelector } from 'reselect'

const selectPristine = (state) => state.screens.GoodsOutRecord.Editor.formState.pristine

const selectIsEditing = createSelector(
  selectPristine,
  (pristine) => !pristine,
)

export default selectIsEditing
