import { createAction, handleActions } from 'redux-actions'

const setNotifications = createAction('MODULES/NOTIFICATION_POPOVER/SET_NOTIFICATIONS')
const resetNotifications = createAction('MODULES/NOTIFICATION_POPOVER/RESET_NOTIFICATIONS')

const defaultState = []

const reducer = handleActions(
  {
    [setNotifications]: (state, action) => action.payload,
    [resetNotifications]: () => defaultState,
  },
  defaultState
)

export { setNotifications, resetNotifications, reducer }
