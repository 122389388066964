import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip, Progress } from 'antd'
import { get, find, reduce } from 'lodash'
import numeral from 'numeral'

const FulFillmentProgress = ({ purchaseOrder, formValues }) => {
  
  const purchaseOrderItems = get(formValues, 'purchase_order_items', [])

  const totalQuantity = reduce(purchaseOrderItems, (carry, purchaseOrderItem) => carry + (purchaseOrderItem.quantity || 0), 0)

  const totalPending = reduce(purchaseOrderItems, (carry, purchaseOrderItem) => {
    const originalPurchaseOrderItem = find(purchaseOrder.purchase_order_items, originalItem => originalItem.id === purchaseOrderItem.id)
    if (!originalPurchaseOrderItem) return carry + (purchaseOrderItem.quantity || 0)
    const { speculated, expected, completed } = originalPurchaseOrderItem
    const pending = purchaseOrderItem.quantity - (speculated + expected + completed)
    return carry + (pending > 0 ? pending : 0)
  }, 0)

  const totalSpeculated = purchaseOrder.total_speculated
  const totalExpected = purchaseOrder.total_expected
  const totalCompleted = purchaseOrder.total_completed

  const pendingPercentage = (totalPending / totalQuantity) * 100
  const fulfilledPercentage = ((totalSpeculated + totalExpected + totalCompleted) / totalQuantity) * 100
  const completePercentage = (totalCompleted / totalQuantity) * 100

  const formatTooltipLine = (value) => {
    if (totalQuantity === 0) return `[${numeral(value).format('0,0')}]`
    const percentage = (value / totalQuantity)*100
    return `${numeral(percentage).format('0,0.[00]')}% [${numeral(value).format('0,0')}]`
  }

  return (

    <Tooltip
      title={(
        <div>
          {totalCompleted > 0 && (<div>{`${formatTooltipLine(totalCompleted)} Received`}</div>)}
          {totalExpected > 0 && (<div>{`${formatTooltipLine(totalExpected)} Booked`}</div>)}
          {totalSpeculated > 0 && (<div>{`${formatTooltipLine(totalSpeculated)} Scheduled`}</div>)}
          {totalPending > 0 && (<div>{`${formatTooltipLine(totalPending)} Pending`}</div>)}
        </div>
      )}
    >
      <Progress
        size="small"
        percent={fulfilledPercentage}
        successPercent={completePercentage}
        strokeColor='#ffa500'
        style={{ width: 200 }}
        status={(() => {
          if (totalQuantity === 0) return 'exception'
          if (totalPending > 0) return 'exception'
          if (totalCompleted > totalQuantity) return 'exception'
          if ((totalCompleted + totalExpected + totalSpeculated) > totalQuantity) return 'exception'
          if (totalCompleted === totalQuantity) return 'success'
          if ((totalCompleted + totalExpected + totalSpeculated) === totalQuantity) return 'active'
          return null
        })()}
        format={() => {
          if (totalQuantity === 0) return 'Invalid Quantities'
          if (totalPending > 0) return `${numeral(pendingPercentage).format('0,0.[00]')}% Pending`
          if (totalCompleted > totalQuantity) return `${numeral(completePercentage - 100).format('0,0.[00]')}% Over Received`
          if ((totalCompleted + totalExpected + totalSpeculated) > totalQuantity) return `${numeral(fulfilledPercentage - 100).format('0,0.[00]')}% Over Fulfilled`
          if (totalCompleted === totalQuantity) return '100% Received'
          if ((totalCompleted + totalExpected + totalSpeculated) === totalQuantity) return '100% Fulfilled'
          return null
        }}
      />
    </Tooltip>

  )

}

FulFillmentProgress.propTypes = {
  purchaseOrder: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
}

export default FulFillmentProgress