import { connect } from 'react-redux'
import Footer from './Footer'

import onClickExport from './Handlers/onClickExport'
import onChangePagination from './Handlers/onChangePagination'

const mapStateToProps = (state) => ({
  pagination: state.screens.GoodsOutRecords.Table.pagination,
})

const actionCreators = {
  onClickExport,
  onChangePagination,
}

export default connect(mapStateToProps, actionCreators)(Footer)
