import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Form, Radio, Select, DatePicker } from 'antd'
import ReactHighcharts from 'react-highcharts'
import { Module } from 'Traqa/Packages'
import styles from './styles.css'

ReactHighcharts.Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
})

const Holding = props => {

  const {
    stockLocations,
    productCategories,
    movementControls,
    config,
    onChangeControl,
  } = props

  return (

    <Module.Section>

      <Row gutter={8}>

        <Col span={20}>
          
          <ReactHighcharts isPureConfig config={config} />
        
        </Col>

        <Col span={4}>

          <h4>Controls</h4>

          <Form>

            <Form.Item>

              <Radio.Group
                value={movementControls.ownerType}
                onChange={event => onChangeControl('ownerType', event.target.value)}
                options={[
                  { label: 'Goods out', value: 'GoodsOutRecord' },
                  { label: 'Goods in', value: 'GoodsInRecord' },
                  { label: 'Assem. in', value: 'Assembly-Out' },
                  { label: 'Assem. out', value: 'Assembly-In' },
                ]}
              />

            </Form.Item>

            <Form.Item>

              <div className={styles.date}>

                <div>Start: </div>

                <DatePicker
                  value={movementControls.startDate}
                  onChange={value => onChangeControl('startDate', value)}
                  allowClear={false}
                  placeholder='Start date'
                  style={{ width: 150 }}
                />

              </div>

            </Form.Item>

            <Form.Item>

              <div className={styles.date}>

                <div>End: </div>

                <DatePicker
                  value={movementControls.endDate}
                  onChange={value => onChangeControl('endDate', value)}
                  allowClear={false}
                  placeholder='End date'
                  style={{ width: 150 }}
                />

              </div>

            </Form.Item>

            <Form.Item>

              <Select
                value={movementControls.stockLocationId}
                onChange={value => onChangeControl('stockLocationId', value)}
                optionFilterProp='children'
                allowClear
                showSearch
                placeholder='Stock location'
                style={{ width: '100%' }}
              >
                {stockLocations.map(stockLocation => (
                  <Select.Option
                    key={stockLocation.id}
                    value={stockLocation.id}
                  >
                    {stockLocation.name}
                  </Select.Option>
                ))}
              </Select>

            </Form.Item>

            <Form.Item>

              <Select
                value={movementControls.productCategoryId}
                onChange={value => onChangeControl('productCategoryId', value)}
                optionFilterProp='children'
                allowClear
                showSearch
                placeholder='Product category'
                style={{ width: '100%' }}
              >
                {productCategories.map(productCategory => (
                  <Select.Option
                    key={productCategory.id}
                    value={productCategory.id}
                  >
                    {productCategory.name}
                  </Select.Option>
                ))}
              </Select>

            </Form.Item>

            <Form.Item>

              <Radio.Group
                value={movementControls.divisionType}
                onChange={event => onChangeControl('divisionType', event.target.value)}
                options={[
                  { label: 'Outers', value: 'Outers' },
                  { label: 'Inners', value: 'Inners' },
                  { label: 'All', value: 'All' },
                ]}
              />

            </Form.Item>

          </Form>

        </Col>

      </Row>

    </Module.Section>

  )

}

Holding.propTypes = {
  stockLocations: PropTypes.array.isRequired,
  productCategories: PropTypes.array.isRequired,
  movementControls: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  onChangeControl: PropTypes.func.isRequired,
}

export default Holding
