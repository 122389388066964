import { createAction, handleActions } from 'redux-actions'

const toggleAdvanced = createAction('SCREENS/TRANSFERS/TRANSFERS_TABLE/TOGGLE_ADVANCED')
const showAdvanced = createAction('SCREENS/TRANSFERS/TRANSFERS_TABLE/SHOW_ADVANCED')
const hideAdvanced = createAction('SCREENS/TRANSFERS/TRANSFERS_TABLE/HIDE_ADVANCED')
const setValues = createAction('SCREENS/TRANSFERS/TRANSFERS_TABLE/SET_VALUES')
const resetValues = createAction('SCREENS/TRANSFERS/TRANSFERS_TABLE/RESET_VALUES')
const resetAdvanced = createAction('SCREENS/TRANSFERS/TRANSFERS_TABLE/RESET_ADVANCED')

const defaultState = {
  show: false,
  values: {
    keywords: '',
    status: null,
    dispatchedBetween: [],
    completedBetween: [],
    originIds: [],
    destinationIds: [],
  },
}

const reducer = handleActions(
  {
    [toggleAdvanced]: (state) => ({ ...state, show: !state.show }),
    [showAdvanced]: (state) => ({ ...state, show: true }),
    [hideAdvanced]: (state) => ({ ...state, show: false }),
    [setValues]: (state, action) => ({
      ...state,
      values: {
        keywords: action.payload.keywords || '',
        status: action.payload.status || null,
        dispatchedBetween: action.payload.dispatchedBetween || [],
        completedBetween: action.payload.completedBetween || [],
        originIds: action.payload.originIds || [],
        destinationIds: action.payload.destinationIds || [],
      }
    }),
    [resetValues]: (state) => ({ ...state, values: { ...defaultState.values } }),
    [resetAdvanced]: () => ({ ...defaultState }),
  },
  defaultState
)

export { toggleAdvanced, showAdvanced, hideAdvanced, setValues, resetValues, resetAdvanced, reducer }