import { closeProductValueModal } from '../State/productValueModal'
import fetch from '../Services/fetch'

export default (shouldReload = false) => (dispatch) => {
  
  dispatch(closeProductValueModal())

  if (shouldReload) {
    dispatch(fetch())
  }
  
}
