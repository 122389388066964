import screenProps from '../../../screenProps'
import { setFormValues } from '../State/formState'
import { getGoodsInRecord } from '../../../../../Api/goodsInRecords'
import fetch from './fetch'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { id } = screenProps().match.params

    let invoiceNo = null

    if (id) {
      const response = await getGoodsInRecord({ id })
      // eslint-disable-next-line prefer-destructuring
      invoiceNo = response.data.data.invoice_no
    }

    const values = {
      stockLocationId: null,
      search: '',
      supplierId: null,
      invoiceNo,
      columnCount: 4,
    }

    dispatch(setFormValues(id ? values : { ...values, ...getState().screens.PurchasePlanner.Report.formState.values }))

    await dispatch(fetch())

  } catch (error) {

    handleException(error)

  }
  
}
