import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import { NavLink } from 'react-router-dom'
import { get } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import { getTeamUrl } from 'Traqa/Helpers'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    data: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    customerFilterOptions: PropTypes.array.isRequired,
    courierFilterOptions: PropTypes.array.isRequired,
    courierServiceFilterOptions: PropTypes.array.isRequired,
    courierZoneFilterOptions: PropTypes.array.isRequired,
    stockLocationOptions: PropTypes.array.isRequired,
    filteredCustomerIds: PropTypes.array.isRequired,
    filteredCourierIds: PropTypes.array.isRequired,
    filteredCourierServiceIds: PropTypes.array.isRequired,
    filteredCourierZoneIds: PropTypes.array.isRequired,
    filteredStockLocationIds: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onRowClick: PropTypes.func.isRequired,
  }

  render() {

    const {
      data,
      isFetching,
      customerFilterOptions,
      courierFilterOptions,
      courierServiceFilterOptions,
      courierZoneFilterOptions,
      stockLocationOptions,
      filteredCustomerIds,
      filteredCourierIds,
      filteredCourierServiceIds,
      filteredCourierZoneIds,
      filteredStockLocationIds,
      onChange,
      onRowClick,
    } = this.props

    return (

      <Table
        dataSource={data}
        loading={isFetching}
        rowKey='id'
        pagination={false}
        onChange={onChange}
        scroll={{ x: 2645 }}
        className={styles.table}
        onRow={record => ({
          onClick: () => onRowClick(record),
        })}
      >

        <Table.Column
          key='id'
          title='#'
          width={75}
          fixed='left'
          sorter
          render={(text, record) => <a>{record.id}</a>}
        />

        <Table.Column
          key='date'
          title='Date'
          width={150}
          fixed='left'
          sorter
          render={(text, record) => moment(record.date).format('D MMM YY')}
        />

        <Table.Column
          key='goods_out_record_invoice_nos'
          title='Invoice No.'
          width={170}
          fixed='left'
          render={(text, record) => !!record.goods_out_records.length && record.goods_out_records.map(goodsOutRecord => (
            <NavLink key={goodsOutRecord.id} to={getTeamUrl(`goods_out_records/${goodsOutRecord.id}`)} onClick={(e) => e.stopPropagation()}>
              {goodsOutRecord.invoice_no}
            </NavLink>
          )).reduce((prev, curr) => [prev, ', ', curr])}
        />

        <Table.Column
          key='customer_id'
          title='Customer / Site'
          width={300}
          fixed='left'
          filters={customerFilterOptions}
          filteredValue={filteredCustomerIds}
          sorter
          render={(text, record) => {
            if (!record.customer_site) {
              return record.customer.name
            }
            if (record.customer.name === record.customer_site.name) {
              return record.customer.name
            }
            return `${record.customer.name} / ${record.customer_site.name}`
          }}
        />

        <Table.Column
          key='courier_id'
          title='Courier'
          width={150}
          filters={courierFilterOptions}
          filteredValue={filteredCourierIds}
          sorter
          render={(text, record) => get(record.courier, 'name', 'Collection')}
        />

        <Table.Column
          key='courier_service_id'
          title='Service'
          width={150}
          filters={courierServiceFilterOptions}
          filteredValue={filteredCourierServiceIds}
          sorter
          render={(text, record) => get(record.courier_service, 'name')}
        />

        <Table.Column
          key='reference'
          title='Consignment Ref.'
          width={200}
          sorter
          className={styles.bold}
          render={(text, record) => record.reference}
        />

        <Table.Column
          key='booking_ref'
          title='Booking Ref.'
          width={200}
          sorter
          render={(text, record) => record.booking_ref}
        />

        <Table.Column
          key='courier_zone_id'
          title='Zone'
          width={100}
          filters={courierZoneFilterOptions}
          filteredValue={filteredCourierZoneIds}
          sorter
          render={(text, record) => get(record.courier_zone, 'code')}
        />

        <Table.Column
          key='stock_location_id'
          title='Location'
          width={150}
          filters={stockLocationOptions}
          filteredValue={filteredStockLocationIds}
          sorter
          render={(text, record) => record.stock_location.name}
        />

        <Table.Column
          key='goods_out_record_ids'
          title='GOR No.'
          width={150}
          render={(text, record) => !!record.goods_out_records.length && record.goods_out_records.map(goodsOutRecord => (
            <NavLink key={goodsOutRecord.id} to={getTeamUrl(`goods_out_records/${goodsOutRecord.id}`)} onClick={(e) => e.stopPropagation()}>
              {goodsOutRecord.id}
            </NavLink>
          )).reduce((prev, curr) => [prev, ', ', curr])}
        />

        <Table.Column
          key='pallet_full_qty'
          title='Pallets'
          width={140}
          sorter
          className={styles.numericColumn}
          render={(text, record) => numeral(record.pallet_full_qty).format('0,0')}
        />

        <Table.Column
          key='pallet_half_qty'
          title='1/2 Pallets'
          width={140}
          sorter
          className={styles.numericColumn}
          render={(text, record) => numeral(record.pallet_half_qty).format('0,0')}
        />

        <Table.Column
          key='pallet_quarter_qty'
          title='1/4 Pallets'
          width={140}
          sorter
          className={styles.numericColumn}
          render={(text, record) => numeral(record.pallet_quarter_qty).format('0,0')}
        />

        <Table.Column
          key='pallet_micro_qty'
          title='Micro Pallets'
          width={140}
          sorter
          className={styles.numericColumn}
          render={(text, record) => numeral(record.pallet_micro_qty).format('0,0')}
        />

        <Table.Column
          key='parcel_qty'
          title='Parcels'
          width={140}
          sorter
          className={styles.numericColumn}
          render={(text, record) => numeral(record.parcel_qty).format('0,0')}
        />

        <Table.Column
          key='total_kgs'
          title='Weight'
          width={140}
          sorter
          className={styles.numericColumn}
          render={(text, record) => `${numeral(record.total_kgs).format('0,0.[00]')}kg`}
        />

        <Table.Column
          key='total_cost'
          title='Cost'
          width={200}
          sorter
          className={styles.numericColumn}
          render={(text, record) => record.total_cost ? `£${numeral(record.total_cost).format('0,0.00')}` : null}
        />

      </Table>

    )

  }

}
