import { openTransactionsDrawer } from '../../../../../State/transactionsDrawer'

export default (record) => (dispatch) => {

  const payload = {}

  if (record.type === 'product') {
    payload.product = record
  }

  if (record.type === 'stock-location') {
    payload.product = record.product
    payload.stockLocation = record
  }

  dispatch(openTransactionsDrawer(payload))

}
