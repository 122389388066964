import { defer } from 'lodash'
import modalProps from '../../../modalProps'
import formService from '../formService'

export default () => async (dispatch, getState) => {

  const { customerId } = modalProps()

  if (customerId) {

    const { customer } = getState().modals.Customer

    const initialValues = {
      name: customer.name,
    }

    defer(() => formService().initialize(initialValues))

  } else {

    const initialValues = {}

    defer(() => formService().initialize(initialValues))

  }

}