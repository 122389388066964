import { configureStore } from 'Traqa/Packages'

import app from './reducer'
import core from '../Core/reducer'
import screens from '../Screens/reducer'
import modals from '../Modals/reducer'
import modules from '../Modules/reducer'

const reducers = {
  app,
  core,
  screens,
  modals,
  modules,
}

const persistConfig = {
  whitelist: [],
}

export const { store, persistor, history } = configureStore(reducers, persistConfig)