import chance from 'chance'
import moment from 'moment'
import { defer } from 'lodash'
import numeral from 'numeral'
import screenProps from '../../../screenProps'
import { getStocktake } from '../../../../../Api/stocktakes'
import { setInitialValues } from '../State/initialValues'
import { handleException } from 'Traqa/Packages'
import formService from '../formService'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    const {
      data: {
        data: stocktake
      }
    } = await getStocktake({
      id,
      params: {
        include: 'stocktake_items'
      }
    })

    const dateTime = moment(stocktake.date_time)

    const initialValues = {
      stock_location_id: stocktake.stock_location_id,
      date: dateTime,
      time: dateTime,
      user_id: stocktake.user_id,
      status: stocktake.status,
      stocktake_items: stocktake.stocktake_items.map(item => ({
        guid: chance().guid(),
        id: item.id,
        product_id: item.product_id,
        position: item.position,
        quantity: item.quantity,
        value: `£${numeral(item.value).format('0,0.00')}`,
      }))
    }

    dispatch(setInitialValues(initialValues))

    defer(() => formService().initialize(initialValues))


  } catch (error) {

    handleException(error)

  }
}