import React from 'react'
import PropTypes from 'prop-types'
import { Button, Pagination } from 'antd'
import { Module } from 'Traqa/Packages'

const Footer = (props) => {

  const {
    pagination,
    onClickExport,
    onChangePagination,
  } = props

  const renderLeft = () => (

    <Button
      type="default"
      icon="download"
      onClick={onClickExport}
    >
      Export Data
    </Button>

  )

  const renderRight = () => (

    <Pagination
      current={pagination.pageNumber}
      pageSize={pagination.pageSize}
      total={pagination.total}
      showSizeChanger
      showTotal={total => `Total: ${total} Assemblies`}
      pageSizeOptions={['10', '20', '30', '40', '50']}
      onChange={onChangePagination}
      onShowSizeChange={onChangePagination}
    />

  )
  
  return (

    <Module.Footer
      renderLeft={renderLeft}
      renderRight={renderRight}
    />

  )
}

Footer.propTypes = {
  pagination: PropTypes.object.isRequired,
  onClickExport: PropTypes.func.isRequired,
  onChangePagination: PropTypes.func.isRequired,
}

export default Footer