import { get } from 'lodash'
import screenProps from '../../../screenProps'
import { getTeamUrl } from 'Traqa/Helpers'

export default () => (dispatch, getState) => {

  const { id } = screenProps().match.params

  const { values } = getState().screens.Reconciliation.Report.formState

  const options = get(values, 'options', [])

  let path = `stocktakes/${id}/reconciliation/export?`

  path = `${path}opening_stocktake_id=${values.openingStocktakeId}`
  path = `${path}&sort_by_variance=${options.includes('sort_by_variance') ? 1 : 0}`
  path = `${path}&outers_only=${options.includes('outers_only') ? 1 : 0}`
  path = `${path}&aggregate=${options.includes('aggregate') ? 1 : 0}`

  window.location = getTeamUrl(path, 'exports')

}
