import { setSorting } from '../../../State/sorting'
import { firstPage } from '../../../State/pagination'
import fetch from '../../../Services/fetch'

export default (pagination, filters, sorting) => (dispatch) => {

  dispatch(setSorting({
    columnKey: sorting.column ? sorting.columnKey : undefined,
    descending: sorting.order === 'descend',
  }))

  dispatch(firstPage())
  
  dispatch(fetch())

}
