import { connect } from "react-redux"
import Component from "./Component"

import onToggleFilter from './Handlers/onToggleFilter'

const mapStateToProps = (state) => ({
  filter: state.modules.NotificationPopover.filter,
})

const actionCreators = {
  onToggleFilter,
}

export default connect(mapStateToProps, actionCreators)(Component)
