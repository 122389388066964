import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field, FormSpy } from 'react-final-form'
import { Form, Typography, Divider, Input, InputNumber } from 'antd'
import { ExposeFormToHelper } from 'Traqa/Packages'
import formService from './formService'
import moment from 'moment'
import modalProps from '../../modalProps'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  render() {

    const {
      onChangeFormState,
      onSubmit,
      validate,
    } = this.props

    const { goodsInRecord, product, goodsInRecordItem } = modalProps()

    return (

      <FormProvider
        onSubmit={onSubmit}
        initialValues={goodsInRecordItem}
        validate={validate}
      >

        {({ handleSubmit, submitting, values, form }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            autoComplete='off'
          >

            <ExposeFormToHelper helper={formService} />

            <FormSpy subscription={{ submitting: true }} onChange={onChangeFormState} />

            <Form.Item label='Reference'>
              <Typography.Text strong>
                {`${goodsInRecord.invoice_no}`}
              </Typography.Text>
            </Form.Item>

            <Form.Item label='Supplier'>
              <Typography.Text strong>
                {`${goodsInRecord.supplier.name}`}
              </Typography.Text>
            </Form.Item>

            <Form.Item label='Location'>
              <Typography.Text strong>
                {`${goodsInRecord.stock_location.name}`}
              </Typography.Text>
            </Form.Item>

            <Form.Item label='Date'>
              <Typography.Text strong>
                {`${moment(goodsInRecord.date_time).format('Do MMM YYYY')}`}
              </Typography.Text>
            </Form.Item>

            <Form.Item label='Product'>
              <Typography.Text strong>
                {`${product.code} - ${product.name} [${product.form}]`}
              </Typography.Text>
            </Form.Item>

            <Divider />

            <Field name='quantity_per_pallet'>
              {({ input, meta }) => (
                <Form.Item
                  label='Per Pallet'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <InputNumber
                    {...input}
                    onChange={value => {
                      if (value && values.pallet_quantity) {
                        form.change('quantity', value * values.pallet_quantity)
                      }
                      input.onChange(value)
                    }}
                    disabled={submitting}
                    style={{ width: 150 }}
                    min={0}
                    step={1}
                    precision={0}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='pallet_quantity'>
              {({ input, meta }) => (
                <Form.Item
                  label='Pallets'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <InputNumber
                    {...input}
                    onChange={value => {
                      if (value && values.quantity_per_pallet) {
                        form.change('quantity', value * values.quantity_per_pallet)
                      }
                      input.onChange(value)
                    }}
                    disabled={submitting}
                    style={{ width: 150 }}
                    min={0}
                    step={1}
                    precision={0}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='quantity'>
              {({ input, meta }) => (
                <Form.Item
                  label='Quantity'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <InputNumber
                    {...input}
                    disabled={submitting}
                    style={{ width: 150 }}
                    min={0}
                    step={1}
                    precision={0}
                  />
                </Form.Item>
              )}
            </Field>

            <Divider />

            <Field name='blend'>
              {({ input, meta }) => (
                <Form.Item
                  label='Blend'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Input
                    {...input}
                    disabled={submitting}
                    style={{ width: 150 }}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='recycled_perc'>
              {({ input, meta }) => (
                <Form.Item
                  label='Recycled (%)'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <InputNumber
                    {...input}
                    disabled={submitting}
                    style={{ width: 150 }}
                    min={0}
                    max={100}
                    step={1}
                    precision={2}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='weight_kgs'>
              {({ input, meta }) => (
                <Form.Item
                  label='Net (KG)'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <InputNumber
                    {...input}
                    disabled={submitting}
                    style={{ width: 150 }}
                    min={0}
                    step={1}
                    precision={2}
                  />
                </Form.Item>
              )}
            </Field>

          </Form>

        )}

      </FormProvider>

    )

  }

}
