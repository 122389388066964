import { push } from 'connected-react-router'
import { defer, omit } from 'lodash'
import moment from 'moment'
import chance from 'chance'
import { getTeamUrl } from 'Traqa/Helpers'
import { setInitialValues } from '../Components/Editor/State/initialValues'
import formService from '../Components/Editor/formService'

export default () => async (dispatch, getState) => {

  const { values } = getState().screens.GoodsOutRecord.Editor.formState

  const { user: { id: userId } } = getState().app

  dispatch(push(getTeamUrl(`goods_out_records/new`)))

  const initialValues = {
    ...omit(values, [
      'id',
      'consignment_id',
      'status',
      'invoice_no',
      'is_printed',
    ]),
    ...{
      date: moment(),
      time: moment(),
      user_id: userId,
      goods_out_record_items: values.goods_out_record_items.map(item => ({
        guid: chance().guid(),
        ...omit(item, ['guid', 'id', 'sales_order_id', 'source_product_id', 'batch_no', 'expiry_date', 'picked_by_user_id']),
      })),
    },
  }

  dispatch(setInitialValues(initialValues))

  defer(() => formService().initialize(initialValues))

}
