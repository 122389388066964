import { connect } from 'react-redux'
import { get } from 'lodash'
import Table from './Table'

import selectFilterOptions from './Selectors/selectFilterOptions'

import onChange from './Handlers/onChange'
import onRowClick from './Handlers/onRowClick'

const mapStateToProps = (state) => {
  const filterOptions = selectFilterOptions(state)
  return ({
    data: state.screens.Consignments.Table.data,
    isFetching: state.screens.Consignments.Table.isFetching,
    customerFilterOptions: filterOptions.customers,
    courierFilterOptions: filterOptions.couriers,
    courierServiceFilterOptions: filterOptions.courierServices,
    courierZoneFilterOptions: filterOptions.courierZones,
    stockLocationOptions: filterOptions.stockLocations,
    filteredCustomerIds: get(state.screens.Consignments.Table.filters, 'customer_id', []),
    filteredCourierIds: get(state.screens.Consignments.Table.filters, 'courier_id', []),
    filteredCourierServiceIds: get(state.screens.Consignments.Table.filters, 'courier_service_id', []),
    filteredCourierZoneIds: get(state.screens.Consignments.Table.filters, 'courier_zone_id', []),
    filteredStockLocationIds: get(state.screens.Consignments.Table.filters, 'stock_location_id', []),
  })
}

const actionCreators = {
  onChange,
  onRowClick,
}

export default connect(mapStateToProps, actionCreators)(Table)
