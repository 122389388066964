import { getTransfers } from '../../../../../Api/transfers'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { setPagination } from '../State/pagination'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { advanced, activeTab, searchTerm, sorting, pagination, hideInSage } = getState().screens.Transfers.Table

    const params = {}

    params.include = [
      'origin',
      'destination',
      'user',
    ].join()

    if (advanced.show) {

      const {
        keywords,
        status,
        dispatchedBetween,
        completedBetween,
        originIds,
        destinationIds,
      } = advanced.values

      if (keywords) params.search = keywords

      if (status) params['filter[status]'] = status

      if (dispatchedBetween.length > 0) params['filter[dispatched_at_between]'] = dispatchedBetween.map(moment => moment.format('YYYY-MM-DD HH:mm:ss')).join(',')

      if (completedBetween.length > 0) params['filter[completed_at_between]'] = completedBetween.map(moment => moment.format('YYYY-MM-DD HH:mm:ss')).join(',')

      if (originIds.length > 0) params['filter[origin_id]'] = originIds.join(',')

      if (destinationIds.length > 0) params['filter[destination_id]'] = destinationIds.join(',')

    } else {

      if (activeTab !== 'All') params['filter[status]'] = activeTab
      
      if (searchTerm) params.search = searchTerm

      if (hideInSage) params['filter[in_sage]'] = false

    }

    params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : '-completed_at'
    
    params['page[number]'] = pagination.pageNumber
    params['page[size]'] = pagination.pageSize

    dispatch(startFetching())

    const response = await getTransfers({ params })

    dispatch(setData(response.data.data))
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
    }))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }
}