import { connect } from 'react-redux'
import Footer from './Footer'

import onChangePagination from './Handlers/onChangePagination'

const mapStateToProps = (state) => ({
  pagination: state.screens.Transactions.Adjustments.pagination,
})

const actionCreators = {
  onChangePagination,
}

export default connect(mapStateToProps, actionCreators)(Footer)
