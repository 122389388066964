import { message } from 'antd'
import screenProps from '../../../screenProps'
import { updateStocktake } from '../../../../../Api/stocktakes'
import initialize from './initialize'
import fetch from '../../../Services/fetch'
//import fetchActivity from '../../../../../Modules/Activity/Services/fetch'
import { handleException } from 'Traqa/Packages'

export default (values) => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    const data = {
      stock_location_id: values.stock_location_id,
      date_time: `${values.date.format('YYYY-MM-DD')} ${values.time.format('HH:mm')}:00`,
      user_id: values.user_id,
      status: values.status,
      stocktake_items: values.stocktake_items.map(item => ({
        id: item.id,
        product_id: item.product_id,
        position: item.position || null,
        quantity: item.quantity || 0,
      })),
    }

    await updateStocktake({ id, data })

    await dispatch(fetch())

    await dispatch(initialize())

    //dispatch(fetchActivity())

    message.success("Saved")

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}