import { connect } from "react-redux"

import Component from "./Component"

import componentDidMount from "./Handlers/componentDidMount"
import componentDidUpdate from "./Handlers/componentDidUpdate"
import componentWillUnmount from "./Handlers/componentWillUnmount"
import onBack from "./Handlers/onBack"
import onCloseTransactionsDrawer from "./Handlers/onCloseTransactionsDrawer"

const mapStateToProps = (state) => ({
  initialized: state.screens.Reconciliation.initialized,
  transactionsDrawer: state.screens.Reconciliation.transactionsDrawer,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onBack,
  onCloseTransactionsDrawer,
}

export default connect(mapStateToProps, actionCreators)(Component)
