import { getCouriers } from '../../../../../Api/couriers'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { setPagination } from '../State/pagination'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  const { activeTab, searchTerm, sorting, pagination } = getState().screens.Couriers.Table

  const params = {}

  if (activeTab === 'Active') params['filter[without_archived]'] = 1

  if (activeTab === 'Archived') params['filter[only_archived]'] = 1

  if (searchTerm) params.search = searchTerm

  params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : 'name'

  params['page[number]'] = pagination.pageNumber
  params['page[size]'] = pagination.pageSize

  dispatch(startFetching())

  try {

    const response = await getCouriers({ params })

    dispatch(setData(response.data.data))
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
    }))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }
  
}
