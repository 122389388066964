import { defer } from 'lodash'
import formService from '../formService'
import { resetSelectedItems } from '../State/selectedItems'
import addItem from './addItem'

export default () => (dispatch, getState) => {

  const { formState, selectedItems } = getState().screens.Transfer.Editor

  const indexesToRemove = formState.values.transfer_items
    .map(({ guid }, index) => ({ guid, index }))
    .filter(({ guid }) => selectedItems.includes(guid))
    .map(({ index }) => index)

  indexesToRemove.reverse().forEach(index => {
    formService().mutators.remove('transfer_items', index)
  })

  dispatch(resetSelectedItems())

  const newLength = getState().screens.Transfer.Editor.formState.values.transfer_items.length

  defer(() => {
    if (newLength === 0) dispatch(addItem())
  }) 
}