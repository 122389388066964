import { getDashboardHoldingValueByProduct } from '../../../../../Api/dashboard'
import { setHoldingValue } from '../State/holdingValue'
import { startFetchingHoldingValue, finishFetchingHoldingValue } from '../State/isFetchingHoldingValue'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  const { holdingControls } = getState().screens.Dashboard.ColumnCharts

  const params = {
    stock_location_id: holdingControls.stockLocationId,
    product_category_id: holdingControls.productCategoryId,
    zero_run_rate: holdingControls.zeroRunRate || undefined,
  }

  dispatch(startFetchingHoldingValue())

  try {

    const response = await getDashboardHoldingValueByProduct({ params })

    dispatch(setHoldingValue(response.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetchingHoldingValue())

  }

}
