import { openGoodsInRecordItemModal } from '../../../../../State/goodsInRecordItemModal'

export default (goodsInRecord, product, goodsInRecordItem) => (dispatch) => {
  
  dispatch(openGoodsInRecordItemModal({
    goodsInRecord,
    product,
    goodsInRecordItem,
  }))

}
