import React from 'react'
import PropTypes from 'prop-types'
import { Input, Select, Row, Col, Button, Icon } from 'antd'
import { Module } from 'Traqa/Packages'

const Controls = (props) => {

  const {
    productCategories,
    stockLocations,
    controls,
    filterApplied,
    onChangeControls,
    onClickAllFilters,
    onSubmit,
  } = props

  return (

    <Module.Section
      borderBottom
      contentStyle={{ padding: 15 }}
    >

      <Row gutter={8}>

        <Col span={4}>

          <Input.Search
            value={controls.search}
            onChange={e => onChangeControls('search', e.target.value)}
            placeholder='Search Product or Category'
            onSearch={onSubmit}
            style={{ width: '100%' }}
          />

        </Col>

        <Col span={4}>

          <Select
            value={controls.productCategoryId || undefined}
            onChange={value => onChangeControls('productCategoryId', value)}
            allowClear
            showSearch
            placeholder='All Categories'
            optionFilterProp='children'
            style={{ width: '100%' }}
          >
            {productCategories.map(productCategory => (
              <Select.Option key={productCategory.id} value={productCategory.id}>
                {productCategory.name}
              </Select.Option>
            ))}
          </Select>

        </Col>

        <Col span={4}>

          <Select
            value={controls.stockLocationId || undefined}
            onChange={value => onChangeControls('stockLocationId', value)}
            allowClear
            showSearch
            placeholder='All Locations'
            optionFilterProp='children'
            style={{ width: '100%' }}
          >
            {stockLocations.map(stockLocation => (
              <Select.Option key={stockLocation.id} value={stockLocation.id}>
                {stockLocation.name}
              </Select.Option>
            ))}
          </Select>

        </Col>

        <Col span={1}>

          <Button
            type={filterApplied ? 'primary' : 'default'}
            onClick={onClickAllFilters}
            style={{ width: '100%' }}
          >
            <Icon type='filter' />
          </Button>

        </Col>

        <Col span={1} offset={4}>

          <Button
            onClick={onSubmit}
            style={{ width: '100%' }}
          >
            <Icon type='reload' />
          </Button>

        </Col>

        <Col span={3}>

          <Select
            value={controls.periodType}
            onChange={value => onChangeControls('periodType', value)}
            style={{ width: '100%' }}
          >
            <Select.OptGroup label='Period Type'>
              <Select.Option value='day'>
                Days
              </Select.Option>
              <Select.Option value='week'>
                Weeks
              </Select.Option>
              <Select.Option value='month'>
                Months
              </Select.Option>
              <Select.Option value='year'>
                Years
              </Select.Option>
            </Select.OptGroup>
          </Select>

        </Col>

        <Col span={3}>

          <Button.Group style={{ width: '100%' }}>
            <Button
              onClick={() => onChangeControls('periodOffset', controls.periodOffset - 1)}
              style={{ width: '33%' }}
            >
              <Icon type='left' />
            </Button>
            <Button
              onClick={() => onChangeControls('periodOffset', -5)}
              style={{ width: '33%' }}
            >
              <Icon type='minus' />
            </Button>
            <Button
              onClick={() => onChangeControls('periodOffset', controls.periodOffset + 1)}
              style={{ width: '33%' }}
            >
              <Icon type='right' />
            </Button>
          </Button.Group>

        </Col>

      </Row>

    </Module.Section>

  )

}

Controls.propTypes = {
  productCategories: PropTypes.array.isRequired,
  stockLocations: PropTypes.array.isRequired,
  controls: PropTypes.object.isRequired,
  filterApplied: PropTypes.bool.isRequired,
  onChangeControls: PropTypes.func.isRequired,
  onClickAllFilters: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default Controls
