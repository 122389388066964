import React from 'react'
import PropTypes from 'prop-types'
import { Table, Typography, Tag } from 'antd'
import { NavLink } from 'react-router-dom'
import { get } from 'lodash'
import moment from 'moment'
import classNames from 'classnames'
import { getTeamUrl } from 'Traqa/Helpers'
import styles from './styles.css'

const formatDate = date => moment(date).calendar(null, {
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  lastDay: '[Yesterday]',
  nextWeek: 'D MMM YYYY',
  lastWeek: 'D MMM YYYY',
  sameElse: 'D MMM YYYY',
})

const formatDateTime = dateTime => moment(dateTime).calendar(null, {
  sameDay: '[Today], h:mm a',
  nextDay: '[Tomorrow], h:mm a',
  lastDay: '[Yesterday], h:mm a',
  nextWeek: 'D MMM YYYY [at] h:mm a',
  lastWeek: 'D MMM YYYY [at] h:mm a',
  sameElse: 'D MMM YYYY [at] h:mm a',
})

export default class extends React.PureComponent {

  static propTypes = {
    data: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    withProductColumn: PropTypes.bool.isRequired,
  }

  render() {

    const {
      data,
      isFetching,
      withProductColumn,
    } = this.props
    
    return (

      <Table
        dataSource={data}
        loading={isFetching}
        rowKey='id'
        pagination={false}
        scroll={{ y: 160 }}
      >

        <Table.Column
          key='owner_type'
          title='Type'
          width={150}
          onHeaderCell={() => ({
            className: styles.headerCell,
          })}
          onCell={() => ({
            className: styles.cell,
          })}
          render={(text, record) => (
            <NavLink to={getTeamUrl(`${record.owner_type_slug}/${record.owner_id}`)}>
              {record.owner_type_formatted}
            </NavLink>
          )}
        />

        {withProductColumn && (

          <Table.Column
            key='product'
            title='Product'
            width={350}
            onHeaderCell={() => ({
              className: styles.headerCell,
            })}
            onCell={() => ({
              className: styles.cell,
            })}
            render={(text, record) => (
              <div>
                {`${record.product.code} - ${record.product.name}`}
                <Tag color='volcano' style={{ marginLeft: 5 }}>{record.product.form}</Tag>
              </div>
            )}
          />

        )}

        <Table.Column
          key='stock_location.name'
          title='Location'
          width={180}
          onHeaderCell={() => ({
            className: styles.headerCell,
          })}
          onCell={() => ({
            className: styles.cell,
          })}
          render={(text, record) => get(record.stock_location, 'name')}
        />

        <Table.Column
          key='quantity'
          title='Qty'
          width={100}
          onHeaderCell={() => ({
            className: classNames(styles.headerCell, styles.numericColumn),
          })}
          onCell={() => ({
            className: classNames(styles.cell, styles.numericColumn),
          })}
          render={(text, record) => record.quantity_formatted}
        />

        <Table.Column
          key='details'
          title='Details'
          width={250}
          onHeaderCell={() => ({
            className: styles.headerCell,
          })}
          onCell={() => ({
            className: styles.cell,
          })}
          render={(text, record) => <Typography.Text type='secondary'>{record.details}</Typography.Text>}
        />

        <Table.Column
          key='batch_no'
          title='Batch No.'
          width={200}
          onHeaderCell={() => ({
            className: styles.headerCell,
          })}
          onCell={() => ({
            className: styles.cell,
          })}
          render={(text, record) => <Typography.Text type='secondary'>{record.batch_no}</Typography.Text>}
        />

        <Table.Column
          key='transaction_at'
          title='Date / Time'
          width={250}
          onHeaderCell={() => ({
            className: styles.headerCell,
          })}
          onCell={() => ({
            className: styles.cell,
          })}
          render={(text, record) => {
            if (record.owner_type === 'App\\Models\\SalesOrder') {
              return formatDate(record.transaction_at)
            }
            return formatDateTime(record.transaction_at)
          }}
        />

      </Table>

    )

  }
  
}
