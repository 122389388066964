import { createAction, handleActions } from 'redux-actions'

const setStatus = createAction('SCREENS/PRODUCT_EDITOR/FORM/ET_STATUS')
const cleatStatus = createAction('SCREENS/PRODUCT_EDITOR/FORM/CLEAR_STATUS')

const defaultState = {}

const reducer = handleActions(
  {
    [setStatus]: (state, action) => action.payload,
    [cleatStatus]: () => {},
  },
  defaultState
)

export { setStatus, cleatStatus, reducer }