import fetch from './fetch'
import { initialized } from '../State/initialized'
import initializeEditor from '../Components/Editor/Services/initialize'
import fetchStockLocations from '../../../Core/Services/fetchStockLocations'
import fetchCustomers from '../../../Core/Services/fetchCustomers'
import fetchCustomerSites from '../../../Core/Services/fetchCustomerSites'
import fetchUsers from '../../../Core/Services/fetchUsers'
import fetchProducts from '../../../Core/Services/fetchProducts'
import fetchSalesOrders from '../../../Core/Services/fetchSalesOrders'

export default () => async (dispatch) => {

  await Promise.all([
    dispatch(fetch()),
    dispatch(fetchStockLocations()),
    dispatch(fetchCustomers()),
    dispatch(fetchCustomerSites()),
    dispatch(fetchUsers()),
    dispatch(fetchProducts()),
    dispatch(fetchSalesOrders()),
  ])

  await dispatch(initializeEditor())

  dispatch(initialized())

}
