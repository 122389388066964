import React from 'react'
import PropTypes from 'prop-types'
import { Input, Radio, Select } from 'antd'
import { Module, LinkButton } from 'Traqa/Packages'
import styles from './Header.css'

const Header = (props) => {

  const {
    activeTab,
    searchTerm,
    hideInSage,
    onChangeTab,
    onChangeSearch,
    onSearch,
    onClickAdvanced,
    onChangeHideInSage,
  } = props

  const renderLeft = () => (

    <React.Fragment>

      <Radio.Group onChange={onChangeTab} value={activeTab}>
        <Radio.Button value="All" className={styles.tab}>All</Radio.Button>
        <Radio.Button value="Draft" className={styles.tab}>Draft</Radio.Button>
        <Radio.Button value="Expected" className={styles.tab}>Scheduled</Radio.Button>
        <Radio.Button value="Completed" className={styles.tab}>Completed</Radio.Button>
      </Radio.Group>

      <Select
        value={hideInSage ? 1 : 0}
        onChange={value => onChangeHideInSage(value)}
        optionFilterProp='children'
        style={{ width: '200px', marginLeft: '20px' }}
      >
        <Select.Option key={1} value={1}>
          Hide In Sage
        </Select.Option>
        <Select.Option key={2} value={0}>
          Include In Sage
        </Select.Option>
      </Select>

    </React.Fragment>

  )

  const renderRight = () => (

    <React.Fragment>

      <LinkButton
        type='primary'
        onClick={onClickAdvanced}
      >
        Advanced
      </LinkButton>

      <div style={{ width: 16 }} />

      <Input.Search
        value={searchTerm}
        onChange={onChangeSearch}
        onSearch={onSearch}
        placeholder="Search"
        style={{ width: 200 }}
      />
    
    </React.Fragment>

  )
  
  return (

    <Module.Header
      renderLeft={renderLeft}
      renderRight={renderRight}
    />

  )
}

Header.propTypes = {
  activeTab: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  hideInSage: PropTypes.bool.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onClickAdvanced: PropTypes.func.isRequired,
  onChangeHideInSage: PropTypes.func.isRequired,
}

export default Header