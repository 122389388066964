import { getTeamUrl } from 'Traqa/Helpers'

export default () => async (dispatch, getState) => {

  const { activeTab, advanced, searchTerm, sorting } = getState().screens.SalesOrders.Table

  let path = `sales_orders/export?`

  if (advanced.show) {

    const {
      type,
      keywords,
      customerIds,
      customerSiteIds,
      dateRange,
    } = advanced.values

    if (type) {
      path = `${path}&type=${type}`
    }

    if (keywords) {
      path = `${path}&search=${keywords}`
    }

    if (customerIds) {
      path = `${path}&filter[customer_id]=${customerIds.join(',')}`
    }

    if (customerSiteIds) {
      path = `${path}&filter[customer_site_id]=${customerSiteIds.join(',')}`
    }

    if (dateRange) {
      path = `${path}&filter[date_between]=${dateRange.map(moment => moment.format('YYYY-MM-DD')).join(',')}`
    }

  } else {

    if (activeTab !== 'All') {
      path = `${path}&filter[type]=${activeTab}`
    }

    if (searchTerm) {
      path = `${path}&search=${searchTerm}`
    }

  }

  path = `${path}&sort=${sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : '-date'}`

  window.location = getTeamUrl(path, 'exports')

}
