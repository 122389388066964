import { closeControlsModal } from '../State/controlsModal'
import fetch from '../Components/Report/Services/fetch'

export default (reload) => (dispatch) => {

  dispatch(closeControlsModal())

  if (reload) {
    dispatch(fetch())
  }

}
