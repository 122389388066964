import { closeGoodsInRecordCommentsModal } from '../State/goodsInRecordCommentsModal'
import fetch from '../Components/Report/Services/fetch'

export default () => (dispatch) => {
  
  dispatch(closeGoodsInRecordCommentsModal())

  dispatch(fetch())
  
}
