import { connect } from "react-redux"
import Header from "./Header"

import onChangeStatus from './Handlers/onChangeStatus'

const mapStateToProps = (state) => ({
  assembly: state.screens.Assembly.assembly,
})

const actionCreators = {
  onChangeStatus,
}

export default connect(mapStateToProps, actionCreators)(Header)
