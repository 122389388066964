import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'

import Form from './Components/Form'

export default class GoodsInRecordItem extends React.PureComponent {

  static propTypes = {
    goodsInRecordItem: PropTypes.object.isRequired,
    submitting: PropTypes.bool.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {

    const {
      goodsInRecordItem,
      submitting,
      onSubmit,
      onDelete,
      onClose,
    } = this.props

    const footer = [
      <Button key='cancel' disabled={submitting} onClick={() => onClose()}>
        Cancel
      </Button>,
      <Button key='save' type='primary' loading={submitting} onClick={onSubmit}>
        Save
      </Button>,
    ]

    if (goodsInRecordItem.id) {
      footer.push(
        <Button key='delete' style={{ float: 'left' }} type='danger' loading={submitting} onClick={onDelete}>
          Remove
        </Button>
      )
    }

    return (
        
      <Modal
        visible
        title='Planned Purchase Item'
        width={650}
        okText='Save'
        footer={footer}
        onCancel={() => onClose()}
      >
        
        <Form />

      </Modal>
      
    )

  }

}
