import { createAction, handleActions } from 'redux-actions'

const openLinkConsignmentModal = createAction('SCREENS/CONSIGNMENT/OPEN_LINK_CONSIGNMENT_MODAL')
const closeLinkConsignmentModal = createAction('SCREENS/CONSIGNMENT/CLOSE_LINK_CONSIGNMENT_MODAL')

const defaultState = {
  show: false,
  goodsOutRecord: null,
}

const reducer = handleActions(
  {
    [openLinkConsignmentModal]: (state, action) => Object.assign({}, state, {
      show: true,
      goodsOutRecord: action.payload.goodsOutRecord,
    }),
    [closeLinkConsignmentModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openLinkConsignmentModal, closeLinkConsignmentModal, reducer }
