import fetchStockLocations from '../../../Core/Services/fetchStockLocations'
import fetchUsers from '../../../Core/Services/fetchUsers'
import fetchStocktakesTableData from '../Components/StocktakesTable/Services/fetch'

export default () => (dispatch) => {
  
  dispatch(fetchStockLocations())
  dispatch(fetchUsers())

  dispatch(fetchStocktakesTableData())
  
}