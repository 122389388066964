import { connect } from 'react-redux'
import ProductDivisions from './ProductDivisions'

import onClickRow from './Handlers/onClickRow'

const mapStateToProps = (state) => ({
  product: state.screens.Product.product,
})

const actionCreators = {
  onClickRow,
}

export default connect(mapStateToProps, actionCreators)(ProductDivisions)
