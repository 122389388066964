import React from 'react'
import PropTypes from 'prop-types'
import { Screen } from 'Traqa/Packages'
import AppBar from '../../Components/AppBar'
import { getTeamUrl } from 'Traqa/Helpers'

export default class Home extends React.PureComponent {

  static propTypes = {
    redirect: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props)
    this.props.redirect(getTeamUrl(`dashboard`))
  }

  render = () => (
    
    <Screen AppBar={AppBar} />

  )
}