import { combineReducers } from 'redux'

import PieCharts from './Components/PieCharts/reducer'
import GoodsInRecords from './Components/GoodsInRecords/reducer'
import ColumnCharts from './Components/ColumnCharts/reducer'

export default combineReducers({
  PieCharts,
  GoodsInRecords,
  ColumnCharts,
})
