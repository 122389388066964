import { createSelector } from 'reselect'

const selectSelectedItems = state => state.screens.GoodsOutRecord.Editor.selectedItems
const selectUuid = (state, props) => props.guid

const selectIsSelected = createSelector(
  selectSelectedItems,
  selectUuid,
  (selectedItems, guid) => selectedItems.includes(guid),
)

export default selectIsSelected
