import { createAction, handleActions } from 'redux-actions'

const setSubmitting = createAction('MODULES/STOCKSHOTS_POPOVER/SET_SUBMITTING')
const resetSubmitting = createAction('MODULES/STOCKSHOTS_POPOVER/RESET_SUBMITTING')

const defaultState = false

const reducer = handleActions(
  {
    [setSubmitting]: (_, { payload }) => payload,
    [resetSubmitting]: () => defaultState,
  },
  defaultState
)

export { setSubmitting, resetSubmitting, reducer }
