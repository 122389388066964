import React from 'react'
import PropTypes from 'prop-types'
import { List, Avatar, Tag } from 'antd'
import pluralize from 'pluralize'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    product: PropTypes.object,
    onClickRow: PropTypes.func.isRequired,
  }

  render() {

    const {
      product,
      onClickRow,
    } = this.props

    if (!product) return null

    const rows = []

    product.division_of_products.forEach(divisionOfProduct => {
      rows.push({
        key: divisionOfProduct.id,
        title: 'Goes Into...',
        product: divisionOfProduct,
        description: `A ${divisionOfProduct.form} divides into ${divisionOfProduct.pivot.quantity} ${pluralize(product.form)}`,
      })
    })

    product.divides_into_products.forEach(dividesIntoProduct => {
      rows.push({
        key: dividesIntoProduct.id,
        title: 'Divides Into...',
        product: dividesIntoProduct,
        description: `A ${product.form} divides into ${dividesIntoProduct.pivot.quantity} ${pluralize(dividesIntoProduct.form)}`,
      })
    })

    return (

      <div>

        {rows.map((row, index) => (

          <div key={row.key} onClick={() => onClickRow(row.product.id)} style={{ cursor: 'pointer' }}>

            {index > 0 && <div style={{ height: 10 }} />}

            <List.Item className={styles.listItem}>
              <List.Item.Meta
                avatar={<Avatar size='large' icon='dropbox' />}
                title={(
                  <div>
                    <div>{row.title}</div>
                    <div className={styles.listItemHeader}>
                      {`${row.product.code} - ${row.product.name}`}
                      <Tag color='volcano' style={{ marginLeft: 5 }}>{row.product.form}</Tag>
                    </div>
                  </div>
                )}
                description={row.description}
              />
            </List.Item>

          </div>

        ))}

      </div>
      
    )

  }

}
