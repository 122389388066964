import React from 'react'
import PropTypes from 'prop-types'
import { Button, Tooltip } from 'antd'
import classNames from 'classnames'
import { LinkButton } from 'Traqa/Packages'
import { isAdmin } from 'Traqa/Helpers'
import styles from './styles.css'

const Header = (props) => {

  const {
    user,
    activeTab,
    onBack,
    onEdit,
    onResetPassword,
    onChangeTab,
  } = props
  
  if (!user) return null
  
  return (

    <div className={styles.wrapper}>

      <div className={styles.main}>
        
        
        <div className={styles.section1}>
            
          <LinkButton onClick={onBack}>
            ← All Users
          </LinkButton>
          
          <div className={styles.title}>
            {user.name}
          </div>
          
        </div>
        
        
        <div className={styles.section2}>
          
          <div className={styles.detail}>
            <div className={styles.detailLabel}>First Name</div>
            <div className={styles.detailContent}>{user.first_name}</div>
          </div>
          
          <div className={styles.detail}>
            <div className={styles.detailLabel}>Last Name</div>
            <div className={styles.detailContent}>{user.last_name}</div>
          </div>
          
          <div className={styles.detail}>
            <div className={styles.detailLabel}>Email</div>
            <div className={styles.detailContent}>{user.email}</div>
          </div>
          
          <div className={styles.detail}>
            <div className={styles.detailLabel}>Username</div>
            <div className={styles.detailContent}>{user.username}</div>
          </div>
          
        </div>
        
        
        {isAdmin() && (

          <div className={styles.buttons}>

            <Tooltip title='Edit' placement='bottom'>
              <Button
                shape='circle'
                icon='edit'
                size='large'
                className={styles.button}
                onClick={onEdit}
              />
            </Tooltip>

            <Tooltip title='Reset Password' placement='bottom'>
              <Button
                shape='circle'
                icon='reload'
                size='large'
                className={styles.button}
                onClick={onResetPassword}
              />
            </Tooltip>

          </div>

        )}
        
        
      </div>
      
      <div className={styles.tabs}>
        
        {[
          'Permissions',
          'Activity',
        ].map((tab, tabIndex) => (
          <div
            key={tab}
            className={classNames(styles.tab, { [styles.tabActive]: activeTab === tabIndex })}
            onClick={() => onChangeTab(tabIndex)}
          >
            {tab}
          </div>
        ))}
        
      </div>
      
    </div>

  )

}

Header.propTypes = {
  user: PropTypes.object,
  activeTab: PropTypes.number.isRequired,
  onBack: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func.isRequired,
}

Header.defaultProps = {
  user: null,
}

export default React.memo(Header)
