import { createAction, handleActions } from 'redux-actions'

const setFormState = createAction('SCREENS/CONSIGNMENT/EDITOR/SET_FORM_STATE')
const resetFormState = createAction('SCREENS/CONSIGNMENT/EDITOR/RESET_FORM_STATE')

const defaultState = {
  values: {},
  pristine: true,
  submitting: false,
}

const reducer = handleActions(
  {
    [setFormState]: (state, action) => action.payload,
    [resetFormState]: () => defaultState,
  },
  defaultState,
)

export { setFormState, resetFormState, reducer }
