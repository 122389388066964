import { message } from 'antd'
import { addUserPermission, removeUserPermission } from '../../../Api/users'
import fetch from './fetch'
import { handleException } from 'Traqa/Packages'

export default (key, value) => async (dispatch, getState) => {

  const { user } = getState().screens.User

  try {

    if (value) {

      await addUserPermission({ id: user.id, key })

      message.success('Permission Enabled')

    } else {

      await removeUserPermission({ id: user.id, key })

      message.success('Permission Disabled')

    }

    dispatch(fetch())

  } catch (error) {

    handleException(error)

  }

}
