import { connect } from 'react-redux'
import Controls from './Controls'

import selectForms from './Selectors/selectForms'

import onChangeForm from './Handlers/onChangeForm'
import onChangeField from './Handlers/onChangeField'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'

const mapStateToProps = (state) => ({
  stockLocations: state.core.stockLocations,
  productCategories: state.core.productCategories,
  products: state.core.products,
  forms: selectForms(state),
  initialValues: state.screens.StockAssessment.Report.initialValues,
  formState: state.screens.StockAssessment.Report.formState,
})

const actionCreators = {
  onChangeForm,
  onChangeField,
  onSubmit,
  validate,
}

export default connect(mapStateToProps, actionCreators)(Controls)
