import screenProps from '../screenProps'
import { clearProduct } from '../State/product'
import { resetYearSummary } from '../State/yearSummary'
import { resetLiveAssessment } from '../State/liveAssessment'

export default () => (dispatch) => {
  
  screenProps()

  dispatch(clearProduct())
  dispatch(resetYearSummary())
  dispatch(resetLiveAssessment())

}