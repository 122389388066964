import { Modal } from 'antd'
import { getStocktakes } from '../../../../../Api/stocktakes'
import { setPreviousStocktakes } from '../State/previousStocktakes'
import { setFormValues } from '../State/formState'
import fetch from './fetch'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { stocktake } = getState().screens.Reconciliation

    const { data: { data: previousStocktakes } } = await getStocktakes({
      params: {
        'filter[stock_location_id]': stocktake.stock_location_id,
        'filter[date_time_before]': stocktake.date_time,
        sort: '-date_time',
      }
    })

    dispatch(setPreviousStocktakes(previousStocktakes))

    const values = {
      openingStocktakeId: previousStocktakes.length > 0 ? previousStocktakes[0].id : null,
      options: ['sort_by_variance', 'outers_only', 'aggregate'],
    }

    dispatch(setFormValues(values))

    if (previousStocktakes.length > 0) {

      await dispatch(fetch())
      
    } else {

      Modal.warn({
        title: 'First Stocktake',
        content: 'You cannot reconcile this stocktake as it\'s the first for this stock location.',
      })

    }

  } catch (error) {

    handleException(error)

  }
}