import { createAction, handleActions } from 'redux-actions'
import moment from 'moment'

const setInitialValues = createAction('SCREENS/STOCK_ASSESSMENT/REPORT/SET_INITIAL_VALUES')
const resetInitialValues = createAction('SCREENS/STOCK_ASSESSMENT/REPORT/RESET_INITIAL_VALUES')

const defaultState = {
  date: moment(),
  time: moment(),
  aggregate: true,
  unallocatedOnly: true,
  includeExpected: false,
  hideZeros: false,
  applyValues: false,
  sortBy: 'value',
  sortDirection: 'desc',

  product_search: '',
  product_ids: [],
  product_forms: [],
  product_categoryIds: [],
  transaction_batchNo: '',
  product_includeArchived: false,
  product_outersOnly: true,
  product_innersOnly: false,

  stockLocation_ids: [],
  stockLocation_includeArchived: false,
}

const reducer = handleActions(
  {
    [setInitialValues]: (state, action) => action.payload,
    [resetInitialValues]: () => defaultState,
  },
  defaultState,
)

export { setInitialValues, resetInitialValues, reducer }
