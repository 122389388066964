import { getTeamUrl } from 'Traqa/Helpers'

export default () => async (dispatch, getState) => {

  const { advanced, activeTab, searchTerm, sorting } = getState().screens.PurchaseOrders.Table

  let path = `purchase_orders/export?`

  if (advanced.show) {

    const {
      keywords,
      supplierIds,
      dateRange,
    } = advanced.values

    if (keywords) {
      path = `${path}&search=${keywords}`
    }

    if (supplierIds) {
      path = `${path}&filter[supplier_id]=${supplierIds.join(',')}`
    }

    if (dateRange) {
      path = `${path}&filter[date_between]=${dateRange.map(moment => moment.format('YYYY-MM-DD')).join(',')}`
    }

  } else {

    if (activeTab !== 'All') {
      path = `${path}&filter[fulfilment]=${activeTab}`
    }

    if (searchTerm) {
      path = `${path}&search=${searchTerm}`
    }

  }

  path = `${path}&sort=${sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : '-date'}`

  window.location = getTeamUrl(path, 'exports')

}
