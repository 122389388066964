import { combineReducers } from "redux"

import { reducer as notifications } from "./State/notifications"
import { reducer as hasUnread } from "./State/hasUnread"
import { reducer as filter } from "./State/filter"

export default combineReducers({
  notifications,
  hasUnread,
  filter,
})
