import { createAction, handleActions } from 'redux-actions'

const loadUsers = createAction("CORE/USERS/LOAD_USERS")

const defaultState = []

const reducer = handleActions(
  {
    [loadUsers]: (state, action) => action.payload,
  },
  defaultState
)

export { loadUsers, reducer }