import React from 'react'
import PropTypes from 'prop-types'
import { Table as AntTable, Tag } from 'antd'
import { NavLink } from 'react-router-dom'
import { getTeamUrl } from 'Traqa/Helpers'

const Table = (props) => {

  const {
    data,
    isFetching,
    onChange,
  } = props
  
  return (

    <AntTable
      dataSource={data}
      loading={isFetching}
      rowKey="id"
      pagination={false}
      onChange={onChange}
    >

      <AntTable.Column
        key='name'
        title="Name"
        width={300}
        sorter
        render={(text, record) => (
          <NavLink key={record.id} to={getTeamUrl(`users/${record.id}`)}>
            {record.name}
          </NavLink>
        )}
      />

      <AntTable.Column
        key='is_admin'
        width={80}
        render={(text, record) => record.is_admin ? (
          <Tag color="blue" style={{ marginLeft: 8 }}>
            Admin
          </Tag>
        ) : null}
      />

      <AntTable.Column
        key='email'
        title="Email"
        sorter
        render={(text, record) => record.email}
      />

      <AntTable.Column
        key='deactivated_at'
        width={150}
        render={(text, record) => record.deactivated_at ? (
          <div style={{ color: '#999' }}>
            Deactivated
          </div>
        ) : null}
      />

    </AntTable>

  )
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Table