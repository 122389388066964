import React from 'react'
import PropTypes from 'prop-types'
import { List, Card, Switch } from 'antd'
import { find } from 'lodash'
import { getCurrentTeam, isAdmin } from 'Traqa/Helpers'

import permissions from './Utils/permissions'

const Permissions = (props) => {

  const {
    user,
    onTogglePermission,
  } = props

  if (!user) return null

  const { pivot } = find(user.teams, team => team.id === getCurrentTeam().id)

  return (

    <div style={{ paddingTop: 20 }}>

      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 6,
          xxl: 3,
        }}
        dataSource={permissions}
        renderItem={permission => (
          <List.Item>
            <Card
              style={{ height: 150 }}
              title={(
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>{permission.name_formatted}</div>
                  <Switch
                    checked={!!pivot[permission.key]}
                    onChange={value => onTogglePermission(permission.key, value)}
                    disabled={!isAdmin()}
                  />
                </div>
              )}
            >
              {permission.description}
            </Card>
          </List.Item>
        )}
      />

    </div>

  )

}

Permissions.propTypes = {
  user: PropTypes.object,
  onTogglePermission: PropTypes.func.isRequired,
}

Permissions.defaultProps = {
  user: null,
}

export default React.memo(Permissions)
