import { createAction, handleActions } from 'redux-actions'

const openCourierModal = createAction('SCREENS/COURIERS/OPEN_COURIER_MODAL')
const closeCourierModal = createAction('SCREENS/COURIERS/CLOSE_COURIER_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openCourierModal]: (state) => Object.assign({}, state, {
      show: true,
    }),
    [closeCourierModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openCourierModal, closeCourierModal, reducer }
