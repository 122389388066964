import { resetInitialized } from '../State/initialized'
import { resetStocktake } from '../State/stocktake'
import resetReport from '../Components/Report/Services/reset'

export default () => async (dispatch) => {

  dispatch(resetInitialized())
  dispatch(resetStocktake())
  dispatch(resetReport())
  
}