import { combineReducers } from "redux"

import CreateStocktake from "./CreateStocktake/reducer"
import CreateAssembly from "./CreateAssembly/reducer"
import User from "./User/reducer"
import Customer from "./Customer/reducer"
import CustomerSite from "./CustomerSite/reducer"
import Supplier from "./Supplier/reducer"
import StockLocation from "./StockLocation/reducer"
import ProductCategory from "./ProductCategory/reducer"
import ManualRunRate from "./ManualRunRate/reducer"
import GoodsInRecord from "./GoodsInRecord/reducer"
import AddProductToGoodsInRecord from "./AddProductToGoodsInRecord/reducer"
import GoodsInRecordItem from "./GoodsInRecordItem/reducer"
import PurchaseOrderGoodsInRecord from "./PurchaseOrderGoodsInRecord/reducer"
import SalesOrderGoodsOutRecord from "./SalesOrderGoodsOutRecord/reducer"
import PlasticTaxRate from "./PlasticTaxRate/reducer"
import ProductValue from "./ProductValue/reducer"
import Courier from "./Courier/reducer"
import CourierService from "./CourierService/reducer"
import CourierZone from "./CourierZone/reducer"
import CourierRate from "./CourierRate/reducer"
import CourierCharge from "./CourierCharge/reducer"
import LinkConsignment from "./LinkConsignment/reducer"
import ImageViewer from "./ImageViewer/reducer"

export default combineReducers({
  CreateStocktake,
  CreateAssembly,
  User,
  Customer,
  CustomerSite,
  Supplier,
  StockLocation,
  ProductCategory,
  ManualRunRate,
  GoodsInRecord,
  AddProductToGoodsInRecord,
  GoodsInRecordItem,
  PurchaseOrderGoodsInRecord,
  SalesOrderGoodsOutRecord,
  PlasticTaxRate,
  ProductValue,
  Courier,
  CourierService,
  CourierZone,
  CourierRate,
  CourierCharge,
  LinkConsignment,
  ImageViewer,
})
