import React from "react"
import PropTypes from "prop-types"
import { Button } from 'antd'
import Screen from '../../Components/Screen'
import ProductCategoriesTable from "./Components/ProductCategoriesTable"

import ProductCategory from '../../Modals/ProductCategory'

export default class ProductCategoriesScreen extends React.PureComponent {

  static propTypes = {
    productCategoryModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onCloseProductCategoryModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  renderHeaderRight = () => (

    <Button type="primary" onClick={this.props.onClickNew}>
      New Category
    </Button>
    
  )

  render () {

    const {
      productCategoryModal,
      onCloseProductCategoryModal,
    } = this.props

    return (

      <Screen
        title="Product Categories"
        headerRight={this.renderHeaderRight()}
      >

        <ProductCategoriesTable />

        {productCategoryModal.show && (
          <ProductCategory
            onClose={onCloseProductCategoryModal}
            productCategoryId={productCategoryModal.productCategoryId}
          />
        )}

      </Screen>

    )
  }
}
