import modalProps from '../modalProps'
import resetList from '../Components/List/Services/reset'

export default () => (dispatch) => {

  modalProps.reset()

  dispatch(resetList())
  
}
