import screenProps from '../screenProps'
import reset from '../Services/reset'
import initialize from '../Services/initialize'

export default (props, prevProps) => async (dispatch) => {

  screenProps(props)

  if (props.match.params.id !== prevProps.match.params.id) {

    await dispatch(reset())

    await dispatch(initialize())

  }

}