import { connect } from 'react-redux'
import Header from './Header'

import onChangeTab from './Handlers/onChangeTab'
import onChangeSearch from './Handlers/onChangeSearch'
import onSearch from './Handlers/onSearch'

const mapStateToProps = (state) => ({
  activeTab: state.screens.Users.UsersTable.activeTab,
  searchTerm: state.screens.Users.UsersTable.searchTerm,
})

const actionCreators = {
  onChangeTab,
  onChangeSearch,
  onSearch,
}

export default connect(mapStateToProps, actionCreators)(Header)
