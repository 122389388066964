import chance from 'chance'
import moment from 'moment'
import { defer } from 'lodash'
import screenProps from '../../../screenProps'
import { getTransfer } from '../../../../../Api/transfers'
import { setInitialValues } from '../State/initialValues'
import { getUser } from 'Traqa/Helpers'
import { handleException } from 'Traqa/Packages'
import formService from '../formService'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    if (id === 'new') {

      const initialValues = {
        user_id: getUser().id,
        dispatched_date: null,
        dispatched_time: null,
        completed_date: null,
        completed_time: null,
        completed_at: null,
        transfer_items: [
          {
            guid: chance().guid(),
          }
        ]
      }

      dispatch(setInitialValues(initialValues))

      defer(() => formService().initialize(initialValues))

    } else {

      const {
        data: {
          data: transfer
        }
      } = await getTransfer({
        id,
        params: {
          include: 'transfer_items'
        }
      })

      const dispatchedAt = transfer.dispatched_at ? moment(transfer.dispatched_at) : null
      const completedAt = transfer.completed_at ? moment(transfer.completed_at) : null

      const initialValues = {
        reference: transfer.reference,
        origin_id: transfer.origin_id,
        destination_id: transfer.destination_id,
        user_id: transfer.user_id,
        dispatched_date: dispatchedAt,
        dispatched_time: dispatchedAt,
        completed_date: completedAt,
        completed_time: completedAt,
        transfer_items: transfer.transfer_items.map(item => ({
          guid: chance().guid(),
          id: item.id,
          product_id: item.product_id,
          batch_no: item.batch_no,
          quantity: item.quantity,
          source_product_id: item.source_product_id,
        }))
      }

      dispatch(setInitialValues(initialValues))

      defer(() => formService().initialize(initialValues))

    } 

  } catch (error) {

    handleException(error)

  }
}