import React from 'react'
import PropTypes from 'prop-types'
import { Radio, Input } from 'antd'
import { Module } from 'Traqa/Packages'
import styles from './styles.css'

const Header = (props) => {

  const {
    activeTab,
    searchTerm,
    onChangeTab,
    onChangeSearch,
    onSearch,
  } = props

  const renderLeft = () => (

    <Radio.Group onChange={onChangeTab} value={activeTab}>
      <Radio.Button value='Active' className={styles.tab}>Active</Radio.Button>
      <Radio.Button value='Archived' className={styles.tab}>Archived</Radio.Button>
    </Radio.Group>

  )

  const renderRight = () => (

    <React.Fragment>

      <Input.Search
        value={searchTerm}
        onChange={onChangeSearch}
        onSearch={onSearch}
        placeholder='Search'
        style={{ width: 200 }}
      />
    
    </React.Fragment>

  )
  
  return (

    <Module.Header
      renderLeft={renderLeft}
      renderRight={renderRight}
    />

  )
  
}

Header.propTypes = {
  activeTab: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
}

export default Header
