import React from "react"
import PropTypes from "prop-types"
import { Dropdown, Menu, Icon, Button } from "antd"

const MoreMenu = (props) => {

  const {
    onReconcile,
    onImport,
    onExport,
    onDelete,
  } = props

  const menu = (
    <Menu style={{ minWidth: 180 }}>

      <Menu.Item onClick={onReconcile}>
        <Icon type="sync" />
        Reconcile
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item onClick={onImport}>
        <Icon type="cloud-upload" />
        Import CSV
      </Menu.Item>

      <Menu.Item onClick={onExport}>
        <Icon type="cloud-download" />
        Export CSV
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item onClick={onDelete}>
        <Icon type="delete" />
        Delete Stocktake
      </Menu.Item>

    </Menu>
  )

  return (

    <Dropdown
      trigger={["click"]}
      placement="bottomRight"
      overlay={menu}
    >
      <Button icon="ellipsis" shape="circle" />
    </Dropdown>

  )
}

MoreMenu.propTypes = {
  onReconcile: PropTypes.func.isRequired,
  onImport: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default MoreMenu