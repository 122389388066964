import { createAction, handleActions } from 'redux-actions'

const setHoldingValue = createAction('SCREENS/DASHBOARD/PIE_CHARTS/SET_HOLDING_VALUE')
const resetHoldingValue = createAction('SCREENS/DASHBOARD/PIE_CHARTS/RESET_HOLDING_VALUE')

const defaultState = {
  stock_locations: [],
  total_value: 0,
}

const reducer = handleActions(
  {
    [setHoldingValue]: (state, action) => action.payload,
    [resetHoldingValue]: () => defaultState,
  },
  defaultState,
)

export { setHoldingValue, resetHoldingValue, reducer }
