import { setFilter } from '../../../State/controls'
import fetch from '../../../Services/fetch'

export default (key, value) => (dispatch) => {

  switch (key) {

    default:
      dispatch(setFilter({ key, value }))
      dispatch(fetch())
      break

  }

}
