import React from 'react'
import { Modal, Form, Input, Icon } from 'antd'
import convertIntoPurchaseOrder from '../../../Services/convertIntoPurchaseOrder'

export default (id) => async (dispatch) => {

  let purchaseOrderNumber = ''

  Modal.confirm({
    title: 'Convert Into Purchase Order?',
    content: (
      <Form.Item
        label='PO No.'
      >
        <Input onChange={event => {purchaseOrderNumber = event.target.value}} />
      </Form.Item>
    ),
    icon: <Icon type='file-protect' style={{ color: '#1890ff' }} />,
    okType: 'primary',
    okText: 'Convert',
    onOk: () => dispatch(convertIntoPurchaseOrder(id, purchaseOrderNumber)),
  })

}
