import React from 'react'
import PropTypes from 'prop-types'
import { Field, FormSpy } from 'react-final-form'
import { Form, Row, Col, Select, Input, DatePicker, TimePicker, Icon } from 'antd'
import { Module } from 'Traqa/Packages'

const Details = (props) => {

  const {
    stockLocations,
    users,
    isNew,
  } = props
  
  return (

    <Module.Section
      contentStyle={{ paddingBottom: 15 }}
    >

      <FormSpy subscription={{ submitting: true }}>

        {({ submitting }) => (

          <Row gutter={8}>

            <Col span={3}>

              <Field name='stock_location_id'>
                {({ input, meta }) => (
                  <Form.Item
                    label='Location'
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Select
                      {...input}
                      disabled={submitting}
                      optionFilterProp='children'
                      showSearch
                      allowClear
                    >
                      <Select.OptGroup label='Active'>
                        {stockLocations.filter(stockLocation => !stockLocation.archived_at).map(stockLocation => (
                          <Select.Option key={stockLocation.id} value={stockLocation.id}>
                            {stockLocation.name}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                      <Select.OptGroup label='Archived'>
                        {stockLocations.filter(stockLocation => !!stockLocation.archived_at).map(stockLocation => (
                          <Select.Option key={stockLocation.id} value={stockLocation.id} style={{ color: '#999' }}>
                            {stockLocation.name}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                    </Select>
                  </Form.Item>
                )}
              </Field>

            </Col>

            <Col span={7}>

              <Field name='description'>
                {({ input, meta }) => (
                  <Form.Item
                    label='Description'
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Input
                      {...input}
                      disabled={submitting}
                    />
                  </Form.Item>
                )}
              </Field>

            </Col>

            <Col span={3}>

              <Field name='date' allowNull>
                {({ input, meta }) => (
                  <Form.Item
                    label='Date'
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <DatePicker
                      {...input}
                      disabled={submitting}
                      format='DD/MM/YYYY'
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )}
              </Field>

            </Col>

            <Col span={3}>

              <Field name='time' allowNull>
                {({ input, meta }) => (
                  <Form.Item
                    label='Time'
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <TimePicker
                      {...input}
                      disabled={submitting}
                      format='HH:mm'
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )}
              </Field>

            </Col>

            <Col span={2}>

              <Field name='direction'>
                {({ input, meta }) => (
                  <Form.Item
                    label='Direction'
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Select
                      {...input}
                      disabled={submitting}
                      optionFilterProp='children'
                      showSearch
                      allowClear
                    >
                      <Select.Option key='Up' value='Up'>
                        <div>
                          <Icon
                            type='arrow-up'
                            style={{ color: '#32a852' }}
                          />
                          {' Up'}
                        </div>
                      </Select.Option>
                      <Select.Option key='Down' value='Down'>
                        <div>
                          <Icon
                            type='arrow-down'
                            style={{ color: '#d62424' }}
                          />
                          {' Down'}
                        </div>
                      </Select.Option>
                    </Select>
                  </Form.Item>
                )}
              </Field>

            </Col>

            {!isNew && (

              <React.Fragment>

                <Col span={3}>

                  <Field name="created_by_user_id">
                    {({ input, meta }) => (
                      <Form.Item
                        label="Created By"
                        validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                      >
                        <Select
                          {...input}
                          disabled
                          optionFilterProp="children"
                          showSearch
                          allowClear
                        >
                          {users.map(user => (
                            <Select.Option key={user.id} value={user.id}>
                              {user.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                  </Field>

                </Col>

                <Col span={3}>

                  <Field name="updated_by_user_id">
                    {({ input, meta }) => (
                      <Form.Item
                        label="Last Updated By"
                        validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                      >
                        <Select
                          {...input}
                          disabled
                          optionFilterProp="children"
                          showSearch
                          allowClear
                        >
                          {users.map(user => (
                            <Select.Option key={user.id} value={user.id}>
                              {user.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                  </Field>

                </Col>

              </React.Fragment>

            )}

          </Row>
      
        )}

      </FormSpy>
  
    </Module.Section>

  )
}

Details.propTypes = {
  stockLocations: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  isNew: PropTypes.bool.isRequired,
}

export default Details