import { getTeamUrl } from 'Traqa/Helpers'

export default () => async (dispatch, getState) => {

  const { advanced, activeTab, searchTerm, sorting } = getState().screens.Products.ProductsTable

  let path = `products/export?`

  if (advanced.show) {

    const {
      keywords,
      productCategoryIds,
      withArchived,
    } = advanced.values

    if (keywords) {
      path = `${path}&search=${keywords}`
    }

    if (productCategoryIds) {
      path = `${path}&filter[product_category_id]=${productCategoryIds.join(',')}`
    }

    if (!withArchived) {
      path = `${path}&filter[without_archived]=1`
    }

  } else {

    if (activeTab === 'Active') {
      path = `${path}&filter[without_archived]=1`
    }
  
    if (activeTab === 'Archived') {
      path = `${path}&filter[only_archived]=1`
    }

    if (searchTerm) {
      path = `${path}&search=${searchTerm}`
    }

  }

  path = `${path}&sort=${sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : 'code'}`

  window.location = getTeamUrl(path, 'exports')
  
}
