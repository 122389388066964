import modalProps from '../modalProps'
import fetchProducts from '../../../Core/Services/fetchProducts'

export default (props) => (dispatch) => {

  modalProps(props)

  dispatch(fetchProducts())
  
}
