import { connect } from 'react-redux'
import Form from './Form'

import modalProps from '../../modalProps'

import onChangeFormState from './Handlers/onChangeFormState'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'

const mapStateToProps = (state) => ({
  products: state.core.products,
  goodsInRecord: modalProps().goodsInRecord,
})

const actionCreators = {
  onChangeFormState,
  onSubmit,
  validate,
}

export default connect(mapStateToProps, actionCreators)(Form)
