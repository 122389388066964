import { resetInitialized } from '../State/initialized'
import { resetTransfer } from '../State/transfer'
import resetEditor from '../Components/Editor/Services/reset'

export default () => async (dispatch) => {

  dispatch(resetInitialized())
  dispatch(resetTransfer())
  dispatch(resetEditor())
  
}