import { connect } from 'react-redux'
import Updated from './Updated'

const mapStateToProps = () => ({
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Updated)
