import { connect } from 'react-redux'
import Component from './Component'

import onSubmit from './Handlers/onSubmit'

const mapStateToProps = () => ({})

const actionCreators = {
  onSubmit,
}

export default connect(mapStateToProps, actionCreators)(Component)