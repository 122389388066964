import { createSelector } from 'reselect'
import { get, find } from 'lodash'

const selectCouriers = state => state.core.couriers
const selectCourierId = (state) => get(state.screens.Consignment.Editor.formState, 'values.courier_id', null)

const selectCourierServices = createSelector(
  selectCouriers,
  selectCourierId,
  (couriers, courierId) => {
    
    const courier = find(couriers, { id: courierId })

    return courier ? courier.courier_services : []

  },
)

export default selectCourierServices
