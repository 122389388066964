import React from 'react'
import PropTypes from 'prop-types'
import { Module } from 'Traqa/Packages'

import Header from './Components/Header'
import Advanced from './Components/Advanced'
import Table from './Components/Table'
import Footer from './Components/Footer'

const StocktakesTable = (props) => {

  const {
    showAdvanced,
  } = props
    
  return (

    <Module>

      {!showAdvanced && <Header />}

      {showAdvanced && <Advanced />}

      <Table />

      <Footer />

    </Module>

  )
}

StocktakesTable.propTypes = {
  showAdvanced: PropTypes.bool.isRequired,
}

export default StocktakesTable