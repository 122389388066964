import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import { NavLink } from 'react-router-dom'
import { getTeamUrl } from 'Traqa/Helpers'

export default class extends React.PureComponent {

  static propTypes = {
    data: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  render() {

    const {
      data,
      isFetching,
      onChange,
    } = this.props

    return (

      <Table
        dataSource={data}
        loading={isFetching}
        rowKey='id'
        pagination={false}
        onChange={onChange}
      >

        <Table.Column
          key='name'
          title='Name.'
          sorter
          render={(text, record) => (
            <NavLink key={record.id} to={getTeamUrl(`couriers/${record.id}`)}>
              {record.name}
            </NavLink>
          )}
        />

      </Table>

    )

  }

}
