import chance from 'chance'
import getParams from '../Utils/getParams'
import { getPlasticTaxReport } from '../../../../../Api/reports'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { setInteractionId } from '../State/interaction'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  const params = getParams(getState())

  // Prevent slower API responses from overriding faster ones

  const guid = chance().guid()

  dispatch(setInteractionId(guid))

  params.interaction_id = guid

  dispatch(startFetching())

  try {

    const { data } = await getPlasticTaxReport({ params })

    if (data.interaction_id === getState().screens.PlasticTax.Report.interaction.id) {

      dispatch(setData(data))

    }

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetching())

  }

}
