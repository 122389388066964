import { connect } from 'react-redux'
import PickingStats from './PickingStats'

const mapStateToProps = () => ({
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(PickingStats)
