import { createAction, handleActions } from 'redux-actions'

const openControlsModal = createAction('SCREENS/DEMAND/OPEN_CONTROLS_MODAL')
const closeControlsModal = createAction('SCREENS/DEMAND/CLOSE_CONTROLS_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openControlsModal]: (state) => Object.assign({}, state, {
      show: true,
    }),
    [closeControlsModal]: () => Object.assign({}, defaultState),
  },
  defaultState
)

export { openControlsModal, closeControlsModal, reducer }
