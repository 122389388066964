import { message } from 'antd'
import { archiveProduct } from '../../../Api/products'
import fetch from './fetch'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { product } = getState().screens.Product

    await archiveProduct({ id: product.id })

    await dispatch(fetch())

    message.success(`Archived Product "${product.code || product.name}"`)

  } catch (error) {

    handleException(error)

  }
}