import { connect } from 'react-redux'
import Item from './Item'

import selectIsNew from '../../../../Selectors/selectIsNew'
import selectIsSelected from './Selectors/selectIsSelected'
import selectSourceProducts from './Selectors/selectSourceProducts'

import onToggle from './Handlers/onToggle'

const mapStateToProps = (state, props) => ({
  isNew: selectIsNew(state, props),
  isSelected: selectIsSelected(state, props),
  users: state.core.users,
  products: state.core.products,
  sourceProducts: selectSourceProducts(state, props),
  salesOrders: state.core.salesOrders,
})

const actionCreators = {
  onToggle,
}

export default connect(mapStateToProps, actionCreators)(Item)
