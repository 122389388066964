import { createAction, handleActions } from 'redux-actions'

const openGoodsInRecordItemModal = createAction('SCREENS/PURCHASE_PLANNER/OPEN_GOODS_IN_RECORD_ITEM_MODAL')
const closeGoodsInRecordItemModal = createAction('SCREENS/PURCHASE_PLANNER/CLOSE_GOODS_IN_RECORD_ITEM_MODAL')

const defaultState = {
  show: false,
  goodsInRecord: null,
  product: null,
  goodsInRecordItem: null,
}

const reducer = handleActions(
  {
    [openGoodsInRecordItemModal]: (state, action) => Object.assign({}, state, {
      show: true,
      goodsInRecord: action.payload.goodsInRecord,
      product: action.payload.product,
      goodsInRecordItem: action.payload.goodsInRecordItem,
    }),
    [closeGoodsInRecordItemModal]: () => Object.assign({}, defaultState),
  },
  defaultState
)

export { openGoodsInRecordItemModal, closeGoodsInRecordItemModal, reducer }
