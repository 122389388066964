import React from 'react'
import PropTypes from 'prop-types'
import { Table, Button } from 'antd'
import { orderBy } from 'lodash'

export default class extends React.PureComponent {

  static propTypes = {
    data: PropTypes.array.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onClickRow: PropTypes.func.isRequired,
  }

  render() {

    const {
      data,
      onClickNew,
      onClickRow,
    } = this.props

    return (

      <div>

        <div style={{ height: '60px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button type='primary' onClick={onClickNew}>New Zone</Button>
        </div>

        <Table
          dataSource={orderBy(data, 'code')}
          rowKey='id'
          pagination={false}
          onRow={(record) => ({
            onClick: () => onClickRow(record),
            style: { cursor: 'pointer' },
          })}
        >

          <Table.Column
            key='code'
            title='Code'
            render={(text, record) => record.code}
          />

          <Table.Column
            key='courier_zone.code'
            title='Group'
            render={(text, record) => record.courier_zone ? record.courier_zone.code : null}
          />

        </Table>

      </div>

    )

  }

}
