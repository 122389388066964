import { createAction, handleActions } from 'redux-actions'

const setUser = createAction('SCREENS/USER/SET_USER')
const resetUser = createAction('SCREENS/USER/RESET_USER')

const defaultState = null

const reducer = handleActions(
  {
    [setUser]: (state, action) => action.payload,
    [resetUser]: () => defaultState,
  },
  defaultState,
)

export { setUser, resetUser, reducer }
