import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field, FormSpy } from 'react-final-form'
import { Form, DatePicker, InputNumber } from 'antd'
import { ExposeFormToHelper } from 'Traqa/Packages'
import formService from './formService'
import moment from 'moment'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    productValue: PropTypes.object,
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  static defaulProps = {
    productValue: null,
  }

  render() {

    const {
      productValue,
      onChangeFormState,
      onSubmit,
      validate,
    } = this.props

    const initialValues = {
      effective_date: (productValue && moment(productValue.effective_date)) || moment(),
      value: productValue && productValue.value,
    }

    return (

      <FormProvider
        validate={validate}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >

        {({ handleSubmit, submitting }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            autoComplete='off'
          >

            <ExposeFormToHelper helper={formService} />

            <FormSpy subscription={{ values: true, submitting: true }} onChange={onChangeFormState} />

            <Field name='effective_date' allowNull>
              {({ input, meta }) => (
                <Form.Item
                  label='Date'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <DatePicker
                    {...input}
                    disabled={submitting}
                    format='DD/MM/YYYY'
                    style={{ width: 150 }}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='value'>
              {({ input, meta }) => (
                <Form.Item
                  label='Value'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <InputNumber
                    {...input}
                    disabled={submitting}
                    style={{ width: 150 }}
                    min={0}
                    step={1}
                    precision={5}
                  />
                </Form.Item>
              )}
            </Field>

          </Form>

        )}

      </FormProvider>

    )

  }

}
