import { closeGoodsInRecordModal } from '../State/goodsInRecordModal'
import { push } from 'connected-react-router'
import { getTeamUrl } from 'Traqa/Helpers'

export default (goodsInRecord = undefined) => (dispatch) => {
  
  dispatch(closeGoodsInRecordModal())

  if (goodsInRecord) {
    dispatch(push(getTeamUrl(`goods_in_records/${goodsInRecord.id}`)))
  }
  
}
