import { setColumnOffset } from '../../../State/columnOffset'
import formService from '../formService'

export default () => (dispatch) => {

  dispatch(setColumnOffset(0))

  formService().submit()

}
