import { createSelector } from 'reselect'
import { get } from 'lodash'
import screenProps from '../screenProps'

const selectId = () => get(screenProps(), 'match.params.id', undefined)

const selectIsNew = createSelector(
  selectId,
  (id) => id === 'new'
)

export default selectIsNew