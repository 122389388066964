import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field, FormSpy } from 'react-final-form'
import { Form, DatePicker, InputNumber, Select } from 'antd'
import { ExposeFormToHelper } from 'Traqa/Packages'
import formService from './formService'

const Component = props => {

  const {
    productCategories,
    onChangeFormState,
    onSubmit,
    validate,
  } = props

  return (

    <FormProvider
      onSubmit={onSubmit}
      validate={validate}
    >

      {({ handleSubmit, submitting }) => (

        <Form
          onSubmit={handleSubmit}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 15 }}
          autoComplete='off'
        >

          <ExposeFormToHelper helper={formService} />

          <FormSpy subscription={{ submitting: true }} onChange={onChangeFormState} />

          <Field name='effective_from'>
            {({ input, meta }) => (
              <Form.Item
                label='Effective From'
                validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
              >
                <DatePicker
                  {...input}
                  value={input.value || null}
                  format='DD/MM/YYYY'
                  disabled={submitting}
                  style={{ width: 200 }}
                />
              </Form.Item>
            )}
          </Field>

          <Field name='recycled_perc'>
            {({ input, meta }) => (
              <Form.Item
                label='Recycled (%)'
                validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
              >
                <InputNumber
                  {...input}
                  min={0}
                  max={100}
                  step={1}
                  precision={2}
                  disabled={submitting}
                  style={{ width: 200 }}
                />
              </Form.Item>
            )}
          </Field>

          <Field name='rate_per_tonne'>
            {({ input, meta }) => (
              <Form.Item
                label='Per Tonne (£)'
                validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
              >
                <InputNumber
                  {...input}
                  min={0}
                  step={1}
                  precision={2}
                  disabled={submitting}
                  style={{ width: 200 }}
                />
              </Form.Item>
            )}
          </Field>

          <Field name='exempt_product_category_ids'>
            {({ input, meta }) => (
              <Form.Item
                label='Exempt'
                validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
              >
                <Select
                  {...input}
                  value={input.value || []}
                  mode='multiple'
                  optionFilterProp='children'
                  disabled={submitting}
                  style={{ width: 200 }}
                >
                  {productCategories.map(productCategory => (
                    <Select.Option key={productCategory.id} value={productCategory.id}>
                      {productCategory.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Field>

        </Form>

      )}

    </FormProvider>

  )

}

Component.propTypes = {
  productCategories: PropTypes.array.isRequired,
}

export default Component
