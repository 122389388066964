import React from 'react'
import { Module } from 'Traqa/Packages'

import Header from './Components/Header'
import Table from './Components/Table'
import Footer from './Components/Footer'

const UsersTable = () => (

  <Module>

    <Header />

    <Table />

    <Footer />

  </Module>

)

export default UsersTable