export default (values) => () => {

  const errors = {}

  if (!values.courier_service_id) errors.courier_service_id = true

  if (!values.type) errors.type = true

  if (!values.quantity) errors.quantity = true

  if (!values.charge) errors.charge = true
  
  return errors

}
