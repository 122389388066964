import { connect } from 'react-redux'
import Holding from './Holding'

import selectValueConfig from './Selectors/selectValueConfig'
import selectWeeksConfig from './Selectors/selectWeeksConfig'

import onChangeControl from './Handlers/onChangeControl'

const mapStateToProps = (state) => ({
  stockLocations: state.core.stockLocations,
  productCategories: state.core.productCategories,
  holdingControls: state.screens.Dashboard.ColumnCharts.holdingControls,
  valueConfig: selectValueConfig(state),
  weeksConfig: selectWeeksConfig(state),
})

const actionCreators = {
  onChangeControl,
}

export default connect(mapStateToProps, actionCreators)(Holding)
