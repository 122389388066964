import { push } from 'connected-react-router'
import { message } from 'antd'
import screenProps from '../../../screenProps'
import { createConsignment, updateConsignment } from '../../../../../Api/consignments'
import initialize from './initialize'
import fetch from '../../../Services/fetch'
//import fetchActivity from '../../../../../Modules/Activity/Services/fetch'
import { getTeamUrl } from 'Traqa/Helpers'
import { handleException } from 'Traqa/Packages'

export default (values) => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    const data = {
      date: values.date.format('YYYY-MM-DD'),
      stock_location_id: values.stock_location_id,
      customer_id: values.customer_id,
      customer_site_id: values.courier_id !== 'null' ? values.customer_site_id : null,

      courier_id: values.courier_id !== 'null' ? values.courier_id : null,
      courier_service_id: values.courier_id !== 'null' ? (values.courier_service_id || null) : null,
      reference: values.courier_id !== 'null' ? values.reference : null,
      booking_ref: values.courier_id !== 'null' ? (values.booking_ref || null) : null,
      courier_zone_id: values.courier_id !== 'null' ? (values.courier_zone_id || null) : null,

      pallet_full_qty: values.pallet_full_qty || 0,
      pallet_half_qty: values.pallet_half_qty || 0,
      pallet_quarter_qty: values.pallet_quarter_qty || 0,
      pallet_micro_qty: values.pallet_micro_qty || 0,
      parcel_qty: values.parcel_qty || 0,
      total_kgs: values.total_kgs,
      total_cost: values.courier_id !== 'null' ? (values.total_cost || null) : null,

      goods_out_record_id: values.goods_out_record_id,
    }

    if (id === 'new') {

      const { data: { data: { id: newId } } } = await createConsignment({ data })

      dispatch(push(getTeamUrl(`consignments/${newId}`)))

      message.success('Saved')

    } else {

      await updateConsignment({ id, data })

      await dispatch(fetch())

      await dispatch(initialize())

      //dispatch(fetchActivity())

      message.success('Saved')

    }

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
