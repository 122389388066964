import screenProps from '../screenProps'
import { getGoodsOutRecord } from '../../../Api/goodsOutRecords'
import { setGoodsOutRecord } from '../State/goodsOutRecord'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    if (id === 'new') return

    const params = {
      include: 'consignment',
    }

    const response = await getGoodsOutRecord({ id, params })

    dispatch(setGoodsOutRecord(response.data.data))

  } catch (error) {

    handleException(error)

  }

}
