import { connect } from 'react-redux'
import Header from './Header'

import onChangeSearch from './Handlers/onChangeSearch'
import onSearch from './Handlers/onSearch'
import onClickAdvanced from './Handlers/onClickAdvanced'

const mapStateToProps = (state) => ({
  searchTerm: state.screens.Stocktakes.StocktakesTable.searchTerm,
})

const actionCreators = {
  onChangeSearch,
  onSearch,
  onClickAdvanced,
}

export default connect(mapStateToProps, actionCreators)(Header)
