import { combineReducers } from 'redux'

import { reducer as courier } from './State/courier'
import { reducer as initialized } from './State/initialized'
import { reducer as courierModal } from './State/courierModal'
import { reducer as courierServiceModal } from './State/courierServiceModal'
import { reducer as courierZoneModal } from './State/courierZoneModal'
import { reducer as courierRateModal } from './State/courierRateModal'
import { reducer as courierChargeModal } from './State/courierChargeModal'

export default combineReducers({
  courier,
  initialized,
  courierModal,
  courierServiceModal,
  courierZoneModal,
  courierRateModal,
  courierChargeModal,
})
