import screenProps from '../../../../../screenProps'
import { openGoodsOutRecordModal } from '../../../../../State/goodsOutRecordModal'

export default () => (dispatch, getState) => {

  const { id } = screenProps().match.params

  const { formState, selectedItems } = getState().screens.SalesOrder.Editor

  let salesOrderItems = formState.values.sales_order_items;

  if (selectedItems.length > 0) salesOrderItems = salesOrderItems.filter(item => selectedItems.includes(item.guid))
  
  dispatch(openGoodsOutRecordModal({
    salesOrderId: id,
    salesOrderItemIds: salesOrderItems.map(salesOrderItem => salesOrderItem.id),
  }))
  
}
