import React from 'react'
import PropTypes from 'prop-types'
import classNames from "classnames"
import numeral from "numeral"
import moment from "moment"
import { Icon, Table as AntTable, Typography, Tag } from 'antd'
import { NavLink } from 'react-router-dom'
import { getTeamUrl } from 'Traqa/Helpers'
import styles from "./Table.css";

const Table = (props) => {

  const {
    data,
    isFetching,
    onChange,
  } = props

  const formatDateTime = (dateTime) => (
    moment(dateTime).calendar(null, {
      sameDay: '[Today], h:mm a',
      nextDay: '[Tomorrow], h:mm a',
      lastDay: '[Yesterday], h:mm a',
      nextWeek: 'ddd D MMM YYYY [at] h:mm a',
      lastWeek: 'ddd D MMM YYYY [at] h:mm a',
      sameElse: 'ddd D MMM YYYY [at] h:mm a',
    })
  )
  
  return (

    <AntTable
      dataSource={data}
      loading={isFetching}
      rowKey="id"
      pagination={false}
      onChange={onChange}
    >

      <AntTable.Column
        key='id'
        title="No."
        width={90}
        sorter
        render={(text, record) => (
          <NavLink key={record.id} to={getTeamUrl(`assemblies/${record.id}`)}>
            {record.id}
          </NavLink>
        )}
      />

      <AntTable.Column
        key='stock_location.name'
        title="Stock Location"
        sorter
        render={(text, record) => record.stock_location.name}
      />

      <AntTable.Column
        key='product.code'
        title="Product"
        sorter
        render={(text, record) => (
          <div>
            {`${record.product.code} - ${record.product.name}`}
            <Tag color='volcano' style={{ marginLeft: 5 }}>{record.product.form}</Tag>
          </div>
        )}
      />

      <AntTable.Column
        key='quantity'
        title="Qty"
        sorter
        render={(text, record) => numeral(record.quantity).format('0,0')}
      />

      <AntTable.Column
        key='date_time'
        title='Date / Time'
        sorter
        render={(text, record) => (
          <span
            className={classNames({
              [styles.isOverdue]: record.is_overdue,
            })}
          >
            {record.is_overdue && <Icon type='exclamation-circle' style={{ marginRight: '5px' }} />}
            {formatDateTime(record.date_time)}
          </span>
        )}
      />

      <AntTable.Column
        key='user.name'
        title='User'
        sorter
        render={(text, record) => record.user.name}
      />

      <AntTable.Column
        key='status'
        title='Status'
        width={140}
        sorter
        render={(text, record) => (
          <div className={styles.status}>
            {record.status === 'Draft' && <Icon type='edit' className={classNames(styles.statusIcon, styles.draft)} />}
            {record.status === 'Draft' && 'Draft'}
            {record.status === 'Expected' && <Icon type='clock-circle' className={classNames(styles.statusIcon, styles.expected)} />}
            {record.status === 'Expected' && 'Scheduled'}
            {record.status === 'Completed' && <Icon type='check-circle' className={classNames(styles.statusIcon, styles.completed)} />}
            {record.status === 'Completed' && 'Assembled'}
          </div>
        )}
      />

      <AntTable.Column
        key='batch_no'
        title="Batch No."
        sorter
        render={(text, record) => <Typography.Text type="secondary">{record.batch_no}</Typography.Text>}
      />

    </AntTable>

  )
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Table