import { createAction, handleActions } from 'redux-actions'

const setSorting = createAction('SCREENS/PURCHASE_ORDER/GOODS_IN_RECORDS_TABLE/SET_SORTING')
const resetSorting = createAction('SCREENS/PURCHASE_ORDER/GOODS_IN_RECORDS_TABLE/RESET_SORTING')

const defaultState = {
  columnKey: null,
  descending: false,
}

const reducer = handleActions(
  {
    [setSorting]: (state, action) => ({ ...state, ...action.payload }),
    [resetSorting]: () => ({ ...defaultState }),
  },
  defaultState
)

export { setSorting, resetSorting, reducer }