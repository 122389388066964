import { message } from 'antd'
import { unarchiveProductCategory } from '../../../../../Api/productCategories'
import fetch from './fetch'
import fetchProductCategoriesCore from '../../../../../Core/Services/fetchProductCategories'
import { handleException } from 'Traqa/Packages'

export default (productCategory) => async (dispatch) => {

  try {

    await unarchiveProductCategory({ id: productCategory.id })

    await dispatch(fetch())

    message.success(`Unarchived Category "${productCategory.name}"`)
    
    dispatch(fetchProductCategoriesCore({ force: true }))

  } catch (error) {

    handleException(error)

  }
}