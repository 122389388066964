import { createSelector } from 'reselect'
import pluralize from 'pluralize'

const selectSortDirection = state => state.screens.Dashboard.ColumnCharts.holdingControls.sortDirection
const selectHoldingWeeks = state => state.screens.Dashboard.ColumnCharts.holdingWeeks
const selectIsFetchingHoldingWeeks = state => state.screens.Dashboard.ColumnCharts.isFetchingHoldingWeeks

export default createSelector(
  selectSortDirection,
  selectHoldingWeeks,
  selectIsFetchingHoldingWeeks,
  (sortDirection, holdingWeeks, isFetchingHoldingWeeks) => {
    
    const products = holdingWeeks.products[sortDirection]

    return {
      chart: {
        type: 'column',
        width: 1120,
        height: 350,
        events: {
          load() {
            // eslint-disable-next-line no-unused-expressions
            isFetchingHoldingWeeks ? this.showLoading() : this.hideLoading()
          },
        },
      },
      title: {
        text: 'Holding by Weeks',
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      xAxis: {
        categories: products.map(product => product.code),
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Weeks',
        },
        stackLabels: {
          enabled: true,
          format: `{total:,.0f}`,
        },
      },
      tooltip: {
        pointFormat: '<b>{point.weeks:,.0f} Weeks</b><br />{point.form}: <b>{point.quantity:,.0f}</b><br />Value: <b>£{point.value:,.0f}</b><br />Run Rate: <b>{point.run_rate:,.0f} pw</b>',
      },
      series: [{
        name: 'Weeks',
        data: products.map(product => ({
          y: product.weeks,
          form: pluralize(product.form),
          quantity: product.quantity,
          value: product.value,
          run_rate: product.run_rate,
          weeks: product.weeks,
        })),
      }],
      legend: {
        enabled: false,
      },
    }
    
  },
)
