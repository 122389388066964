import { connect } from 'react-redux'
import TransactionsDrawer from './TransactionsDrawer'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'

const mapStateToProps = () => ({
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
}

export default connect(mapStateToProps, actionCreators)(TransactionsDrawer)
