import drawerProps from '../drawerProps'
import fetch from '../Services/fetch'

export default (props) => (dispatch) => {

  drawerProps(props)

  dispatch(fetch())
  
}
