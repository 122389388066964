import { push } from 'connected-react-router'
import { message } from 'antd'
import screenProps from '../../../screenProps'
import { createSalesOrder, updateSalesOrder } from '../../../../../Api/salesOrders'
import initialize from './initialize'
import fetch from '../../../Services/fetch'
//import fetchActivity from '../../../../../Modules/Activity/Services/fetch'
import { getTeamUrl } from 'Traqa/Helpers'
import { handleException } from 'Traqa/Packages'

export default (values) => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    const data = {
      number: values.number,
      customer_id: values.customer_id,
      customer_site_id: values.customer_site_id,
      is_export: values.is_export,
      customer_po_no: values.customer_po_no || null,
      date: values.date.format('YYYY-MM-DD'),
      due_date: values.due_date.format('YYYY-MM-DD'),
      description: values.description || null,
      user_id: values.user_id,
      sales_order_items: values.sales_order_items.map(salesOrderItem => ({
        id: salesOrderItem.id,
        product_id: salesOrderItem.product_id,
        price: salesOrderItem.price,
        quantity: salesOrderItem.quantity,
      })),
    }

    if (id === 'new') {

      const { data: { data: { id: newId } } } = await createSalesOrder({ data })

      dispatch(push(getTeamUrl(`sales_orders/${newId}`)))

      message.success('Saved')

    } else {

      await updateSalesOrder({ id, data })

      await dispatch(fetch())

      await dispatch(initialize())

      //dispatch(fetchActivity())

      message.success('Saved')

    }

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
