import { createAction, handleActions } from 'redux-actions'

const setSearchTerm = createAction("SCREENS/STOCKTAKE/EDITOR/SET_SEARCH_TERM")
const resetSearchTerm = createAction("SCREENS/STOCKTAKE/EDITOR/RESET_SEARCH_TERM")

const defaultState = ''

const reducer = handleActions(
  {
    [setSearchTerm]: (state, action) => action.payload,
    [resetSearchTerm]: () => defaultState,
  },
  defaultState
)

export { setSearchTerm, resetSearchTerm, reducer }
