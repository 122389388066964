import { closeGoodsOutRecordModal } from '../State/goodsOutRecordModal'
import { push } from 'connected-react-router'
import { getTeamUrl } from 'Traqa/Helpers'

export default (goodsOutRecord = undefined) => (dispatch) => {
  
  dispatch(closeGoodsOutRecordModal())

  if (goodsOutRecord) {
    dispatch(push(getTeamUrl(`goods_out_records/${goodsOutRecord.id}`)))
  }
  
}
