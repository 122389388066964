import { connect } from 'react-redux'
import Item from './Item'

import selectIsNew from '../../../../Selectors/selectIsNew'
import selectIsSelected from './Selectors/selectIsSelected'

import onToggle from './Handlers/onToggle'

const mapStateToProps = (state, props) => ({
  isNew: selectIsNew(state, props),
  isSelected: selectIsSelected(state, props),
  products: state.core.products,
})

const actionCreators = {
  onToggle,
}

export default connect(mapStateToProps, actionCreators)(Item)
