import React from 'react'
import { Tag } from 'antd'
import { openTransactionsDrawer } from '../../../../../State/transactionsDrawer'

export default (product, group, period, currentType, demand, backOrder) => (dispatch, getState) => {

  const { controls } = getState().screens.Demand.Report

  const { stockLocationId, aggregate } = controls

  const { index, start_date_time: startDateTime, end_date_time: endDateTime } = period

  const title = (
    <div>
      {`${product.code} - ${product.name}`}
      <Tag color='volcano' style={{ marginLeft: 5 }}>{product.form}</Tag>
    </div>
  )

  const subtitle = period.display

  const productId = aggregate ? [
    product.id,
    ...product.divides_into_products.map(row => row.id),
    ...product.division_of_products.map(row => row.id),
  ].join(): product.id

  const params = {
    'filter[is_allocation]': 0,
    'filter[product_id]': productId,
  }

  if (backOrder) {
    params['filter[transaction_at_up_to_now]'] = 1
  } else {
    params['filter[transaction_at_between]'] = `${startDateTime},${endDateTime}`
  }

  if (!stockLocationId) params['filter[without_owner_type]'] = 'Transfer'
  if (stockLocationId) params['filter[stock_location_id]'] = stockLocationId

  if (index < 0) params['filter[is_expectation]'] = 0
  if (index === 0 && currentType === 'current-past') params['filter[is_expectation]'] = 0

  if (group === 'run_rate') {
    params['filter[is_reading]'] = 0
    params['filter[is_demand]'] = demand ? 1 : undefined
    params['filter[is_back_order]'] = backOrder ? 1 : undefined
    params['filter[direction]'] = 'out'
  } else if (group === 'incoming') {
    params['filter[is_reading]'] = 0
    params['filter[direction]'] = 'in'
  }

  if (aggregate) {
    params['filter[is_breakout]'] = 0
  }
  
  dispatch(openTransactionsDrawer({ title, subtitle, withProductColumn: aggregate, params }))

}
