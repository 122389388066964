import { connect } from 'react-redux'
import Header from './Header'

import onChangeMonthYear from './Handlers/onChangeMonthYear'
import onResetFilters from './Handlers/onResetFilters'
import onChangeSearch from './Handlers/onChangeSearch'
import onSearch from './Handlers/onSearch'

const mapStateToProps = (state) => ({
  monthYear: state.screens.Consignments.Table.monthYear,
  filters: state.screens.Consignments.Table.filters,
  searchTerm: state.screens.Consignments.Table.searchTerm,
})

const actionCreators = {
  onChangeMonthYear,
  onResetFilters,
  onChangeSearch,
  onSearch,
}

export default connect(mapStateToProps, actionCreators)(Header)
