import { getCustomers } from '../../../../../Api/customers'
import { getCourierZones } from '../../../../../Api/courierZones'
import { setFilterOptions } from '../State/filterOptions'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch) => {

  try {

    let response = await getCustomers({ params: { 'filter[has_consignments]': true, sort: 'name' } })

    dispatch(setFilterOptions({ customers: response.data.data }))

    response = await getCourierZones({ params: { 'filter[has_consignments]': true, sort: 'code' } })

    dispatch(setFilterOptions({ courierZones: response.data.data }))

  } catch (error) {

    handleException(error)

  }
  
}
