import { createAction, handleActions } from 'redux-actions'

const setFilterOptions = createAction('SCREENS/CONSIGNMENTS/TABLE/SET_FILTER_OPTIONS')
const resetFilterOptions = createAction('SCREENS/CONSIGNMENTS/TABLE/RESET_FILTER_OPTIONS')

const defaultState = {
  customers: [],
  courierZones: [],
}

const reducer = handleActions(
  {
    [setFilterOptions]: (state, action) => ({ ...state, ...action.payload }),
    [resetFilterOptions]: () => ({ ...defaultState }),
  },
  defaultState,
)

export { setFilterOptions, resetFilterOptions, reducer }
