/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts'
import { Card } from 'antd'
import numeral from 'numeral'
import styles from './Component.css'

export default class _ActivityChart_ extends React.PureComponent {

  static propTypes = {
    product: PropTypes.object,
    yearSummary: PropTypes.array.isRequired,
  }

  static defaultProps = {
    product: null,
  }

  render () {

    const {
      product,
      yearSummary,
    } = this.props

    if (!product) return null

    return (

      <Card
        title="Adjustments in / out over last 12 months"
      >

        <ResponsiveContainer height={200}>

          <BarChart data={yearSummary} margin={{ left: -15 }}>

            <XAxis dataKey="name" />

            <YAxis tickFormatter={value => value} />

            <Tooltip />

            <Bar dataKey="In" fill="#1990FF" />

            <Bar dataKey="Out" fill="#173856" />
            
          </BarChart>

        </ResponsiveContainer>

        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <tbody>

              <tr>
                <td>IN</td>
                {yearSummary.map((period, index) => (
                  <td key={`in-${index}`}>{numeral(period.In).format('0,0')}</td>
                ))}
              </tr>

              <tr>
                <td>OUT</td>
                {yearSummary.map((period, index) => (
                  <td key={`out-${index}`}>{numeral(period.Out).format('0,0')}</td>
                ))}
              </tr>

            </tbody>
          </table>
        </div>

      </Card>

    )
  }
}