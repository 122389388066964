import { createAction, handleActions } from 'redux-actions'

const setData = createAction('MODULES/STOCKSHOTS_POPOVER/SET_DATA')
const resetData = createAction('MODULES/STOCKSHOTS_POPOVER/RESET_DATA')

const defaultState = null

const reducer = handleActions(
  {
    [setData]: (_, { payload }) => payload,
    [resetData]: () => defaultState,
  },
  defaultState
)

export { setData, resetData, reducer }
