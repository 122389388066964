import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip, Progress } from 'antd'
import numeral from 'numeral'

const FulFillmentProgress = ({ salesOrder }) => {

  const totalQuantity = salesOrder.total_quantity
  const totalPending = salesOrder.total_pending
  const totalExpected = salesOrder.total_expected
  const totalPicked = salesOrder.total_picked
  const totalCompleted = salesOrder.total_completed

  const pendingPercentage = (totalPending / totalQuantity) * 100
  const fulfilledPercentage = ((totalExpected + totalPicked + totalCompleted) / totalQuantity) * 100
  const completePercentage = (totalCompleted / totalQuantity) * 100

  const formatTooltipLine = (value) => {
    if (totalQuantity === 0) return `[${numeral(value).format('0,0')}]`
    const percentage = (value / totalQuantity)*100
    return `${numeral(percentage).format('0,0.[00]')}% [${numeral(value).format('0,0')}]`
  }

  return (

    <Tooltip
      title={(
        <div>
          {totalCompleted > 0 && (<div>{`${formatTooltipLine(totalCompleted)} Dispatched`}</div>)}
          {totalExpected > 0 && (<div>{`${formatTooltipLine(totalExpected)} Scheduled`}</div>)}
          {totalPicked > 0 && (<div>{`${formatTooltipLine(totalPicked)} Picked`}</div>)}
          {totalPending > 0 && (<div>{`${formatTooltipLine(totalPending)} Pending`}</div>)}
        </div>
      )}
    >
      <Progress
        size="small"
        percent={fulfilledPercentage}
        successPercent={completePercentage}
        strokeColor='#ffa500'
        style={{ width: 200 }}
        status={(() => {
          if (totalQuantity === 0) return 'exception'
          if (totalPending > 0) return 'exception'
          if (totalCompleted > totalQuantity) return 'exception'
          if ((totalCompleted + totalExpected + totalPicked) > totalQuantity) return 'exception'
          if (totalCompleted === totalQuantity) return 'success'
          if ((totalCompleted + totalExpected + totalPicked) === totalQuantity) return 'active'
          return null
        })()}
        format={() => {
          if (totalQuantity === 0) return 'Invalid Quantities'
          if (totalPending > 0) return `${numeral(pendingPercentage).format('0,0.[00]')}% Pending`
          if (totalCompleted > totalQuantity) return `${numeral(completePercentage - 100).format('0,0.[00]')}% Over Dispatched`
          if ((totalCompleted + totalExpected + totalPicked) > totalQuantity) return `${numeral(fulfilledPercentage - 100).format('0,0.[00]')}% Over Fulfilled`
          if (totalCompleted === totalQuantity) return '100% Dispatched'
          if ((totalCompleted + totalExpected + totalPicked) === totalQuantity) return '100% Fulfilled'
          return null
        }}
      />
    </Tooltip>

  )

}

FulFillmentProgress.propTypes = {
  salesOrder: PropTypes.object.isRequired,
}

export default FulFillmentProgress
