import { combineReducers } from "redux"

import { reducer as stockLocationModal } from "./State/stockLocationModal"

import StockLocationsTable from './Components/StockLocationsTable/reducer'

export default combineReducers({
  stockLocationModal,
  StockLocationsTable,
})
