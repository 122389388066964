import React from 'react'
import { FieldArray } from 'react-final-form-arrays'
import { Module } from 'Traqa/Packages'

import Item from '../Item'

const ItemsArray = () => (

  <Module.Section
    title="Items"
    contentStyle={{ paddingTop: 15 }}
    borderTop
  >

    <FieldArray name="assembly_items">

      {({ fields }) => (
        
        <div>

          {fields.map((field, index) => (

            <Item
              key={fields.value[index].guid}
              field={field}
              index={index}
              guid={fields.value[index].guid}
              productId={fields.value[index].product_id}
            />

          ))}
          
        </div>

      )}

    </FieldArray>

  </Module.Section>

)

export default ItemsArray