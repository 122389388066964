import moment from 'moment'
import { getProductCategories } from '../../Api/productCategories'
import { loadProductCategories } from '../State/productCategories'

let lastGetTimestamp = null

const cacheTTL = 60000

export default ({ force } = {}) => async (dispatch) => {

  try {

    if (!force && lastGetTimestamp) {

      const now = Number(moment().format('x'))

      if (now < (lastGetTimestamp + cacheTTL)) return
    }
      
    lastGetTimestamp = Number(moment().format('x'))

    const params = {
      sort: 'name'
    }

    const response = await getProductCategories({ params })

    dispatch(loadProductCategories(response.data.data))

  } catch (error) {
    
    throw error

  }
}