import { connect } from 'react-redux'
import Movement from './Movement'

import selectConfig from './Selectors/selectConfig'

import onChangeControl from './Handlers/onChangeControl'

const mapStateToProps = (state) => ({
  stockLocations: state.core.stockLocations,
  productCategories: state.core.productCategories,
  movementControls: state.screens.Dashboard.ColumnCharts.movementControls,
  config: selectConfig(state),
})

const actionCreators = {
  onChangeControl,
}

export default connect(mapStateToProps, actionCreators)(Movement)
