import { connect } from 'react-redux'
import PurchasePlanner from './PurchasePlanner'

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onClickNew from './Handlers/onClickNew'
import onCloseGoodsInRecordModal from './Handlers/onCloseGoodsInRecordModal'
import onCloseAddProductToGoodsInRecordModal from './Handlers/onCloseAddProductToGoodsInRecordModal'
import onCloseGoodsInRecordItemModal from './Handlers/onCloseGoodsInRecordItemModal'
import onCloseGoodsInRecordCommentsModal from './Handlers/onCloseGoodsInRecordCommentsModal'

const mapStateToProps = (state) => ({
  initialized: state.screens.PurchasePlanner.initialized,
  goodsInRecordModal: state.screens.PurchasePlanner.goodsInRecordModal,
  addProductToGoodsInRecordModal: state.screens.PurchasePlanner.addProductToGoodsInRecordModal,
  goodsInRecordItemModal: state.screens.PurchasePlanner.goodsInRecordItemModal,
  goodsInRecordCommentsModal: state.screens.PurchasePlanner.goodsInRecordCommentsModal,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onClickNew,
  onCloseGoodsInRecordModal,
  onCloseAddProductToGoodsInRecordModal,
  onCloseGoodsInRecordItemModal,
  onCloseGoodsInRecordCommentsModal,
}

export default connect(mapStateToProps, actionCreators)(PurchasePlanner)
