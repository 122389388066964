import { getPurchaseOrders } from '../../../../../Api/purchaseOrders'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { setPagination } from '../State/pagination'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { advanced, activeTab, searchTerm, sorting, pagination } = getState().screens.PurchaseOrders.Table

    const params = {}

    params.include = [
      'supplier',
      'user',
    ].join()

    if (advanced.show) {

      const {
        keywords,
        supplierIds,
        dateRange,
      } = advanced.values

      if (keywords) params.search = keywords

      if (dateRange) params['filter[date_between]'] = dateRange.map(moment => moment.format('YYYY-MM-DD')).join(',')

      if (supplierIds) params['filter[supplier_id]'] = supplierIds.join(',')

    } else {

      if (activeTab !== 'All') params['filter[fulfilment]'] = activeTab
      
      if (searchTerm) params.search = searchTerm

    }

    params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : '-date'
    
    params['page[number]'] = pagination.pageNumber
    params['page[size]'] = pagination.pageSize

    dispatch(startFetching())

    const response = await getPurchaseOrders({ params })

    dispatch(setData(response.data.data))
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
    }))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }
}