import React from 'react'
import PropTypes from 'prop-types'
import arrayMutators from 'final-form-arrays'
import { Form as FormProvider, FormSpy } from 'react-final-form'
import { Form } from 'antd'
import { Module } from 'Traqa/Packages'
import formService from './formService'
import styles from './styles.css'

import PrimaryDetails from './Components/PrimaryDetails'
import CourierDetails from './Components/CourierDetails'
import ContentDetails from './Components/ContentDetails'

const Editor = (props) => {

  const {
    initialValues,
    onSubmit,
    onFormChange,
    validate,
  } = props
  
  return (

    <FormProvider
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmit}
      validate={validate}
      subscription={{}}
    >

      {({ handleSubmit, form }) => {
        
        formService(form)
        
        return (

          <Form
            onSubmit={handleSubmit}
            autoComplete='off'
            layout='vertical'
          >

            <FormSpy
              subscription={{ values: true, pristine: true, submitting: true }}
              onChange={onFormChange}
            />

            <div className={styles.wrapper}>

              <Module className={styles.details}>

                <Module.Header title='Details' />

                <PrimaryDetails />

              </Module>

              <div style={{ width: 20 }} />

              <Module className={styles.details}>

                <Module.Header title='Courier' />

                <CourierDetails />

              </Module>

              <div style={{ width: 20 }} />

              <Module className={styles.details}>

                <Module.Header title='Content' />

                <ContentDetails />

              </Module>

            </div>

          </Form>

        )
      
      }}
    
    </FormProvider>

  )

}

Editor.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
}

export default Editor
