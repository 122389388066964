import modalProps from '../modalProps'
import initializeList from '../Components/List/Services/initialize'

export default (props) => (dispatch) => {

  modalProps(props)
  
  dispatch(initializeList())
  
}
