import { resetInitialized } from '../State/initialized'
import { resetConsignment } from '../State/consignment'
import resetEditor from '../Components/Editor/Services/reset'
import resetGoodsOutRecords from '../Components/GoodsOutRecords/Services/reset'

export default () => async (dispatch) => {

  dispatch(resetInitialized())
  dispatch(resetConsignment())

  dispatch(resetEditor())
  dispatch(resetGoodsOutRecords())
  
}
