import { openCourierRateModal } from '../../../State/courierRateModal'

export default (record) => (dispatch, getState) => {

  dispatch(openCourierRateModal({
    id: record.id,
    courierId: getState().screens.Courier.courier.id,
  }))

}
