import screenProps from '../../../screenProps'
import { openGoodsOutRecordModal } from '../../../State/goodsOutRecordModal'

export default () => (dispatch) => {

  const { id } = screenProps().match.params

  dispatch(openGoodsOutRecordModal({
    salesOrderId: id,
    salesOrderItemIds: null,
  }))

}
