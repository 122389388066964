import React from 'react'
import PropTypes from 'prop-types'
import { Avatar as AntAvatar } from 'antd'
import styles from './Avatar.css'

const Avatar = (props) => {

  const {
    user
  } = props

  const initials = [
    user.first_name,
    user.last_name,
  ].filter(string => string).map(string => string.charAt(0)).join('')

  return (

    <AntAvatar className={styles.avatar} size="large">
      {initials}
    </AntAvatar>
    
  )
}

Avatar.propTypes = {
  user: PropTypes.object.isRequired,
}

export default Avatar