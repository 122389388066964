import { defer } from 'lodash'
import modalProps from '../../../modalProps'
import formService from '../formService'
import { getSupplier } from '../../../../../Api/suppliers'
import { handleException } from 'Traqa/Packages'

export default () => async () => {

  try {

    const { supplierId } = modalProps()

    if (supplierId) {

      const response = await getSupplier({ id: supplierId })

      const supplier = response.data.data

      const initialValues = {
        name: supplier.name,
        telephone: supplier.telephone,
        email: supplier.email,
        country_code: supplier.country_code,
      }
    
      defer(() => formService().initialize(initialValues))

    } else {

      const initialValues = {}
    
      defer(() => formService().initialize(initialValues))

    }

  } catch (error) {

    handleException(error)
  }

}