import { get, trimStart } from 'lodash'

const getTeamUrl = (path, prefix = 'app') => {
  
  let url = `/${prefix}/teams/${get(window, 'app.state.currentTeam.slug', '')}`
  
  if (path && trimStart(path, '/')) {
    
    url = `${url}/${path}`
  }
  
  return url
}

export default getTeamUrl
