import screenProps from '../screenProps'
import initialize from '../Services/initialize'

export default (props) => (dispatch) => {

  screenProps(props)

  dispatch(initialize())
  
}
