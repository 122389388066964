import { openCustomerModal, closeCustomerModal } from '../State/customerModal'
import fetchCustomersTable from '../Components/CustomersTable/Services/fetch'

export default (customer = undefined, action = undefined) => (dispatch) => {

  if (customer) {
    dispatch(fetchCustomersTable())
  }

  if (customer && action === 'create') {
    dispatch(openCustomerModal({ customerId: customer.id }))
  } else {
    dispatch(closeCustomerModal())
  }
  
}