import { combineReducers } from "redux"

import { reducer as createAssemblyModal } from "./State/createAssemblyModal"

import Table from './Components/Table/reducer'

export default combineReducers({
  createAssemblyModal,
  Table,
})
