import { createAction, handleActions } from 'redux-actions'

const openTransactionsDrawer = createAction('SCREENS/RECONCILIATION/OPEN_TRANSACTIONS_DRAWER')
const closeTransactionsDrawer = createAction('SCREENS/RECONCILIATION/CLOSE_TRANSACTIONS_DRAWER')

const defaultState = {
  show: false,
  title: null,
  subtitle: null,
  withProductColumn: false,
  params: {},
}

const reducer = handleActions(
  {
    [openTransactionsDrawer]: (state, action) => Object.assign({}, state, {
      show: true,
      title: action.payload.title,
      subtitle: action.payload.subtitle,
      withProductColumn: action.payload.withProductColumn,
      params: action.payload.params,
    }),
    [closeTransactionsDrawer]: () => Object.assign({}, defaultState),
  },
  defaultState
)

export { openTransactionsDrawer, closeTransactionsDrawer, reducer }
