import { connect } from "react-redux"

import Component from "./Component"

import componentDidMount from "./Handlers/componentDidMount"
import onClickNew from "./Handlers/onClickNew"
import onCloseCreateAssemblyModal from "./Handlers/onCloseCreateAssemblyModal"

const mapStateToProps = (state) => ({
  createAssemblyModal: state.screens.Assemblies.createAssemblyModal,
})

const actionCreators = {
  componentDidMount,
  onClickNew,
  onCloseCreateAssemblyModal,
}

export default connect(mapStateToProps, actionCreators)(Component)
