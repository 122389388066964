import React from 'react'
import PropTypes from 'prop-types'
import { Module } from 'Traqa/Packages'
import PlasticTaxRateModal from '../../../../Modals/PlasticTaxRate'

import Controls from './Components/Controls'
import Table from './Components/Table'
import Footer from './Components/Footer'

const Report = props => {

  const {
    plasticTaxRateModal,
    onClosePlasticTaxRateModal,
  } = props

  return (

    <Module>

      <Controls />

      <Table />

      <Footer />

      {plasticTaxRateModal.show && (
        <PlasticTaxRateModal
          {...plasticTaxRateModal}
          onClose={onClosePlasticTaxRateModal}
        />
      )}

    </Module>

  )

}

Report.propTypes = {
  plasticTaxRateModal: PropTypes.object.isRequired,
  onClosePlasticTaxRateModal: PropTypes.func.isRequired,
}

export default Report
