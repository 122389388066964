import screenProps from '../screenProps'
import { getTransfer } from '../../../Api/transfers'
import { setTransfer } from '../State/transfer'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    if (id === 'new') return

    const response = await getTransfer({ id })

    dispatch(setTransfer(response.data.data))

  } catch (error) {

    handleException(error)

  }
}