import { createAction, handleActions } from 'redux-actions'

const openCourierChargeModal = createAction('SCREENS/COURIER/OPEN_COURIER_CHARGE_MODAL')
const closeCourierChargeModal = createAction('SCREENS/COURIER/CLOSE_COURIER_CHARGE_MODAL')

const defaultState = {
  show: false,
  id: null,
  courierId: null,
}

const reducer = handleActions(
  {
    [openCourierChargeModal]: (state, action) => Object.assign({}, state, {
      show: true,
      id: action.payload.id,
      courierId: action.payload.courierId,
    }),
    [closeCourierChargeModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openCourierChargeModal, closeCourierChargeModal, reducer }
