import modalProps from '../../../modalProps'
import formService from '../formService'
import { getGoodsInRecord } from '../../../../../Api/goodsInRecords'
import { handleException } from 'Traqa/Packages'
import moment from 'moment'
import { defer } from 'lodash'
import { getUser } from 'Traqa/Helpers'

export default () => async () => {

  try {

    const { id } = modalProps()

    if (id) {

      const response = await getGoodsInRecord({ id })

      const goodsInRecord = response.data.data

      const initialValues = {
        invoice_no: goodsInRecord.invoice_no,
        supplier_id: goodsInRecord.supplier_id,
        stock_location_id: goodsInRecord.stock_location_id,
        user_id: goodsInRecord.user_id,
        date_time: moment(goodsInRecord.date_time),
        commercial_value_usd: goodsInRecord.commercial_value_usd,
        on_the_water: !!goodsInRecord.on_the_water,
      }

      defer(() => formService().initialize(initialValues))

    } else {

      const initialValues = {
        user_id: getUser().id,
        date_time: null,
        on_the_water: false,
      }

      defer(() => formService().initialize(initialValues))

    }

  } catch (error) {

    handleException(error)
    
  }

}
