import { createAction, handleActions } from 'redux-actions'

const setHoldingControls = createAction('SCREENS/DASHBOARD/COLUMN_CHARTS/HOLDING/SET_HOLDING_CONTROLS')
const resetHoldingControls = createAction('SCREENS/DASHBOARD/COLUMN_CHARTS/HOLDING/RESET_HOLDING_CONTROLS')

const defaultState = {
  type: 'value',
  stockLocationId: undefined,
  productCategoryId: undefined,
  zeroRunRate: false,
  sortDirection: 'desc',
}

const reducer = handleActions(
  {
    [setHoldingControls]: (state, action) => ({ ...state, ...action.payload }),
    [resetHoldingControls]: () => defaultState,
  },
  defaultState,
)

export { setHoldingControls, resetHoldingControls, reducer }
