import { createAction, handleActions } from 'redux-actions'

const openImageViewerModal = createAction('SCREENS/PRODUCT_EDITOR/OPEN_IMAGE_VIEWER_MODAL')
const closeImageViewerModal = createAction('SCREENS/PRODUCT_EDITOR/CLOSE_IMAGE_VIEWER_MODAL')

const defaultState = {
  show: false,
  modelType: 'Product',
  modelId: null,
}

const reducer = handleActions(
  {
    [openImageViewerModal]: (state, action) => Object.assign({}, state, {
      show: true,
      modelId: action.payload.modelId,
    }),
    [closeImageViewerModal]: () => Object.assign({}, defaultState),
  },
  defaultState,
)

export { openImageViewerModal, closeImageViewerModal, reducer }
