import { message } from 'antd'
import { archiveSupplier } from '../../../../../Api/suppliers'
import fetch from './fetch'
import fetchSuppliersCore from '../../../../../Core/Services/fetchSuppliers'
import { handleException } from 'Traqa/Packages'

export default (supplier) => async (dispatch) => {

  try {

    await archiveSupplier({ id: supplier.id })

    await dispatch(fetch())

    message.success(`Archived Supplier "${supplier.name}"`)
    
    dispatch(fetchSuppliersCore({ force: true }))

  } catch (error) {

    handleException(error)

  }
}