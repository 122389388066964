import { defer } from 'lodash'
import modalProps from '../../../modalProps'
import { findCourierZone } from '../../../../../Api/courierZones'
import formService from '../formService'
import { handleException } from 'Traqa/Packages'

export default () => async () => {

  const { id } = modalProps()

  if (!id) return

  const params = {
    include: 'courier_zone',
  }

  try {

    const response = await findCourierZone({ id, params })

    const courierZone = response.data.data

    const initialValues = {
      code: courierZone.code,
      parent_courier_zone_code: courierZone.courier_zone.code,
    }

    defer(() => formService().initialize(initialValues))

  } catch (error) {

    handleException(error)

  }

}
