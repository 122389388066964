import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Field, FormSpy } from 'react-final-form'
import { Form, Row, Col, Select, Input, InputNumber, Checkbox, DatePicker, Button, Popover, Tag } from 'antd'
import { getTeamUrl } from 'Traqa/Helpers'
import recycleIcon from '../../../../Resources/recycleIcon'
import styles from './Item.css'

const Item = (props) => {

  const {
    field,
    index,
    guid,
    purchaseOrderId,
    isNew,
    isSelected,
    isImport,
    products,
    purchaseOrders,
    onToggle,
  } = props

  const truncate = (input) => input.length > 50 ? `${input.substring(0, 50)}...` : input
  
  return (

    <FormSpy subscription={{ submitting: true }}>

      {({ submitting }) => (

        <div>

          <Row gutter={8}>

            <Col span={1}>

              <Row type="flex" align="middle" className={classNames(styles.checkboxWrapper, {[styles.firstRow]: index === 0})}>

                <Col span={12}>

                  <Checkbox
                    checked={isSelected}
                    onChange={() => onToggle(guid)}
                    disabled={submitting}
                  />
                
                </Col>

                <Col span={12}>

                  <span className={styles.itemNumber}>{index + 1}</span>
                
                </Col>
              
              </Row>

            </Col>

            <Col span={9}>

              <Field name={`${field}.product_id`} allowNull>
                {({ input, meta }) => (
                  <Form.Item
                    label={index === 0 ? 'Product' : undefined}
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Select
                      {...input}
                      disabled={submitting}
                      optionFilterProp="children"
                      showSearch
                      allowClear
                      dropdownMatchSelectWidth={false}
                    >

                      <Select.OptGroup label="Products">
                        {products.filter(product => !product.archived_at).map(product => (
                          <Select.Option key={product.id} value={product.id}>
                            {truncate(`${product.code} - ${product.name}`)}
                            <Tag color='volcano' style={{ marginLeft: 5 }}>{product.form}</Tag>
                          </Select.Option>
                        ))}
                      </Select.OptGroup>

                      {!isNew && (

                        <Select.OptGroup label="Archived">
                          {products.filter(product => product.archived_at).map(product => (
                            <Select.Option key={product.id} value={product.id} style={{ color: '#999' }}>
                              {truncate(`${product.code} - ${product.name}`)}
                              <Tag color='volcano' style={{ marginLeft: 5 }}>{product.form}</Tag>
                            </Select.Option>
                          ))}
                        </Select.OptGroup>

                      )}

                    </Select>
                  </Form.Item>
                )}
              </Field>

            </Col>

            <Col span={4}>

              <Field name={`${field}.batch_no`}>
                {({ input, meta }) => (
                  <Form.Item
                    label={index === 0 ? 'Batch No.' : undefined}
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Input
                      {...input}
                      disabled={submitting}
                    />
                  </Form.Item>
                )}
              </Field>

            </Col>

            <Col span={3}>

              <Field name={`${field}.expiry_date`} allowNull>
                {({ input, meta }) => (
                  <Form.Item
                    label={index === 0 ? 'Expiry Date' : undefined}
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <DatePicker
                      {...input}
                      disabled={submitting}
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                )}
              </Field>

            </Col>

            <Col span={2}>

              <Field name={`${field}.quantity`}>
                {({ input, meta }) => (
                  <Form.Item
                    label={index === 0 ? 'Qty' : undefined}
                    required
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <InputNumber
                      {...input}
                      disabled={submitting}
                      min={0}
                      step={1}
                      precision={0}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )}
              </Field>

            </Col>

            {isImport && (
              
              <Col span={1}>

                <FormSpy subscription={{ values: true }}>
                  {({ values }) => {
                    const goodsInRecordItem = values.goods_in_record_items[index]
                    return (
                      <Popover
                        trigger={['hover']}
                        content={(
                          <div style={{ width: 140 }}>
                            <Field name={`${field}.blend`}>
                              {({ input, meta }) => (
                                <Form.Item
                                  label='Blend'
                                  colon={false}
                                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                                >
                                  <Input
                                    {...input}
                                    disabled={submitting}
                                  />
                                </Form.Item>
                              )}
                            </Field>
                            <Field name={`${field}.recycled_perc`}>
                              {({ input, meta }) => (
                                <Form.Item
                                  label='Recycled (%)'
                                  colon={false}
                                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                                >
                                  <InputNumber
                                    {...input}
                                    disabled={submitting}
                                    min={0}
                                    max={100}
                                    step={1}
                                    precision={2}
                                    style={{ width: '100%' }}
                                  />
                                </Form.Item>
                              )}
                            </Field>
                            <Field name={`${field}.weight_kgs`}>
                              {({ input, meta }) => (
                                <Form.Item
                                  label='Net (KG)'
                                  colon={false}
                                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                                >
                                  <InputNumber
                                    {...input}
                                    disabled={submitting}
                                    min={0}
                                    step={1}
                                    precision={2}
                                    style={{ width: '100%' }}
                                  />
                                </Form.Item>
                              )}
                            </Field>
                          </div>
                        )}
                      >
                        <Button
                          type={(goodsInRecordItem.blend || goodsInRecordItem.recycled_perc || goodsInRecordItem.weight_kgs) ? 'primary' : 'default'}
                          style={{ marginTop: index === 0 ? 29 : 0, width: '100%' }}
                        >
                          {recycleIcon}
                        </Button>
                      </Popover>
                    )
                  }}
                </FormSpy>

              </Col>

            )}

            <Col span={3}>

              <Field name={`${field}.purchase_order_id`}>
                {({ input, meta }) => (
                  <Form.Item
                    label={index === 0 ? 'PO No.' : undefined}
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Select
                      {...input}
                      disabled={submitting}
                      optionFilterProp="children"
                      showSearch
                      allowClear
                    >

                      {purchaseOrders.map(purchaseOrder => (
                        <Select.Option key={purchaseOrder.id} value={purchaseOrder.id}>
                          {purchaseOrder.number}
                        </Select.Option>
                      ))}

                    </Select>
                  </Form.Item>
                )}
              </Field>

            </Col>

            <Col span={1} style={{ marginTop: index === 0 ? '34px' : '5px' }}>

              {purchaseOrderId && (

                <NavLink to={getTeamUrl(`purchase_orders/${purchaseOrderId}`)}>
                  &#x2192; PO
                </NavLink>

              )}

            </Col>

          </Row>

        </div>
            
      )}

    </FormSpy>

  )
}

Item.propTypes = {
  field: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  guid: PropTypes.string.isRequired,
  productId: PropTypes.any,
  purchaseOrderId: PropTypes.any,
  isNew: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
  purchaseOrders: PropTypes.array.isRequired,
  onToggle: PropTypes.func.isRequired,
}

Item.defaultProps = {
  productId: null,
}

export default Item