import React from 'react'
import PropTypes from 'prop-types'
import { Input, Radio } from 'antd'
import { Module } from 'Traqa/Packages'
import styles from './Header.css'

const Header = (props) => {

  const {
    activeTab,
    searchTerm,
    onChangeTab,
    onChangeSearch,
    onSearch,
  } = props

  const renderLeft = () => (

    <Radio.Group onChange={onChangeTab} value={activeTab}>
      <Radio.Button value="admin" className={styles.tab}>Admin</Radio.Button>
      <Radio.Button value="member" className={styles.tab}>Member</Radio.Button>
    </Radio.Group>

  )

  const renderRight = () => (

    <Input.Search
      value={searchTerm}
      onChange={onChangeSearch}
      onSearch={onSearch}
      placeholder="Search"
      style={{ width: 200 }}
    />

  )
  
  return (

    <Module.Header
      renderLeft={renderLeft}
      renderRight={renderRight}
    />

  )
}

Header.propTypes = {
  activeTab: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
}

export default Header