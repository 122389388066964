import { message } from 'antd'
import { push } from 'connected-react-router'
import { deleteProduct } from '../../../Api/products'
import { getTeamUrl } from 'Traqa/Helpers'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch, getState) => {

  try {

    const { product } = getState().screens.Product

    await deleteProduct({ id: product.id })

    message.success(`Deleted Product "${product.code || product.name}"`)

    dispatch(push(getTeamUrl(`products`)))

  } catch (error) {

    handleException(error)

  }
}