import { connect } from "react-redux"
import MediaItems from "./MediaItems"
import upload from '../../Core/Services/upload'

import onChange from './Handlers/onChange'

const mapStateToProps = () => ({
  
})

const actionCreators = {
  upload,
  onChange,
}

export default connect(mapStateToProps, actionCreators)(MediaItems)
