import { getTeamUrl } from 'Traqa/Helpers'

export default () => async (dispatch, getState) => {

  const { salesOrder } = getState().screens.SalesOrder

  const path = `sales_orders/${salesOrder.id}/export`

  window.location = getTeamUrl(path, 'exports')

}
