import screenProps from '../screenProps'
import { getAdjustment } from '../../../Api/adjustments'
import { setAdjustment } from '../State/adjustment'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch) => {

  try {

    const { id } = screenProps().match.params

    if (id === 'new') return

    const response = await getAdjustment({ id })

    dispatch(setAdjustment(response.data.data))

  } catch (error) {

    handleException(error)

  }
}