import moment from 'moment'
import modalProps from '../../../modalProps'
import { getConsignments } from '../../../../../Api/consignments'
import { setData } from '../State/data'
import { handleException } from 'Traqa/Packages'

export default () => async (dispatch) => {

  const { goodsOutRecord } = modalProps()

  const params = {
    'filter[customer_id]': goodsOutRecord.customer_id,
    'filter[date]': moment(goodsOutRecord.date_time).format('YYYY-MM-DD'),
    include: 'customer,customer_site,courier,courier_service',
  }

  try {

    const response = await getConsignments({ params })
    
    dispatch(setData(response.data.data))

  } catch (error) {

    handleException(error)

  }

}
