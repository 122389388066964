import { connect } from 'react-redux'
import Report from './Report'

import onClosePlasticTaxRateModal from './Handlers/onClosePlasticTaxRateModal'

const mapStateToProps = (state) => ({
  plasticTaxRateModal: state.screens.PlasticTax.Report.plasticTaxRateModal,
})

const actionCreators = {
  onClosePlasticTaxRateModal,
}

export default connect(mapStateToProps, actionCreators)(Report)
