import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, FormSpy, Field } from 'react-final-form'
import { Form, Input, Select, DatePicker, Row, Col, Button } from 'antd'
import countryList from 'react-select-country-list'
import { Module, LinkButton, ExposeFormToHelper } from 'Traqa/Packages'
import formHelper from './formHelper'
import styles from './Advanced.css'

const Advanced = (props) => {

  const {
    advanced,
    suppliers,
    stockLocations,
    onClickClose,
    onFormChange,
    onSubmit,
  } = props

  const renderHeaderRight = () => (

    <LinkButton
      type="default"
      icon="close"
      onClick={onClickClose}
      style={{ fontSize: 18 }}
    />

  )

  return (

    <Module.Section
      title="Advanced Search"
      renderHeaderRight={renderHeaderRight}
      borderBottom
    >

      <FormProvider
        initialValues={advanced.values}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, submitting }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            autoComplete="off"
          >

            <ExposeFormToHelper helper={formHelper} />

            <FormSpy subscription={{ values: true }} onChange={onFormChange} />

            <Field name="type">
              {({ input }) => (
                <Form.Item label="Type">
                  <Select
                    {...input}
                    disabled={submitting}
                    allowClear
                  >
                    <Select.Option value="Week">
                      Week
                    </Select.Option>
                    <Select.Option value="Overdue">
                      Overdue
                    </Select.Option>
                    <Select.Option value="Speculated">
                      Scheduled
                    </Select.Option>
                    <Select.Option value="Expected">
                      Booked
                    </Select.Option>
                    <Select.Option value="Completed">
                      Received
                    </Select.Option>
                    <Select.Option value="Returned">
                      Returned
                    </Select.Option>
                    <Select.Option value="Draft">
                      Draft
                    </Select.Option>
                    <Select.Option value="All">
                      All
                    </Select.Option>
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name="keywords">
              {({ input }) => (
                <Form.Item label="Keywords">
                  <Input.Search
                    {...input}
                    disabled={submitting}
                    onSearch={handleSubmit}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name="countryCodes">
              {({ input }) => (
                <Form.Item label="Countries">
                  <Select
                    {...input}
                    disabled={submitting}
                    mode="multiple"
                    optionFilterProp="children"
                  >
                    {countryList().getData().map(({ label, value }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name="supplierIds">
              {({ input }) => (
                <Form.Item label="Suppliers">
                  <Select
                    {...input}
                    disabled={submitting}
                    mode="multiple"
                    optionFilterProp="children"
                  >
                    <Select.OptGroup label='Active'>
                      {suppliers.filter(supplier => !supplier.archived_at).map(supplier => (
                        <Select.Option key={supplier.id} value={supplier.id}>
                          {supplier.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                    <Select.OptGroup label='Archived'>
                      {suppliers.filter(supplier => !!supplier.archived_at).map(supplier => (
                        <Select.Option key={supplier.id} value={supplier.id} style={{ color: '#999' }}>
                          {supplier.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name="stockLocationIds">
              {({ input }) => (
                <Form.Item label="Stock Locations">
                  <Select
                    {...input}
                    disabled={submitting}
                    mode="multiple"
                    optionFilterProp="children"
                  >
                    <Select.OptGroup label='Active'>
                      {stockLocations.filter(stockLocation => !stockLocation.archived_at).map(stockLocation => (
                        <Select.Option key={stockLocation.id} value={stockLocation.id}>
                          {stockLocation.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                    <Select.OptGroup label='Archived'>
                      {stockLocations.filter(stockLocation => !!stockLocation.archived_at).map(stockLocation => (
                        <Select.Option key={stockLocation.id} value={stockLocation.id} style={{ color: '#999' }}>
                          {stockLocation.name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name="dateRange">
              {({ input }) => (
                <Form.Item label="Date">
                  <DatePicker.RangePicker
                    {...input}
                    disabled={submitting}
                    format="DD/MM/YYYY"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              )}
            </Field>

            <Row>
              <Col offset={7}>

                <Button
                  type="primary"
                  onClick={handleSubmit}
                >
                  Search
                </Button>

              </Col>
            </Row>

          </Form>
        
        )}
      
      </FormProvider>

    </Module.Section>

  )
}

Advanced.propTypes = {
  advanced: PropTypes.object.isRequired,
  suppliers: PropTypes.array.isRequired,
  stockLocations: PropTypes.array.isRequired,
  onClickClose: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default Advanced