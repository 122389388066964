import { combineReducers } from 'redux'

import { reducer as courierModal } from './State/courierModal'

import Table from './Components/Table/reducer'

export default combineReducers({
  courierModal,
  
  Table,
})
