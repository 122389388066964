import { connect } from 'react-redux'
import GoodsInRecords from './GoodsInRecords'

const mapStateToProps = (state) => ({
  data: state.screens.Dashboard.GoodsInRecords.data,
  isFetching: state.screens.Dashboard.GoodsInRecords.isFetching,
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(GoodsInRecords)
