import { message } from 'antd'
import { unarchiveCustomer } from '../../../../../Api/customers'
import fetch from './fetch'
import fetchCustomersCore from '../../../../../Core/Services/fetchCustomers'
import { handleException } from 'Traqa/Packages'

export default (customer) => async (dispatch) => {

  try {

    await unarchiveCustomer({ id: customer.id })

    await dispatch(fetch())

    message.success(`Unarchived Customer "${customer.name}"`)
    
    dispatch(fetchCustomersCore({ force: true }))

  } catch (error) {

    handleException(error)

  }
}