import { combineReducers } from 'redux'

import { reducer as initialized } from './State/initialized'
import { reducer as goodsInRecordModal } from './State/goodsInRecordModal'
import { reducer as addProductToGoodsInRecordModal } from './State/addProductToGoodsInRecordModal'
import { reducer as goodsInRecordItemModal } from './State/goodsInRecordItemModal'
import { reducer as goodsInRecordCommentsModal } from './State/goodsInRecordCommentsModal'

import Report from './Components/Report/reducer'

export default combineReducers({
  initialized,
  goodsInRecordModal,
  addProductToGoodsInRecordModal,
  goodsInRecordItemModal,
  goodsInRecordCommentsModal,

  Report,
})
