import { connect } from "react-redux"
import NotificationPopover from "./NotificationPopover"

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onVisibleChange from './Handlers/onVisibleChange'

const mapStateToProps = () => ({})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onVisibleChange,
}

export default connect(mapStateToProps, actionCreators)(NotificationPopover)
